import { useDelayedCallbacks } from './useDelayedCallbacks';

export const useDelayedHoverProps = (callback: () => void, delayMs: number = 100) => {
  const { handleMouseEnter, handleMouseLeave } = useDelayedCallbacks(callback, delayMs);

  return {
    onHoverIn: handleMouseEnter,
    onHoverOut: handleMouseLeave
  };
};

export default useDelayedHoverProps;
