import {
  MarkThreadAsSpamApiArg,
  MarkThreadAsSpamApiResponse
} from '../api/codegen';
import { MutationHandler, MutationHandlerProps } from './MutationHandler';
import { ThreadViewModel, setThreadViewModelInboxItemId, setThreadViewModelIsSpam } from '../view-models/ThreadViewModel';
import { typedKeys } from '../../infrastructure/global/typedKeys';
import { createThreadUpdatePatchesForThreadId } from './thread-patch-factory/createThreadUpdatePatchesForThreadId';
import { getInboxParams } from './utils/getInboxParams';
import { getSnoozedParams } from './utils/getSnoozedParams';
import { getCurrentChannelParams } from './utils/getCurrentChannelParams';

export class MarkThreadAsSpamMutationHandler extends MutationHandler<MarkThreadAsSpamApiArg, MarkThreadAsSpamApiResponse> {
  constructor(props: MutationHandlerProps<MarkThreadAsSpamApiArg, MarkThreadAsSpamApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: MarkThreadAsSpamApiArg) {
    return [
      this.createRemoveThreadInInboxPatch(patch),
      this.createRemoveThreadFromSnoozedFolderPatch(patch),
      ...this.createOtherPatches(patch),
      ...createThreadUpdatePatchesForThreadId(patch.threadId, this.updateThread),
    ];
  }

  private* createOtherPatches(patch: MarkThreadAsSpamApiArg) {
    const thread = this.getThreadInCurrentContext(patch.threadId);
    if (thread && thread.isUnread) {
      yield this.updateQueryData('getSelfAccount', undefined, (draftedAccount) => {
        draftedAccount.numberOfUnreadEvents -= 1;
        if (draftedAccount.numberOfUnreadEvents < 0) {
          draftedAccount.numberOfUnreadEvents = 0;
        }
        for (const inboxCategory of typedKeys(draftedAccount.numberOfUnreadEventsByCategory)) {
          if (this.isThreadInInboxCategory(thread.labels, inboxCategory)) {
            draftedAccount.numberOfUnreadEventsByCategory[inboxCategory] -= 1;
          }
          if ((draftedAccount.numberOfUnreadEventsByCategory[inboxCategory] ?? 0) < 0) {
            draftedAccount.numberOfUnreadEventsByCategory[inboxCategory] = 0;
          }
        }
      });
    }

    if (thread) {
      yield this.updateQueryData('getThread', { threadId: thread.id }, (draftedThread) => {
        setThreadViewModelInboxItemId(draftedThread, undefined);
      });
    }
  }

  private updateThread(thread: ThreadViewModel) {
    setThreadViewModelIsSpam(thread, true);
    setThreadViewModelInboxItemId(thread, undefined);
  }

  private createRemoveThreadInInboxPatch(patch: MarkThreadAsSpamApiArg) {
    return this.updateQueryData('getSelfInboxEvents', getInboxParams()!, (draftedInboxItems) => {
      const itemIndex = draftedInboxItems.inboxItems.findIndex((item) => item.thread.id === patch.threadId);

      if (itemIndex !== -1) {
        draftedInboxItems.inboxItems.splice(itemIndex, 1);
      }
    });
  }

  private createRemoveThreadFromSnoozedFolderPatch(patch: MarkThreadAsSpamApiArg) {
    return this.updateQueryData('getSelfSnoozedThreads', getSnoozedParams()!, (draftedThreads) => {
      const itemIndex = draftedThreads.threads.findIndex((item) => item.id === patch.threadId);

      if (itemIndex !== -1) {
        draftedThreads.threads.splice(itemIndex, 1);
      }
    });
  }

  protected generateInvalidationTags(arg: MarkThreadAsSpamApiArg) {
    return [
      {
        type: 'Thread' as const,
        id: arg.threadId,
        schedule: {
          delayMs: 10000,
          uniqueKey: `ChannelThreadListManagement-${getCurrentChannelParams()}`,
        }
      },
      {
        type: 'SelfAccount' as const,
        schedule: {
          delayMs: 5000,
          uniqueKey: 'InboxUnreadThreadCount',
        }
      },
      {
        schedule: {
          delayMs: 5000,
          uniqueKey: 'InboxRemoveEvents',
        },
        type: 'InboxEventsList' as const,
      }
    ];
  }
}
