import { memo } from 'react';
import isEqual from 'react-fast-compare';
import { MutationInitiator } from '@/adapters/mutation-cancellation/mutationCancellation';
import { ThreadViewModel } from '@/adapters/view-models/ThreadViewModel';
import { ThreadListItemPressableContainer } from './ThreadListItemPressableContainer';
import { ThreadListItemSnippetContainer, ThreadListItemSnippetContainerProps } from './ThreadListItemSnippetContainer';

export type ChangeUnreadStatusCallback = (thread: ThreadViewModel, status: 'read' | 'unread', initiator?: MutationInitiator) => void;
export type MessageSnippetThreadListItemProps = ThreadListItemSnippetContainerProps;

function SnippetThreadListItem(props: MessageSnippetThreadListItemProps) {
  const { thread, selectThreadCallback: threadSelectedCallback } = props;

  return (
    <ThreadListItemPressableContainer thread={thread} selectThreadCallback={threadSelectedCallback} threadHoveredCallback={props.threadHoveredCallback}>
      <ThreadListItemSnippetContainer {...props} />
    </ThreadListItemPressableContainer>
  );
}

// when you mark read/unread, relevantChannels include unreadCount and break the memoization
export default memo(SnippetThreadListItem, isEqual);
