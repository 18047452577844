// @ts-ignore
import readDate from 'date.js';
import {
  Button,
  Center,
  HStack, Text
} from 'native-base';
import { useState } from 'react';
import { addSeconds } from 'date-fns';
import { MenuDivider } from '../../menu/MenuDivider';
import { TimePicker } from './time-picker/TimePicker';
import { useForceDisabledStateRerenderAtInterval } from './useDisableStateRerenderAtInterval';
import { DatePicker } from './date-picker/DatePicker';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';

type DateTimePickerProps = {
  isPickerOpen: boolean;
  minDelayBetweenNowAndSelectedDate?: number;
  onSubmitDate: (date: Date) => void;
  onCancel: () => void;
  activeDate?: {
    date: Date;
    label: string;
  }
};

export function DateTimePicker(props: DateTimePickerProps) {
  const defaultSelectedDate = (props.activeDate?.date ?? readDate('tomorrow morning') as Date);
  const [selectedDate, setSelectedDate] = useState<Date>(defaultSelectedDate);
  const earliestSelectableDate = props.minDelayBetweenNowAndSelectedDate ? addSeconds(new Date(), props.minDelayBetweenNowAndSelectedDate) : undefined;
  const confirmButtonDisabled = earliestSelectableDate ? selectedDate < earliestSelectableDate : false;

  const enableRerenderAtRegularInterval = !!earliestSelectableDate && !confirmButtonDisabled;
  useForceDisabledStateRerenderAtInterval(10000, enableRerenderAtRegularInterval);

  return (
    <>
      <Center mt={2}>
        <DatePicker
          activeDate={!props.activeDate ? undefined : {
            date: props.activeDate.date,
            label: props.activeDate.label
          }}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          minDate={earliestSelectableDate}
        />
      </Center>
      <HStack alignItems="center" mx={6} mt={2} mb={3}>
        <Text bold flexGrow={1} flexShrink={1}>Time</Text>
        <TimePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
      </HStack>
      <MenuDivider />
      <HStack justifyContent="flex-end" mx={6} mt={2} mb={1} space={2}>
        <Button px={4} variant="ghost" colorScheme="blueGray" onPress={() => props.onCancel()}>
          Cancel
        </Button>
        <Button
          px={4}
          isDisabled={confirmButtonDisabled}
          onPress={() => props.onSubmitDate(selectedDate)}
        >
          Done
        </Button>
      </HStack>
    </>
  );
}
