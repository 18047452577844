import { RemoveChannelParticipantsApiArg, RemoveChannelParticipantsApiResponse } from '../api/codegen';
import { MutationHandler, MutationHandlerProps, PatchWrapper } from './MutationHandler';
import { PictureViewModel } from '../view-models/PictureViewModel';
import { ChannelsViewModel } from '../view-models/ChannelsViewModel';
import { ChannelViewModel } from '../view-models/ChannelViewModel';
import { getCurrentChannelParams } from './utils/getCurrentChannelParams';

export class RemoveChannelParticipantsMutationHandler extends MutationHandler<RemoveChannelParticipantsApiArg, RemoveChannelParticipantsApiResponse> {
  constructor(props: MutationHandlerProps<RemoveChannelParticipantsApiArg, RemoveChannelParticipantsApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: RemoveChannelParticipantsApiArg) {
    const patches: PatchWrapper<any>[] = [];
    const channelPatch = this.createChannelPatch(patch);
    if (channelPatch !== undefined) {
      patches.push(channelPatch);
    }

    patches.push(...this.createSelfChannelsPatch(patch));

    return patches;
  }

  private createChannelPatch(patch: RemoveChannelParticipantsApiArg) {
    if (patch.channelId !== getCurrentChannelParams()?.channelId) {
      return;
    }

    return this.updateQueryData('getChannelParticipants', getCurrentChannelParams()!, (draftedParticipants) => {
      this.updateParticipants(draftedParticipants, patch);
    });
  }

  private createSelfChannelsPatch(patch: RemoveChannelParticipantsApiArg) {
    const selfUser = this.getSelfUser();
    const isSelfRemoved = patch.body.some((userId) => userId === selfUser?.id);
    const patches: PatchWrapper<any>[] = [];

    if (isSelfRemoved) {
      patches.push(this.updateQueryData('getSelfChannels', undefined, (draftedChannels) => {
        this.removeChannelsFromList(draftedChannels, patch);
      }));

      patches.push(this.updateQueryData('getOrganizationChannels', { organizationId: this.getSelfOrganization()?.id! }, (draft) => {
        this.removeChannelsFromList(draft, patch, (channel) => !channel.isPublic);
      }));
    }

    return patches;
  }

  private removeChannelsFromList(draftedChannels: ChannelsViewModel, patch: RemoveChannelParticipantsApiArg, validator: (channel: ChannelViewModel) => boolean = () => true) {
    for (let i = draftedChannels.channels.length - 1; i >= 0; i--) {
      const channel = draftedChannels.channels[i];
      if (channel.id === patch.channelId && validator(channel)) {
        draftedChannels.channels.splice(i, 1);
      }
    }
  }

  private updateParticipants(participants: PictureViewModel[], patch: RemoveChannelParticipantsApiArg): void {
    const userIdsToRemove = new Set(patch.body);

    for (let i = participants.length - 1; i >= 0; i--) {
      const participant = participants[i];
      if (participant.userId && userIdsToRemove.has(participant.userId)) {
        participants.splice(i, 1);
      }
    }
  }

  protected generateInvalidationTags(_arg: RemoveChannelParticipantsApiArg) {
    return [];
  }
}
