import { apiClient, useGetChannelQuery } from '../../../../adapters/api';
import { CHANNEL_QUERY_OPTIONS } from '../../../api/query-parameters';

export const useGetChannel = (channelId: string | undefined) => {
  const channel = useGetChannelQuery({ channelId: channelId ?? '' }, { ...CHANNEL_QUERY_OPTIONS, skip: !channelId });

  return channel;
};

export const usePrefetchChannel = () => apiClient.usePrefetch('getChannel');
