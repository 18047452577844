import {
  Text, HStack, Icon
} from 'native-base';
import { InterfaceHStackProps } from 'native-base/lib/typescript/components/primitives/Stack/HStack';
import { ChannelViewModel, CHANNEL_ICON_SETS } from '../../adapters/view-models/ChannelViewModel';
import { useThreadListStoreSelectorMaybe } from './thread-list/context';

type ChannelBadgesListProps = InterfaceHStackProps & {
  channels: ChannelViewModel[];
  badgeProps?: InterfaceHStackProps;
  threadId?: string;
};

export function ChannelBadgesList({
  channels, badgeProps, threadId, ...rest
}: ChannelBadgesListProps) {
  const isFocusedOrSelectedInList = useThreadListStoreSelectorMaybe((state) => {
    if (state && threadId) {
      return state.focusedId === threadId || state.selectedIds.includes(threadId);
    }
  });
  return (
    <HStack space={1} {...rest} flexShrink={1}>
      {channels.map((channel: ChannelViewModel) => (
        <HStack
          key={channel.id}
          flexShrink={1}
          borderRadius={10}
          bgColor={isFocusedOrSelectedInList ? 'primary.100' : 'muted.100'}
          px={2}
          space={0.5}
          {...badgeProps}
        >
          <Icon
            alignSelf="center"
            name={CHANNEL_ICON_SETS[channel.iconSetName].default.name}
            as={CHANNEL_ICON_SETS[channel.iconSetName].default.collection}
            size="xs"
            color="dark.600"
            flexShrink={1}
          />
          <Text fontSize="xs" color={isFocusedOrSelectedInList ? 'primary.800' : 'dark.600'} flexShrink={1} numberOfLines={1}>
            {channel.name}
          </Text>
        </HStack>
      ))}
    </HStack>
  );
}
