import React from 'react';

import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import {
  Button, HStack, Icon, useTheme
} from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { SideMenuButtonProps } from './SideMenuButton';
import { ComprehensiveThreadViewModel } from '../../../adapters/view-models/ThreadViewModel';
import { PopoverMenuButton } from './PopoverMenuButton';

export type ThreadDueDateSideMenuButtonProps = {
  paddingLeft: SideMenuButtonProps['paddingLeft'];
  open: SideMenuButtonProps['open'];
  animationDuration: SideMenuButtonProps['animationDuration'];
  thread: ComprehensiveThreadViewModel;
  updateThreadDueDateCallback: (threadId: string, dueDate: Date | null) => void;
};

const iconForDueDate = (date: Date | null) => {
  if (date === null) return 'calendar-today';
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  if (date < today) return 'calendar-alert';
  return 'calendar-clock';
};

export function ThreadDueDateSideMenuButton(props: ThreadDueDateSideMenuButtonProps) {
  const dueDate = props.thread.dueDate ? new Date(props.thread.dueDate) : null;
  const buttonTitle = dueDate !== null
    ? dueDate.toDateString()
    : 'Set due date';

  const theme = useTheme();

  return (
    <PopoverMenuButton
      paddingLeft={props.paddingLeft}
      open={props.open}
      animationDuration={props.animationDuration}
      buttonTitle={buttonTitle}
      icon={(<Icon as={MaterialCommunityIcons} name={iconForDueDate(dueDate)} color="dark.700" />)}
      isDisabled={props.thread.isTrashed}
    >
      <style>
        {`.rdp {
            --rdp-background-color: ${(theme.colors.dark as Record<number, string>)[5]};
            --rdp-accent-color: ${theme.colors.primary[400]};
            --rdp-cell-size: 32px;
            --rdp-selected-color: ${theme.colors.dark[700]};
            .rdp-day, .rdp-nav_button {
              border-radius: 8px;
            }
            .rdp-day_selected {
              font-weight: 900;
              font-family: ${theme.fontConfig.InterTight[700].normal};
            }
        }`}
      </style>
      <DayPicker
        mode="single"
        selected={dueDate ?? undefined}
        onSelect={(date) => props.updateThreadDueDateCallback(props.thread.id, date || null)}
        style={{
          fontFamily: theme.fontConfig.InterTight[400].normal,
          fontSize: theme.fontSizes.xs,
          marginTop: '0.5rem',
          marginBottom: 0,
          marginLeft: '1rem',
          marginRight: '1rem',
        }}
        footer={(
          <HStack justifyContent="flex-end">
            <Button
              isDisabled={props.thread.dueDate === null}
              onPress={() => props.updateThreadDueDateCallback(props.thread.id, null)}
              colorScheme="blueGray"
              variant="ghost"
              size="sm"
            >
              Clear due date
            </Button>
          </HStack>
)}
        showOutsideDays
        ISOWeek
      />
    </PopoverMenuButton>
  );
}
