import { ScrollArea as MantineScrollArea } from '@mantine/core';
import { ScrollAreaProps } from './types';
import './ScrollArea.web.css';

export const ScrollArea = ({ children }: ScrollAreaProps) => {
  return (
    <MantineScrollArea
      type="scroll"
      scrollbarSize={10}
      scrollbars="y"
      classNames={{
        viewport: 'scroll-area-viewport',
      }}
    >
      {children}
    </MantineScrollArea>
  );
};
