import { Toast } from 'native-base';
import { useCallback } from 'react';
import { buildThreadArchiveMutationDescription } from '../../UndoMutationDescriptions';
import { MutationInitiator, useWithMutationMetadata } from '@/adapters/mutation-cancellation/mutationCancellation';
import { useRemoveEventFromInboxMutation } from '@/adapters/api/codegen';
import { TEMPORARY_ID_PENDING_REFRESH } from '@/adapters/mutation-handlers/model-factories/mutation-constants';
import { ComprehensiveThreadOrInboxItemViewModel } from '@/adapters/view-models/InboxItemViewModel';

export function useArchiveThreadCallback() {
  const [archiveThread] = useWithMutationMetadata(useRemoveEventFromInboxMutation());

  const archiveThreadCallback = useCallback(async (item: ComprehensiveThreadOrInboxItemViewModel, initiator?: MutationInitiator) => {
    const inboxItemId = 'thread' in item ? item.id : item.eventId!;

    if (inboxItemId == TEMPORARY_ID_PENDING_REFRESH) {
      Toast.show({
        description: 'This thread is still being added to your inbox, please try again in a few seconds.',
        duration: 3000,
      });
    } else {
      await archiveThread({ inboxItemId: inboxItemId! }, {
        initiator: initiator || 'user',
        description: buildThreadArchiveMutationDescription(),
      });
    }
  }, [archiveThread]);

  return archiveThreadCallback;
}
