import { LoadingScreenSection } from '../../../ui/screen-sections/LoadingScreenSection';
import { ComposeScreenSectionController } from '../../../controllers/screen-sections/ComposeScreenSectionController';
import { ChannelScreenHeaderController } from '../../../controllers/headers/ChannelScreenHeaderController';
import { ChannelScreenHeaderTitleController } from '../../../controllers/headers/ChannelScreenHeaderTitleController';
import { RouteComponentWrapper } from '../../RouteComponentWrapper';
import { ChannelScreenSectionController } from '../../../controllers/screen-sections/ChannelScreenSectionController';
import { ThreadScreenSectionController } from '../../../controllers/screen-sections/ThreadScreenSectionController';
import { InboxScreenSectionController } from '../../../controllers/screen-sections/InboxScreenSectionController';
import { InboxHeaderController } from '../../../controllers/headers/InboxHeaderController';
import { SectionHeaderTitle } from '../../../ui/SectionHeaderTitle';
import { CustomStackNavigator } from '../custom/CustomStackNavigator';
import { ThreadScreenActionsHeaderController } from '../../../controllers/headers/ThreadScreenActionsHeaderController';
import { ThreadScreenChannelsHeaderController } from '../../../controllers/headers/ThreadScreenChannelsHeaderController';
import { SentScreenSectionController } from '../../../controllers/screen-sections/SentScreenSectionController';
import { Stack } from '../Stack';
import { SentHeaderController } from '../../../controllers/headers/SentHeaderController';
import { SearchScreenSectionController } from '../../../controllers/screen-sections/SearchScreenSectionController';
import { SearchHeaderController } from '../../../controllers/headers/SearchHeaderController';
import { InboxHeaderTitleController } from '../../../controllers/headers/InboxHeaderTitleController';
import type { FIRST_ROUTE_IN_APP } from '../../getCurrentScreenParams';
import { SpamHeaderController } from '../../../controllers/headers/SpamHeaderController';
import { SpamScreenSectionController } from '../../../controllers/screen-sections/SpamScreenSectionController';
import { DraftsScreenSectionController } from '../../../controllers/screen-sections/DraftsScreenSectionController';
import { SnoozedScreenSectionController } from '../../../controllers/screen-sections/SnoozedScreenSectionController';
import { SnoozedHeaderController } from '../../../controllers/headers/SnoozedHeaderController';
import { AllMessagesHeaderController } from '../../../controllers/headers/AllMessagesHeaderController';
import { AllMessagesScreenSectionController } from '../../../controllers/screen-sections/AllMessagesSectionController';
import { TrashScreenSectionController } from '../../../controllers/screen-sections/TrashScreenSectionController';
import { TrashHeaderController } from '../../../controllers/headers/TrashHeaderController';
import { StarredScreenSectionController } from '@/infrastructure/controllers/screen-sections/StarredScreenSectionController';
import { StarredHeaderController } from '@/infrastructure/controllers/headers/StarredHeaderController';

const InboxChildren = () => <RouteComponentWrapper component={InboxScreenSectionController} routeName="Inbox" keyCallback={(route) => `${route.params?.page ?? 'default'}|${route.params?.category}`} />;
const SentChildren = () => <RouteComponentWrapper component={SentScreenSectionController} routeName="Sent" keyCallback={(route) => route.params?.page ?? 'default'} />;
const SearchChildren = () => <RouteComponentWrapper component={SearchScreenSectionController} routeName="Search" keyCallback={(route) => `${route.params?.page ?? 'default'}|${route.params.query}`} />;
const SpamChildren = () => <RouteComponentWrapper component={SpamScreenSectionController} routeName="Spam" keyCallback={(route) => route.params?.page ?? 'default'} />;
const SnoozedChildren = () => <RouteComponentWrapper component={SnoozedScreenSectionController} routeName="Snoozed" keyCallback={(route) => route.params?.page ?? 'default'} />;
const TrashChildren = () => <RouteComponentWrapper component={TrashScreenSectionController} routeName="Trash" keyCallback={(route) => route.params?.page ?? 'default'} />;
const StarredChildren = () => <RouteComponentWrapper component={StarredScreenSectionController} routeName="Starred" keyCallback={(route) => route.params?.page ?? 'default'} />;
const AllThreadsChildren = () => <RouteComponentWrapper component={AllMessagesScreenSectionController} routeName="AllMessages" keyCallback={(route) => route.params?.page ?? 'default'} />;
const ChannelChildren = () => <RouteComponentWrapper component={ChannelScreenSectionController} routeName="Channel" keyCallback={(route) => route.params.channelId + route.params.page} />;
const ChannelHeaderTitle = () => <RouteComponentWrapper component={ChannelScreenHeaderTitleController} routeName="Channel" keyCallback={(route) => route.params.channelId + route.params.page} />;
const ChannelHeaderRight = () => <RouteComponentWrapper component={ChannelScreenHeaderController} routeName="Channel" keyCallback={(route) => route.params.channelId + route.params.page} />;
const ThreadScreen = () => <RouteComponentWrapper component={ThreadScreenSectionController} routeName="Thread" keyCallback={(route) => route.params.threadId} />;
const ThreadHeaderTitle = () => <RouteComponentWrapper component={ThreadScreenActionsHeaderController} routeName="Thread" keyCallback={(route) => route.params.threadId} />;
const ThreadHeaderRight = () => <RouteComponentWrapper component={ThreadScreenChannelsHeaderController} routeName="Thread" keyCallback={(route) => route.params.threadId} />;

export function AppScreenNavigator() {
  return (
    <CustomStackNavigator>

      <Stack.Screen
        name={'Inbox' satisfies typeof FIRST_ROUTE_IN_APP}
        component={InboxChildren}
        options={{
          title: 'Inbox',
          headerTitle: InboxHeaderTitleController,
          headerRight: InboxHeaderController,
        }}
      />

      <Stack.Screen
        name="Sent"
        component={SentChildren}
        options={{
          title: 'Sent',
          headerTitle: SectionHeaderTitle,
          headerRight: SentHeaderController,
        }}
      />

      <Stack.Screen
        name="Drafts"
        component={DraftsScreenSectionController}
        options={{
          title: 'Drafts',
          headerTitle: SectionHeaderTitle,
        }}
      />

      <Stack.Screen
        name="Spam"
        component={SpamChildren}
        options={{
          title: 'Spam',
          headerTitle: SectionHeaderTitle,
          headerRight: SpamHeaderController,
        }}
      />

      <Stack.Screen
        name="Snoozed"
        component={SnoozedChildren}
        options={{
          title: 'Snoozed',
          headerTitle: SectionHeaderTitle,
          headerRight: SnoozedHeaderController,
        }}
      />

      <Stack.Screen
        name="Trash"
        component={TrashChildren}
        options={{
          title: 'Trash',
          headerTitle: SectionHeaderTitle,
          headerRight: TrashHeaderController,
        }}
      />

      <Stack.Screen
        name="Starred"
        component={StarredChildren}
        options={{
          title: 'Starred',
          headerTitle: SectionHeaderTitle,
          headerRight: StarredHeaderController,
        }}
      />

      <Stack.Screen
        name="Search"
        component={SearchChildren}
        options={{
          title: 'Search results',
          headerTitle: SectionHeaderTitle,
          headerRight: SearchHeaderController,
        }}
      />

      <Stack.Screen name="Loading" component={LoadingScreenSection} options={{ headerShown: false }} />

      <Stack.Screen
        name="Channel"
        options={{
          headerTitle: ChannelHeaderTitle,
          headerRight: ChannelHeaderRight,
        }}
      >
        {ChannelChildren}
      </Stack.Screen>

      <Stack.Screen
        name="AllMessages"
        options={{
          title: 'All messages',
          headerTitle: SectionHeaderTitle,
          headerRight: AllMessagesHeaderController,
        }}
      >
        {AllThreadsChildren}
      </Stack.Screen>

      <Stack.Screen
        name="Thread"
        component={ThreadScreen}
        options={{
          headerTitle: ThreadHeaderTitle,
          headerRight: ThreadHeaderRight,
        }}
      />

      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen
          name="Compose"
          component={ComposeScreenSectionController}
          options={{
            title: 'Compose',
            headerTitle: SectionHeaderTitle
          }}
        />
      </Stack.Group>

    </CustomStackNavigator>
  );
}
