import { useRef, useInsertionEffect, useCallback } from 'react';
import { Fn } from './types';

// https://react.dev/reference/react/experimental_useEffectEvent
export function useEffectEvent<T extends Fn, E extends T | null | undefined>(fn: E): NonNullable<E> {
  const ref = useRef<E | null>(null);

  useInsertionEffect(() => {
    ref.current = fn;
  }, [fn]);

  return useCallback((...args: Parameters<T>) => {
    const f = ref.current;
    return f?.(...args);
  }, []) as NonNullable<E>;
}

export const useEvent = useEffectEvent;
