import {
  createContext, MutableRefObject, ReactNode, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useIsFocused } from '@react-navigation/native';
import { Pressable, useToken } from 'native-base';
import { useIsPresent, usePresence } from 'framer-motion';
import {
  Animated, LayoutRectangle, type MeasureOnSuccessCallback, NativeMethods
} from 'react-native';
import { useEffectEvent } from '@/infrastructure/hooks/useEffectEvent';
import { startAsync } from '@/infrastructure/global/animatedAsync';
import { ComprehensiveThreadViewModel } from '@/adapters/view-models/ThreadViewModel';
import { useDelayedCallbacks } from '../../hooks/useDelayedCallbacks';
import { useThreadListStore, useThreadListStoreSelector } from '../thread-list/context';
import { rafGenerator } from '@/infrastructure/global/rafGenerator';
import { useThreadListItemIndex } from '@/infrastructure/ui/thread-list-items/ThreadListItem.context';
import { usePreviousValue } from '@/infrastructure/hooks/usePreviousValue';

export type ThreadListItemPressableContainerProps = {
  thread: ComprehensiveThreadViewModel;
  selectThreadCallback: (thread: ComprehensiveThreadViewModel) => void;
  threadHoveredCallback: (thread: ComprehensiveThreadViewModel) => void;
  children: ReactNode;
};

const context = createContext<{ isHovered: boolean; } | null>(null);
const ThreadListItemHoverContextProvider = context.Provider;

export function useIsThreadListItemHovered() {
  const value = useContext(context);
  if (!value) {
    throw new Error('useIsThreadListItemHovered must be used within a ThreadListItemHoverContextProvider');
  }
  return value.isHovered;
}

// const useBoxShadow = () => {
//   const dark5 = useToken('colors', 'dark.5');
//   return `inset 0 -1px 0 ${dark5}`;
// };

const asyncMeasure = (ref: NativeMethods) => new Promise<Parameters<MeasureOnSuccessCallback>>((resolve) => {
  ref.measure((...args) => resolve(args));
});

export function ThreadListItemPressableContainer({
  thread, selectThreadCallback, threadHoveredCallback, children,
}: ThreadListItemPressableContainerProps) {
  const present = useIsPresent();
  const isFocusedScreen = useIsFocused();
  const [isHovered, setIsHovered] = useState(false);
  const { handleMouseEnter, handleMouseLeave } = useDelayedCallbacks(() => threadHoveredCallback(thread));

  const ref = useRef<HTMLDivElement & NativeMethods>(null);
  const layoutRef = useRef<LayoutRectangle | null>(null);

  const threadListStore = useThreadListStore();
  const isFocused = useThreadListStoreSelector((state) => state.focusedId === thread.id);
  const setFocusedId = useThreadListStoreSelector((state) => state.setFocusedId);
  const updateFocusedId = () => setFocusedId(thread.id);
  const isSelected = useThreadListStoreSelector((state) => state.selectedIds.includes(thread.id));

  const updateLayout = useCallback((layout: LayoutRectangle) => {
    layoutRef.current = layout;
    threadListStore.getState().setLayout(thread.id, layout);
  }, [thread.id, threadListStore]);

  const measureAndUpdateLayout = useCallback(async () => {
    if (!ref.current) return;
    const currentRef = ref.current;
    const [x, y, width, height] = await asyncMeasure(currentRef);
    updateLayout({
      x, y, width, height,
    });
  }, [updateLayout]);

  useHotkeys(['o', 'Enter'], () => selectThreadCallback(thread), { enabled: isFocused && isFocusedScreen });

  const listItemIndex = useThreadListItemIndex();
  const prevListItemIndex = usePreviousValue(listItemIndex);
  useLayoutEffect(() => {
    if (listItemIndex !== prevListItemIndex) {
      void measureAndUpdateLayout();
    }
  }, [listItemIndex, prevListItemIndex, measureAndUpdateLayout]);

  useLayoutEffect(() => {
    const { navigatedFromKeyboard } = threadListStore.getState();
    if (isFocused && isFocusedScreen && ref.current && navigatedFromKeyboard) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
      ref.current.focus();
    }
  }, [isFocused, isFocusedScreen, threadListStore]);

  useEffect(() => {
    if (!present) {
      threadListStore.getState().unmounted(thread.id);
    }
  }, [present, threadListStore, thread.id]);

  const isAnimatingOut = useThreadListStoreSelector((state) => Boolean(state.isAnimatingOut));
  useEffect(() => {
    if (!ref.current) return;
    const currentRef = ref.current;
    if (isAnimatingOut && present && isFocused) {
      let cancelled = false;
      // eslint-disable-next-line no-inner-declarations
      async function doMeasureLoop() {
        for await (const _ of rafGenerator()) {
          const [x, y, width, height] = await asyncMeasure(currentRef);
          updateLayout({
            x, y, width, height
          });
          if (cancelled) break;
        }
      }
      doMeasureLoop().catch(console.error);
      return () => {
        cancelled = true;
      };
    }
  }, [isAnimatingOut, isFocused, present, updateLayout]);
  const onEndAnimation = useEffectEvent(async () => {
    if (present) {
      await measureAndUpdateLayout();
    }
  });
  useLayoutEffect(() => {
    if (isAnimatingOut) {
      return () => void onEndAnimation();
    }
  }, [isAnimatingOut]);

  // const boxShadow = useBoxShadow();
  const hoverContextValue = useMemo(() => ({ isHovered }), [isHovered]);

  const getBgColor = () => {
    if (isSelected) return 'opacityPrimary.100';
    if (!thread.isUnread) return 'opacityPrimary.35';
    return 'opacityWhite.100';
  };
  return (
    <Pressable
      ref={ref}
      onLayout={present ? (event) => {
        layoutRef.current = event.nativeEvent.layout;
        threadListStore.getState().setLayout(thread.id, event.nativeEvent.layout);
      } : undefined}
      onPress={() => selectThreadCallback(thread)}
      pointerEvents={present ? undefined : 'none'}
      onPointerMove={updateFocusedId}
      onFocus={updateFocusedId}
      onPointerEnter={() => {
        handleMouseEnter();
        setIsHovered(true);
      }}
      onPointerLeave={() => {
        handleMouseLeave();
        setIsHovered(false);
      }}
      py={0}
      pr={2}
      flexGrow={1}
      maxW="100%"
      borderRadius={0}
      bgColor={getBgColor()}
      isFocusVisible={false}
      // @ts-ignore
      // _web={{ style: { boxShadow } }}
      _focus={{
        _web: {
          style: {
            // @ts-ignore
            // boxShadow,
            outline: 'none',
          },
        },
      }}
      _focusVisible={{
        _web: {
          style: {
            // @ts-ignore
            // boxShadow,
            outline: 'none',
          },
        },
      }}
    >
      <AnimateExit layoutRef={layoutRef}>
        <ThreadListItemHoverContextProvider value={hoverContextValue}>
          {children}
        </ThreadListItemHoverContextProvider>
      </AnimateExit>
    </Pressable>
  );
}

function AnimateExit(
  {
    children,
    layoutRef,
  }: {
    children: ReactNode;
    layoutRef: MutableRefObject<LayoutRectangle | null>;
  }
) {
  const listStore = useThreadListStore();
  const [present, safeToUnmount] = usePresence();
  const [exit] = useState(() => new Animated.Value(1));
  // const boxShadow = useBoxShadow();
  const onAnimateOut = useEffectEvent(async () => {
    listStore.getState().setIsAnimatingOut(true);
    const animation = Animated.timing(exit, {
      toValue: 0,
      duration: 200,
      useNativeDriver: false,
    });
    await startAsync(animation);
    safeToUnmount?.();
    listStore.getState().setIsAnimatingOut(false);
  });
  useEffect(() => {
    if (!present) void onAnimateOut();
  }, [present]);
  const backgroundColor = useToken('colors', 'primary.50');
  if (present) return children;
  return (
    <Animated.View
      style={{
        height: exit.interpolate({
          inputRange: [0, 1],
          outputRange: [0, layoutRef.current?.height || 45],
        }),
        opacity: exit,
        transform: [
          {
            translateX: exit.interpolate({
              inputRange: [0, 1],
              outputRange: [-500, 0],
            }),
          }
        ],
        overflow: 'hidden',
        backgroundColor,
        // @ts-ignore - web only
        // boxShadow,
      }}
    >
      {children}
    </Animated.View>
  );
}
