import React from 'react';
import { Button, Icon, Tooltip } from 'native-base';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';

export type JoinButtonProps = Parameters<typeof Button>[0] & {
  joined: boolean;
  subscribeCallback?: () => void;
  type: 'channel' | 'thread';
  noIcon?: boolean;
};

const FOLLOW_HINT = 'Get notified about new replies in this';
const UNFOLLOW_HINT = 'Turn off notifications for replies in this';

export function JoinButton(props: JoinButtonProps) {
  const actionTerm = determineActionTerm(props.joined, props.type);
  const leftIcon = <Icon name={props.joined ? 'bell-off-outline' : 'bell-ring-outline'} as={MaterialCommunityIcons} size="sm" />;
  const button = (
    <Button
      {...props}
      size="sm"
      variant="text"
      onPress={() => {
        if (props.subscribeCallback) {
          props.subscribeCallback();
        }
      }}
      leftIcon={props.noIcon ? undefined : leftIcon}
    >
      {props.joined ? `${actionTerm} ${props.type}` : `${actionTerm} ${props.type}`}
    </Button>
  );

  if (props.type === 'thread') {
    return (
      <Tooltip openDelay={200} placement="top" hasArrow label={props.joined ? `${UNFOLLOW_HINT} ${props.type}` : `${FOLLOW_HINT} ${props.type}`}>
        {button}
      </Tooltip>
    );
  }

  return button;
}
function determineActionTerm(joined: boolean, type: 'thread' | 'channel') {
  if (!joined) {
    return type === 'thread' ? 'Follow' : 'Join';
  }

  return type === 'thread' ? 'Unfollow' : 'Leave';
}
