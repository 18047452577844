import { RouteProp, useRoute } from '@react-navigation/native';
import { ComponentType, ReactElement, useMemo } from 'react';
import { AppScreenStackParamList } from './navigators/app/AppScreenProps';

export interface RouteComponentWrapperProps<T, R extends keyof AppScreenStackParamList> {
  component: ComponentType<T>;
  keyCallback: (route: RouteProp<AppScreenStackParamList, R>) => string | number;
  componentProps?: T;
  routeName: R;
}

export function RouteComponentWrapper<T, R extends keyof AppScreenStackParamList>(
  props: RouteComponentWrapperProps<T, R>
): ReactElement {
  const route = useRoute<RouteProp<AppScreenStackParamList, R>>();
  const { component: Component, keyCallback: keyPropCallback, componentProps } = props;

  const keyProp = keyPropCallback(route);

  return useMemo(() => <Component key={keyProp} {...(componentProps as T)} />, [Component, componentProps, keyProp]);
}
