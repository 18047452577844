import {
  MarkAllThreadMessagesAsReadApiArg, MarkAllThreadMessagesAsReadApiResponse
} from '../api/codegen';
import { CacheTagInvalidationIntent, MutationHandlerProps } from './MutationHandler';
import { UnreadCountMutationHandler } from './UnreadCountMutationHandler';
import { ThreadViewModel } from '../view-models/ThreadViewModel';
import { MessageViewModel } from '../view-models/MessageViewModel';
import { createThreadUpdatePatchesForThreadId } from './thread-patch-factory/createThreadUpdatePatchesForThreadId';
import { getCurrentChannelParams } from './utils/getCurrentChannelParams';

export class MarkAllThreadMessagesAsReadMutationHandler extends UnreadCountMutationHandler<MarkAllThreadMessagesAsReadApiArg, MarkAllThreadMessagesAsReadApiResponse> {
  constructor(props: MutationHandlerProps<MarkAllThreadMessagesAsReadApiArg, MarkAllThreadMessagesAsReadApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: MarkAllThreadMessagesAsReadApiArg) {
    return [
      ...createThreadUpdatePatchesForThreadId(patch.threadId, this.updateThread, this.updateMessages),
      ...this.createChannelsListPatches(patch, 'read', true),
    ];
  }

  private updateThread(draftedThread: ThreadViewModel) {
    draftedThread.isUnread = false;
  }

  private updateMessages(draftedMessages: MessageViewModel[]) {
    for (const message of draftedMessages) {
      message.isUnread = false;
    }
  }

  protected generateInvalidationTags(arg: MarkAllThreadMessagesAsReadApiArg) {
    return [
      {
        type: 'Thread' as const,
        id: arg.threadId,
        schedule: {
          delayMs: 10000,
          uniqueKey: `ChannelThreadListManagement-${getCurrentChannelParams()}`,
        }
      } as CacheTagInvalidationIntent,
      {
        type: 'SelfAccount' as const,
        schedule: {
          delayMs: 5000,
          uniqueKey: 'InboxUnreadThreadCount',
        }
      },
      {
        type: 'SelfChannels' as const,
        schedule: {
          delayMs: 5000,
          uniqueKey: 'ChannelsThreadCount',
        }
      }

    ];
  }
}
