import React, { useCallback, useRef } from 'react';
import {
  Center, HStack, Pressable, Skeleton, Toast
} from 'native-base';
import {
  NavigationProp, RouteProp, useNavigation, useRoute
} from '@react-navigation/native';
import {
  MutationMetadata,
  useWithMutationMetadata
} from '../../../adapters/mutation-cancellation/mutationCancellation';
import { useAddChannelParticipantsMutation, useRemoveChannelParticipantsMutation, useUpdateChannelNotificationLevelMutation } from '../../../adapters/api/codegen';
import { ParticipantViewModel } from '../../../adapters/view-models/ParticipantViewModel';
import { useCurrentChannel } from '../hooks/routes/useCurrentChannel';
import { RootStackParamList } from '../../navigation/navigators/root/RootStackProps';
import { useGetAllAccessibleChannels } from '../hooks/api/useGetAllAccessibleChannels';
import { ChannelViewModel } from '../../../adapters/view-models/ChannelViewModel';
import { useGetSelfOrganizations } from '../hooks/api/useGetSelfOrganizations';
import { useGetSelfUser } from '../hooks/api/useGetSelfUser';
import { useGetChannelParticipants } from '../hooks/api/useGetChannelParticipants';
import { useGetChannelThreads } from '../hooks/api/useGetChannelThreads';
import { useGetSelfOrganizationMembers } from '../hooks/api/useGetSelfOrganizationMembers';
import { apiClient } from '../../../adapters/api';
import { Paginator } from '../../ui/Paginator';
import { AddParticipantsModalControllerHandles } from '../modals/AddParticipantsModalController';
import { ChannelNotificationSettings } from '../../ui/screen-sections/channel/ChannelNotificationSettings';
import { ParticipantAvatars } from '../../ui/ParticipantAvatars';
import { JoinButton } from '../../ui/JoinButton';
import { ManageParticipantsModalController } from '../modals/ManageParticipantsModalController';
import {
  buildChannelJoinedMutationDescription,
  buildChannelParticipantMutationDescription
} from '../UndoMutationDescriptions';

export function ChannelScreenHeaderController() {
  const { channel, isLoading: isCurrentlyLoadingChannel } = useCurrentChannel();
  const route = useRoute<RouteProp<RootStackParamList, 'Channel'>>();
  const { channelId, page } = route.params ?? {};
  const navigation = useNavigation<NavigationProp<RootStackParamList, 'Channel'>>();
  const { currentData: participants, isLoading: isCurrentlyLoadingParticipants } = useGetChannelParticipants(channel);
  const { currentData: selfUser, isLoading: isLoadingUser } = useGetSelfUser();
  const { currentData: selfOrganizations, organizationId } = useGetSelfOrganizations();
  const { currentData: organizationMembers } = useGetSelfOrganizationMembers(organizationId);
  const { allRawChannels } = useGetAllAccessibleChannels(selfOrganizations);
  const { currentData: threads } = useGetChannelThreads(channelId, selfUser?.id, organizationMembers?.users, allRawChannels, page);
  const [addParticipants, { isLoading: isAddingParticipants }] = useWithMutationMetadata(useAddChannelParticipantsMutation());
  const isLoadingChannel = isCurrentlyLoadingChannel && !channel;
  const isLoadingParticipants = isCurrentlyLoadingParticipants && !participants;

  const prefetchChannelThreads = apiClient.usePrefetch('getChannelThreads');

  const selfUserJoinedChannel = participants?.some((p) => p.id === selfUser?.id) ?? false;
  const disableSubscribeButton = isLoadingParticipants || isLoadingUser;

  const submitNewParticipantsCallback = useUpdateChannelParticipantsCallback(channel!, 'add');
  const removeParticipantsCallback = useUpdateChannelParticipantsCallback(channel!, 'remove');

  const checkIfParticipantMutationsAreLoading = useCallback(() => {
    if (isAddingParticipants) {
      Toast.show({ title: 'Please wait until the current operation is finished', });
    }
    return isAddingParticipants;
  }, [isAddingParticipants]);

  const joinCallback = useCallback(() => {
    if (checkIfParticipantMutationsAreLoading()) {
      return;
    }
    void addParticipants({ channelId: channel!.id, body: [selfUser!.id] }, {
      initiator: 'user',
      description: buildChannelJoinedMutationDescription(true),
    });
  }, [checkIfParticipantMutationsAreLoading, channel, addParticipants, selfUser]);

  const [updateNotificationLevel] = useUpdateChannelNotificationLevelMutation();

  const manageParticipantsModalControllerRef = useRef<AddParticipantsModalControllerHandles>(null);
  const canHaveParticipants = !channel?.isFolder;
  const showSpinner = (isLoadingParticipants || isLoadingChannel) && canHaveParticipants;

  if (showSpinner) {
    return (
      <Center flex={1} mx={6}>
        <Skeleton.Text lines={1} h={3} width="230px" />
      </Center>
    );
  }

  const paginator = (threads?.meta && channel) ? (
    <Paginator
      meta={threads.meta}
      onPageChange={(page) => navigation.setParams({ channelId: channel.id, page })}
      onPrefetchPage={(pageToken) => prefetchChannelThreads({ channelId: channel.id, pageToken })}
    />
  ) : null;

  if (!canHaveParticipants) {
    // For All messages channel
    return paginator;
  }

  return (
    <HStack mx="3" space={1} alignItems="center">
      {paginator}
      {channel && participants && (
        <HStack space={4} alignItems="center">
          {selfUserJoinedChannel && (
            <ChannelNotificationSettings
              value={channel.notificationLevel}
              onChange={(notificationLevel) => {
                void updateNotificationLevel({
                  channelId: channel.id,
                  channelNotificationLevelUpdate: { notification_level: notificationLevel },
                });
              }}
            />
          )}
          <Pressable onPress={() => manageParticipantsModalControllerRef.current?.show()}>
            <ParticipantAvatars participants={participants} maxNumberOfAvatars={5} size="sm" />
          </Pressable>
          {!selfUserJoinedChannel && (
            <JoinButton
              noIcon
              type="channel"
              joined={false}
              subscribeCallback={joinCallback}
              disabled={disableSubscribeButton}
            />
          )}
          <ManageParticipantsModalController
            participants={participants}
            ref={manageParticipantsModalControllerRef}
            didSubmitNewParticipantsCallback={submitNewParticipantsCallback}
            didDeleteParticipantsCallback={removeParticipantsCallback}
            participantsType="users"
          />
        </HStack>
      )}
    </HStack>
  );
}

function useUpdateChannelParticipantsCallback(channel: ChannelViewModel | undefined, type: 'add' | 'remove') {
  const [addParticipants] = useWithMutationMetadata(useAddChannelParticipantsMutation());
  const [removeParticipants] = useWithMutationMetadata(useRemoveChannelParticipantsMutation());

  return useCallback(async (participants: ParticipantViewModel[]) => {
    const userIds = participants.map((p) => p.id);

    const mutationMetadata: MutationMetadata = {
      initiator: 'user',
      description: buildChannelParticipantMutationDescription(type === 'add', participants),
    };

    if (type === 'add') {
      void addParticipants({
        channelId: channel!.id,
        body: userIds
      }, mutationMetadata);
    } else {
      void removeParticipants({
        channelId: channel!.id,
        body: userIds
      }, mutationMetadata);
    }
  }, [addParticipants, channel, removeParticipants, type]);
}
