import type { ComponentType } from 'react';

export function getDisplayName(Component: any | ComponentType<any>): string {
  return (
    Component.displayName
    || Component.name
    || (typeof Component === 'string' && Component.length > 0
      ? Component
      : 'Unknown')
  );
}
