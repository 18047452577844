import { useCallback, useMemo } from 'react';
import { UserViewModel } from '../../../../adapters/view-models/UserViewModel';
import { apiClient, useGetThreadMessagesQuery } from '../../../../adapters/api';
import { MessageViewModel, createComprehensiveMessageViewModel } from '../../../../adapters/view-models/MessageViewModel';
import { THREAD_MESSAGES_QUERY_OPTIONS } from '../../../api/query-parameters';

export const useGetThreadMessages = (threadId: string | undefined, selfUserId?: string, users?: UserViewModel[]) => {
  const params = useMemo(() => ({ selfUserId, users }), [selfUserId, users]);
  const messages = useGetThreadMessagesQuery({ threadId: threadId ?? '' }, { ...THREAD_MESSAGES_QUERY_OPTIONS, skip: !threadId });
  const curriedCreateMessagesViewModel = useCallback((message: MessageViewModel) => createComprehensiveMessageViewModel(message, params), [params]);
  const comprehensiveMessages = useMemo(() => messages.currentData?.messages.map(curriedCreateMessagesViewModel), [curriedCreateMessagesViewModel, messages.currentData?.messages]);

  const result = {
    ...messages,
    currentData: {
      messages: comprehensiveMessages
    }
  };

  return result;
};

export const usePrefetchGetThreadMessages = () => apiClient.usePrefetch('getThreadMessages');
