import { GetThreadMessagesApiArg } from '../api/codegen';
import { CacheTagDescription } from './cache-tag-types';
import { CacheTagProvider } from './CacheTagProvider';
import { MessagesViewModel } from '../view-models/MessagesViewModel';
import { MessageViewModel } from '../view-models/MessageViewModel';

export class GetThreadMessagesCacheTagProvider extends CacheTagProvider<GetThreadMessagesApiArg, MessagesViewModel> {
  protected generateTags(arg: GetThreadMessagesApiArg, result: MessagesViewModel) {
    return [
      this.getThreadTag(arg),
      ...this.getThreadMessageTags(result.messages),
    ];
  }

  private getThreadTag(arg: GetThreadMessagesApiArg): CacheTagDescription {
    return { type: 'Thread', id: arg.threadId };
  }

  private getThreadMessageTags(result: MessageViewModel[]): CacheTagDescription[] {
    return result.map((message) => ({ type: 'Message' as const, id: message.id }));
  }
}
