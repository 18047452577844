import { ScrollArea, ScrollAreaProps } from '@mantine/core';
import React from 'react';

export const CommandListScrollArea = (props: ScrollAreaProps) => {
  return (
    <ScrollArea
      type="scroll"
      scrollbarSize={10}
      scrollbars="y"
      {...props}
    />
  );
};
