export const maxEmojiShortcodeLength = 26;

export const maxMentionLength = 26;

export enum KEYS {
  TAB = 'Tab',
  ENTER = 'Enter',
  ESCAPE = 'Escape',
  LEFT = 'ArrowLeft',
  UP = 'ArrowUp',
  RIGHT = 'ArrowRight',
  DOWN = 'ArrowDown',
}
