import { Organization } from '../api/codegen';
import { createOrganizationViewModel, OrganizationViewModel } from './OrganizationViewModel';

export type OrganizationsViewModel = {
  organizations: OrganizationViewModel[];
};

export function createOrganizationsViewModel(organizations: Organization[]): OrganizationsViewModel {
  return {
    organizations: organizations.map(createOrganizationViewModel),
  };
}
