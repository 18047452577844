import {
  ForwardedRef, forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState
} from 'react';
import { NativeSyntheticEvent, TextInput, TextInputKeyPressEventData } from 'react-native';
import { ChannelViewModel } from '@/adapters/view-models/ChannelViewModel';
import { UserViewModel } from '@/adapters/view-models/UserViewModel';
import { ParticipantScreenSection, AddParticipantScreenSectionType } from '../../ui/screen-sections/add-participants/ParticipantScreenSection';
import { createComposeNewExternalParticipantViewModel, ParticipantViewModel } from '@/adapters/view-models/ParticipantViewModel';
import { useGetAllAccessibleChannels } from '../hooks/api/useGetAllAccessibleChannels';
import { useHasEmailSync } from '../../hooks/useHasEmailSync';
import { useGetSelfUser } from '../hooks/api/useGetSelfUser';
import { useGetSelfOrganizations } from '../hooks/api/useGetSelfOrganizations';
import { useGetSelfOrganizationMembers } from '../hooks/api/useGetSelfOrganizationMembers';
import { useOnMentioned } from '@/infrastructure/ui/rich-text-editor/mention/MentionEventsProvider';

export interface AddParticipantScreenSectionControllerHandles {
  focus: () => void;
  clear: () => void;
}

type ParticipantScreenSectionControllerProps = {
  selectedParticipantsListChangedCallback: (selectedParticipants: ParticipantViewModel[]) => void;
  clearAllParticipantsCallback?: () => void;
  includeChannels: boolean;
  includeUsers: boolean;
  type: AddParticipantScreenSectionType;
  excludedParticipants?: ParticipantViewModel[];
  maxNumberOfUserSuggestions?: number;
  maxNumberOfChannelSuggestions?: number;
  singleParticipant?: boolean;
  includeSelfUser?: boolean;
  clearAllText?: string;
  allowExternalUsers?: boolean;
  suggestionsOnTop?: boolean;
  initialParticipants?: ParticipantViewModel[];
  minimumLettersForSuggestions?: number;
};

export const isValidEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const ParticipantScreenSectionController = forwardRef(
  ({
    singleParticipant, selectedParticipantsListChangedCallback, excludedParticipants, clearAllParticipantsCallback, clearAllText, initialParticipants, minimumLettersForSuggestions, suggestionsOnTop, maxNumberOfChannelSuggestions, maxNumberOfUserSuggestions, includeChannels, allowExternalUsers, includeUsers, includeSelfUser, type
  }: ParticipantScreenSectionControllerProps, ref: ForwardedRef<AddParticipantScreenSectionControllerHandles>) => {
    const [recipientSearch, setRecipientSearch] = useState<string>('');
    const { currentData: selfUser } = useGetSelfUser();
    const { currentData: selfOrganizations, organizationId } = useGetSelfOrganizations();
    const { currentData: members } = useGetSelfOrganizationMembers(organizationId);
    const { allChannels } = useGetAllAccessibleChannels(selfOrganizations);
    const [filterInputIsFocused, setFilterInputIsFocused] = useState<boolean>(false);
    const [interactingWithFilteredRecipientsList, setInteractingWithFilteredRecipientsList] = useState<boolean>(false);
    const loadedMembers = useMemo(() => (!includeSelfUser ? (members?.users ?? []).filter((member) => member.id !== selfUser?.id) : (members?.users ?? [])), [includeSelfUser, members?.users, selfUser?.id]);
    const loadedChannels = useMemo(() => allChannels ?? [], [allChannels]);
    const filterInput = useRef<TextInput>(null);
    const { shouldHideEmailFeatures } = useHasEmailSync(selfOrganizations);

    const [selectedRecipients, setSelectedRecipients] = useState<(ParticipantViewModel)[]>(initialParticipants ?? []);

    useImperativeHandle(ref, () => ({
      focus: () => {
        // focus after delay
        setTimeout(() => {
          filterInput.current?.focus();
        }, 100);
      },
      clear: () => {
        filterInput.current?.clear();
        setSelectedRecipients([]);
      },
    }));

    useOnMentioned((recipient: UserViewModel) => {
      if (selectedRecipients.some((selectedRecipient) => selectedRecipient.id === recipient.id)) return;
      const newRecipients = [...selectedRecipients, recipient];
      selectedParticipantsListChangedCallback(newRecipients);
      setSelectedRecipients(newRecipients);
    });

    const selectRecipientCallback = useCallback((recipient: ParticipantViewModel) => {
      const newRecipients = [...selectedRecipients, recipient];
      selectedParticipantsListChangedCallback(newRecipients);
      filterInput.current?.focus();
      if (singleParticipant) return;
      setSelectedRecipients(newRecipients);
      filterInput.current?.clear();
      setRecipientSearch('');
      filterInput.current?.focus();
    }, [selectedParticipantsListChangedCallback, selectedRecipients, singleParticipant]);

    const removeRecipientCallback = useCallback((recipient: ParticipantViewModel) => {
      const newRecipients = selectedRecipients.filter((selectedRecipient) => selectedRecipient.id !== recipient.id);
      selectedParticipantsListChangedCallback(newRecipients);
      setSelectedRecipients(newRecipients);
      filterInput.current?.focus();
    }, [selectedParticipantsListChangedCallback, selectedRecipients]);

    const selectExternalUserCallback = useCallback((email: string) => {
      const newRecipients: ParticipantViewModel[] = [...selectedRecipients, createComposeNewExternalParticipantViewModel(email, email)];
      setSelectedRecipients(newRecipients);
      selectedParticipantsListChangedCallback(newRecipients);
      filterInput.current?.clear();
      setRecipientSearch('');
      filterInput.current?.focus();
    }, [selectedParticipantsListChangedCallback, selectedRecipients]);

    const filteredRecipients = useMemo(() => {
      if (recipientSearch.length < (minimumLettersForSuggestions ?? 0)) return [];

      const channels = includeChannels ? loadedChannels : [];
      const users = includeUsers ? loadedMembers : [];
      return filterRecipients(users, recipientSearch, channels, selfUser, selectedRecipients, excludedParticipants, maxNumberOfUserSuggestions, maxNumberOfChannelSuggestions, includeSelfUser);
    }, [recipientSearch, minimumLettersForSuggestions, includeChannels, includeUsers, excludedParticipants, maxNumberOfUserSuggestions, maxNumberOfChannelSuggestions, includeSelfUser, loadedChannels, loadedMembers, selfUser, selectedRecipients]);

    const onKeyPress = useCallback((e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
      const { value } = e.target as any;

      switch (e.nativeEvent.key) {
        case 'Backspace':
        case 'Delete': {
          if (selectedRecipients.length > 0 && value === '') {
            removeRecipientCallback(selectedRecipients[selectedRecipients.length - 1]);
          }
          break;
        }
        case 'Enter':
        case 'Tab':
        case ' ': {
          const email = value;
          if (!shouldHideEmailFeatures && isValidEmail(email)) {
            e.preventDefault();
            selectExternalUserCallback(email);
            setTimeout(() => (e.target as any)?.focus?.(), 10);
            break;
          }
          if (filteredRecipients.length === 1) {
            e.preventDefault();
            selectRecipientCallback(filteredRecipients[0]);
            break;
          }
          break;
        }
        case 'Escape': {
          filterInput.current?.blur();
          break;
        }
        default: {
          break;
        }
      }
    }, [selectedRecipients, removeRecipientCallback, shouldHideEmailFeatures, filteredRecipients, selectExternalUserCallback, selectRecipientCallback]);

    return (
      <ParticipantScreenSection
        recipientFilterChangedCallback={setRecipientSearch}
        filterInputRef={filterInput}
        selectRecipientCallback={selectRecipientCallback}
        selectedRecipients={selectedRecipients}
        removeRecipientCallback={removeRecipientCallback}
        filterIsFocusedCallback={setFilterInputIsFocused}
        showFilteredRecipientsList={filterInputIsFocused || interactingWithFilteredRecipientsList}
        interactingWithFilteredRecipientsListCallback={setInteractingWithFilteredRecipientsList}
        users={filteredRecipients}
        includeChannels={includeChannels}
        includeUsers={includeUsers}
        allowExternalUsers={allowExternalUsers}
        onKeyPress={onKeyPress}
        type={type}
        singleParticipant={singleParticipant}
        clearAllParticipantsCallback={clearAllParticipantsCallback}
        clearAllText={clearAllText}
        suggestionsOnTop={suggestionsOnTop}
      />
    );
  }
);

function filterRecipients(
  loadedMembers: UserViewModel[],
  recipientSearch: string,
  loadedChannels: ChannelViewModel[],
  selfUser: UserViewModel | undefined,
  alreadySelectedRecipients: (ParticipantViewModel)[],
  excludedParticipants?: ParticipantViewModel[],
  maxNumberOfUserSuggestions?: number,
  maxNumberOfChannelSuggestions?: number,
  includeSelfUser?: boolean
) {
  const filteredMembers = loadedMembers
    .filter((member) => member.displayName.toLowerCase().includes(recipientSearch.toLowerCase()))
    .slice(0, maxNumberOfUserSuggestions ?? 100);

  const filteredChannels = loadedChannels
    .filter((channel) => !channel.isFolder)
    .filter((channel) => channel.name.toLowerCase().includes(recipientSearch.toLowerCase()))
    .slice(0, maxNumberOfChannelSuggestions ?? 100);

  const filteredMembersWithoutSelf = !includeSelfUser ? filteredMembers.filter((member) => member.id !== selfUser?.id) : filteredMembers;
  const filteredRecipients = [...filteredMembersWithoutSelf, ...filteredChannels];

  const filteredRecipientsWithoutAlreadySelected = filteredRecipients.filter((recipient) => !alreadySelectedRecipients.some((selectedRecipient) => selectedRecipient.id === recipient.id));

  if (excludedParticipants) {
    return filteredRecipientsWithoutAlreadySelected.filter((recipient) => !excludedParticipants.some((excludedParticipant) => excludedParticipant.id === recipient.id));
  }

  return filteredRecipientsWithoutAlreadySelected;
}
