import {
  CreateThreadApiArg, CreateThreadApiResponse, GetSelfUserApiResponse, Thread
} from '../api/codegen';
import { createThread } from './model-factories/createThread';
import { createMessage } from './model-factories/createMessage';
import { MutationHandler, MutationHandlerProps } from './MutationHandler';
import { createMessageViewModel } from '../view-models/MessageViewModel';
import { ThreadViewModel, createThreadViewModel } from '../view-models/ThreadViewModel';

export class CreateThreadMutationHandler extends MutationHandler<CreateThreadApiArg, CreateThreadApiResponse> {
  constructor(props: MutationHandlerProps<CreateThreadApiArg, CreateThreadApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: CreateThreadApiArg, _patchId: string) {
    const user = this.getSelfUser();

    if (!user) {
      return [];
    }

    const thread = createThreadViewModel(this.createThread(patch));

    return [
      ...this.createChannelThreadPatches(patch, thread),
      this.createSentFolderThreadPatch(patch, thread),
      this.createThreadMessagesPatch(patch, user.rawUser),
      this.createThreadPatch(patch, thread),
    ];
  }

  private createChannelThreadPatches(patch: CreateThreadApiArg, thread: ThreadViewModel) {
    const channelIds = [...patch.threadCreateRequestBody.channel_ids];

    const patches = channelIds.map((channelId) => this.updateQueryData('getChannelThreads', { channelId }, (draft) => {
      if (draft) {
        draft.threads.unshift(thread);
      }
    }));

    return patches;
  }

  private createSentFolderThreadPatch(_patch: CreateThreadApiArg, thread: ThreadViewModel) {
    return this.updateQueryData('getSelfSentThreads', {}, (draft) => {
      if (draft) {
        draft.threads.unshift(thread);
      }
    });
  }

  private createThread(patch: CreateThreadApiArg): Thread {
    const user = this.getSelfUser()!;
    return createThread(patch, user.rawUser, this.getOrganizationMembers().map((member) => member.rawUser));
  }

  private createThreadMessagesPatch(patch: CreateThreadApiArg, user: GetSelfUserApiResponse) {
    return this.upsertQueryData('getThreadMessages', { threadId: patch.threadCreateRequestBody.thread_id! }, {
      messages: [createMessageViewModel(createMessage(patch.threadCreateRequestBody.message_body, patch.threadCreateRequestBody.message_id!, user))]
    });
  }

  private createThreadPatch(patch: CreateThreadApiArg, thread: ThreadViewModel) {
    return this.upsertQueryData('getThread', { threadId: patch.threadCreateRequestBody.thread_id! }, thread);
  }

  protected generateInvalidationTags(patch: CreateThreadApiArg) {
    const channelIds = [...patch.threadCreateRequestBody.channel_ids];

    return [
      { type: 'SelfSentThreadsList' as const },
      ...channelIds.map((channelId) => ({ type: 'Channel' as const, id: channelId })),
    ];
  }
}
