import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { DeleteChannelApiArg, DeleteChannelApiResponse } from '../api/codegen';
import { MutationHandler, MutationHandlerProps } from './MutationHandler';
import { ChannelsViewModel } from '../view-models/ChannelsViewModel';

export class DeleteChannelMutationHandler extends MutationHandler<DeleteChannelApiArg, DeleteChannelApiResponse> {
  constructor(props: MutationHandlerProps<DeleteChannelApiArg, DeleteChannelApiResponse>) {
    super(props);
  }

  protected createRequestCompletedPatchWrappers(patch: DeleteChannelApiArg) {
    return [
      this.createSelfChannelsPatch(patch),
      this.createOrganizationChannelsPatch(patch)
    ];
  }

  private createSelfChannelsPatch(patch: DeleteChannelApiArg) {
    return this.updateQueryData('getSelfChannels', undefined, (draft) => {
      this.removeChannelFromChannelsList(draft, patch);
    });
  }

  private createOrganizationChannelsPatch(patch: DeleteChannelApiArg) {
    return this.updateQueryData('getOrganizationChannels', { organizationId: this.getSelfOrganization()?.id! }, (draft) => {
      this.removeChannelFromChannelsList(draft, patch);
    });
  }

  private removeChannelFromChannelsList(draft: MaybeDrafted<ChannelsViewModel>, patch: DeleteChannelApiArg) {
    const channelIndex = draft.channels.findIndex((c) => c.id === patch.channelId);
    if (channelIndex !== -1) {
      draft.channels.splice(channelIndex, 1);
    }
  }

  protected generateInvalidationTags(_arg: DeleteChannelApiArg) {
    return [{ type: 'SelfChannels' as const }, { type: 'OrganizationChannels' as const }];
  }
}
