import { ConversationParticipant } from '../api/codegen';
import { PictureViewModel } from './PictureViewModel';
import { ChannelViewModel } from './ChannelViewModel';
import { UserViewModel } from './UserViewModel';
import { getConversationParticipantDisplayName } from './utilities/getConversationParticipantDisplayName';

export type ComposeNewExternalParticipantViewModel = {
  id: string;
  email: string;
  displayName: string;
  external: boolean;
  _type: 'external_user';
};

export const createComposeNewExternalParticipantViewModel = (email: string, displayName: string): ComposeNewExternalParticipantViewModel => ({
  id: email, email, displayName, external: true, _type: 'external_user'
});

export const createComposeNewExternalParticipantViewModelFromConversationParticipant = (participant: ConversationParticipant): ComposeNewExternalParticipantViewModel => ({
  id: participant.user?.id || participant.email_participant!.email,
  email: participant.user?.email || participant.email_participant!.email,
  displayName: participant.user?.display_name || getConversationParticipantDisplayName(participant),
  external: !participant.user,
  _type: 'external_user'
});

export type ParticipantViewModel = UserViewModel | ChannelViewModel | PictureViewModel | ComposeNewExternalParticipantViewModel;
export type ConversationParticipantViewModel = UserViewModel | ComposeNewExternalParticipantViewModel;

export function getParticipantDisplayName(participant: ParticipantViewModel): string {
  if ('displayName' in participant) {
    return participant.displayName;
  }
  if ('name' in participant) {
    return participant.name;
  }
  return '';
}
