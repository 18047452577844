import { MaterialIcons } from '@expo/vector-icons';
import { IconButton } from 'native-base';
import React, { useCallback } from 'react';
import { EmojiPickerPopover, EmojiPickerPopoverProps } from '../../emoji/popover/EmojiPickerPopover';
import { EmojiSelectedCallback } from '../../emoji/popover/EmojiSelectedCallback';

export type AddReactionButtonControllerProps = {
  emojiSelectedCallback: EmojiSelectedCallback;
  emojiPickerOpenCallback?: (isOpen: boolean) => void;
  emojiPickerPlacement?: EmojiPickerPopoverProps['placement'];
  enabled: boolean;
};

export function AddReactionButton({
  emojiPickerPlacement, emojiPickerOpenCallback, emojiSelectedCallback, enabled
}: AddReactionButtonControllerProps) {
  const trigger = useCallback((triggerProps: any) => (
    <IconButton
      {...triggerProps}
      mb={0.5}
      ml={-0.5}
      size="sm"
      isDisabled={!enabled}
      _disabled={{ _icon: { color: 'dark.300' } }}
      _icon={{ as: MaterialIcons, name: 'insert-emoticon', color: '#444' }}
      _hover={{
        _icon: {
          color: 'rgba(33, 104, 200, 1)',
        },
      }}
    />
  ), [enabled]);

  return (
    <EmojiPickerPopover
      emojiSelectedCallback={emojiSelectedCallback}
      trigger={trigger}
      emojiPickerOpenCallback={emojiPickerOpenCallback}
      placement={emojiPickerPlacement}
    />
  );
}
