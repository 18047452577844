import {
  Box, FlatList, Icon, VStack,
} from 'native-base';
import React, { useCallback, useMemo } from 'react';
import { MaterialIcons } from '@expo/vector-icons';
import { ListRenderItemInfo } from '@react-native/virtualized-lists/Lists/VirtualizedList';
import { ChannelViewModel, FolderType, folderTypeValues } from '../../../../adapters/view-models/ChannelViewModel';
import { AddChannelButton } from './AddChannelButton';
import MenuHeader from '../../MenuHeader';
import { MENU_HORIZONTAL_PADDING } from './Constants';
import { ChannelListItem } from '../../ChannelListItem';
import { findLastIndex } from '../../../global/findLastIndex';
import { ScrollArea } from '../../scroll-area';

export type ChannelSelectedCallback = (channel: ChannelViewModel) => void;

type MenuScreenSectionProps = {
  showSpinner: boolean;
  channelSelectedCallback: ChannelSelectedCallback;
  channels: ChannelViewModel[] | undefined;
  isLoadingChannels: boolean;
  selectedChannelId: string | undefined;
  channelHoveredCallback: (channel: ChannelViewModel) => void;
  organizationName?: string;
  composeCallback: () => void;
};

export type MenuHeaderProps = {
  organizationName: string | undefined;
  composeCallback: () => void;
};

export function MenuScreenSection(props: MenuScreenSectionProps) {
  return (
    <>
      <MenuHeader organizationName={props.organizationName} composeCallback={props.composeCallback} />
      {props.isLoadingChannels && !props.channels ? (
        <ChannelSectionsSkeleton />
      ) : null}

      {!props.channels && !props.isLoadingChannels ? (
        <VStack flex={1} justifyContent="center" alignItems="center">
          <Icon as={MaterialIcons} name="error-outline" size="2xl" alignSelf="center" color="warmGray.400" />
        </VStack>
      ) : null}

      {props.channels && (
        <ScrollArea>
          <ChannelSections
            channelSelectedCallback={props.channelSelectedCallback}
            channelHoveredCallback={props.channelHoveredCallback}
            selectedChannelId={props.selectedChannelId}
            channels={props.channels}
          />
        </ScrollArea>
      )}
    </>
  );
}

const NUMBER_OF_SKELETON_CHANNELS = 15;
function ChannelSectionsSkeleton() {
  const channels: number[] = useMemo(() => Array.from({ length: NUMBER_OF_SKELETON_CHANNELS }, (_, i) => i + 1), []);

  const channelItem = useCallback(({ item }: ListRenderItemInfo<number>) => (<ChannelListItem showLoader item={null} key={item as number} />), []);

  return (
    <VStack space={0} flexGrow={1} mb={3}>
      <FlatList my={2} renderItem={channelItem} data={channels} px={MENU_HORIZONTAL_PADDING} keyExtractor={(item) => item.toString()} initialNumToRender={NUMBER_OF_SKELETON_CHANNELS} />
    </VStack>
  );
}

function ChannelSections({
  channels, channelSelectedCallback, channelHoveredCallback, selectedChannelId
}: {
  channels: ChannelViewModel[], channelSelectedCallback: ChannelSelectedCallback;
  channelHoveredCallback: (channel: ChannelViewModel) => void;
  selectedChannelId?: string;
}) {
  const channelsByTypes = splitChannelsByType(channels);

  const indexOfSectionWithRegularGroupChannels = findLastIndex(channelsByTypes, (channels) => channels.some((channel) => !channel.isFolder));
  const indexOfSectionWithInboxChannel = findLastIndex(channelsByTypes, (channels) => channels.some((channel) => channel.folderType === 'inbox'));

  const channelItem = useCallback(({ item }: ListRenderItemInfo<ChannelViewModel>) => (
    <ChannelListItem
      showLoader={false}
      item={item}
      key={item.id}
      channelSelectedCallback={channelSelectedCallback}
      channelHoveredCallback={channelHoveredCallback}
      selectedChannelId={selectedChannelId}
    />
  ), [channelHoveredCallback, channelSelectedCallback, selectedChannelId]);

  const sections = channelsByTypes.map((channels, index) => {
    const section = (
      <FlatList
        my={2}
        renderItem={channelItem}
        data={channels}
        px={MENU_HORIZONTAL_PADDING}
        keyExtractor={(item) => item.id}
        key={channels.reduce((key, channel) => key + channel.id, '')}
        ListFooterComponent={<Box h={1} />}
      />
    );

    if (index === indexOfSectionWithRegularGroupChannels) {
      return (
        <VStack key="regular_group">
          {section}
          <AddChannelButton />
        </VStack>
      );
    }

    return section;
  });

  if (indexOfSectionWithRegularGroupChannels === -1) {
    const addChannelSection = <VStack mt={2} pt={2}><AddChannelButton /></VStack>;
    if (indexOfSectionWithInboxChannel !== -1) {
      sections.splice(indexOfSectionWithRegularGroupChannels, 0, addChannelSection);
    } else {
      sections.push(addChannelSection);
    }
  }

  return (
    <VStack space={0} flexGrow={1} mb={3}>
      {sections}
    </VStack>
  );
}

function splitChannelsByType(channels: ChannelViewModel[]): ChannelViewModel[][] {
  let remainingNumberOfSections = 10;

  return channels.reduce((sectionedChannels, channel) => {
    if (sectionedChannels.length === 0) {
      sectionedChannels.push([channel]);
      return sectionedChannels;
    }

    const lastSection = sectionedChannels[sectionedChannels.length - 1];

    if (areChannelTypesInSameSection(lastSection[0].folderType, channel.folderType) || remainingNumberOfSections === 0) {
      lastSection.push(channel);
    } else {
      remainingNumberOfSections--;
      sectionedChannels.push([channel]);
    }

    return sectionedChannels;
  }, [] as ChannelViewModel[][]);
}

const isSpecialChannelType = (type: FolderType) => folderTypeValues.includes(type);

function areChannelTypesInSameSection(type1?: FolderType, type2?: FolderType) {
  // If both are undefined (the case for channels, not folders), they are in the same section.
  if (type1 === undefined || type2 === undefined) {
    return type1 === type2;
  }

  const isSpecialType1 = isSpecialChannelType(type1);
  const isSpecialType2 = isSpecialChannelType(type2);
  return isSpecialType1 === isSpecialType2;
}
