import {
  Box, HStack, Text, Tooltip
} from 'native-base';
import { useMemo, useState } from 'react';
import { ParticipantAvatars } from '../ParticipantAvatars';
import { ChannelBadgesList } from '../ChannelBadgesList';
import { ThreadActionBar, ThreadActionBarProps } from '../ThreadActionBar';
import { StatusIcon } from '../StatusIcon';
import { useMarkJs } from '../MarkJs';
import { useThreadDraft } from '../../../domain/state/drafts';
import { ThreadSnippetText } from './blocks/ThreadSnippetText';
import { SnoozeHintText } from './blocks/SnoozeHintText';
import { VERTICAL_PADDING } from './constants';
import { ThreadListItemCheckbox } from './blocks/ThreadListItemCheckbox';
import { ThreadListItemUnreadBubble } from './blocks/ThreadListItemUnreadBubble';
import { useIsThreadListItemHovered } from './ThreadListItemPressableContainer';
import { useThreadListStoreSelector } from '../thread-list/context';
import { ThreadListItemStar } from './blocks/ThreadListItemStar';

export type ThreadListItemAbstractContainerProps = ThreadActionBarProps & {
  customDateHint?: React.ReactNode;
};

const nbsp = '\u00A0';

export function ThreadListItemAbstractContainer(props: ThreadListItemAbstractContainerProps) {
  const { thread, children } = props;
  const { memberPictures } = thread;
  const markRef = useMarkJs();
  const draft = useThreadDraft(thread);
  const draftSnippet: string | null = useMemo(() => {
    if (!draft) {
      return null;
    }
    // todo move all quill related code to rich text component
    return draft.content.map((op: any) => {
      if (typeof op.insert === 'string') return op.insert.trim();
      if (op.insert?.image) return '[Uploaded image]';
      return null;
    }).filter(Boolean).join(nbsp.repeat(2));
  }, [draft]);
  const showDraftSnippet = draftSnippet && !thread.isUnread;

  return (
    <HStack flexGrow={1} space={0} pr={3} alignItems="center" maxW="100%">
      <ThreadListItemCheckbox threadId={thread.id} />
      <HStack w={4} bgColor="amber.100" alignSelf="center">
        <ThreadListItemStar isStarred={thread.isStarred} />
        <ThreadListItemUnreadBubble isUnread={thread.isUnread && !thread.isStarred} />
      </HStack>
      <HStack flexGrow={1} flexShrink={1} space={3} alignItems="center">
        {draft && <Text color="green.600">Draft</Text>}
        <HStack ref={markRef} alignItems="center" flexGrow={0} flexShrink={1} space={3} pr={3}>
          <Text
            fontSize="sm"
            bold={thread.isUnread}
            numberOfLines={1}
            selectable={false}
            color={thread.titleGrayedOut ? 'dark.600' : 'dark.900'}
            flexShrink={1}
            py={VERTICAL_PADDING}
            isTruncated
            aria-label="Thread title"
          >
            {thread.displayedTitle}
          </Text>
          {thread.relevantChannels && (
            <ChannelBadgesList channels={thread.relevantChannels} threadId={thread.id} />
          )}
          {showDraftSnippet ? <ThreadSnippetText text={draftSnippet} /> : children}
        </HStack>
        <HStack flexGrow={1} flexShrink={0} justifyContent="flex-end">
          {thread.status && (
            <Tooltip label={thread.status.text} placement="top" openDelay={40}>
              <Box alignSelf="center" mr={5} py={1} px={2}>
                <StatusIcon status={thread.status.key} />
              </Box>
            </Tooltip>
          )}
          <Box flexGrow={0} flexShrink={0} alignItems="center" justifyContent="flex-end" flexDir="row" mr={3}>
            <ParticipantAvatars participants={memberPictures} maxNumberOfAvatars={4} size="xs" />
          </Box>
          <Box flexGrow={0} flexShrink={0} alignItems="center" flexDir="row" w="150px" justifyContent="flex-end">
            <ActionBarOrMetaOnTheRight {...props} />
          </Box>
        </HStack>
      </HStack>
    </HStack>
  );
}

function ActionBarOrMetaOnTheRight(props: ThreadListItemAbstractContainerProps) {
  const [snoozeOpen, setSnoozeOpen] = useState(false);
  const { thread, customDateHint } = props;
  const threadHovered = useIsThreadListItemHovered();
  const isFocused = useThreadListStoreSelector((state) => state.focusedId === thread.id);
  const showActionBar = threadHovered || snoozeOpen;
  const shouldRenderActionBar = showActionBar || isFocused;
  return (
    <>
      <Box display={showActionBar ? undefined : 'none'}>
        {shouldRenderActionBar && (<ThreadActionBar {...props} setSnoozeOpen={setSnoozeOpen} />)}
      </Box>
      <Box display={showActionBar ? 'none' : undefined}>
        {thread.snoozedUntil && (
          <SnoozeHintText>
            {thread.snoozedUntil}
          </SnoozeHintText>
        )}
        {!thread.snoozedUntil && !customDateHint && (
          <Text
            fontSize="sm"
            selectable={false}
            color="muted.400"
            flexGrow={0}
            flexShrink={0}
            overflow="hidden"
            numberOfLines={1}
          >
            {thread.lastSenderMessageDate}
          </Text>
        )}
        {customDateHint}
      </Box>
    </>
  );
}
