import { useNavigation } from '@react-navigation/native';
import { Toast } from 'native-base';
import { useHotkeys } from 'react-hotkeys-hook';
import { ThreadViewModel } from '@/adapters/view-models/ThreadViewModel';

export function useMarkThreadAsUnreadHotkey(thread: ThreadViewModel | undefined, changeUnreadStatusAndGoBackCallback: (thread: ThreadViewModel, status: 'read' | 'unread') => void) {
  const navigation = useNavigation();

  useHotkeys(
    'shift+u',
    () => {
      const canMarkAsUnread = !thread!.isUnread;
      const canGoBack = navigation.canGoBack();
      if (canMarkAsUnread) {
        changeUnreadStatusAndGoBackCallback(thread!, 'unread');
      }

      if (!canMarkAsUnread || !canGoBack) {
        Toast.show({
          title: 'Conversation marked as unread',
        });
      }
    },
    [thread?.id, thread?.isUnread, changeUnreadStatusAndGoBackCallback],
    {
      enabled: Boolean(thread),
      enableOnFormTags: false,
      enableOnContentEditable: false
    }
  );
}
