import {
  HStack, Icon, Menu, Pressable, Skeleton
} from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { ComponentProps, useCallback, useMemo } from 'react';
import { HeaderTitle } from '../../HeaderTitle';
import { ChannelViewModel } from '../../../../adapters/view-models/ChannelViewModel';

export type ChannelHeaderTitleProps = {
  channel?: ChannelViewModel
  tintColor?: string;
  showLoader: boolean;
  onInitiateUpdateCallback?: () => void;
  onInitiateDeleteCallback?: () => void;
  onLeave?: () => void;
  pressable: boolean;
};

export function ChannelScreenHeaderTitle(props: ChannelHeaderTitleProps) {
  const channelTitle = useMemo(() => {
    if (!props.channel) return null;

    return (
      <HStack space={3} alignItems="center" px={0} ml={props.pressable ? 0 : 4}>
        <HeaderTitle tintColor={props.tintColor}>
          {props.channel.name}
        </HeaderTitle>
        <Icon
          as={MaterialCommunityIcons}
          name="chevron-down"
          variant="solid"
          display={props.pressable ? 'flex' : 'none'}
          ml={-1}
        />
      </HStack>
    );
  }, [props.channel, props.pressable, props.tintColor]);

  const trigger: ComponentProps<typeof Menu>['trigger'] = useCallback((triggerProps, { open }) => (
    <Pressable
      borderRadius={10}
      _hover={{ backgroundColor: 'light.100' }}
      _pressed={{ backgroundColor: 'light.200' }}
      backgroundColor={open ? 'light.100' : 'transparent'}
      p={2}
      px={4}
      {...triggerProps}
    >
      {channelTitle}
    </Pressable>
  ), [channelTitle]);

  if (props.showLoader) {
    return <Skeleton.Text lines={1} h={3} width="230px" />;
  }

  if (!props.channel) {
    return null;
  }

  if (props.pressable) {
    return (
      <Menu shouldOverlapWithTrigger={false} placement="bottom" trigger={trigger}>
        <Menu.Item onPress={props.onInitiateUpdateCallback}>Edit channel</Menu.Item>
        {props.onLeave && (<Menu.Item colorScheme="danger" onPress={props.onLeave}>Leave channel</Menu.Item>)}
        <Menu.Item colorScheme="danger" onPress={props.onInitiateDeleteCallback}>Delete channel</Menu.Item>
      </Menu>
    );
  }

  return channelTitle;
}
