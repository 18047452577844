import React, {
  useState, useCallback, useImperativeHandle, forwardRef
} from 'react';
import { FlatListProps } from 'react-native';

import { ParticipantsListModal } from '../../ui/ParticipantsListModal';
import { ParticipantViewModel } from '../../../adapters/view-models/ParticipantViewModel';

export interface ParticipantsListModalControllerHandles {
  show: () => void;
  close: () => void;
}

export type ParticipantsListModalControllerProps = {
  participants: ParticipantViewModel[];
  addParticipantsButtonClickedCallback?: () => void;
  renderItem?: FlatListProps<ParticipantViewModel>['renderItem'];
  listEmptyComponent?: FlatListProps<ParticipantViewModel>['ListEmptyComponent'];
  modalTitle: string;
  buttonTitle?: string;
};

export const ParticipantsListModalController = forwardRef<ParticipantsListModalControllerHandles, ParticipantsListModalControllerProps>((props, ref) => {
  const [showParticipantsModal, setShowParticipantsModal] = useState(false);

  useImperativeHandle(ref, () => ({
    show: () => {
      setShowParticipantsModal(true);
    },
    close: () => {
      setShowParticipantsModal(false);
    }
  }));

  const closeParticipantsModal = useCallback(() => {
    setShowParticipantsModal(false);
  }, []);

  const { participants } = props;

  if (!showParticipantsModal) {
    return null;
  }

  return (
    <ParticipantsListModal
      modalDisplayed={showParticipantsModal}
      closeCallback={closeParticipantsModal}
      addParticipantsButtonClickedCallback={props.addParticipantsButtonClickedCallback}
      participants={participants}
      renderItem={props.renderItem}
      listEmptyComponent={props.listEmptyComponent}
      modalTitle={props.modalTitle}
      buttonTitle={props.buttonTitle}
    />
  );
});
