import Quill from 'quill';
import type { EmbedBlot } from 'parchment';
import { createRoot } from 'react-dom/client';
import { PictureViewModel } from '@/adapters/view-models/PictureViewModel';
import { MentionPill } from './MentionPill';

export interface MentionBlotData {
  value: string;
  denotationChar: string;
  pictureInfo: PictureViewModel;
}

export function isMentionBlotData(data: unknown): data is MentionBlotData {
  return (
    typeof data === 'object'
    && data !== null
    && 'value' in data
    && typeof data.value === 'string'
    && 'denotationChar' in data
    && typeof data.denotationChar === 'string'
  );
}

const Embed = Quill.import('blots/embed') as typeof EmbedBlot;

export class MentionBlot extends Embed {
  static blotName = 'mention';
  static tagName = 'span';
  static className = 'ql-mention';

  static create(data?: MentionBlotData) {
    const node = super.create();
    if (!isMentionBlotData(data) || !(node instanceof HTMLElement)) {
      return node;
    }

    node.setAttribute('contenteditable', 'false');
    Object.assign(node.dataset, data);
    node.dataset.pictureInfo = JSON.stringify(data.pictureInfo);

    const root = createRoot(node);
    root.render(<MentionPill data={data} node={node} />);
    return node;
  }

  static value(domNode: HTMLElement): any {
    return {
      ...domNode.dataset,
      pictureInfo: JSON.parse(domNode.dataset.pictureInfo || '{}'),
    };
  }
}
