import { draftActions, isSendingPayload } from '../../domain/state/drafts';
import {
  ConversationParticipant, Message, PostMessageApiArg, PostMessageApiResponse, User
} from '../api/codegen';
import { MutationHandler } from './MutationHandler';
import { createMessageViewModel } from '../view-models/MessageViewModel';
import { ThreadViewModel, addThreadViewModelParticipants } from '../view-models/ThreadViewModel';
import { getCurrentChannelParams } from './utils/getCurrentChannelParams';

export class PostMessageMutationHandler extends MutationHandler<PostMessageApiArg, PostMessageApiResponse> {
  protected createOptimisticUpdatePatchWrappers(arg: PostMessageApiArg, _patchId: string) {
    if (isSendingPayload(arg)) {
      this.dispatch(draftActions.sending(arg));
    }
    return [];
  }

  protected createRequestCompletedPatchWrappers(arg: PostMessageApiArg, data: PostMessageApiResponse, patchId: string) {
    if (isSendingPayload(arg)) {
      this.dispatch(draftActions.sendSuccess(arg));
    }
    return [
      this.createCompleteMessagePatch(arg, data, patchId),
      this.createAddParticipantInThreadsListPatch(arg),
    ];
  }

  private createCompleteMessagePatch(patch: PostMessageApiArg, newMessage: Message, _patchId: string) {
    return this.updateQueryData('getThreadMessages', { threadId: patch.threadId }, (draft) => {
      draft.messages.unshift(createMessageViewModel(newMessage));
    });
  }

  private createAddParticipantInThreadsListPatch(patch: PostMessageApiArg) {
    // todo update all channels from thread.channels and other threads lists
    return this.updateQueryData('getChannelThreads', getCurrentChannelParams()!, (draftedThreads) => {
      for (const thread of draftedThreads.threads) {
        if (thread.id === patch.threadId) {
          this.addSelfUserAsParticipantToThread(thread);
        }
      }
    });
  }

  private addSelfUserAsParticipantToThread(draftedThread: ThreadViewModel) {
    const selfUser = this.getSelfUser();
    addThreadViewModelParticipants(draftedThread, [this.createConversationParticipant(selfUser!.rawUser)]);
  }

  private createConversationParticipant(user: User): ConversationParticipant {
    return {
      type: 'user',
      user
    };
  }

  protected generateInvalidationTags(arg: PostMessageApiArg) {
    return [
      { type: 'Thread' as const, id: arg.threadId },
    ];
  }
}
