import { AuthorizationParams, useAuth0 } from '@auth0/auth0-react';
import React, { useCallback, useEffect, useState } from 'react';
import { SignInScreen } from '../../ui/screens/SignInScreen';
import { RootStackScreenProps } from '../../navigation/navigators/root/RootStackProps';

type SignInScreenControllerProps = RootStackScreenProps<'SignIn' | 'SignInWithEmailAccess'> & {
  authorizationParams?: AuthorizationParams;
};

export function SignInScreenController({ authorizationParams, navigation }: SignInScreenControllerProps) {
  const {
    isAuthenticated, loginWithRedirect, isLoading, error,
  } = useAuth0();
  const [isRedirecting, setIsRedirecting] = useState(false);

  const signInCallback = useCallback(async () => {
    setIsRedirecting(true);
    await loginWithRedirect({
      authorizationParams,
    });
  }, [authorizationParams, loginWithRedirect]);

  useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      setIsRedirecting(false);
    });

    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    if (isAuthenticated && !isLoading && !isRedirecting) {
      // what is screen?
      navigation.replace('App', { screen: 'Inbox' });
    }
  }, [isAuthenticated, isLoading, isRedirecting, navigation]);

  const buttonIsLoading = isLoading || isAuthenticated || isRedirecting;

  const errorMessage = getErrorMessage(error);

  const openPrivacyPolicyCallback = useCallback(() => {
    navigation.navigate('PrivacyPolicy');
  }, [navigation]);

  return <SignInScreen signInCallback={signInCallback} isLoding={buttonIsLoading} errorMessage={errorMessage} openPrivacyPolicyCallback={openPrivacyPolicyCallback} />;
}

function getErrorMessage(error: Error | undefined) {
  if (error) {
    if (error.message.includes('unable')) {
      return error.message;
    }

    return 'An error occurred while signing in. Please contact us if the problem persists.';
  }

  return null;
}
