import {
  MarkMessagesAsReadApiArg, MarkMessagesAsReadApiResponse
} from '../api/codegen';
import { UnreadCountMutationHandler } from './UnreadCountMutationHandler';
import { MutationHandlerProps } from './MutationHandler';
import { ThreadViewModel } from '../view-models/ThreadViewModel';
import { createThreadUpdatePatchesForThreadId } from './thread-patch-factory/createThreadUpdatePatchesForThreadId';
import { MessageViewModel } from '../view-models/MessageViewModel';

export class MarkMessagesAsReadMutationHandler extends UnreadCountMutationHandler<MarkMessagesAsReadApiArg, MarkMessagesAsReadApiResponse> {
  constructor(props: MutationHandlerProps<MarkMessagesAsReadApiArg, MarkMessagesAsReadApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: MarkMessagesAsReadApiArg) {
    return [
      ...createThreadUpdatePatchesForThreadId(this.getCurrentThreadId()!, this.updateThread, (draftedMessages) => this.updateMessages(draftedMessages, patch)),
      ...this.createChannelsListPatches(patch, 'read'),
    ];
  }

  private updateThread(draftedThread: ThreadViewModel) {
    draftedThread.isUnread = draftedThread.inboxItemIsUnread ?? false;
    draftedThread.numberOfUnreadMessages = 0;
  }

  private updateMessages(draftedMessages: MessageViewModel[], patch: MarkMessagesAsReadApiArg) {
    for (const message of draftedMessages) {
      if (patch.messageIdsRequestBody.message_ids.some((unreadMessageId) => unreadMessageId === message.id)) {
        message.isUnread = false;
      }
    }
  }

  protected generateInvalidationTags(arg: MarkMessagesAsReadApiArg) {
    const messageTags = arg.messageIdsRequestBody.message_ids.map((messageId) => ({ type: 'Message' as const, id: messageId }));
    const threadTag = { type: 'Thread' as const, id: this.getCurrentThreadId() };
    const accountTag = {
      type: 'SelfAccount' as const,
      schedule: {
        delayMs: 5000,
        uniqueKey: 'InboxUnreadThreadCount',
      }
    };
    const channelTag = {
      type: 'SelfChannels' as const,
      schedule: {
        delayMs: 5000,
        uniqueKey: 'ChannelsThreadCount',
      }
    };

    return [
      ...messageTags,
      threadTag,
      accountTag,
      channelTag,
    ];
  }
}
