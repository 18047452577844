import React from 'react';
import { CHANNEL_ICON_SETS, IconSetName } from '../../adapters/view-models/ChannelViewModel';
import { EmptyScreenSection } from './EmptyScreenSection';

export type EmptyFolderScreenSectionProps = {
  iconSetName: IconSetName;
  text: string;
};

export function EmptyFolderScreenSection(
  { iconSetName, text }: EmptyFolderScreenSectionProps
) {
  return (
    <EmptyScreenSection
      text={text}
      iconCollection={CHANNEL_ICON_SETS[iconSetName].default.collection}
      iconName={CHANNEL_ICON_SETS[iconSetName].default.name}
    />
  );
}
