import { useAuth0 } from '@auth0/auth0-react';
import { datadogLogs } from '@datadog/browser-logs';
import { useEffect } from 'react';
import { useAppDependencies } from '../../dependencies';
import { useGetSelfUser } from '../../controllers/hooks/api/useGetSelfUser';
import { useGetSelfOrganizations } from '../../controllers/hooks/api/useGetSelfOrganizations';

let isDatadogInitialized = false;

export function useDatadogLogs() {
  const { env } = useAppDependencies().environmentVariablesStore;
  const datadogEnabled = parseInt(env.DATADOG_BROWSER_LOGS_ENABLED);

  const { isAuthenticated } = useAuth0();
  const { currentData: user } = useGetSelfUser();
  const { selfOrganization: org } = useGetSelfOrganizations();

  if (datadogEnabled) {
    if (!isDatadogInitialized) {
      datadogLogs.init({
        clientToken: env.DATADOG_BROWSER_RUM_CLIENT_TOKEN,
        site: 'datadoghq.eu',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
        service: 'frontend',
        env: env.ENVIRONMENT_NAME
      });

      isDatadogInitialized = true;
    }
  }

  useEffect(() => {
    if (datadogEnabled && isAuthenticated && user && org) {
      datadogLogs.setUser({
        id: user.id,
        name: user.displayName,
        organizationId: org.id,
        organizationName: org.name
      });
    }

    if (!datadogEnabled && !isAuthenticated) {
      datadogLogs.clearUser();
    }
  }, [user, org, datadogEnabled, isAuthenticated]);
}
