import { extendTheme } from 'native-base';

const component: Parameters<typeof extendTheme>[0]['components']['Pressable'] = {
  defaultProps: {
    borderWidth: 0,
    borderColor: 'transparent',
    _pressed: {
      borderColor: 'primary.400'
    }
  },
  variants: {
    menu: {
      rounded: 'xl',
      backgroundColor: 'transparent',
      opacity: 0.8,
      _hover: {
        backgroundColor: 'opacityBlack.35',
        opacity: 1,
      },
      _pressed: {
        backgroundColor: 'opacityBlack.50',
        opacity: 1,
      },
    },
    'menu-selected': {
      rounded: 'xl',
      backgroundColor: 'white',
      shadow: 2,
    },
    'list-item': {
      rounded: 'md',
      backgroundColor: 'transparent',
      _hover: {
        backgroundColor: 'light.50',
        _text: {
          color: 'dark.900',
        },
      },
      _pressed: {
        _text: {
          color: 'dark.900',
        },
        backgroundColor: 'primary.100',
      },
    },
    outline: {
      padding: '2',
      borderRadius: 'xl',
      borderWidth: 2,
      borderColor: 'dark.5',
      backgroundColor: 'transparent',
      _hover: {
        backgroundColor: 'dark.5',
        _text: {
          color: 'dark.900',
        }
      },
      _pressed: {
        _text: {
          color: 'dark.900',
        },
        backgroundColor: 'primary.100',
      },
      _text: {
        color: 'dark.900',
      },
      _icon: {
        color: 'dark.900',
      }
    },
  }
};

export default component;
