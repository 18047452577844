import { GenericQueryOptions } from './types';

export const SCREEN_SECTION_DEFAULT_QUERY_PARAMETERS: GenericQueryOptions = {
  pollingInterval: 60000,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  skip: false,
};

export const CHANNEL_THREADS_POLLING_INTERVAL = 1 * 45 * 1000;
export const MENU_CHANNELS_POLLING_INTERVAL = 3 * 60 * 1000;
export const MENU_ORGANIZATIONS_POLLING_INTERVAL = 3600 * 1000;
export const THREAD_MESSAGES_POLLING_INTERVAL = 30 * 1000;
export const THREAD_POLLING_INTERVAL = 60 * 1000 * 5;
export const CHANNEL_POLLING_INTERVAL = 60 * 1000 * 5;
export const SELF_USER_FETCH_INTERVAL = 3600 * 1000;
export const SELF_ACCOUNT_FETCH_INTERVAL = 45 * 1000;
export const CHANNEL_PARTICIPANTS_FETCH_INTERVAL = 3600 * 1000;
export const ORGANIZATION_MEMBERS_FETCH_INTERVAL = 3600 * 1000;
export const ORGANIZATION_REACTIONS_FETCH_INTERVAL = 3600 * 1000 * 24;

export const ORGANIZATIONS_QUERY_OPTIONS = { ...SCREEN_SECTION_DEFAULT_QUERY_PARAMETERS, pollingInterval: MENU_ORGANIZATIONS_POLLING_INTERVAL };
export const CHANNELS_QUERY_OPTIONS = { ...SCREEN_SECTION_DEFAULT_QUERY_PARAMETERS, pollingInterval: MENU_CHANNELS_POLLING_INTERVAL };
export const THREADS_QUERY_OPTIONS = { ...SCREEN_SECTION_DEFAULT_QUERY_PARAMETERS, pollingInterval: CHANNEL_THREADS_POLLING_INTERVAL, refetchOnMountOrArgChange: true };
export const THREAD_MESSAGES_QUERY_OPTIONS = { ...SCREEN_SECTION_DEFAULT_QUERY_PARAMETERS, pollingInterval: THREAD_MESSAGES_POLLING_INTERVAL, refetchOnMountOrArgChange: true };
export const ORGANIZATION_MEMBERS_QUERY_OPTION = { ...SCREEN_SECTION_DEFAULT_QUERY_PARAMETERS, pollingInterval: ORGANIZATION_MEMBERS_FETCH_INTERVAL };
export const ORGANIZATION_REACTIONS_QUERY_OPTION = { ...SCREEN_SECTION_DEFAULT_QUERY_PARAMETERS, pollingInterval: ORGANIZATION_REACTIONS_FETCH_INTERVAL };
export const CHANNEL_QUERY_OPTIONS = { ...SCREEN_SECTION_DEFAULT_QUERY_PARAMETERS, pollingInterval: CHANNEL_POLLING_INTERVAL };
