import { User } from '@auth0/auth0-react';
import { GetSelfUserApiResponse, Message } from '../../api/codegen';

export function createMessage(messageBody: string, messageId: string, user?: GetSelfUserApiResponse | undefined): Message {
  return {
    id: messageId,
    is_unread: false,
    body: messageBody,
    creation_date: new Date().toISOString(),
    sender: {
      type: 'user',
      user: user || {
        display_name: 'Loading...',
      } as User
    },
    reactions: [],
    attachments: [],
    state: 'staged',
  } as Message;
}
