import { useCallback } from 'react';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { CATEGORIES, ChannelViewModel, isInboxCategory } from '../../../adapters/view-models/ChannelViewModel';
import { RootStackParamList } from '../../navigation/navigators/root/RootStackProps';

export function useOpenChannelCallback() {
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  return useCallback((channel: ChannelViewModel) => {
    const selectedChannelId = channel.id;
    if (selectedChannelId) {
      if (selectedChannelId == 'inbox') {
        navigation.reset({
          index: 0,
          routes: [{ name: 'Inbox' }],
        });
      } else if (isInboxCategory(selectedChannelId)) {
        navigation.reset({
          index: 0,
          routes: [{ name: 'Inbox', params: { category: CATEGORIES[selectedChannelId].slug } }],
        });
      } else if (selectedChannelId == 'sent') {
        navigation.reset({
          index: 0,
          routes: [{ name: 'Sent' }],
        });
      } else if (selectedChannelId == 'drafts') {
        navigation.reset({
          index: 0,
          routes: [{ name: 'Drafts' }],
        });
      } else if (selectedChannelId == 'spam') {
        navigation.reset({
          index: 0,
          routes: [{ name: 'Spam' }],
        });
      } else if (selectedChannelId == 'snoozed') {
        navigation.reset({
          index: 0,
          routes: [{ name: 'Snoozed' }],
        });
      } else if (selectedChannelId == 'trash') {
        navigation.reset({
          index: 0,
          routes: [{ name: 'Trash' }],
        });
      } else if (selectedChannelId == 'starred') {
        navigation.reset({
          index: 0,
          routes: [{ name: 'Starred' }],
        });
      } else if (selectedChannelId == 'all') {
        navigation.reset({
          index: 0,
          routes: [{ name: 'AllMessages' }],
        });
      } else {
        navigation.reset({
          index: 0,
          routes: [{ name: 'Channel', params: { channelId: selectedChannelId } }],
        });
      }
    }
  }, [navigation]);
}
