import { NavigationProp, useNavigation } from '@react-navigation/native';
import { Command } from 'cmdk';
import React from 'react';
import { CHANNEL_ICON_SETS, IconSetName } from '../../../adapters/view-models/ChannelViewModel';
import { useCommandStore } from './store';
import { AppScreenStackParamList } from '../../navigation/navigators/app/AppScreenProps';
import { CommandItem } from './CommandItem';
import { useGetSelfChannels } from '../../controllers/hooks/api/useGetSelfChannels';

const getIconSet = (iconSetName: IconSetName) => CHANNEL_ICON_SETS[iconSetName].default;

export const GoToFolderCommands = () => {
  const setOpen = useCommandStore((s) => s.setOpen);
  const navigation = useNavigation<NavigationProp<AppScreenStackParamList>>();
  const folders: [iconSet: IconSetName, route: keyof AppScreenStackParamList, label: string, shortcut: string][] = [
    ['inbox', 'Inbox', 'Inbox', 'g,i'],
    ['snoozed', 'Snoozed', 'Snoozed', 'g,b'],
    ['all', 'AllMessages', 'All Messages', 'g,a'],
    ['sent', 'Sent', 'Sent', 'g,t'],
    ['drafts', 'Drafts', 'Drafts', 'g,d'],
    ['spam', 'Spam', 'Spam', 'g,s'],
  ];
  return (
    <Command.Group heading="Go to">
      {folders.map(([iconSet, route, label, shortcut]) => (
        <CommandItem
          key={route}
          icon={getIconSet(iconSet)}
          onSelect={() => {
            setOpen(false);
            navigation.reset({
              index: 0,
              routes: [{ name: route }],
            });
          }}
          shortcut={shortcut}
          value={label}
        >
          Go to {label}
        </CommandItem>
      ))}
    </Command.Group>
  );
};

export const GoToChannelCommands = () => {
  const setOpen = useCommandStore((s) => s.setOpen);
  const navigation = useNavigation<NavigationProp<AppScreenStackParamList>>();
  const selfChannelsQuery = useGetSelfChannels();
  const channels = selfChannelsQuery.currentData?.channels;
  if (channels?.length) {
    const seenNames: Record<string, number> = {};
    const getUniqueValue = (name: string) => {
      seenNames[name] = (seenNames[name] || 0) + 1;
      return name + (seenNames[name] > 1 ? ` (${seenNames[name]})` : '');
    };
    return (
      <Command.Group heading="Go to channel">
        {channels.map((channel) => (
          <CommandItem
            key={channel.id}
            icon={getIconSet(channel.iconSetName)}
            onSelect={() => {
              setOpen(false);
              navigation.reset({
                index: 0,
                routes: [{ name: 'Channel', params: { channelId: channel.id } }],
              });
            }}
            value={getUniqueValue(channel.name)}
            keywords={[channel.name]}
          >
            {channel.name}
          </CommandItem>
        ))}
      </Command.Group>
    );
  }
  return null;
};
