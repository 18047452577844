import { getAllAccessibleChannels } from '../../utils/getAllAccessibleChannels';
import { getCurrentChannelParams } from '../../utils/getCurrentChannelParams';
import { updateQueryData } from '../../utils/updateQueryData';
import { ThreadsUpdateHandler } from '../ThreadsUpdateHandler';

export function* createThreadsListPatch(threadUpdateHandler: ThreadsUpdateHandler) {
  yield updateQueryData('getChannelThreads', getCurrentChannelParams()!, (draftedThreads) => {
    threadUpdateHandler(draftedThreads.threads);
  });

  for (const channel of getAllAccessibleChannels()) {
    yield updateQueryData('getChannelThreads', { channelId: channel.id }, (draftedThreads) => {
      threadUpdateHandler(draftedThreads.threads);
    });
  }
}
