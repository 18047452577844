import { useNavigation } from '@react-navigation/native';
import { useHotkeys } from 'react-hotkeys-hook';
import { useMenuStore } from '../../ui/date-picker-menu/menu/useMenuStore';

export function useGoBackToThreadListHotkey() {
  const navigation = useNavigation();

  useHotkeys(['u', 'Escape'], () => {
    const anyMenuOpen = useMenuStore.getState().open;
    if (!anyMenuOpen) {
      return navigation.goBack();
    }
  }, {
    enabled: Boolean(navigation.canGoBack()),
    enableOnFormTags: false,
    enableOnContentEditable: false,
  });
}
