import React, { useMemo } from 'react';

import { useRoute, RouteProp } from '@react-navigation/native';
import { ThreadList } from '../../ui/thread-list/ThreadList';

import {
  useStarThreadCallback,
  useUnstarThreadCallback,
  useSnoozeThreadCallback,
  usePrefetchThreadCallback,
  useSelectThreadCallback,
  useArchiveThreadCallback,
  useMoveToInboxCallback,
  useChangeUnreadStatusCallback
} from '../hooks/channelScreenHooks';
import { RootStackParamList } from '../../navigation/navigators/root/RootStackProps';
import { usePrefetchFreshThreads } from '../../hooks/usePrefetchFreshThreads';
import { useGetSelfInboxEvents } from '../hooks/api/useGetSelfInboxEvents';
import { useGetSelfUser } from '../hooks/api/useGetSelfUser';
import { useGetSelfOrganizations } from '../hooks/api/useGetSelfOrganizations';
import { useGetSelfOrganizationMembers } from '../hooks/api/useGetSelfOrganizationMembers';
import { useGetAllAccessibleChannels } from '../hooks/api/useGetAllAccessibleChannels';
import { createStandardErrorIfAny } from '@/adapters/other/createStandardError';
import { useUpdateInboxUnreadCountUponInboxItemsChange } from '../../hooks/useUpdateInboxUnreadCountUponInboxItemsChange';
import { EmptyFolderScreenSection } from '../../ui/EmptyFolderScreenSection';
import { CATEGORIES, Category } from '@/adapters/view-models/ChannelViewModel';

export function InboxScreenSectionController() {
  const route = useRoute<RouteProp<RootStackParamList, 'Inbox'>>();
  const { page, category: categorySlug } = route.params ?? {};
  const { currentData: selfUser } = useGetSelfUser();
  const { currentData: selfOrganizations, organizationId } = useGetSelfOrganizations();
  const { currentData: organizationMembers } = useGetSelfOrganizationMembers(organizationId);
  const { allRawChannels } = useGetAllAccessibleChannels(selfOrganizations);

  const {
    currentData, isLoading, error, data, threads
  } = useGetSelfInboxEvents(selfUser?.id, organizationMembers?.users, categorySlug, page, allRawChannels);

  useUpdateInboxUnreadCountUponInboxItemsChange(data);

  const threadHoveredCallback = usePrefetchThreadCallback();
  const selectThreadCallback = useSelectThreadCallback(currentData?.inboxItems);
  const archiveThreadCallback = useArchiveThreadCallback();
  const moveToInboxCallback = useMoveToInboxCallback();
  const changeUnreadStatusCallback = useChangeUnreadStatusCallback();
  const setSnoozeEndDateCallback = useSnoozeThreadCallback();
  const starThreadCallback = useStarThreadCallback();
  const unstarThreadCallback = useUnstarThreadCallback();

  usePrefetchFreshThreads(threads);

  const errorToDisplay = !currentData?.inboxItems ? createStandardErrorIfAny(error)?.displayMessage : undefined;

  const isLoadingForFirstTime = isLoading && !threads;

  const listEmptyComponent = useListEmptyComponent(categorySlug);

  return (
    <ThreadList
      showSpinner={isLoadingForFirstTime && !errorToDisplay}
      items={currentData?.inboxItems}
      errorToDisplay={errorToDisplay}
      selectThreadCallback={selectThreadCallback}
      archiveThreadCallback={archiveThreadCallback}
      showComposeArea={false}
      changeUnreadStatusCallback={changeUnreadStatusCallback}
      threadHoveredCallback={threadHoveredCallback}
      starThreadCallback={starThreadCallback}
      unstarThreadCallback={unstarThreadCallback}
      moveThreadToInboxCallback={moveToInboxCallback}
      setSnoozeEndDateCallback={setSnoozeEndDateCallback}
      ListEmptyComponent={listEmptyComponent}
    />
  );
}

function useListEmptyComponent(categorySlug?: Category['slug']) {
  return useMemo(() => {
    const categoryData = Object.values(CATEGORIES).find((category) => category.slug === categorySlug);
    if (categoryData) {
      return <EmptyFolderScreenSection iconSetName={categoryData.slug} text={`Your ${categoryData.name} folder is currently empty.`} />;
    }
    return <EmptyFolderScreenSection iconSetName="inbox" text="Your inbox is currently empty. New threads from channels you're part of, as well as messages sent directly to you, will appear here." />;
  }, [categorySlug]);
}
