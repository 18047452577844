/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { Feather, MaterialIcons } from '@expo/vector-icons';
import {
  HStack, Icon, IconButton, Text
} from 'native-base';
import { useHotkeys } from 'react-hotkeys-hook';
import { ComposeToolBarDivider } from './ComposeToolBarDivider';
import { SendButton } from './SendButton';
import { ParticipantViewModel } from '../../../../adapters/view-models/ParticipantViewModel';
import { isMac } from '../../../global/platform';
import { EmojiPickerPopoverProps } from '../../emoji/popover/EmojiPickerPopover';
import { EmojiSelectedCallback } from '../../emoji/popover/EmojiSelectedCallback';
import { AddReactionButton } from './AddReactionButton';
import { withTooltip } from '../../shortcuts/withTooltip';

const noop = () => {};

const ComposeToolbarButton = withTooltip('button');
const BoxWithTooltip = withTooltip('div');

export type ComposeToolbarProps = {
  displayed: boolean;
  enabled: boolean;
  sendButtonDisabled: boolean;
  submit?: () => void;
  uniqueId: number;
  hotkeysEnabled: boolean;
  emailRecipients?: ParticipantViewModel[] | null;
  allowModifyParticipants?: boolean;
  onModifyParticipants?: () => void;
  emojiSelectedCallback: EmojiSelectedCallback;
  emojiPickerOpenCallback?: (isOpen: boolean) => void;
  emojiPickerPlacement?: EmojiPickerPopoverProps['placement'];
};

export function ComposeToolbar(props: ComposeToolbarProps) {
  const keyCombination = isMac ? 'Cmd + Enter' : 'Ctrl + Enter';
  const hotkeysEnabled = props.hotkeysEnabled && !props.sendButtonDisabled;

  const submit = props.submit ?? noop;

  useHotkeys('mod+enter', submit, [submit], {
    enabled: hotkeysEnabled,
    enableOnFormTags: true,
    enableOnContentEditable: true
  });

  return (
    <HStack bgColor="light.50" alignItems="center" alignContent="center" p={props.displayed ? 2 : null} display={props.displayed ? undefined : 'none'} borderBottomRadius={8}>
      <div id={`toolbar-${props.uniqueId}`} style={{ padding: 0, margin: 0 }}>
        <HStack h="34px" alignItems="center" space={2} display={!props.displayed ? 'none' : null}>
          <ComposeToolbarButton tooltip={['Bold', 'mod+b']} className="ql-bold" disabled={!props.enabled} />
          <ComposeToolbarButton tooltip={['Italic', 'mod+i']} className="ql-italic" disabled={!props.enabled} />
          <ComposeToolbarButton tooltip={['Underline', 'mod+u']} className="ql-underline" disabled={!props.enabled} />
          <ComposeToolbarButton tooltip={['Strikethrough', 'mod+shift+x']} className="ql-strike" disabled={!props.enabled} />
          <ComposeToolBarDivider />
          <ComposeToolbarButton tooltip={['Insert image']} className="ql-image" disabled={!props.enabled} />
          <ComposeToolbarButton tooltip={['Link', 'mod+k']} className="ql-link" disabled={!props.enabled} />
          <ComposeToolBarDivider />
          <ComposeToolbarButton tooltip={['Unordered List', 'mod+shift+8']} className="ql-list" value="bullet" disabled={!props.enabled} />
          <ComposeToolbarButton tooltip={['Ordered List', 'mod+shift+7']} className="ql-list" value="ordered" disabled={!props.enabled} />
          <ComposeToolbarButton tooltip={['Quote', 'mod+shift+9']} className="ql-blockquote" disabled={!props.enabled} />
          <ComposeToolbarButton tooltip={['Code', 'mod+shift+m']} className="ql-code-block" disabled={!props.enabled} />
          <ComposeToolBarDivider />
          <ComposeToolbarButton tooltip={['Remove formatting', 'mod+\\']} className="ql-clean" disabled={!props.enabled}><Icon as={MaterialIcons} name="format-clear" /></ComposeToolbarButton>
          <ComposeToolBarDivider />
          <BoxWithTooltip tooltip={['Emoji']}>
            <AddReactionButton emojiPickerOpenCallback={props.emojiPickerOpenCallback} emojiSelectedCallback={props.emojiSelectedCallback} enabled={props.enabled} emojiPickerPlacement={props.emojiPickerPlacement} />
          </BoxWithTooltip>
        </HStack>
      </div>
      <HStack flexGrow={1} justifyContent="flex-end" space={1} display={!props.displayed ? 'none' : null} alignItems="center" alignContent="center">
        {props.allowModifyParticipants ? <IconButton onPress={props.onModifyParticipants} marginRight={1} p={2} icon={<Icon name="people" />} /> : null }
        { isMac ? <Icon as={Feather} name="command" color={!hotkeysEnabled ? 'dark.100' : 'dark.300'} /> : null}
        <Text fontSize="sm" mr={2} selectable={false} bold color={!hotkeysEnabled ? 'dark.100' : 'dark.300'}>
          {keyCombination}
        </Text>
        {props.submit ? <SendButton enabled={!props.sendButtonDisabled} submit={props.submit} emailRecipients={props.emailRecipients} /> : null}
      </HStack>
    </HStack>
  );
}
