import { memo } from 'react';
import isEqual from 'react-fast-compare';
import { HStack, Box } from 'native-base';
import { ThreadListItemPressableContainer } from './ThreadListItemPressableContainer';
import { ThreadListItemSnippetContainer } from './ThreadListItemSnippetContainer';
import { ThreadReactionsBlock } from './blocks/ThreadReactionsBlock';
import { ReactionsThreadListItemProps } from './ReactionsThreadListItemProps';
import { UNREAD_BUBBLE_SIZE } from './constants';

function SnippetAndReactionsThreadListItem(props: ReactionsThreadListItemProps) {
  const { inboxItem, selectThreadCallback: threadSelectedCallback } = props;
  const { thread } = inboxItem;

  return (
    <ThreadListItemPressableContainer thread={thread} selectThreadCallback={threadSelectedCallback} threadHoveredCallback={props.threadHoveredCallback}>
      <ThreadListItemSnippetContainer {...props} thread={thread} />
      <HStack flexGrow={1} space={3} pr={3} pb={1} pl={3} alignItems="center" maxW="100%">
        <Box h={2} w={`${UNREAD_BUBBLE_SIZE}px`} />
        <ThreadReactionsBlock inboxItem={inboxItem} />
      </HStack>
    </ThreadListItemPressableContainer>
  );
}

export default memo(SnippetAndReactionsThreadListItem, isEqual);
