import { ConversationParticipant } from '../../api/codegen';

export function getConversationParticipantDisplayName(sender?: ConversationParticipant) {
  if (!sender) {
    return 'No message';
  }

  if (sender.type === 'user') {
    return sender.user!.display_name || 'Unknown user';
  }

  const emailParticipant = sender.email_participant!;

  if (emailParticipant.name) {
    return `${emailParticipant.name} (${emailParticipant.email})`;
  }

  return emailParticipant.email;
}

export function getConversationParticipantDisplayNameForInitials(sender: ConversationParticipant) {
  if (sender.type === 'user') {
    return sender.user!.display_name || 'Unknown user';
  }

  const emailParticipant = sender.email_participant!;

  if (emailParticipant.name) {
    return emailParticipant.name;
  }

  return emailParticipant.email;
}
