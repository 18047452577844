import { Icon, Text } from 'native-base';
import { useHotkeys } from 'react-hotkeys-hook';
import { ComprehensiveThreadViewModel } from '../../../../adapters/view-models/ThreadViewModel';
import { CustomMenuItem } from '../../menu/CustomMenuItem';
import { CHANNEL_ICON_SETS } from '@/adapters/view-models/ChannelViewModel';
import { CommandItem, ProvideCommands, closeCommandMenu } from '../../CommandMenu';

export type AddStarMenuItemProps = {
  starThreadCallback: (thread: ComprehensiveThreadViewModel) => void;
  unstarThreadCallback: (thread: ComprehensiveThreadViewModel) => void;
  thread: ComprehensiveThreadViewModel;
};

export function ToggleStarMenuItem(props: AddStarMenuItemProps) {
  const { isStarred } = props.thread;
  const { starIconSet, hint } = getTextAndIconSet(isStarred);
  const onPress = () => (isStarred ? props.unstarThreadCallback(props.thread) : props.starThreadCallback(props.thread));

  return (
    <CustomMenuItem
      onPress={onPress}
    >
      <Icon as={starIconSet.collection} name={starIconSet.name} size="16px" color="dark.600" />
      <Text>{hint}</Text>
    </CustomMenuItem>
  );
}

export function getTextAndIconSet(isStarred: boolean) {
  const starIconSet = isStarred ? CHANNEL_ICON_SETS.starred.selected : CHANNEL_ICON_SETS.starred.default;
  const hint = isStarred ? 'Remove star' : 'Add star';
  return { starIconSet, hint };
}

type ToggleStarCommandProps = {
  thread: ComprehensiveThreadViewModel,
  starThreadCallback: (thread: ComprehensiveThreadViewModel) => void,
  unstarThreadCallback: (thread: ComprehensiveThreadViewModel) => void
};

export function ToggleStarCommand({ thread, starThreadCallback, unstarThreadCallback }: ToggleStarCommandProps) {
  const shortcut = 's';
  const hotkeyCallback = () => (thread.isStarred ? unstarThreadCallback(thread) : starThreadCallback(thread));

  useHotkeys(shortcut, hotkeyCallback, {
    enabled: true,
  });

  const { starIconSet } = getTextAndIconSet(thread.isStarred);

  if (thread.isStarred) {
    return (
      <ProvideCommands id="remove-star">
        <CommandItem
          icon={starIconSet}
          shortcut="s"
          onSelect={() => {
            closeCommandMenu();
            unstarThreadCallback(thread);
          }}
        >
          Remove star
        </CommandItem>
      </ProvideCommands>
    );
  }

  return (
    <ProvideCommands id="add-star">
      <CommandItem
        icon={starIconSet}
        shortcut="s"
        onSelect={() => {
          closeCommandMenu();
          starThreadCallback(thread);
        }}
      >
        Add star
      </CommandItem>
    </ProvideCommands>
  );
}
