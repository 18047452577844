import { GetSelfSpamThreadsApiArg } from '../../api/codegen';
import { getCurrentScreenParams, RouteInStackWithNoParametersDefined } from '../../../infrastructure/navigation/getCurrentScreenParams';
import { rootNavigationContainerRef } from '../../../infrastructure/navigation/navigators/root/RootNavigationContainerRef';

export function getSpamParams(): GetSelfSpamThreadsApiArg | undefined {
  const spamParams = getCurrentScreenParams(rootNavigationContainerRef.current?.getState(), 'Spam');

  if (spamParams) {
    return { pageToken: spamParams.page };
  }

  if (spamParams === RouteInStackWithNoParametersDefined) {
    return {};
  }
}
