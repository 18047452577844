import Hotjar from '@hotjar/browser';
import { useCallback, useEffect } from 'react';
import { NavigationProp, StackActions, useNavigation } from '@react-navigation/native';
import { useAppDependencies } from '../../dependencies';
import { AppHeader } from '../../ui/app-header/AppHeader';
import { useLogoutCallback } from '../hooks/useLogoutCallback';
import { RootStackParamList } from '../../navigation/navigators/root/RootStackProps';
import { useGetSelfUser } from '../hooks/api/useGetSelfUser';

export function AppHeaderController() {
  const { currentData, isLoading } = useGetSelfUser();
  const logoutCallback = useLogoutCallback();
  const { env } = useAppDependencies().environmentVariablesStore;

  const navigation = useNavigation<NavigationProp<RootStackParamList>>();

  useEffect(() => {
    if (parseInt(env.HOTJAR_ENABLED) && Hotjar.isReady() && currentData) {
      Hotjar.identify(currentData.id, {
        display_name: currentData.displayName,
      });
    }
  }, [currentData, env]);

  const navigateToInbox = useCallback(() => {
    navigation.dispatch(StackActions.replace('Inbox'));
  }, [navigation]);

  return (
    <AppHeader
      isLoadingSelfUser={isLoading}
      user={currentData}
      logoutCallback={logoutCallback}
      navigateToInbox={navigateToInbox}
    />
  );
}
