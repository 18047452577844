import {
  MarkAllThreadMessagesAsReadApiArg, MarkEventAsReadApiArg, MarkMessagesAsReadApiArg, MarkMessagesAsUnreadApiArg
} from '../api/codegen';
import { MutationHandler, MutationHandlerProps, PatchWrapper } from './MutationHandler';
import { ChannelsViewModel } from '../view-models/ChannelsViewModel';
import { MessagesViewModel } from '../view-models/MessagesViewModel';
import { ThreadViewModel } from '../view-models/ThreadViewModel';
import { typedKeys } from '../../infrastructure/global/typedKeys';
import { getCurrentChannelParams } from './utils/getCurrentChannelParams';
import { getInboxParams } from './utils/getInboxParams';
import { getSearchParams } from './utils/getSearchParams';
import { getSentParams } from './utils/getSentParams';
import { getSpamParams } from './utils/getSpamParams';

type PossiblePatchTypes = MarkMessagesAsReadApiArg | MarkMessagesAsUnreadApiArg | MarkAllThreadMessagesAsReadApiArg | MarkEventAsReadApiArg;
type ChangeType = 'read' | 'unread';

export abstract class UnreadCountMutationHandler<TArg, TResponse> extends MutationHandler<TArg, TResponse> {
  constructor(props: MutationHandlerProps<TArg, TResponse>) {
    super(props);
  }

  protected createChannelsListPatches(patch: PossiblePatchTypes, type: ChangeType, forceApplyCountChange = false) {
    const threads = this.getRelatedThreadsForCurrentRequest(patch);

    const threadMessagesPatches = this.createThreadMessagesPatches(patch, threads, type);

    const selfChannelsPatch = this.createSelfChannelsPatch(threads, type, forceApplyCountChange);
    const orgChannelsPatch = this.createOrgChannelsPatch(threads, type, forceApplyCountChange);
    const inboxPatch = this.createInboxCountPatch(threads, type, forceApplyCountChange);
    const threadPatches = this.createThreadPatches(threads, type);
    const spamPatch = this.createSpamCountPatch(threads, type, forceApplyCountChange);

    const patches: PatchWrapper<any>[] = [...threadPatches, selfChannelsPatch, ...threadMessagesPatches, spamPatch];

    if (orgChannelsPatch) {
      patches.push(orgChannelsPatch);
    }

    if (inboxPatch) {
      patches.push(inboxPatch);
    }

    return patches;
  }

  private createThreadPatches(threads: ThreadViewModel[], type: ChangeType) {
    return threads.map((thread) => this.updateQueryData('getThread', { threadId: thread.id }, (draftedThread) => {
      if (draftedThread) {
        draftedThread.isUnread = type === 'unread';
      }
    }));
  }

  private createThreadMessagesPatches(patch: PossiblePatchTypes, threads: ThreadViewModel[], type: ChangeType) {
    if ('messageIdsRequestBody' in patch) {
      const threadMessagesPatches = threads.map((thread) => this.updateQueryData('getThreadMessages', { threadId: thread.id }, (draftedMessages: MessagesViewModel) => {
        draftedMessages.messages.filter((message) => patch.messageIdsRequestBody.message_ids.includes(message.id)).forEach((message) => {
          message.isUnread = type === 'unread';
        });
      }));

      return threadMessagesPatches;
    }

    return [];
  }

  private getRelatedThreadsForCurrentRequest(patch: PossiblePatchTypes) {
    const threadId = this.getCurrentThreadId();
    if (threadId) {
      const thread = this.apiClient.endpoints.getThread.select({ threadId })(this.state).data;
      if (thread) {
        return [thread];
      }
    }

    const channelParams = getCurrentChannelParams();
    if (channelParams) {
      const allThreads = this.apiClient.endpoints.getChannelThreads.select(channelParams)(this.state).data?.threads as (ThreadViewModel[] | undefined);
      if (allThreads) {
        return this.filterRelatedThreads(allThreads, patch);
      }
    }

    const inboxParams = getInboxParams();
    if (inboxParams) {
      const allThreads = this.apiClient.endpoints.getSelfInboxEvents.select(inboxParams)(this.state).data?.inboxItems.map((item) => item.thread);
      if (allThreads) {
        return this.filterRelatedThreads(allThreads, patch);
      }
    }

    const sentParams = getSentParams();
    if (sentParams) {
      const allThreads = this.apiClient.endpoints.getSelfSentThreads.select(sentParams)(this.state).data?.threads as (ThreadViewModel[] | undefined);
      if (allThreads) {
        return this.filterRelatedThreads(allThreads, patch);
      }
    }

    const spamParams = getSpamParams();
    if (spamParams) {
      const allThreads = this.apiClient.endpoints.getSelfSpamThreads.select(spamParams)(this.state).data?.threads as (ThreadViewModel[] | undefined);
      if (allThreads) {
        return this.filterRelatedThreads(allThreads, patch);
      }
    }

    const searchParams = getSearchParams();
    if (searchParams) {
      const allThreads = this.apiClient.endpoints.getSelfFilteredThreads.select(searchParams)(this.state).data?.threads as (ThreadViewModel[] | undefined);
      if (allThreads) {
        return this.filterRelatedThreads(allThreads, patch);
      }
    }

    return [];
  }

  private filterRelatedThreads(threads: ThreadViewModel[], patch: PossiblePatchTypes) {
    if ('messageIdsRequestBody' in patch) {
      return threads.filter((thread) => patch.messageIdsRequestBody.message_ids.includes(thread.lastMessageId ?? ''));
    }
    if ('inboxItemId' in patch) {
      return threads.filter((thread) => thread.inboxItemId === patch.inboxItemId);
    }
    return threads.filter((thread) => patch.threadId === thread.id);
  }

  private createInboxCountPatch(threads: ThreadViewModel[], type: ChangeType, forceApplyCountChange: boolean) {
    const changedThreads = threads
      .filter((thread) => thread.eventId)
      .filter((thread) => shouldChangeUnreadCountForThread(thread, type, forceApplyCountChange));

    const sign = type === 'read' ? -1 : 1;
    if (changedThreads.length) {
      return this.updateQueryData('getSelfAccount', undefined, (draftedAccount) => {
        draftedAccount.numberOfUnreadEvents += changedThreads.length * sign;
        if (draftedAccount.numberOfUnreadEvents < 0) {
          draftedAccount.numberOfUnreadEvents = 0;
        }

        for (const inboxCategory of typedKeys(draftedAccount.numberOfUnreadEventsByCategory)) {
          for (const thread of changedThreads) {
            if (this.isThreadInInboxCategory(thread.labels, inboxCategory)) {
              draftedAccount.numberOfUnreadEventsByCategory[inboxCategory] += sign;
            }
          }
          if ((draftedAccount.numberOfUnreadEventsByCategory[inboxCategory] ?? 0) < 0) {
            draftedAccount.numberOfUnreadEventsByCategory[inboxCategory] = 0;
          }
        }
      });
    }
  }

  private createSpamCountPatch(threads: ThreadViewModel[], type: ChangeType, forceApplyCountChange: boolean) {
    const spamCount = threads
      .filter((thread) => thread.showMarkAsNotSpamButton)
      .filter((thread) => shouldChangeUnreadCountForThread(thread, type, forceApplyCountChange))
      .length;

    const sign = type === 'read' ? -1 : 1;

    return this.updateQueryData('getSelfAccount', undefined, (draftedAccount) => {
      draftedAccount.numberOfUnreadSpamThreads += spamCount * sign;
    });
  }

  private createOrgChannelsPatch(threads: ThreadViewModel[], type: ChangeType, forceApplyCountChange: boolean) {
    const orgId = this.getSelfOrganization()?.id;
    if (orgId) {
      return this.updateQueryData('getOrganizationChannels', { organizationId: orgId }, (draftedChannels: ChannelsViewModel) => {
        this.updateChannelsList(draftedChannels, threads, type, forceApplyCountChange);
      });
    }
  }

  private createSelfChannelsPatch(threads: ThreadViewModel[], type: ChangeType, forceApplyCountChange: boolean) {
    return this.updateQueryData('getSelfChannels', undefined, (draftedChannels: ChannelsViewModel) => {
      this.updateChannelsList(draftedChannels, threads, type, forceApplyCountChange);
    });
  }

  private updateChannelsList(draftedChannels: ChannelsViewModel, threads: ThreadViewModel[], type: ChangeType, forceApplyCountChange: boolean) {
    for (const channel of draftedChannels.channels) {
      if (threads.some((thread) => thread.channelIds.includes(channel.id) && shouldChangeUnreadCountForThread(thread, type, forceApplyCountChange))) {
        if (type === 'read') {
          channel.unreadCount = Math.max(0, channel.unreadCount - 1);
        } else {
          channel.unreadCount += 1;
        }
      }
    }
  }
}

const shouldChangeUnreadCountForThread = (thread: ThreadViewModel, type: ChangeType, forceApplyCountChange: boolean) => {
  const willEveryParameterBeUnread = thread.numberOfUnreadMessages === 0 || !thread.inboxItemIsUnread;
  return type === 'unread' || willEveryParameterBeUnread || forceApplyCountChange;
};
