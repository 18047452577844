const promiseResolvers: ((token: string | PromiseLike<string>) => void)[] = [];
const promiseRejectors: ((error: Error) => void)[] = [];

const defaultAccessTokenGetter = (): Promise<string> => {
  const newPromise = new Promise<string>((resolve, reject) => {
    promiseResolvers.push(resolve);
    promiseRejectors.push(reject);
  });

  setTimeout(() => {
    promiseRejectors.forEach((reject) => {
      reject(new Error('Timed out waiting for access token.'));
    });
  }, 10000);

  return newPromise;
};

let accessTokenGetter = defaultAccessTokenGetter;

export const accessTokenHandler = {
  getAccessTokenGetter: () => accessTokenGetter,
  setAccessTokenGetter: (getter: () => Promise<string>) => {
    accessTokenGetter = getter;
    resolvePromisesWaitingForAccessToken(getter);
  },
};

function resolvePromisesWaitingForAccessToken(getter: () => Promise<string>) {
  promiseResolvers.forEach((resolve) => {
    resolve(getter());
  });

  clearPromises();
}

function clearPromises() {
  promiseResolvers.length = 0;
  promiseRejectors.length = 0;
}
