import {
  Box, Divider, HStack, Spinner, VStack
} from 'native-base';
import { useMemo, useRef } from 'react';
import { MaterialIcons } from '@expo/vector-icons';
import { Status } from '../../../../adapters/api/codegen';
import { ComposeArea, ComposeAreaHandles, ComposeAreaSubmitCallback } from '../../compose';
import { MessagesListItem, ReactionSelectedCallback } from './ThreadMessageItem';
import { ComprehensiveMessageViewModel, createComprehensiveSendingMessageViewModel } from '../../../../adapters/view-models/MessageViewModel';
import { ComprehensiveThreadViewModel } from '../../../../adapters/view-models/ThreadViewModel';
import { ParticipantViewModel } from '../../../../adapters/view-models/ParticipantViewModel';
import { EmptyScreenSection } from '../../EmptyScreenSection';
import { DropZone } from '../../DropZone';
import { ThreadScreenParticipantsHeaderController } from '../../../controllers/headers/ThreadScreenParticipantsHeaderController';
import { ThreadOptionsSideMenu } from '../../thread-menu/ThreadOptionsSideMenu';
import { ThreadScreenHeaderDescription } from './ThreadScreenHeaderDescription';
import { useCurrentThread } from '../../../controllers/hooks/routes/useCurrentThread';
import { ThreadScreenHeaderTitle } from './ThreadScreenHeaderTitle';
import { threadDraftIdPrefix, useSendingByThread } from '../../../../domain/state/drafts';
import { useGetSelfUser } from '../../../controllers/hooks/api/useGetSelfUser';
import { ThreadMessagesList } from './ThreadMessagesList';

type ThreadScreenProps = {
  messages?: ComprehensiveMessageViewModel[];
  showSpinner: boolean;
  errorToDisplay?: string;
  submitCallback: ComposeAreaSubmitCallback;
  reactionSelectedCallback?: ReactionSelectedCallback;
  emailParticipants: ParticipantViewModel[] | null;
  setEmailParticipants: (participants: ParticipantViewModel[]) => void;
  thread: ComprehensiveThreadViewModel;
  setThreadStatusCallback: (threadId: string, status: Status) => void;
  updateThreadDueDateCallback: (threadId: string, dueDate: Date | null) => void;
  updateThreadAssigneeCallback: (threadId: string, assignee: string | null) => void;
  didSubmitNewChannelsCallback: (participants: ParticipantViewModel[]) => void;
  didDeleteChannelsCallback: (participants: ParticipantViewModel[]) => void;
};

export function ThreadScreenSection(props: ThreadScreenProps) {
  const { thread, messages } = props;
  const composeAreaRef = useRef<ComposeAreaHandles>(null);
  const { currentData: user } = useGetSelfUser();
  const { isLoading } = useCurrentThread();
  const isLoadingForFirstTime = isLoading && !messages;
  const sendingMessages = useSendingByThread(thread.id);
  const list: MessagesListItem[] = useMemo(() => {
    if (!props.messages || !user) {
      return [];
    }
    const sendingReversed = sendingMessages ? sendingMessages.slice().reverse() : [];
    return [
      ...sendingReversed.map((message) => createComprehensiveSendingMessageViewModel(message, user.rawUser)),
      ...props.messages
    ];
  }, [props.messages, sendingMessages, user]);

  return (
    <Box flex={1} minHeight={300} borderTopWidth={0} borderTopColor="transparent" mt={-3}>
      <VStack>
        <HStack justifyContent="space-between" pl={3} mb={0} space={5}>
          <ThreadScreenHeaderTitle thread={thread} showLoader={isLoadingForFirstTime} />
          <ThreadScreenParticipantsHeaderController />
        </HStack>
        <Box px={3}>
          <ThreadScreenHeaderDescription
            threadId={thread.id}
            description={thread.description ?? ''}
            showLoader={isLoadingForFirstTime}
            isDisabled={thread.isTrashed}
          />
        </Box>
      </VStack>
      {props.showSpinner ? <Spinner size="lg" flexGrow={1} /> : <Divider display="flex" />}
      {!props.showSpinner && props.errorToDisplay && !props.messages ? <EmptyScreenSection text={props.errorToDisplay} iconCollection={MaterialIcons} iconName="error-outline" /> : null}
      {!props.showSpinner && props.messages ? (
        <HStack flexGrow={1} flexShrink={1}>
          <ThreadOptionsSideMenu
            thread={props.thread}
            setThreadStatusCallback={props.setThreadStatusCallback}
            updateThreadDueDateCallback={props.updateThreadDueDateCallback}
            updateThreadAssigneeCallback={props.updateThreadAssigneeCallback}
          />
          <VStack flexGrow={1} flexShrink={1} px={2} pl={0}>
            <DropZone dropCallback={props.submitCallback} flexGrow={1} flexShrink={1} submitItem="message" display={props.showSpinner ? 'none' : null}>
              <ThreadMessagesList
                messages={list}
                thread={thread}
                composeAreaRef={composeAreaRef}
                reactionSelectedCallback={!props.thread.isTrashed ? props.reactionSelectedCallback : undefined}
              />
            </DropZone>
            <ComposeArea
              readOnly={props.thread.isTrashed}
              ref={composeAreaRef}
              bodyPlaceholder={!props.thread.isTrashed ? 'Reply to this thread' : 'Take this thread out of the trash to reply'}
              submitCallback={props.submitCallback}
              showTitle={false}
              emailRecipients={props.emailParticipants}
              participantsChangedCallback={props.setEmailParticipants}
              draftKey={`${threadDraftIdPrefix}${thread.id}`}
            />
          </VStack>
        </HStack>
      ) : null}
    </Box>
  );
}
