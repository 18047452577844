import { useRef, useEffect, useCallback } from 'react';
import { TimeoutId } from '../dependencies/TimeoutId';

export const useDelayedCallbacks = (callback: () => void, delayMs: number = 100) => {
  const timeoutIdRef = useRef<TimeoutId | null>(null);

  const handleMouseEnter = useCallback(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
    timeoutIdRef.current = setTimeout(callback, delayMs);
  }, [callback, delayMs]);

  const handleMouseLeave = useCallback(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
    timeoutIdRef.current = null;
  }, []);

  useEffect(() => () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
  }, []);

  return {
    handleMouseEnter,
    handleMouseLeave
  };
};
