import { useLayoutEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { Animated } from 'react-native';
import { useToken } from 'native-base';
import { useThreadListStore, useThreadListStoreSelector } from './context';

export function ActiveThreadBackground() {
  const layout = useThreadListStoreSelector(useShallow((state) => {
    if (!state.focusedId) return null;
    const itemLayout = state.layouts[state.focusedId];
    if (!itemLayout) return null;
    return { top: itemLayout.y, height: itemLayout.height };
  }));
  if (!layout) return null;
  return <AnimatedView top={layout.top} height={layout.height} />;
}

const usualHeight = 44;
const getScaleY = (height: number) => height / usualHeight;
const springConfig = { speed: 220, bounciness: 0, useNativeDriver: false };
const getTargetValues = (top: number, height: number) => {
  const offsetTop = (height - usualHeight) / 2;
  return {
    y: top + offsetTop,
    scaleY: height / usualHeight,
  };
};

function AnimatedView(props: { top: number; height: number; }) {
  const store = useThreadListStore();
  const [[animatedY, animatedScaleY]] = useState(() => {
    const { y, scaleY } = getTargetValues(props.top, props.height);
    return [new Animated.Value(y), new Animated.Value(getScaleY(scaleY))];
  });
  const [borderLeftColor, backgroundColor] = useToken('colors', ['primary.700', 'primary.50']);

  useLayoutEffect(() => {
    const { y, scaleY } = getTargetValues(props.top, props.height);
    if (store.getState().navigatedFromKeyboard) {
      Animated.spring(animatedY, { toValue: y, ...springConfig }).start();
      Animated.spring(animatedScaleY, { toValue: scaleY, ...springConfig }).start();
    } else {
      animatedY.setValue(y);
      animatedScaleY.setValue(scaleY);
    }
  }, [props.top, props.height, animatedY, animatedScaleY, store]);

  return (
    <Animated.View
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: usualHeight,
        transform: [{
          translateY: animatedY,
        }, {
          scaleY: animatedScaleY,
        }],
        borderLeftWidth: 3,
        borderLeftColor,
        backgroundColor,
      }}
    />
  );
}
