import {
  ComponentProps, Fragment, ReactNode, useEffect
} from 'react';
import { Command } from 'cmdk';
import { selectProvidedCommand, useCommandStore } from './store';
import { ProvidedCommandIds } from './types';

interface Props {
  id: ProvidedCommandIds;
  children: ReactNode;
}

export const ProvideCommands = ({ children, id }: Props) => {
  const provideCommands = useCommandStore((state) => state.provideCommands);
  useEffect(() => {
    const removeProvidedCommands = provideCommands(id, children);
    return () => removeProvidedCommands(id);
  }, [children, id, provideCommands]);
  return null;
};

export const ProvidedCommands = ({ id }: { id: ProvidedCommandIds }) => {
  const providedCommands = useCommandStore((state) => selectProvidedCommand(state, id));
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{providedCommands}</>;
};

interface ProvidedCommandsGroupProps extends ComponentProps<typeof Command.Group> {
  ids: ProvidedCommandIds[];
}

export const ProvidedCommandsGroup = ({ ids, ...groupProps }: ProvidedCommandsGroupProps) => {
  const providedCommands = useCommandStore((state) => {
    const result = [];
    for (const id of ids) {
      const commands = selectProvidedCommand(state, id);
      if (commands) result.push({ id, commands });
    }
    return result;
  });
  if (providedCommands.length === 0) {
    return null;
  }
  return (
    <Command.Group {...groupProps}>
      {providedCommands.map(({ id, commands }) => (
        <Fragment key={id}>{commands}</Fragment>
      ))}
    </Command.Group>
  );
};
