import * as React from 'react';
import {
  createContext, memo, ReactNode, useContext, useEffect, useMemo, useState
} from 'react';
import { Checkbox, Pressable } from 'native-base';
import { Animated } from 'react-native';
import { useThreadListStore, useThreadListStoreSelector } from '../../thread-list/context';

const CheckboxMemo = memo(Checkbox);

function withShiftKey(e: React.BaseSyntheticEvent): boolean {
  return (e as React.MouseEvent).shiftKey;
}

export function ThreadListItemCheckbox({ threadId }: { threadId: string }) {
  const threadSelected = useThreadListStoreSelector((state) => state.selectedIds.includes(threadId));
  const store = useThreadListStore();
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Pressable
      onPress={(e) => {
        e.preventDefault();
        e.stopPropagation();
        const shiftKey = withShiftKey(e);
        store.getState().toggleSelected(threadId, { shiftKey });
      }}
      onPointerDown={(e) => {
        // Prevents shift-click selection of text
        e.preventDefault();
      }}
      onPointerEnter={() => setIsHovered(true)}
      onPointerLeave={() => setIsHovered(false)}
      w={6}
      h={`${11 * 4}px`}
      alignItems="center"
      justifyContent="center"
      aria-label="Select thread"
    >
      <AnimatedCheckbox isHovered={isHovered} isChecked={threadSelected} />
    </Pressable>
  );
}

interface AnimatedCheckboxesContextValue {
  opacity: Animated.Value;
  shouldRender: boolean;
}

const animatedCheckboxesContext = createContext<AnimatedCheckboxesContextValue | null>(null);
const CheckboxesContextProvider = animatedCheckboxesContext.Provider;

/**
 * Using single animated value for all checkboxes to animate in sync
 */
export function AnimatedCheckboxesProvider({ children }: { children: ReactNode }) {
  const visible = useThreadListStoreSelector((state) => state.selectedIds.length > 0);
  const [shouldRender, setShouldRender] = useState(visible);
  const [opacity] = useState(() => new Animated.Value(visible ? 1 : 0));
  useEffect(() => {
    if (visible) {
      setShouldRender(true);
      Animated.timing(opacity, {
        duration: 80,
        toValue: 1,
        useNativeDriver: false,
      }).start();
    } else {
      Animated.timing(opacity, {
        duration: 80,
        toValue: 0,
        useNativeDriver: false,
      }).start(({ finished }) => {
        if (finished) setShouldRender(false);
      });
    }
  }, [opacity, visible]);
  const contextValue = useMemo(() => ({ opacity, shouldRender }), [opacity, shouldRender]);
  return (
    <CheckboxesContextProvider value={contextValue}>
      {children}
    </CheckboxesContextProvider>
  );
}

function AnimatedCheckbox({ isHovered, isChecked }: { isHovered: boolean; isChecked: boolean; }) {
  const context = useContext(animatedCheckboxesContext);
  if (!context) {
    throw new Error('AnimatedCheckbox must be rendered inside AnimatedCheckboxesProvider');
  }
  const { opacity, shouldRender } = context;
  const forceVisible = isHovered || isChecked;
  if (shouldRender || forceVisible) {
    return (
      <Animated.View style={{ opacity: forceVisible ? 1 : opacity, pointerEvents: 'none' }}>
        <CheckboxMemo
          value="selected"
          isChecked={isChecked}
          aria-label="Selected"
        />
      </Animated.View>
    );
  }
  return null;
}
