import { lazy, Suspense } from 'react';

type PickerProps = {
  autoFocus?: boolean;
  dynamicWidth?: boolean;
  emojiButtonColors?: any;
  emojiButtonRadius?: string;
  emojiButtonSize?: number;
  emojiSize?: number;
  emojiVersion?: number;
  exceptEmojis?: any[];
  icons?: string;
  locale?: string;
  maxFrequentRows?: number;
  navPosition?: string;
  noCountryFlags?: boolean;
  noResultsEmoji?: any;
  perLine?: number;
  previewEmoji?: any;
  previewPosition?: string;
  searchPosition?: string;
  set?: string;
  skin?: number;
  skinTonePosition?: string;
  theme?: string;
  categories?: any;
  categoryIcons?: any;
  custom?: any;
  data?: any;
  i18n?: any;
  getImageURL?: any;
  getSpritesheetURL?: any;
  onAddCustomEmoji?: any;
  onClickOutside?: any;
  onEmojiSelect?: any;
  stickySearch?: boolean;
};

const EmojiMartPickerLazy = lazy(() => import('./EmojiMartPicker'));

export function EmojiPicker(props: PickerProps) {
  return (
    <Suspense fallback={null}>
      <EmojiMartPickerLazy {...props} />
    </Suspense>
  );
}
