import { AntDesign } from '@expo/vector-icons';
import {
  HStack, Text, Icon, IconButton
} from 'native-base';
import { SelectedRecipientProps } from './SelectedRecipientProps';
import { ChannelViewModel } from '../../../../adapters/view-models/ChannelViewModel';
import { PictureViewModel } from '../../../../adapters/view-models/PictureViewModel';
import { UserViewModel } from '../../../../adapters/view-models/UserViewModel';

export function SelectedRecipient(props: SelectedRecipientProps) {
  const { recipient } = props;

  const channel = recipient as ChannelViewModel;
  const member = recipient as UserViewModel;
  const picture = recipient as PictureViewModel;

  const removeRecipientCallback = () => {
    props.removeRecipientCallback(recipient);
  };

  const name = member.displayName ?? channel.name ?? picture.name;

  return (
    <HStack
      flexShrink={0}
      space={0.5}
      alignItems="center"
      bgColor="gray.100"
      borderRadius={5}
      p={2}
      py={0.5}
      my={0.5}
      mr={2}
    >
      <Text>{name}</Text>
      <IconButton
        onPress={removeRecipientCallback}
        mr={-1}
        bgColor="transparent"
        _hover={{ bgColor: 'transparent', _icon: { color: 'muted.900' } }}
        size="xs"
        variant="solid"
        icon={<Icon as={AntDesign} name="close" color="muted.400" />}
      />
    </HStack>
  );
}
