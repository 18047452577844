import { formatDistance, formatRelative } from 'date-fns';
import { enGB } from 'date-fns/locale';

export function formatDate(stringDate: string, showTime: boolean = true) {
  const date = new Date(stringDate);
  const today = new Date();

  const diffInSeconds = (today.getTime() - date.getTime()) / 1000;
  const diffInMinutes = diffInSeconds / 60;
  const diffInHours = diffInMinutes / 60;
  const isToday = today.toDateString() === date.toDateString();

  if (diffInHours < 2) {
    return capitalizeFirstLetter(formatDistance(date, new Date(), { addSuffix: true }));
  }

  if (isToday) {
    return capitalizeFirstLetter(formatRelative(date, new Date()));
  }

  const formatRelativeLocale = determineRelativeTimeFormats(showTime);

  const locale = {
    ...enGB,
    formatRelative: (token: keyof typeof formatRelativeLocale) => formatRelativeLocale[token],
  };

  return formatRelative(date, new Date(), { locale });
  // return formatDistance(date, new Date(), { addSuffix: true });
  // return Math.round(diffInMinutes) + "m";

  // return date.toLocaleDateString("en-US", {
  //   year: "numeric",
  //   month: "short",
  //   day: "numeric",
  //   hour: "numeric",
  //   minute: "numeric",
  // });
}

function determineRelativeTimeFormats(showTime: boolean) {
  if (!showTime) {
    return {
      lastWeek: "'Last' eeee",
      yesterday: "'Yesterday'",
      today: "'Today'",
      tomorrow: "'Tomorrow'",
      nextWeek: "'Next' eeee",
      other: 'dd.MM.yyyy',
    };
  }

  return {
    lastWeek: "'Last' eeee 'at' hh:mm a",
    yesterday: "'Yesterday' 'at' hh:mm a",
    today: "'Today' 'at' hh:mm a",
    tomorrow: "'Tomorrow' 'at' hh:mm a",
    nextWeek: "'Next' eeee 'at' hh:mm a",
    other: "dd.MM.yyyy 'at' hh:mm a",
  };
}

function capitalizeFirstLetter(str: string): string {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}
