import { HeaderTitleProps } from '@react-navigation/elements';
import {
  Box
} from 'native-base';
import { HeaderTitle } from './HeaderTitle';

export type SectionHeaderTitle = HeaderTitleProps;

export function SectionHeaderTitle(props: SectionHeaderTitle) {
  return (
    <Box marginLeft={4}>
      <HeaderTitle {...props}>
        {props.children}
      </HeaderTitle>
    </Box>
  );
}
