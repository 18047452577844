import {
  ComponentProps, forwardRef, Ref, useCallback, useMemo, useRef, useState
} from 'react';
import {
  autoPlacement, autoUpdate, offset, shift, useFloating
} from '@floating-ui/react-dom';
import { FloatingPortal } from '@floating-ui/react';
import Quill from 'quill';
import { useMergeRefs } from '@/infrastructure/hooks/useMergeRefs';
import { PopoverState, QuillModuleEmoji, QuillModuleEmojiOptions } from './QuillModuleEmoji';
import { EmojiSearch, EmojiSearchHandle } from '../emoji/EmojiSearch';
import 'quill/dist/quill.snow.css';
import '../../styles/quill.css';
import { ReactQuill, ReactQuillHandle } from './ReactQuill';
import { KEYS } from './constants';
import { QuillMention } from '@/infrastructure/ui/rich-text-editor/mention/QuillMention';

Quill.register('modules/emoji', QuillModuleEmoji);

export type RichTextEditorHandle = ReactQuillHandle;

interface Props extends ComponentProps<typeof ReactQuill> {}

export const RichTextEditor = forwardRef(function RichTextEditor(props: Props, ref: Ref<RichTextEditorHandle>) {
  const [popover, setPopover] = useState<PopoverState | null>(null);
  const quillRef = useRef<ReactQuillHandle>(null);
  const mergedRef = useMergeRefs(ref, quillRef);

  const floating = useFloating({
    placement: 'top-start',
    open: Boolean(popover),
    elements: {
      reference: popover?.reference,
    },
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(4),
      autoPlacement({
        allowedPlacements: ['top-start', 'bottom-start'],
      }),
      shift({ padding: 8 }),
    ],
  });

  const emojiSearchRef = useRef<EmojiSearchHandle | null>(null);
  const selectActiveEmoji = useCallback(() => {
    emojiSearchRef.current?.selectActiveEmoji();
  }, []);
  const handleArrowKey = useCallback((key: KEYS) => {
    emojiSearchRef.current?.handleArrowKey(key);
  }, []);

  const modules = useMemo(() => {
    const options: QuillModuleEmojiOptions = {
      setPopover,
      selectActiveEmoji,
      handleArrowKey,
    };
    const emoji = props.modules?.emoji ? { emoji: options } : {};
    return {
      ...props.modules,
      ...emoji,
    };
  }, [handleArrowKey, props.modules, selectActiveEmoji]);

  return (
    <>
      <ReactQuill ref={mergedRef} {...props} modules={modules} />
      <QuillMention quillRef={quillRef} />
      <FloatingPortal>
        <div
          className="ql-emoji-picker"
          style={{
            display: floating.isPositioned ? 'block' : 'none',
            ...floating.floatingStyles,
          }}
          ref={floating.refs.setFloating}
        >
          <EmojiSearch
            query={popover?.query || ''}
            onEmojiSelect={popover?.onEmojiSelect}
            previewPosition={floating.placement.startsWith('top') ? 'bottom' : 'top'}
            ref={emojiSearchRef}
          />
        </div>
      </FloatingPortal>
    </>
  );
});
