import { Text } from 'native-base';
import {
  createRef, useMemo, useRef, useState
} from 'react';
import { TextInput } from 'react-native';
import { CustomMenuItem } from '../../menu/CustomMenuItem';
import { MenuDivider } from '../../menu/MenuDivider';
import { DateMenuItem } from './DateMenuItem';
import { DatePromptInput } from './DatePromptInput';
import { useDateOptions } from './options/useDateOptions';
import { usePreventCursorFromInterferingWithAutocomplete } from './usePreventCursorFromInterferingWithAutocomplete';
import { useFocusOnKeyPress } from './useFocusOnKeyPress';

type AutocompleteOptionsProps = {
  onEscape: () => void;
  setDate: (date: Date | null) => void;
  onPickTimeButtonPress: () => void;
  clearDateOptionLabel?: string;
  isClearDateOptionEnabled: boolean;
};

export function AutocompleteOptions(props: AutocompleteOptionsProps) {
  const promptInputRef = useRef<TextInput>(null);
  const [promptInputFocused, setPromptInputFocused] = useState(false);
  const [isMenuInteractionDisabled, setIsMenuInteractionDisabled] = useState(false);
  const [datePrompt, setDatePrompt] = useState<string>('');
  const options = useDateOptions(datePrompt);
  const forceFirstMenuItemFocus = promptInputFocused;
  const additionalNumberOfOptions = props.isClearDateOptionEnabled ? 2 : 1;

  const optionsRefs = useMemo(() => {
    return [...Array(options.length + (additionalNumberOfOptions))].map(() => createRef<any>());
  }, [options.length, additionalNumberOfOptions]);

  useFocusOnKeyPress(promptInputFocused, promptInputRef, setDatePrompt);
  usePreventCursorFromInterferingWithAutocomplete(setIsMenuInteractionDisabled);

  return (
    <>
      <DatePromptInput
        ref={promptInputRef}
        value={datePrompt}
        onChangeText={(text) => {
          setDatePrompt(text);
        }}
        onArrowDown={() => {
          optionsRefs[1].current?.focus();
        }}
        onArrowUp={() => {
          optionsRefs[optionsRefs.length - 1].current?.focus();
        }}
        onEscape={() => {
          props.onEscape();
        }}
        onEnter={() => {
          if (options.length > 0) {
            const firstOptionDate = options[0].date;
            props.setDate(firstOptionDate);
          } else {
            props.onPickTimeButtonPress();
          }
        }}
        onFocusChange={(focused) => {
          setPromptInputFocused(focused);
          setIsMenuInteractionDisabled(focused);
        }}
      />

      {options.map((option, index) => (
        <DateMenuItem
          focused={index === 0 && forceFirstMenuItemFocus ? true : undefined}
          key={option.dateLabel}
          setDate={props.setDate}
          {...option}
          ref={optionsRefs[index]}
          isDisabled={isMenuInteractionDisabled}
        />
      ))}

      <MenuDivider />
      <CustomMenuItem
        focused={options.length === 0 && forceFirstMenuItemFocus ? true : undefined}
        isDisabled={isMenuInteractionDisabled}
        ref={optionsRefs[options.length]}
        onPress={props.onPickTimeButtonPress}
      >
        <Text>Pick a time</Text>
      </CustomMenuItem>

      {props.isClearDateOptionEnabled && (
        <CustomMenuItem
          isDisabled={isMenuInteractionDisabled}
          ref={optionsRefs[options.length + 1]}
          onPress={() => props.setDate(null)}
        >
          <Text>{props.clearDateOptionLabel ?? 'Clear date'}</Text>
        </CustomMenuItem>
      )}
    </>
  );
}
