import { GetSelfInboxEventsApiArg } from '../api/codegen';
import { CacheTagProvider } from './CacheTagProvider';
import { InboxItemsViewModel } from '../view-models/InboxItemsViewModel';
import { ThreadViewModel } from '../view-models/ThreadViewModel';

export class GetSelfInboxEventsCacheTagProvider extends CacheTagProvider<GetSelfInboxEventsApiArg, InboxItemsViewModel> {
  protected generateTags(_arg: GetSelfInboxEventsApiArg, result: InboxItemsViewModel) {
    const threads = result.inboxItems.map((item) => item.thread);

    return [
      this.getListTag(),
      ...this.getChannelThreadTags(threads),
      ...this.getLastMessagesTags(threads),
      ...this.getEventTags(threads),
    ];
  }

  private getChannelThreadTags(result: ThreadViewModel[]) {
    return result.map((event) => ({ type: 'Event' as const, id: event.id }));
  }

  private getLastMessagesTags(result: ThreadViewModel[]) {
    return result
      .filter((thread) => thread.lastMessageId)
      .map((thread) => ({ type: 'Message' as const, id: thread.lastMessageId! }));
  }

  private getEventTags(result: ThreadViewModel[]) {
    return result
      .map((event) => ({ type: 'Thread' as const, id: event.id }));
  }

  protected getListTag() {
    return { type: 'InboxEventsList' as const };
  }
}
