import { Icon, Text, VStack } from 'native-base';
import React from 'react';
import { EmptyChannelScreenSectionProps } from './thread-list/ThreadList';

export function EmptyScreenSection(
  { text, iconCollection, iconName }: EmptyChannelScreenSectionProps
) {
  return (
    <VStack w="100%" space={3} justifyContent="center" alignItems="center" flexGrow={1} px={10}>
      <Icon as={iconCollection} name={iconName} size="xl" color="muted.500" />
      <Text selectable={false} fontSize="xl" color="muted.500" flexGrow={0} textAlign="center" maxW="70%">{text}</Text>
    </VStack>
  );
}
