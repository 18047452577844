import { PatchWrapper } from '../MutationHandler';
import { ThreadViewModel } from '../../view-models/ThreadViewModel';
import { createSelfFilteredThreadsPatch } from './factories/createSelfFilteredThreadsPatch';
import { createSelfSentThreadsPatch } from './factories/createSelfSentThreadsPatch';
import { createThreadsListPatch } from './factories/createThreadsListPatch';
import { createInboxPatch } from './factories/createInboxPatch';
import { createSelfSpamThreadsPatch } from './factories/createSelfSpamThreadsPatch';
import { createSnoozedThreadsPatch } from './factories/createSnoozedThreadsPatch';
import { createSelfAllThreadsPatch } from './factories/createSelfAllThreadsPatch';
import { createTrashedThreadsPatch } from './factories/createTrashedThreadsPatch';
import { createStarredThreadsPatch } from './factories/createStarredThreadsPatch';

export function createThreadUpdatePatches(threadsUpdateHandler: (draftedThreads: ThreadViewModel[]) => void) {
  const patches: PatchWrapper<any>[] = [
    createInboxPatch(threadsUpdateHandler),
    ...createThreadsListPatch(threadsUpdateHandler),
    createSelfSentThreadsPatch(threadsUpdateHandler),
    createSelfSpamThreadsPatch(threadsUpdateHandler),
    createSnoozedThreadsPatch(threadsUpdateHandler),
    createTrashedThreadsPatch(threadsUpdateHandler),
    createStarredThreadsPatch(threadsUpdateHandler),
    createSelfFilteredThreadsPatch(threadsUpdateHandler),
    createSelfAllThreadsPatch(threadsUpdateHandler),
  ];

  return patches;
}
