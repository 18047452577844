import { extendTheme } from 'native-base';

export function getModalStyle(componentName: string = 'Modal'): Parameters<typeof extendTheme>[0]['components']['Modal'] {
  return {
    [componentName]: {
      sizes: {
        xs: {
          contentSize: {
            maxWidth: 268,
          },
        },
        xxl: {
          contentSize: {
            width: '90%',
            maxWidth: '900'
          }
        },
      },
    },
    [`${componentName}Content`]: {
      baseStyle: {
        borderRadius: '12px',
        borderWidth: 0,
      },
      defaultProps: {
        backgroundColor: 'white',
      },
    },
    [`${componentName}CloseButton`]: {
      baseStyle: {
        top: '22px',
        right: '22px',
        p: 0,
        borderWidth: 0,
        w: '24px',
        h: '24px',
        alignItems: 'center',
        justifyContent: 'center',
        _icon: {
          size: '3.5',
        },
        _hover: {
          bg: 'muted.100',
        },
        _pressed: {
          bg: 'muted.200',
        },
      },
    },
    [`${componentName}Header`]: {
      baseStyle: {
        p: '24px',
        pb: '12px',
        borderBottomWidth: 0,
      },
      defaultProps: {
        backgroundColor: 'white',
      },
    },
    [`${componentName}Body`]: {
      baseStyle: {
        p: '24px',
        pt: '12px',
      },
      defaultProps: {
        backgroundColor: 'white',
      },
    },
    [`${componentName}Footer`]: {
      defaultProps: {
        backgroundColor: 'white',
      },
    },
  };
}
