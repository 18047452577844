import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { AppScreenController } from '../../../controllers/screens/AppScreenController';
import { SignInWithEmailAccessScreenController } from '../../../controllers/screens/SignInWithEmailAccessScreenController';
import { RegularSignInScreenController } from '../../../controllers/screens/RegularSignInScreenController';
import { PrivacyPolicyScreen } from '../../../ui/screens/PrivacyPolicyScreen';
import { CustomStackNavigator } from '../custom/CustomStackNavigator';
import { RootStackParamList } from './RootStackProps';

const Stack = createNativeStackNavigator<RootStackParamList>();

export function RootScreenNavigator() {
  return (
    <CustomStackNavigator>
      <Stack.Screen
        name="SignIn"
        component={RegularSignInScreenController}
        options={{
          headerShown: false,
          title: 'Upstream',
        }}
      />
      <Stack.Screen
        name="SignInWithEmailAccess"
        component={SignInWithEmailAccessScreenController}
        options={{
          headerShown: false,
          title: 'Upstream',
        }}
      />
      <Stack.Screen
        name="App"
        component={AppScreenController}
        options={{
          headerShown: false,
        }}
      />

      <Stack.Screen
        name="PrivacyPolicy"
        component={PrivacyPolicyScreen}
        options={{
          headerShown: false,
        }}
      />

    </CustomStackNavigator>
  );
}
