import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useCreateThreadMutation } from '../../../../adapters/api/codegen';
import { ComposeAreaSubmitCallback } from '../../../ui/compose';
import { participantsPerCategory } from '../participantsUtil';

export function useCreateThreadCallback(channelId: string) {
  const [createThread] = useCreateThreadMutation();
  const createThreadCallback: ComposeAreaSubmitCallback = useCallback(async ({ body, title, recipients }) => {
    const {
      internal: participantPublicIds,
      external: externalParticipantEmails
    } = participantsPerCategory(recipients ?? []);

    return await createThread({
      threadCreateRequestBody: {
        thread_id: uuidv4(),
        message_id: uuidv4(),
        message_body: body,
        thread_title: title != '' ? title : undefined,
        channel_ids: [channelId],
        participant_ids: participantPublicIds,
        external_participant_emails: externalParticipantEmails,
      }
    });
  }, [createThread, channelId]);
  return createThreadCallback;
}
