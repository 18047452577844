import { API_TAGS } from '../cache-tag-providers/cache-tag-types';
import { createAccountViewModel } from '../view-models/AccountViewModel';
import {
  getThreadMessagesCacheTagProvider,
  getChannelsCacheTagProvider,
  getSelfAccountCacheTagProvider,
  getOrganizationChannelsCacheTagProvider,
  getChannelThreadsCacheTagProvider,
  getThreadCacheTagProvider,
  getSelfInboxEventsCacheTagProvider,
  getSelfSentThreadsCacheTagProvider,
  getThreadsCacheTagProvider,
  getSpamThreadsCacheTagProvider,
  getSnoozedThreadsCacheTagProvider,
  getAllThreadsCacheTagProvider,
  getStarredThreadsCacheTagProvider,
  getTrashedThreadsCacheTagProvider,
} from './tag-providers';
import { ApiWithViewModelDefinitions, TagTypes } from './types';
import { createChannelsViewModel } from '../view-models/ChannelsViewModel';
import { createChannelViewModel } from '../view-models/ChannelViewModel';
import { createUserViewModel } from '../view-models/UserViewModel';
import { createUsersViewModel } from '../view-models/UsersViewModel';
import { createOrganizationsViewModel } from '../view-models/OrganizationsViewModel';
import { createPictureViewModelFromParticipant } from '../view-models/PictureViewModel';
import { createMessagesViewModel } from '../view-models/MessagesViewModel';
import { createThreadViewModel } from '../view-models/ThreadViewModel';
import { createThreadsViewModel } from '../view-models/ThreadsViewModel';
import { createInboxItemsViewModel } from '../view-models/InboxItemsViewModel';
import {
  rawApiClient,
  GetThreadMessagesApiResponse,
  GetSelfChannelsApiResponse,
  GetSelfAccountApiResponse,
  GetChannelApiResponse,
  GetOrganizationChannelsApiResponse,
  GetSelfUserApiResponse,
  GetOrganizationMembersApiResponse,
  GetSelfOrganizationsApiResponse,
  GetChannelParticipantsApiResponse,
  GetChannelThreadsApiResponse,
  GetThreadApiResponse,
  GetSelfInboxEventsApiResponse,
  GetSelfFilteredThreadsApiResponse,
  GetSelfSentThreadsApiResponse,
  GetSelfSpamThreadsApiResponse,
  GetSelfSnoozedThreadsApiResponse,
  GetSelfAllThreadsApiResponse,
  GetSelfTrashedThreadsApiResponse,
  GetSelfStarredThreadsApiResponse,
} from './codegen';

export function buildApiClientWithViewModels() {
  return rawApiClient.enhanceEndpoints<TagTypes, ApiWithViewModelDefinitions>({

    addTagTypes: API_TAGS,
    endpoints: {
      getThreadMessages: {
        providesTags: (result, _error, arg) => getThreadMessagesCacheTagProvider.provideTags(arg, result),
        transformResponse(baseQueryReturnValue: GetThreadMessagesApiResponse) {
          return createMessagesViewModel(baseQueryReturnValue);
        },
      },

      getSelfChannels: {
        providesTags: (result, _error, arg) => getChannelsCacheTagProvider.provideTags(arg, result),
        transformResponse(baseQueryReturnValue: GetSelfChannelsApiResponse) {
          return createChannelsViewModel(baseQueryReturnValue);
        },
      },

      getSelfAccount: {
        providesTags: (result, _error, arg) => getSelfAccountCacheTagProvider.provideTags(arg, result),
        transformResponse(baseQueryReturnValue: GetSelfAccountApiResponse) {
          return createAccountViewModel(baseQueryReturnValue);
        },
      },

      getChannel: {
        transformResponse(baseQueryReturnValue: GetChannelApiResponse) {
          return createChannelViewModel(baseQueryReturnValue);
        }
      },

      getOrganizationChannels: {
        providesTags: (result, _error, arg) => getOrganizationChannelsCacheTagProvider.provideTags(arg, result),
        transformResponse(baseQueryReturnValue: GetOrganizationChannelsApiResponse) {
          return createChannelsViewModel(baseQueryReturnValue);
        },
      },

      getSelfUser: {
        transformResponse(baseQueryReturnValue: GetSelfUserApiResponse) {
          return createUserViewModel(baseQueryReturnValue);
        }
      },

      getOrganizationMembers: {
        transformResponse(baseQueryReturnValue: GetOrganizationMembersApiResponse) {
          return createUsersViewModel(baseQueryReturnValue);
        },
      },

      getSelfOrganizations: {
        transformResponse(baseQueryReturnValue: GetSelfOrganizationsApiResponse) {
          return createOrganizationsViewModel(baseQueryReturnValue);
        }
      },

      getChannelParticipants: {
        transformResponse(baseQueryReturnValue: GetChannelParticipantsApiResponse) {
          return baseQueryReturnValue.map(createPictureViewModelFromParticipant);
        },
      },

      getChannelThreads: {
        providesTags: (result, _error, arg) => getChannelThreadsCacheTagProvider.provideTags(arg, result),
        transformResponse(baseQueryReturnValue: GetChannelThreadsApiResponse) {
          return createThreadsViewModel(baseQueryReturnValue);
        },
      },

      getSelfSentThreads: {
        providesTags: (result, _error, arg) => getSelfSentThreadsCacheTagProvider.provideTags(arg, result),
        transformResponse(baseQueryReturnValue: GetSelfSentThreadsApiResponse) {
          return createThreadsViewModel(baseQueryReturnValue);
        },
      },

      getSelfSnoozedThreads: {
        providesTags: (result, _error, arg) => getSnoozedThreadsCacheTagProvider.provideTags(arg, result),
        transformResponse(baseQueryReturnValue: GetSelfSnoozedThreadsApiResponse) {
          return createThreadsViewModel(baseQueryReturnValue);
        },
      },

      getSelfTrashedThreads: {
        providesTags: (result, _error, arg) => getTrashedThreadsCacheTagProvider.provideTags(arg, result),
        transformResponse(baseQueryReturnValue: GetSelfTrashedThreadsApiResponse) {
          return createThreadsViewModel(baseQueryReturnValue);
        },
      },

      getSelfStarredThreads: {
        providesTags: (result, _error, arg) => getStarredThreadsCacheTagProvider.provideTags(arg, result),
        transformResponse(baseQueryReturnValue: GetSelfStarredThreadsApiResponse) {
          return createThreadsViewModel(baseQueryReturnValue);
        },
      },

      getSelfSpamThreads: {
        providesTags: (result, _error, arg) => getSpamThreadsCacheTagProvider.provideTags(arg, result),
        transformResponse(baseQueryReturnValue: GetSelfSpamThreadsApiResponse) {
          return createThreadsViewModel(baseQueryReturnValue);
        },
      },

      getSelfAllThreads: {
        providesTags: (result, _error, arg) => getAllThreadsCacheTagProvider.provideTags(arg, result),
        transformResponse(baseQueryReturnValue: GetSelfAllThreadsApiResponse) {
          return createThreadsViewModel(baseQueryReturnValue);
        },
      },

      getSelfFilteredThreads: {
        providesTags: (result, _error, arg) => getThreadsCacheTagProvider.provideTags(arg, result),
        transformResponse(baseQueryReturnValue: GetSelfFilteredThreadsApiResponse) {
          return createThreadsViewModel(baseQueryReturnValue);
        },
      },

      getThread: {
        providesTags: (result, _error, arg) => getThreadCacheTagProvider.provideTags(arg, result),
        transformResponse(baseQueryReturnValue: GetThreadApiResponse) {
          return createThreadViewModel(baseQueryReturnValue);
        },
      },

      getSelfInboxEvents: {
        providesTags: (result, _error, arg) => getSelfInboxEventsCacheTagProvider.provideTags(arg, result),
        transformResponse(baseQueryReturnValue: GetSelfInboxEventsApiResponse) {
          return createInboxItemsViewModel(baseQueryReturnValue);
        },
      },
    }
  });
}
