import { CreateOrganizationApiResponse, Organization } from '../api/codegen';

export type OrganizationViewModel = {
  id: string;
  name: string;
  features: CreateOrganizationApiResponse['features'];
};

export function createOrganizationViewModel(organization: Organization): OrganizationViewModel {
  return {
    ...organization,
  };
}
