import { GetChannelParticipantsApiResponse, User } from '../api/codegen';
import { createUserViewModel, UserViewModel } from './UserViewModel';
import { createPictureViewModelFromParticipant } from './PictureViewModel';

export type UsersViewModel = {
  users: UserViewModel[];
};

export function createUsersViewModel(users: User[]): UsersViewModel {
  return {
    users: users.map(createUserViewModel),
  };
}

export function createChannelParticipantsViewModel(participants: GetChannelParticipantsApiResponse) {
  return participants.map(createPictureViewModelFromParticipant);
}
