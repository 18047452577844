import {
  ComponentProps, ComponentType, ReactElement, JSX, isValidElement,
} from 'react';
import type { FlatList, ListRenderItemInfo } from 'react-native';
import { map } from 'lodash';
import { AnimatePresence } from 'framer-motion';
import { ScrollArea } from '../scroll-area';
import { ThreadListItemIndexContextProvider } from '@/infrastructure/ui/thread-list-items/ThreadListItem.context';

export type ThreadListRenderItemInfo<T> = Pick<ListRenderItemInfo<T>, 'item' | 'index'>;

interface Props<T> extends ComponentProps<typeof FlatList<T>> {
  renderItem: (props: { item: T, index: number }) => JSX.Element;
}

function renderComponent(component?: ComponentType<any> | ReactElement | null) {
  if (!component) return null;
  if (isValidElement(component)) return component;
  if (typeof component === 'function' || typeof component === 'object') {
    const Component = component as ComponentType<any>;
    return <Component />;
  }
  return null;
}

export function ThreadFlatList<T>(props: Props<T>) {
  const hasItems = Boolean(props.data?.length);
  return (
    <>
      <ScrollArea>
        {hasItems && renderComponent(props.ListHeaderComponent)}
        <AnimatePresence>
          {map(props.data, (item, index) => {
            const key = props.keyExtractor?.(item, index) || (item as { key?: string }).key || index;
            return (
              <ThreadListItemIndexContextProvider key={key} value={index}>
                {props.renderItem({ item, index })}
              </ThreadListItemIndexContextProvider>
            );
          })}
        </AnimatePresence>
        {renderComponent(props.ListFooterComponent)}
      </ScrollArea>
      {!hasItems && renderComponent(props.ListEmptyComponent)}
    </>
  );
}
