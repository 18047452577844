import { Menu, PresenceTransition } from 'native-base';
import { ComponentProps, useCallback, useState } from 'react';
import { MenuDivider } from '../../menu/MenuDivider';
import { CustomMenuTitle } from '../../menu/CustomMenuTitle';
import { useMenuStore } from './useMenuStore';
import { DateTimePicker } from '../datetime-picker/DateTimePicker';
import { FocusableArea } from './FocusableArea';
import { AutocompleteOptions } from '../autocomplete/AutocompleteOptions';

type DatePickerMenuProps = {
  onSubmitDate: (date: Date | null) => void;
  menuPlacement?: ComponentProps<typeof Menu>['placement'];
  title: string;
  trigger: ComponentProps<typeof Menu>['trigger'];
  onOpenStateChange?: (isOpen: boolean) => void;
  clearDateOptionLabel?: string;
  activeDate?: {
    date: Date;
    label: string;
    isClearDateOptionEnabled: boolean;
  };
};

export function DatePickerMenu(props: DatePickerMenuProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const { setOpen } = useMenuStore();
  const { onSubmitDate, onOpenStateChange } = props;

  const changeMenuOpenStatus = useCallback((isOpen: boolean) => {
    onOpenStateChange?.(isOpen);
    setIsMenuOpen(isOpen);

    setTimeout(() => {
      setOpen(isOpen);
    }, 0);

    if (isOpen) {
      setIsPickerOpen(false);
    }
  }, [onOpenStateChange, setOpen]);

  const setDate = useCallback((date: Date | null) => {
    onSubmitDate(date);
    changeMenuOpenStatus(false);
  }, [changeMenuOpenStatus, onSubmitDate]);

  return (
    <Menu
      my="4px"
      shouldOverlapWithTrigger={false}
      placement={props.menuPlacement}
      trigger={props.trigger}
      closeOnSelect={false}
      _presenceTransition={{
        animate: {
          opacity: 1,
          transition: {
            duration: 50
          }
        }
      }}
      isOpen={isMenuOpen}
      onClose={() => changeMenuOpenStatus(false)}
      onOpen={() => changeMenuOpenStatus(true)}
      w="290px"
    >
      <CustomMenuTitle>{props.title}</CustomMenuTitle>
      <MenuDivider />

      {isPickerOpen && (
        <PresenceTransition
          initial={{
            opacity: 0
          }}
          visible={isPickerOpen}
          animate={{
            opacity: 1,
            transition: {
              duration: 75
            }
          }}
        >
          <FocusableArea>
            <DateTimePicker
              isPickerOpen={isPickerOpen}
              onCancel={() => changeMenuOpenStatus(false)}
              onSubmitDate={setDate}
              minDelayBetweenNowAndSelectedDate={60}
              activeDate={!props.activeDate ? undefined : {
                date: props.activeDate.date,
                label: props.activeDate.label
              }}
            />
          </FocusableArea>
        </PresenceTransition>
      )}

      {!isPickerOpen && (
        <AutocompleteOptions
          onEscape={() => changeMenuOpenStatus(false)}
          setDate={setDate}
          onPickTimeButtonPress={() => setIsPickerOpen(true)}
          clearDateOptionLabel={props.clearDateOptionLabel}
          isClearDateOptionEnabled={props.activeDate?.isClearDateOptionEnabled ?? false}
        />
      )}
    </Menu>
  );
}
