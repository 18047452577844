import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Toast } from 'native-base';
import { StackActions } from '@react-navigation/native';
import { RootStackScreenProps } from '../../navigation/navigators/root/RootStackProps';
import { AppScreen } from '../../ui/screens/AppScreen';
import { LoadingScreenSection } from '../../ui/screen-sections/LoadingScreenSection';
import { useHotjar } from '../../hooks/useHotjar';
import { useDatadogRum } from '../../hooks/datadog/useDatadogRum';
import { AppScreenMobile } from '../../ui/screens/AppScreenMobile';
import { useGoToComposeScreenHotkey } from '../../hooks/useGoToComposeScreenHotkey';
import { isMobile } from '../../global/platform';
import { useGetSelfAccount } from '../hooks/api/useGetSelfAccount';
import { useLogoutCallback } from '../hooks/useLogoutCallback';
import { linking } from '../../navigation/linking';
import { useUndoHotkey } from '../../hooks/useUndoHotkey';

const NOT_AUTHENTICATED_TOAST_ID = 'not-authenticated';

export function AppScreenController({ navigation }: RootStackScreenProps<'App'>) {
  const {
    isAuthenticated, isLoading, error
  } = useAuth0();
  useHotjar();
  useDatadogRum();
  useGoToComposeScreenHotkey();
  useUndoHotkey();
  const logout = useLogoutCallback();

  // Get the account information
  const { currentData: account, isLoading: isAccountLoading } = useGetSelfAccount();
  const { hasEmailSyncEnabled, isEmailSyncOperational } = account ?? { hasEmailSyncEnabled: false };

  useEffect(() => {
    // First check if the user is not authenticated.
    if (!isAuthenticated && !isLoading && !Toast.isActive(NOT_AUTHENTICATED_TOAST_ID)) {
      Toast.show({
        id: NOT_AUTHENTICATED_TOAST_ID,
        title: 'You are no longer authenticated, please sign in again.',
        duration: 5000,
      });

      if (error) {
        console.log(error);
      }

      navigation.dispatch(StackActions.replace('SignIn'));
      return;
    }

    // Then check if the email sync is not operational.
    if (isAuthenticated && !isLoading && !isAccountLoading && hasEmailSyncEnabled && !isEmailSyncOperational) {
      const path = linking.config?.screens.SignInWithEmailAccess;
      logout(`/${path}`);
    }

    // Otherwise, all good, show the app screen.
  }, [error, isAuthenticated, isLoading, logout, navigation, hasEmailSyncEnabled, isEmailSyncOperational, isAccountLoading]);

  if (!isAuthenticated && isLoading) {
    return <LoadingScreenSection />;
  }

  if (isMobile) {
    return <AppScreenMobile />;
  }

  return <AppScreen />;
}
