import { RouteProp } from '@react-navigation/native';
import { RootStackParamList } from './navigators/root/RootStackProps';

export type RouteParams<T extends keyof RootStackParamList> = RouteProp<RootStackParamList, T>['params'];

export const RouteNotInStack = undefined;
export const RouteInStackWithNoParametersDefined = null;

export const FIRST_ROUTE_IN_APP = 'Inbox' as const;

export function getCurrentScreenParams<T extends keyof RootStackParamList>(state: any, routeName: T): RouteParams<T> | undefined | null {
  if (!state?.routes?.length) {
    return RouteNotInStack;
  }
  const rootRoute = state.routes[0];
  if (rootRoute.name === routeName) {
    return rootRoute.params as RouteParams<T> ?? RouteInStackWithNoParametersDefined;
  }
  for (const route of rootRoute.state?.routes ?? []) {
    if (route.name === routeName) {
      return route.params as RouteParams<T> ?? RouteInStackWithNoParametersDefined;
    }
  }
  if (!rootRoute.state && routeName === FIRST_ROUTE_IN_APP) {
    // state after redirected from '/'
    return RouteInStackWithNoParametersDefined;
  }
  return RouteNotInStack;
}
