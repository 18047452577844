import { extendTheme } from 'native-base';
import { colors, type ColorTheme, getColors } from './colors';
import { components } from './components';
import { fontConfig } from './fontConfig';
import { fonts } from './fonts';

export const mainTheme = extendTheme({
  components,
  colors,
  fontConfig,
  fonts
} as Parameters<typeof extendTheme>[0]);

export const getMainTheme = (theme: ColorTheme) => extendTheme({
  components,
  colors: getColors(theme),
  fontConfig,
  fonts
} as Parameters<typeof extendTheme>[0]);
