import { useEffect, useMemo } from 'react';
import { apiClient } from '../../adapters/api';
import { useAppDispatch } from '../state/hooks';
import { InboxItemsViewModel } from '../../adapters/view-models/InboxItemsViewModel';
import { usePreviousValue } from './usePreviousValue';

export function useUpdateInboxUnreadCountUponInboxItemsChange(items: InboxItemsViewModel | undefined) {
  const unreadCount = useMemo(() => {
    return items?.inboxItems?.map((item) => item.thread).reduce((acc, thread) => (acc + (thread.isUnread ? 1 : 0)), 0);
  }, [items?.inboxItems]);

  const previousUnreadCount = usePreviousValue(unreadCount) ?? unreadCount;

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (unreadCount !== previousUnreadCount && typeof unreadCount === 'number' && typeof previousUnreadCount === 'number') {
      dispatch(apiClient.util.invalidateTags(['SelfAccount']));
    }
  }, [unreadCount, previousUnreadCount, dispatch]);
}
