let preventUnloadCount = 0;

function handleBeforeUnload(event: BeforeUnloadEvent) {
  event.preventDefault();
  event.returnValue = '';
}

export async function preventUnload(callback: () => Promise<any>) {
  preventUnloadCount++;
  window.onbeforeunload = handleBeforeUnload;
  try {
    return await callback();
  } finally {
    preventUnloadCount--;
    if (preventUnloadCount === 0) {
      window.onbeforeunload = null;
    }
  }
}
