import { useEffect } from 'react';

/**
 * Prevents the cursor from autofocusing menu options as the user
 * types in the date prompt, when the cursor is above the menu.
 */
export function usePreventCursorFromInterferingWithAutocomplete(setPromptInputFocused: (focused: boolean) => void) {
  useEffect(() => {
    const handleMouseMove = () => {
      setPromptInputFocused(false);
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [setPromptInputFocused]);
}
