import { updateQueryData } from '../../utils/updateQueryData';
import { getInboxParams } from '../../utils/getInboxParams';
import { ThreadsUpdateHandler } from '../ThreadsUpdateHandler';

export function createInboxPatch(threadUpdateHandler: ThreadsUpdateHandler) {
  return updateQueryData('getSelfInboxEvents', getInboxParams() ?? {}, (draftedEvents) => {
    const threads = draftedEvents.inboxItems.map((item) => item.thread);
    threadUpdateHandler(threads);
  });
}
