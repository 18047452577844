import {
  InterTight_100Thin, InterTight_200ExtraLight, InterTight_300Light, InterTight_400Regular, InterTight_500Medium, InterTight_600SemiBold, InterTight_700Bold, InterTight_800ExtraBold, InterTight_900Black, InterTight_100Thin_Italic, InterTight_200ExtraLight_Italic, InterTight_300Light_Italic, InterTight_400Regular_Italic, InterTight_500Medium_Italic, InterTight_600SemiBold_Italic, InterTight_700Bold_Italic, InterTight_800ExtraBold_Italic, InterTight_900Black_Italic
} from '@expo-google-fonts/inter-tight';
import {
  SpaceGrotesk_300Light, SpaceGrotesk_400Regular, SpaceGrotesk_500Medium, SpaceGrotesk_600SemiBold, SpaceGrotesk_700Bold
} from '@expo-google-fonts/space-grotesk';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { useEffect, useState } from 'react';

const materialCommunity = require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf');
const material = require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialIcons.ttf');
const feather = require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Feather.ttf');
const entypo = require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Entypo.ttf');
const antDesign = require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/AntDesign.ttf');

async function loadResourcesAndDataAsync() {
  try {
    void SplashScreen.preventAutoHideAsync();

    // Load fonts
    const mainFonts = Font.loadAsync({
      SpaceGrotesk_300Light,
      SpaceGrotesk_400Regular,
      SpaceGrotesk_500Medium,
      SpaceGrotesk_600SemiBold,
      SpaceGrotesk_700Bold,
      InterTight_100Thin,
      InterTight_200ExtraLight,
      InterTight_300Light,
      InterTight_400Regular,
      InterTight_500Medium,
      InterTight_600SemiBold,
      InterTight_700Bold,
      InterTight_800ExtraBold,
      InterTight_900Black,
      InterTight_100Thin_Italic,
      InterTight_200ExtraLight_Italic,
      InterTight_300Light_Italic,
      InterTight_400Regular_Italic,
      InterTight_500Medium_Italic,
      InterTight_600SemiBold_Italic,
      InterTight_700Bold_Italic,
      InterTight_800ExtraBold_Italic,
      InterTight_900Black_Italic,
      // ...FontAwesome.font,
      // // eslint-disable-next-line global-require
      // 'space-mono': require('../assets/fonts/SpaceMono-Regular.ttf'),
    });

    void Font.loadAsync({
      material, materialCommunity, feather, entypo, antDesign
    });

    await mainFonts;
  } catch (e) {
    // We might want to provide this error information to an error reporting service
    console.warn(e);
  } finally {
    void SplashScreen.hideAsync();
  }
}

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    void loadResourcesAndDataAsync().finally(() => setLoadingComplete(true));
  }, []);

  return isLoadingComplete;
}
