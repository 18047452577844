import { create } from 'zustand';

export const useMenuStore = create<PickerMenuStore>((set) => ({
  open: false,
  setOpen: (open: boolean) => set({ open }),
}));

interface PickerMenuStore {
  readonly open: boolean;
  setOpen: (open: boolean) => void;
}
