import { GetSelfInboxEventsApiResponse } from '../api/codegen';
import { ThreadViewModelAdditionalParams } from './ThreadViewModel';
import {
  ComprehensiveInboxItemViewModel, InboxItemViewModel, createComprehensiveInboxItemViewModel, createInboxItemViewModel
} from './InboxItemViewModel';

export type InboxItemsViewModel = {
  inboxItems: InboxItemViewModel[];
  meta: GetSelfInboxEventsApiResponse['meta'];
};

export type ComprehensiveInboxItemsViewModel = {
  inboxItems: ComprehensiveInboxItemViewModel[];
  meta: GetSelfInboxEventsApiResponse['meta'];
};

export function createInboxItemsViewModel(inboxItems: GetSelfInboxEventsApiResponse): InboxItemsViewModel {
  return {
    inboxItems: inboxItems.data.map((inboxItem) => createInboxItemViewModel(inboxItem)),
    meta: inboxItems.meta,
  };
}

export function createComprehensiveInboxItemsViewModel(inboxItems: InboxItemsViewModel, params: ThreadViewModelAdditionalParams): ComprehensiveInboxItemsViewModel {
  return {
    inboxItems: inboxItems.inboxItems.map((inboxItem) => createComprehensiveInboxItemViewModel(inboxItem, params)),
    meta: inboxItems.meta,
  };
}
