import React, { useCallback, useRef } from 'react';

import { Button, HStack, Icon } from 'native-base';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { buildThreadChannelsMutationDescription } from '../UndoMutationDescriptions';
import {
  useWithMutationMetadata
} from '../../../adapters/mutation-cancellation/mutationCancellation';
import { useAddThreadChannelsMutation, useRemoveThreadChannelsMutation } from '../../../adapters/api/codegen';
import { useCurrentThread } from '../hooks/routes/useCurrentThread';

import { ChannelBadgesList } from '../../ui/ChannelBadgesList';
import { AddParticipantsModalControllerHandles } from '../modals/AddParticipantsModalController';
import { ManageParticipantsModalController } from '../modals/ManageParticipantsModalController';
import { ParticipantViewModel } from '../../../adapters/view-models/ParticipantViewModel';

export function ThreadScreenChannelsHeaderController() {
  const threadData = useCurrentThread();
  const manageChannelsModalControllerRef = useRef<AddParticipantsModalControllerHandles>(null);

  const channelsListPressedCallback = useCallback(() => {
    manageChannelsModalControllerRef.current?.show();
  }, []);

  const thread = threadData?.thread;
  const threadId = thread?.id;

  const [addThreadChannels] = useWithMutationMetadata(useAddThreadChannelsMutation());
  const [removeThreadChannels] = useWithMutationMetadata(useRemoveThreadChannelsMutation());

  const addChannelsCallback = useCallback(async (channels: ParticipantViewModel[]) => {
    const channelIds = channels.map((channel) => channel.id);
    if (threadId) {
      await addThreadChannels({ threadId, body: channelIds }, {
        initiator: 'user',
        description: buildThreadChannelsMutationDescription(true, channels),
      });
    }
  }, [addThreadChannels, threadId]);

  const removeChannelsCallback = useCallback(async (channels: ParticipantViewModel[]) => {
    const channelIds = channels.map((channel) => channel.id);
    if (threadId) {
      await removeThreadChannels({ threadId, body: channelIds }, {
        initiator: 'user',
        description: buildThreadChannelsMutationDescription(true, channels),
      });
    }
  }, [removeThreadChannels, threadId]);

  if (!thread?.relevantChannels) {
    return null;
  }

  return (
    <HStack space={4} alignItems="center">
      <ChannelBadgesList justifyContent="flex-end" channels={thread.relevantChannels ?? []} badgeProps={{ py: 1 }} />

      <Button
        isDisabled={thread.isTrashed}
        size="xs"
        variant="text"
        onPress={channelsListPressedCallback}
        leftIcon={<Icon name={thread.relevantChannels.length > 0 ? 'plus-minus' : 'plus'} as={MaterialCommunityIcons} size="sm" />}
      >
        {thread.relevantChannels.length > 0 ? 'Manage channels' : 'Add channels'}
      </Button>

      <ManageParticipantsModalController
        participants={thread.relevantChannels}
        ref={manageChannelsModalControllerRef}
        didSubmitNewParticipantsCallback={addChannelsCallback}
        didDeleteParticipantsCallback={removeChannelsCallback}
        participantsType="channels"
      />
    </HStack>
  );
}
