import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistReducer, persistStore, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE
} from 'redux-persist';
import { rtkQueryErrorLogger } from './rtkQueryErrorLogger';
import { apiClient } from '@/adapters/api';
import { draftsSlice } from './drafts';
import { persistStorage } from './persist-storage';

const rootReducer = combineReducers({
  [apiClient.reducerPath]: apiClient.reducer,
  [draftsSlice.name]: draftsSlice.reducer,
});

const persistConfig = {
  key: 'root',
  storage: persistStorage,
  whitelist: [draftsSlice.name], // only yourSlice will be persisted
  // or use blacklist if you want to exclude specific slices
  // blacklist: ['otherSlice']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(rtkQueryErrorLogger, apiClient.middleware),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
