import { MessageReaction } from '../api/codegen';
import { UserViewModel } from './UserViewModel';
import { ReactionViewModel, createReactionViewModel } from './ReactionViewModel';
import { getEmojiByUnified } from '../other/getEmojiByUnified';

export type MessageReactionViewModel = {
  displayText: string;
  count: number;
  isHighlighted: boolean;
  hint: string;
  reactionViewModel: ReactionViewModel;
};

export type MessageReactionViewModelAdditionalParams = {
  selfUserId: string | undefined;
  users: UserViewModel[] | undefined;
};

export function createMessageReactionViewModel(messageReaction: MessageReaction, params: MessageReactionViewModelAdditionalParams): MessageReactionViewModel {
  const userNames = params.users ? messageReaction.user_ids.map((userId) => {
    const user = params.users!.find((user) => user.id === userId);
    return user?.displayName;
  }).filter((name): name is string => !!name) : [];

  return {
    displayText: getEmojiByUnified(messageReaction.reaction.code),
    count: messageReaction.user_ids.length,
    isHighlighted: params.selfUserId ? messageReaction.user_ids.includes(params.selfUserId) : false,
    hint: userNames.join(', '),
    reactionViewModel: createReactionViewModel(messageReaction.reaction)
  };
}
