import { CHANNELS_QUERY_OPTIONS } from '../../../api/query-parameters';
import { apiClient, useGetSelfChannelsQuery } from '../../../../adapters/api';

export const useGetSelfChannels = () => {
  const channels = useGetSelfChannelsQuery(undefined, CHANNELS_QUERY_OPTIONS);

  return channels;
};

export const usePrefetchSelfChannels = () => apiClient.usePrefetch('getSelfChannels');
