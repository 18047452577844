import { Text } from 'native-base';

import { useMemo } from 'react';
import { VERTICAL_PADDING } from '../constants';

const nbsp = '\u00A0';
const twoNbsp = nbsp.repeat(2);

export function removeExtraSnippetSpace(text: string) {
  return text.trim().replace(/[\s\p{Cf}\p{Mn}]{2,}/gu, twoNbsp);
}

export function ThreadSnippetText(props: { text: string }) {
  const text = useMemo(() => removeExtraSnippetSpace(props.text), [props.text]);
  return (
    <Text
      fontSize="sm"
      selectable={false}
      numberOfLines={1}
      flexShrink={0}
      flexGrow={1}
      flexBasis={0}
      minWidth={0}
      color="opacityBlack.500"
      isTruncated
      py={VERTICAL_PADDING}
    >
      {text}
    </Text>
  );
}
