import { useMemo } from 'react';
import { determineDateFromPrompt } from './determineDateFromPrompt';
import { DateProps } from './DateProps';

export function useDefaultDateOptions() {
  return useMemo(() => {
    return generateDefaultOptions();
  }, []);
}

export function generateDefaultOptions(): DateProps[] {
  const currentDate = new Date();
  const isWeekend = currentDate.getDay() === 0 || currentDate.getDay() === 6;
  const isNotWeekend = !isWeekend;
  const isEarlyEnoughInWeek = currentDate.getDay() <= 3 && isNotWeekend;

  const defaultOptions: {
    dateLabel: string;
    datePrompt: string;
  }[] = [];

  if (currentDate.getHours() < 18) {
    defaultOptions.push({ dateLabel: 'Later today', datePrompt: 'today at 18:00' });
  }

  defaultOptions.push(
    { dateLabel: 'Tomorrow morning', datePrompt: 'tomorrow at 8:00 am' },
    { dateLabel: 'Next week', datePrompt: 'next Monday at 8:00 am' }
  );

  if (isNotWeekend) {
    defaultOptions.push({ dateLabel: 'This weekend', datePrompt: 'saturday at 8:00 am' });
  }

  if (isWeekend) {
    defaultOptions.push({ dateLabel: 'Next weekend', datePrompt: 'next week saturday at 8:00 am' });
  }

  if (isEarlyEnoughInWeek) {
    defaultOptions.push({ dateLabel: 'Later this week', datePrompt: 'in 2 days at 1 pm' });
  }

  return defaultOptions.map((option) => ({
    dateLabel: option.dateLabel,
    date: determineDateFromPrompt(option.datePrompt) as Date,
  }));
}
