import type Quill from 'quill';
import { debounce } from 'lodash';
import type { ClientRectObject, ReferenceType, VirtualElement } from '@floating-ui/react';
import { KEYS, maxEmojiShortcodeLength } from './constants';
import type { KeyboardBindingHandler } from './types';

export interface PopoverState {
  query: string;
  onEmojiSelect: QuillModuleEmoji['onEmojiSelect'];
  reference: ReferenceType;
}

export interface QuillModuleEmojiOptions {
  setPopover: (popover: PopoverState | null) => void;
  selectActiveEmoji: () => void;
  handleArrowKey: (key: KEYS) => void;
}

export class QuillModuleEmoji {
  private colonPos: number | null = null;
  private query: string | null = null;
  private isOpen: boolean = false;

  constructor(private quill: Quill, private options: QuillModuleEmojiOptions) {
    this.quill.on('text-change', (_delta, _oldDelta, source) => {
      if (source === 'user') {
        this.onChangeCurrent();
      }
    });

    this.quill.on('selection-change', (range) => {
      if (range?.length === 0) {
        this.onChangeCurrent();
      } else {
        this.close();
      }
    });

    const whenOpen = (handler: KeyboardBindingHandler): KeyboardBindingHandler => (...args) => {
      if (!this.isOpen) return true;
      return handler.call({ quill: this.quill }, ...args);
    };

    this.quill.keyboard.addBinding({ key: KEYS.ENTER }, whenOpen(() => this.options.selectActiveEmoji()));
    this.moveKeyboardBindingToStart(KEYS.ENTER);

    this.quill.keyboard.addBinding({ key: KEYS.TAB }, whenOpen(() => this.options.selectActiveEmoji()));
    this.moveKeyboardBindingToStart(KEYS.TAB);

    this.quill.keyboard.addBinding({ key: KEYS.ESCAPE }, whenOpen(() => this.close()));

    const arrowKeys = [KEYS.LEFT, KEYS.UP, KEYS.RIGHT, KEYS.DOWN];
    this.quill.keyboard.addBinding({ key: arrowKeys }, whenOpen((_, __, { key }) => {
      this.options.handleArrowKey(key as KEYS);
    }));
  }

  moveKeyboardBindingToStart = (key: string) => {
    const addedBinding = this.quill.keyboard.bindings[key].pop();
    this.quill.keyboard.bindings[key].unshift(addedBinding!);
  };

  get quillContainer(): HTMLDivElement {
    // @ts-ignore
    return this.quill.container;
  }

  onChange = () => {
    const range = this.quill.getSelection();
    if (range === null) return;
    const length = Math.min(range.index, maxEmojiShortcodeLength);
    const textBeforeCursor = this.quill.getText(range.index - length, length);
    const match = textBeforeCursor.match(/(?:^|\s):(\w+)$/);
    if (match) {
      const query = match[1];
      this.colonPos = range.index - query.length - 1;
      this.query = query;
      this.open(query);
    } else {
      this.close();
    }
  };
  onChangeDebounced = debounce(this.onChange, 100);
  onChangeCurrent: () => void = this.onChangeDebounced;
  close = () => {
    this.onChangeCurrent = this.onChangeDebounced;
    this.options.setPopover(null);
    this.isOpen = false;
  };
  open = (query: string) => {
    this.onChangeCurrent = this.onChange;
    this.isOpen = true;
    const virtualElement: VirtualElement = {
      getBoundingClientRect: (): ClientRectObject => {
        const rect = this.quillContainer.getBoundingClientRect();
        const bounds = this.quill.getBounds(this.colonPos || 0, query.length + 1);
        const x = rect.left + (bounds?.left || 0);
        const y = rect.top + (bounds?.top || 0);
        return {
          x,
          y,
          top: y,
          left: x,
          bottom: rect.top + (bounds?.bottom || 0),
          right: rect.left + (bounds?.right || 0),
          width: (bounds?.width || 0),
          height: (bounds?.height || 0),
        };
      },
    };
    this.options.setPopover({
      query,
      onEmojiSelect: this.onEmojiSelect,
      reference: virtualElement,
    });
  };
  onEmojiSelect = (emoji: { native: string; }) => {
    if (this.colonPos === null || this.query === null) return;
    this.quill.deleteText(this.colonPos, this.query.length + 1);
    this.quill.insertText(this.colonPos, emoji.native, 'user');
    this.quill.setSelection(this.colonPos + emoji.native.length, 0, 'user');
    this.close();
  };
}
