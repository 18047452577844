import {
  UnstarThreadsApiArg,
  UnstarThreadsApiResponse
} from '../api/codegen';
import { MutationHandler, MutationHandlerProps } from './MutationHandler';
import { ThreadViewModel } from '../view-models/ThreadViewModel';
import { createThreadUpdatePatchesForThreadId } from './thread-patch-factory/createThreadUpdatePatchesForThreadId';
import { createStarredThreadsPatch } from './thread-patch-factory/factories/createStarredThreadsPatch';

export class UnstarThreadsMutationHandler extends MutationHandler<UnstarThreadsApiArg, UnstarThreadsApiResponse> {
  constructor(props: MutationHandlerProps<UnstarThreadsApiArg, UnstarThreadsApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: UnstarThreadsApiArg) {
    return [
      ...this.createThreadUpdatePatchesForThreadId(patch),
      this.createRemoveThreadFromFolderPatch(patch),
    ];
  }

  private updateThread(thread: ThreadViewModel) {
    thread.isStarred = false;
  }

  private createThreadUpdatePatchesForThreadId(patch: UnstarThreadsApiArg) {
    const threadIds = patch.threadIdsRequestBody.thread_ids;
    return threadIds.flatMap((threadId) => createThreadUpdatePatchesForThreadId(threadId, this.updateThread));
  }

  private createRemoveThreadFromFolderPatch(patch: UnstarThreadsApiArg) {
    const threadIds = patch.threadIdsRequestBody.thread_ids;

    return createStarredThreadsPatch((draftedThreads) => {
      const itemIndex = draftedThreads.findIndex((item) => threadIds.includes(item.id));

      if (itemIndex !== -1) {
        draftedThreads.splice(itemIndex, 1);
      }
    });
  }

  protected generateInvalidationTags() {
    return [
      {
        type: 'SelfStarredThreadsList' as const,
        schedule: {
          delayMs: 5000,
          uniqueKey: 'ChannelsThreadCount',
        }
      }
    ];
  }
}
