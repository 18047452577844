import { AddThreadToInboxApiArg, AddThreadToInboxApiResponse } from '../api/codegen';
import { TEMPORARY_ID_PENDING_REFRESH } from './model-factories/mutation-constants';
import {
  CacheTagInvalidationIntent, MutationHandler, MutationHandlerProps, Patch
} from './MutationHandler';
import { createInboxItemViewModelFromThreadAddedToInbox } from '../view-models/InboxItemViewModel';
import { ThreadViewModel, setThreadViewModelInboxItemId } from '../view-models/ThreadViewModel';
import { typedKeys } from '../../infrastructure/global/typedKeys';
import { allUserInboxCategories } from '../view-models/ChannelViewModel';
import { createThreadUpdatePatchesForThreadId } from './thread-patch-factory/createThreadUpdatePatchesForThreadId';
import { getCurrentChannelParams } from './utils/getCurrentChannelParams';
import { getSnoozedParams } from './utils/getSnoozedParams';

export class AddThreadToInboxMutationHandler extends MutationHandler<AddThreadToInboxApiArg, AddThreadToInboxApiResponse> {
  constructor(props: MutationHandlerProps<AddThreadToInboxApiArg, AddThreadToInboxApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: AddThreadToInboxApiArg) {
    return [
      this.createRemoveThreadFromSnoozedFolderPatch(patch),
      ...this.createInboxPatches(patch),
      ...createThreadUpdatePatchesForThreadId(patch.threadId, (draftedThread: ThreadViewModel) => {
        this.updateThread(draftedThread, TEMPORARY_ID_PENDING_REFRESH);
      })
    ];
  }

  protected createRequestCompletedPatchWrappers(patch: AddThreadToInboxApiArg, data: AddThreadToInboxApiResponse, _patchId: string): Patch[] {
    return [
      ...createThreadUpdatePatchesForThreadId(patch.threadId, (draftedThread: ThreadViewModel) => {
        this.updateThread(draftedThread, data.id);
      })
    ];
  }

  private* createInboxPatches(patch: AddThreadToInboxApiArg) {
    const thread = this.getThreadInCurrentContext(patch.threadId);
    if (thread) {
      for (const category of allUserInboxCategories) {
        if (this.isThreadInInboxCategory(thread.labels, category)) {
          yield this.updateQueryData('getSelfInboxEvents', { category }, (draftedEvents) => {
            if (draftedEvents.inboxItems) {
              draftedEvents.inboxItems = draftedEvents.inboxItems.filter((item) => item.thread.id !== patch.threadId);
              draftedEvents.inboxItems.unshift(createInboxItemViewModelFromThreadAddedToInbox(thread));
            }
          });
        }
      }
      if (thread.isUnread) {
        yield this.updateQueryData('getSelfAccount', undefined, (draftedAccount) => {
          draftedAccount.numberOfUnreadEvents += 1;
          for (const inboxCategory of typedKeys(draftedAccount.numberOfUnreadEventsByCategory)) {
            if (this.isThreadInInboxCategory(thread.labels, inboxCategory)) {
              draftedAccount.numberOfUnreadEventsByCategory[inboxCategory] += 1;
            }
          }
        });
      }
    }
  }

  private createRemoveThreadFromSnoozedFolderPatch(patch: AddThreadToInboxApiArg) {
    return this.updateQueryData('getSelfSnoozedThreads', getSnoozedParams()!, (draftedInboxItems) => {
      const itemIndex = draftedInboxItems.threads.findIndex((item) => item.id === patch.threadId);

      if (itemIndex !== -1) {
        draftedInboxItems.threads.splice(itemIndex, 1);
      }
    });
  }

  private updateThread(thread: ThreadViewModel, inboxItemId: string) {
    setThreadViewModelInboxItemId(thread, inboxItemId);
  }

  protected generateInvalidationTags(arg: AddThreadToInboxApiArg) {
    return [
      {
        type: 'Thread' as const,
        id: arg.threadId,
        schedule: {
          delayMs: 10000,
          uniqueKey: `ChannelThreadListManagement-${getCurrentChannelParams()}`,
        }
      } as CacheTagInvalidationIntent,
      {
        type: 'SelfAccount' as const,
        schedule: {
          delayMs: 5000,
          uniqueKey: 'InboxUnreadThreadCount',
        }
      },
      {
        type: 'InboxEventsList' as const,
      },
      {
        type: 'SelfSnoozedThreadsList' as const,
      }
    ];
  }
}
