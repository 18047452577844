import React, { useRef } from 'react';
import { AlertDialog, Button } from 'native-base';

type LeavePrivateChannelAlertDialogProps = {
  submitCallback: () => void;
  cancelCallback: () => void;
  visible: boolean;
};
export function LeavePrivateChannelAlertDialog({ submitCallback, visible, cancelCallback }: LeavePrivateChannelAlertDialogProps) {
  const cancelRef = useRef(null);

  return (
    <AlertDialog leastDestructiveRef={cancelRef} isOpen={visible}>
      <AlertDialog.Content>
        <AlertDialog.CloseButton onPress={cancelCallback} />
        <AlertDialog.Header>
          Leave channel
        </AlertDialog.Header>
        <AlertDialog.Body>
          When you leave a private channel, you’ll no longer be able to see any of its threads (except for the ones that you follow). To rejoin this channel later, you’ll need to be invited.
        </AlertDialog.Body>
        <AlertDialog.Footer>
          <Button.Group space={2}>
            <Button colorScheme="blueGray" variant="ghost" onPress={cancelCallback} ref={cancelRef}>
              Cancel
            </Button>
            <Button colorScheme="danger" onPress={submitCallback}>
              Leave Channel
            </Button>
          </Button.Group>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
}
