import { Thread } from './rtkQueryApiClient';

export type PlatformError = {
  'type': PlatformErrorTypeEnum;
  'message': string;
  'id'?: string;
};

export const PlatformErrorTypeEnum = {
  NotFound: 'not_found',
  BadRequest: 'bad_request',
  Internal: 'internal',
  Unauthorized: 'unauthorized',
  Forbidden: 'forbidden'
} as const;

export type PlatformErrorTypeEnum = typeof PlatformErrorTypeEnum[keyof typeof PlatformErrorTypeEnum];

export type Status = Thread['status'];
