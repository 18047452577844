import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { Menu } from 'native-base';
import { SideMenuButton, SideMenuButtonProps } from './SideMenuButton';

export type PopoverMenuButtonProps = {
  paddingLeft: SideMenuButtonProps['paddingLeft'];
  open: SideMenuButtonProps['open'];
  animationDuration: SideMenuButtonProps['animationDuration'];
  buttonTitle: string;
  iconName?: string;
  children: React.ReactNode;
  icon?: React.ReactElement;
  isDisabled?: boolean;
};

export function PopoverMenuButton(props: PopoverMenuButtonProps) {
  const [tooltipDisabled, setTooltipDisabled] = useState(false);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const changeTooltipState = (enabled: boolean, delayed: boolean) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }

    if (delayed) {
      timerRef.current = setTimeout(() => {
        setTooltipDisabled(!enabled);
      }, 650);
    } else {
      setTooltipDisabled(!enabled);
    }
  };

  useEffect(
    () => () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    },
    []
  );

  const trigger = useCallback((triggerProps: any) => (
    <SideMenuButton
      isDisabled={props.isDisabled}
      paddingLeft={props.paddingLeft}
      open={props.open}
      onPress={() => {}}
      animationDuration={props.animationDuration}
      text={props.buttonTitle}
      iconName={props.iconName}
      triggerProps={triggerProps}
      tooltipDisabled={tooltipDisabled}
      icon={props.icon}
    />
  ), [props.animationDuration, props.buttonTitle, props.icon, props.iconName, props.isDisabled, props.open, props.paddingLeft, tooltipDisabled]);

  return (
    <Menu
      onOpen={() => {
        changeTooltipState(false, false);
      }}
      onClose={() => {
        changeTooltipState(true, true);
      }}
      trigger={trigger}
    >
      {props.children}
    </Menu>
  );
}
