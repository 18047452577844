import { TimeInput } from '@mantine/dates';
import { useRef } from 'react';
import { format } from 'date-fns';
import { usePreventArrowShortcutConflicts } from './usePreventArrowShortcutConflicts';

type TimePickerProps = {
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
};
export function TimePicker({ selectedDate, setSelectedDate }: TimePickerProps) {
  const timerRef = useRef<HTMLInputElement>(null);
  usePreventArrowShortcutConflicts();

  return (
    <TimeInput
      ref={timerRef}
      defaultValue={format(selectedDate, 'HH:mm')}
      onChange={() => {
        const date = new Date(selectedDate);
        const [hours, minutes] = timerRef.current?.value.split(':') ?? ['00', '00'];
        date.setHours(Number(hours), Number(minutes));
        setSelectedDate(date);
      }}
    />
  );
}
