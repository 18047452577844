import { datadogRum } from '@datadog/browser-rum';
import { useCallback, useEffect } from 'react';
import { useAppDependencies } from '../../dependencies';
import { rootNavigationContainerRef } from '../../navigation/navigators/root/RootNavigationContainerRef';
import { useOnlyIfActualUsers } from '../useOnlyIfActualUsers';
import { useGetSelfUser } from '../../controllers/hooks/api/useGetSelfUser';
import { useGetSelfOrganizations } from '../../controllers/hooks/api/useGetSelfOrganizations';

let isDatadogInitialized = false;

export function useDatadogRum() {
  const { env } = useAppDependencies().environmentVariablesStore;
  const datadogEnabled = parseInt(env.DATADOG_BROWSER_RUM_ENABLED);
  const { currentData: user } = useGetSelfUser();
  const { selfOrganization: org } = useGetSelfOrganizations();

  const callback = useCallback(() => {
    if (datadogEnabled) {
      if (!isDatadogInitialized) {
        datadogRum.init({
          applicationId: env.DATADOG_BROWSER_RUM_APPLICATION_ID,
          clientToken: env.DATADOG_BROWSER_RUM_CLIENT_TOKEN,
          site: 'datadoghq.eu',
          service: 'frontend',
          env: env.ENVIRONMENT_NAME,
          // Specify a version number to identify the deployed version of your application in Datadog
          // version: '1.0.0',
          sessionSampleRate: 100,
          allowedTracingUrls: [env.API_URL], // https://docs.datadoghq.com/real_user_monitoring/platform/connect_rum_and_traces/?tab=browserrum
          sessionReplaySampleRate: 20,
          trackViewsManually: true,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: 'allow',
          excludedActivityUrls: [
            // Exclude polled URLs from activity tracking (used to compute loading times).
            `${env.API_URL}/self/account`,
            `${env.API_URL}/self/channels`,
          ],
        });

        isDatadogInitialized = true;
      }

      datadogRum.startSessionReplayRecording();
    }
  }, [datadogEnabled, env.DATADOG_BROWSER_RUM_APPLICATION_ID, env.DATADOG_BROWSER_RUM_CLIENT_TOKEN, env.ENVIRONMENT_NAME, env.API_URL]);

  useOnlyIfActualUsers(callback);

  useEffect(() => () => {
    datadogRum.stopSessionReplayRecording();
    datadogRum.stopSession();
  }, []);

  useEffect(() => {
    const unsubscribe = rootNavigationContainerRef.current?.addListener('state', (e) => {
      const routeName = getActiveRouteName(e.data.state);
      if (routeName && datadogEnabled) {
        datadogRum.startView({ name: routeName });
      }
    });

    return unsubscribe;
  }, [datadogEnabled]);

  useEffect(() => {
    if (datadogEnabled && user && org) {
      datadogRum.setUser({
        id: user.id,
        name: user.displayName,
        organizationId: org.id,
        organizationName: org.name
      });
    }
  }, [user, org, datadogEnabled]);
}

function getActiveRouteName(state: any): any {
  const route = state?.routes[state?.index ?? 0];

  if (route?.state) {
    return getActiveRouteName(route.state);
  }

  return route?.name ?? 'Unknown';
}

export function addRumAction(name: string) {
  datadogRum.addAction(name);
}
