import { GetChannelThreadsApiResponse } from '../api/codegen';
import {
  ComprehensiveThreadViewModel, ThreadViewModel, ThreadViewModelAdditionalParams, createComprehensiveThreadViewModel, createThreadViewModel
} from './ThreadViewModel';

export type ThreadsViewModel = {
  threads: ThreadViewModel[];
  meta: GetChannelThreadsApiResponse['meta'] ;
};

export type ComprehensiveThreadsViewModel = {
  threads: ComprehensiveThreadViewModel[];
  meta: GetChannelThreadsApiResponse['meta'] ;
};

export function createThreadsViewModel(threads: GetChannelThreadsApiResponse): ThreadsViewModel {
  return {
    threads: threads.data.map((thread) => createThreadViewModel(thread)),
    meta: threads.meta,
  };
}

export function createComprehensiveThreadsViewModel(threads: ThreadsViewModel, params: ThreadViewModelAdditionalParams): ComprehensiveThreadsViewModel {
  return {
    threads: threads.threads.map((thread) => createComprehensiveThreadViewModel(thread, params)),
    meta: threads.meta,
  };
}
