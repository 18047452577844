import { Reaction } from '../api/codegen';
import { getEmojiByUnified } from '../other/getEmojiByUnified';

export type ReactionViewModel = {
  id: string;
  code: string;
  displayText: string;
};

export function createReactionViewModel(reaction: Reaction): ReactionViewModel {
  return {
    id: reaction.code,
    displayText: getEmojiByUnified(reaction.code),
    code: reaction.code,
  };
}
