import Hotjar from '@hotjar/browser';
import { useCallback } from 'react';
import { useAppDependencies } from '../dependencies';
import { useOnlyIfActualUsers } from './useOnlyIfActualUsers';

export function useHotjar() {
  const { env } = useAppDependencies().environmentVariablesStore;
  const hotjarEnabled = parseInt(env.HOTJAR_ENABLED);

  const callback = useCallback(() => {
    if (hotjarEnabled) {
      Hotjar.init(parseInt(env.HOTJAR_SITE_ID), parseInt(env.HOTJAR_VERSION));
    }
  }, [env.HOTJAR_SITE_ID, env.HOTJAR_VERSION, hotjarEnabled]);

  useOnlyIfActualUsers(callback);
}
