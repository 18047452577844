import { Status } from '../api/codegen';

export type NonNullStatus = Exclude<Status, null>;

export type StatusDetail = {
  text: string;
  textColor: string;
  labelTextColor: string;
  labelBackgroundColor: string;
};

export const STATUSES: {
  [key in NonNullStatus]?: StatusDetail;
} = {
  open: {
    text: 'Open',
    textColor: 'primary.500', // Blue
    labelTextColor: 'coolGray.800',
    labelBackgroundColor: 'primary.200',
  },
  in_progress: {
    text: 'In progress',
    textColor: 'emerald.500', // Green
    labelTextColor: 'coolGray.800',
    labelBackgroundColor: 'emerald.200',
  },
  review: {
    text: 'Review',
    textColor: 'amber.500', // Amber
    labelTextColor: 'coolGray.800',
    labelBackgroundColor: 'amber.200',
  },
  resolved: {
    text: 'Resolved',
    textColor: 'purple.500', // Purple
    labelTextColor: 'white',
    labelBackgroundColor: 'purple.300',
  },
  closed: {
    text: 'Closed',
    textColor: 'coolGray.500', // Gray
    labelTextColor: 'coolGray.800',
    labelBackgroundColor: 'coolGray.200',
  },
};
