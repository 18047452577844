import {
  Icon, Modal, Button, FormControl, Radio
} from 'native-base';
import React, { ForwardedRef } from 'react';
import { GestureResponderEvent } from 'react-native';
import { InputUncontrolled, InputUncontrolledHandle } from '../InputUncontrolled';

export interface ChannelData {
  channelName: string;
  isPublic: boolean;
}

type ConfigureChannelModalProps = {
  visible: boolean | undefined;
  setVisible: (visible: boolean) => void;
  initialRef?: ForwardedRef<InputUncontrolledHandle>;
  isLoading: boolean;
  channelData: ChannelData;
  handleInputChange: (type: string, value: string | boolean) => void;
  handleClose: ((event: GestureResponderEvent) => void);
  canFormBeSubmitted: boolean;
  handleSubmit: ((event: GestureResponderEvent) => void);
  type: 'create' | 'update';
  channelNameAlreadyTakenError: boolean;
};

export function ConfigureChannelModal(props: ConfigureChannelModalProps) {
  const title = props.type === 'create' ? 'Create a new channel' : 'Edit channel';

  return (
    <Modal
      isOpen={props.visible}
      onClose={() => props.setVisible(false)}
      _backdropFade={{
        exitDuration: 1
      }}
      _fade={{
        exitDuration: 1
      }}
      closeOnOverlayClick={!props.isLoading}
    >
      <Modal.Content>
        <Modal.CloseButton disabled={props.isLoading} />
        <Modal.Header>{title}</Modal.Header>
        <Modal.Body>
          <FormControl isInvalid={props.channelNameAlreadyTakenError}>
            <FormControl.Label>Name</FormControl.Label>
            <InputUncontrolled
              autoCorrect={false}
              ref={props.initialRef}
              isDisabled={props.isLoading}
              isRequired
              placeholder="e. g. company-updates"
              InputLeftElement={<Icon name="tag" color="muted.400" size="xs" ml={2} mr={-2} />}
              onChangeText={(value) => props.handleInputChange('channelName', value)}
              _invalid={{
                bgColor: 'red.50',
              }}
            />
            <FormControl.HelperText _invalid={{ display: 'none' }}>&nbsp;</FormControl.HelperText>
            <FormControl.ErrorMessage _disabled={{ height: 10 }}>This name is already taken</FormControl.ErrorMessage>
          </FormControl>
          <FormControl mt="3">
            <FormControl.Label>Visibility</FormControl.Label>
            <Radio.Group mt={1} ml={1} colorScheme="blueGray" value={props.channelData.isPublic ? '1' : '0'} name="privacy" onChange={(value) => props.handleInputChange('isPublic', !!parseInt(value))} space={2} isDisabled={props.isLoading} accessibilityLabel="Privacy">
              <Radio value="1" size="sm" isDisabled={props.isLoading}>Public (accessible organization-wide)</Radio>
              <Radio value="0" size="sm" isDisabled={props.isLoading}>Private (accessible to specific members only)</Radio>
            </Radio.Group>
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <Button.Group space={2}>
            <Button variant="ghost" colorScheme="blueGray" onPress={props.handleClose} disabled={props.isLoading}>
              Cancel
            </Button>
            <Button isDisabled={!props.canFormBeSubmitted} colorScheme="blueGray" onPress={props.handleSubmit} isLoading={props.isLoading}>
              {props.type === 'create' ? 'Create' : 'Save'}
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}
