import { UpdateThreadApiArg, UpdateThreadApiResponse } from '../api/codegen';
import { MutationHandler, MutationHandlerProps } from './MutationHandler';
import {
  ThreadViewModel, updateThreadViewModelAssigneeParams, updateThreadViewModelDescriptionParams, updateThreadViewModelDueDateParams, updateThreadViewModelStatusParams, updateThreadViewModelTitleParams
} from '../view-models/ThreadViewModel';
import { createThreadUpdatePatchesForThreadId } from './thread-patch-factory/createThreadUpdatePatchesForThreadId';

export class UpdateThreadMutationHandler extends MutationHandler<UpdateThreadApiArg, UpdateThreadApiResponse> {
  constructor(props: MutationHandlerProps<UpdateThreadApiArg, UpdateThreadApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: UpdateThreadApiArg) {
    return [
      ...createThreadUpdatePatchesForThreadId(patch.threadId, (draftedThread) => this.updateThread(draftedThread, patch)),
    ];
  }

  private updateThread(draftedThread: ThreadViewModel, patch: UpdateThreadApiArg) {
    if (patch.threadUpdateRequestBody.title !== undefined) {
      updateThreadViewModelTitleParams(draftedThread, patch.threadUpdateRequestBody.title);
    }

    if (patch.threadUpdateRequestBody.status !== undefined) {
      updateThreadViewModelStatusParams(draftedThread, patch.threadUpdateRequestBody.status);
    }

    if (patch.threadUpdateRequestBody.due_date !== undefined) {
      updateThreadViewModelDueDateParams(draftedThread, patch.threadUpdateRequestBody.due_date);
    }

    if (patch.threadUpdateRequestBody.description !== undefined) {
      updateThreadViewModelDescriptionParams(draftedThread, patch.threadUpdateRequestBody.description);
    }

    if (patch.threadUpdateRequestBody.assignee_id !== undefined) {
      const assignee = this.getOrganizationMembers().find((member) => member.id === patch.threadUpdateRequestBody.assignee_id);
      updateThreadViewModelAssigneeParams(draftedThread, assignee);
    }
  }

  protected generateInvalidationTags(_arg: UpdateThreadApiArg) {
    const threadTag = { type: 'Thread' as const, id: this.getCurrentThreadId() };

    return [
      threadTag,
    ];
  }
}
