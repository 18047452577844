import { GetThreadApiArg } from '../api/codegen';
import { CacheTagProvider } from './CacheTagProvider';
import { ThreadViewModel } from '../view-models/ThreadViewModel';

export class GetThreadCacheTagProvider extends CacheTagProvider<GetThreadApiArg, ThreadViewModel> {
  protected generateTags(arg: GetThreadApiArg, result: ThreadViewModel) {
    const eventTag = this.getEventTag(result);

    return [
      this.getThreadTag(arg),
      ...(eventTag ? [eventTag] : []),
    ];
  }

  private getThreadTag(arg: GetThreadApiArg) {
    return { type: 'Thread' as const, id: arg.threadId };
  }

  private getEventTag(result: ThreadViewModel) {
    if (result.eventId) {
      return { type: 'Event' as const, id: result.eventId };
    }
  }
}
