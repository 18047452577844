import { extendTheme } from 'native-base';

const component: Parameters<typeof extendTheme>[0]['components']['Button'] = {
  baseStyle: {
    rounded: 'xl',
  },
  defaultProps: {
    variant: 'primary',
    _pressed: {
      borderColor: 'primary.400'
    }
  },
  variants: {
    primary: {
      borderWidth: 2,
      borderColor: 'transparent',
      backgroundColor: 'primary.800',
      _hover: {
        backgroundColor: 'primary.700',
      },
      _pressed: {
        backgroundColor: 'primary.700',
      },
      _text: {
        color: 'white',
        fontWeight: '700',
        fontFamily: 'button',
      }
    },
    secondary: {
      borderWidth: 2,
      borderColor: 'transparent',
      backgroundColor: 'dark.900',
      _hover: {
        backgroundColor: 'dark.800',
      },
      _pressed: {
        backgroundColor: 'dark.700',
      },
      _text: {
        color: 'white',
        fontWeight: '700',
        fontFamily: 'button',
      }
    },
    outline: {
      borderWidth: 2,
      borderColor: 'dark.5',
      backgroundColor: 'transparent',
      _hover: {
        backgroundColor: 'dark.5',
        _text: {
          color: 'dark.900',
        }
      },
      _pressed: {
        _text: {
          color: 'dark.900',
        },
        backgroundColor: 'primary.100',
      },
      _text: {
        color: 'dark.900',
        fontWeight: '700',
        fontFamily: 'button',
      },
      _icon: {
        color: 'dark.900',
      }
    },
    text: {
      borderWidth: 2,
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      _hover: {
        backgroundColor: 'dark.5',
        _text: {
          color: 'dark.900',
        }
      },
      _pressed: {
        _text: {
          color: 'dark.900',
        },
        backgroundColor: 'primary.100',
      },
      _text: {
        color: 'dark.900',
        fontWeight: '700',
        fontFamily: 'button',

      },
      _icon: {
        color: 'dark.900',
      }
    },
    'light-text': {
      borderWidth: 2,
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      _hover: {
        backgroundColor: 'dark.5',
        fontWeight: '400',
        _text: {
          fontWeight: '400',
          color: 'dark.900',
        }
      },
      _pressed: {
        _text: {
          fontWeight: '400',
          color: 'dark.900',
        },
        backgroundColor: 'primary.100',
      },
      _text: {
        fontFamily: 'lightButton',
        fontWeight: '400',
      },
      _icon: {
        color: 'dark.900',
      }
    }
  }
};

export default component;
