import { MutationHandler, MutationHandlerProps } from './MutationHandler';
import { ChannelViewModel } from '../view-models/ChannelViewModel';
import { ChannelsViewModel } from '../view-models/ChannelsViewModel';
import { PictureViewModel, createPictureViewModelFromUser } from '../view-models/PictureViewModel';
import { AddChannelParticipantsApiArg, AddChannelParticipantsApiResponse } from '../api/codegen';
import { getCurrentChannelParams } from './utils/getCurrentChannelParams';

export class AddChannelParticipantsMutationHandler extends MutationHandler<AddChannelParticipantsApiArg, AddChannelParticipantsApiResponse> {
  constructor(props: MutationHandlerProps<AddChannelParticipantsApiArg, AddChannelParticipantsApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: AddChannelParticipantsApiArg) {
    const patches = [];
    const channelPatch = this.createChannelPatch(patch);
    if (channelPatch !== undefined) {
      patches.push(channelPatch);
    }

    const selfChannelPatch = this.createSelfChannelsPatch(patch);

    if (selfChannelPatch !== undefined) {
      patches.push(selfChannelPatch);
    }
    return patches;
  }

  private createSelfChannelsPatch(patch: AddChannelParticipantsApiArg) {
    const selfUser = this.getSelfUser();
    const isSelfAdded = patch.body.some((userId) => userId === selfUser?.id);

    if (isSelfAdded) {
      return this.updateQueryData('getSelfChannels', undefined, (draftedChannels) => {
        const channel = this.findChannel(patch.channelId);

        if (channel) {
          const index = this.determineIndexForNewChannel(channel, draftedChannels);
          draftedChannels.channels.splice(index, 0, channel);
        }
      });
    }
  }

  private determineIndexForNewChannel(channel: ChannelViewModel, selfChannels: ChannelsViewModel) {
    const organizationId = this.apiClient.endpoints.getSelfOrganizations.select()(this.state).data!.organizations[0].id;
    const orgChannels = this.apiClient.endpoints.getOrganizationChannels.select({ organizationId })(this.state).data!;

    const allOrgChannelsBeforeNewChannel = orgChannels.channels.slice(0, orgChannels.channels.findIndex((orgChannel) => orgChannel.id === channel.id)).reverse();

    if (allOrgChannelsBeforeNewChannel.length === 0) {
      return 0;
    }

    const channelRightBeforeNewChannel = allOrgChannelsBeforeNewChannel.find((orgChannel) => selfChannels.channels.some((selfChannel) => selfChannel.id === orgChannel.id));
    const indexOfchannelRightBeforeNewChannel = selfChannels.channels.findIndex((selfChannel) => selfChannel.id === channelRightBeforeNewChannel?.id);

    if (indexOfchannelRightBeforeNewChannel === -1) {
      const inboxIndex = selfChannels.channels.findIndex((selfChannel) => selfChannel.folderType === 'all');
      return inboxIndex - 1;
    }

    return indexOfchannelRightBeforeNewChannel + 1;
  }

  private findChannel(channelId: string): ChannelViewModel | undefined {
    const organizationId = this.apiClient.endpoints.getSelfOrganizations.select()(this.state).data!.organizations[0].id;

    if (!organizationId) {
      return;
    }

    const channels = this.apiClient.endpoints.getOrganizationChannels.select({ organizationId })(this.state).data;

    if (!channels) {
      return;
    }

    return channels.channels.find((channel) => channel.id === channelId);
  }

  private createChannelPatch(patch: AddChannelParticipantsApiArg) {
    if (patch.channelId !== getCurrentChannelParams()?.channelId) {
      return;
    }

    return this.updateQueryData('getChannelParticipants', getCurrentChannelParams()!, (draftedParticipants) => {
      this.updateParticipants(draftedParticipants, patch);
    });
  }

  private updateParticipants(participants: PictureViewModel[], patch: AddChannelParticipantsApiArg) {
    const newParticipants = this.getNewParticipants(patch);
    participants.push(...newParticipants);
  }

  private getNewParticipants(patch: AddChannelParticipantsApiArg) {
    const newMembers = this.getOrganizationMembers().filter((member) => patch.body.some((userId) => userId === member.id));
    return newMembers.map((member) => member.rawUser).map(createPictureViewModelFromUser);
  }

  protected generateInvalidationTags(_arg: AddChannelParticipantsApiArg) {
    return [];
  }
}
