import React, { ReactNode, useRef, useState } from 'react';
import { AlertDialog, Button } from 'native-base';
import { getDisplayName } from '../../global/getDisplayName';

type PressableProps = { onPress?: () => void; };

export function withConfirmationAlert<Props extends PressableProps>(
  Component: React.ComponentType<Props>,
  { header, body, confirmButtonText }: { header: string; body: ReactNode; confirmButtonText: string }
) {
  const WithConfirmationAlert = function (props: Props) {
    const { onPress } = props;
    const [visible, setVisible] = useState(false);
    const cancelRef = useRef(null);
    const onCancel = () => setVisible(false);
    const onConfirm = () => {
      onPress?.();
      setVisible(false);
    };
    return (
      <>
        <Component {...props} onPress={() => setVisible(true)} />
        <AlertDialog leastDestructiveRef={cancelRef} isOpen={visible}>
          <AlertDialog.Content>
            <AlertDialog.CloseButton onPress={onCancel} />
            <AlertDialog.Header>
              {header}
            </AlertDialog.Header>
            <AlertDialog.Body>
              {body}
            </AlertDialog.Body>
            <AlertDialog.Footer>
              <Button.Group space={2}>
                <Button colorScheme="blueGray" variant="ghost" onPress={onCancel} ref={cancelRef}>
                  Cancel
                </Button>
                <Button colorScheme="danger" onPress={onConfirm}>
                  {confirmButtonText}
                </Button>
              </Button.Group>
            </AlertDialog.Footer>
          </AlertDialog.Content>
        </AlertDialog>
      </>
    );
  };
  WithConfirmationAlert.displayName = `WithConfirmationAlert(${getDisplayName(Component)})`;
  return WithConfirmationAlert;
}
