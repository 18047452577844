import React from 'react';
import { Button, Modal } from 'native-base';
import { ParticipantScreenSectionController, AddParticipantScreenSectionControllerHandles } from '../controllers/screen-sections/ParticipantScreenSectionController';
import { ParticipantViewModel } from '../../adapters/view-models/ParticipantViewModel';

export type AddParticipantsModalProps = {
  modalDisplayed: boolean;
  addParticipantScreenSectionControllerRef: React.Ref<AddParticipantScreenSectionControllerHandles>;
  selectedParticipantsListChangedCallback: (selectedParticipants: ParticipantViewModel[]) => void;
  participants: ParticipantViewModel[];
  submitButtonDisabled: boolean;
  didCancelCallback: () => void;
  didSubmitCallback: () => void;
  participantsType: 'channels' | 'users';
};

export function AddParticipantsModal(props: AddParticipantsModalProps) {
  const modalTitle = props.participantsType === 'channels' ? 'Add channels' : 'Add participants';

  return (
    <Modal isOpen={props.modalDisplayed} onClose={props.didCancelCallback}>
      <Modal.Content maxWidth="500px">
        <Modal.CloseButton />
        <Modal.Header>{ modalTitle }</Modal.Header>
        <Modal.Body p={0} pr={2} h={300}>
          <ParticipantScreenSectionController ref={props.addParticipantScreenSectionControllerRef} selectedParticipantsListChangedCallback={props.selectedParticipantsListChangedCallback} includeChannels={props.participantsType === 'channels'} includeUsers={props.participantsType === 'users'} type={props.participantsType === 'users' ? 'add_people' : 'add_channels'} excludedParticipants={props.participants} />
        </Modal.Body>
        <Modal.Footer>
          <Button.Group space={2}>
            <Button
              colorScheme="blueGray"
              variant="ghost"
              size="sm"
              onPress={props.didCancelCallback}
            >
              Cancel
            </Button>

            <Button
              colorScheme="blueGray"
              size="sm"
              _text={{
                bold: true
              }}
              disabled={props.submitButtonDisabled}
              onPress={props.didSubmitCallback}
            >
              Confirm
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}
