import {
  NavigationProp, StackActions, useNavigation, useNavigationState
} from '@react-navigation/native';
import { Box, Icon, Input } from 'native-base';
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { NativeSyntheticEvent, TextInput, TextInputKeyPressEventData } from 'react-native';
import { useHotkeys } from 'react-hotkeys-hook';
import { RootStackParamList } from '../../navigation/navigators/root/RootStackProps';
import { AppScreenStackParamList } from '../../navigation/navigators/app/AppScreenProps';

export function SearchBar() {
  const searchInputRef = useRef<TextInput>(null);
  const [searchInputValue, setSearchInputValue] = useState<string>('');

  useHotkeys(['/'], () => searchInputRef.current?.focus(), { preventDefault: true });
  useSetInitialSearchInputValue(searchInputRef, setSearchInputValue);
  const onKeyPress = usePerformSearchOnAppropriateKeyPressCallback();

  return (
    <Box flexGrow={1} alignItems="center" flexDir="row">
      <Input
        onChangeText={(value) => setSearchInputValue(value)}
        value={searchInputValue}
        ref={searchInputRef}
        borderWidth={0}
        placeholder="Search"
        width="100%"
        borderRadius="12"
        onKeyPress={(e) => {
          if (e.nativeEvent.key === 'Escape') {
            searchInputRef.current?.blur();
          } else {
            onKeyPress(e);
          }
        }}
        px={1}
        py={2}
        fontSize="14"
        bgColor="opacityWhite.500"
        outlineColor="transparent"
        focusOutlineColor="transparent"
        placeholderTextColor="opacityBlack.700"
        InputLeftElement={<Icon m="2" size="sm" variant="dark" name="search" color="opacityBlack.700" />}
      />
    </Box>
  );
}

function usePerformSearchOnAppropriateKeyPressCallback() {
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  return useCallback((e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    const { value } = e.target as any;

    if (e.nativeEvent.key === 'Enter' && value.length > 0) {
      navigation.dispatch(StackActions.popToTop());
      navigation.dispatch(StackActions.replace('Search', {
        query: value
      }));
    }
  }, [navigation]);
}

function useSetInitialSearchInputValue(searchInputRef: React.RefObject<TextInput>, setSearchInputValue: React.Dispatch<React.SetStateAction<string>>) {
  const initialQuery = useNavigationState<RootStackParamList, string | undefined>((state) => {
    if (state.routes[state.index].name === 'App') {
      const appRoute = state.routes[state.index].state?.routes[0];

      if (appRoute?.name === 'Search') {
        return (appRoute.params as AppScreenStackParamList['Search']).query;
      }
    }
  });

  useEffect(() => {
    if (searchInputRef.current && initialQuery) {
      setSearchInputValue(initialQuery);
    }
  }, [initialQuery, searchInputRef, setSearchInputValue]);
}
