import { GetSelfAllThreadsApiArg } from '../api/codegen';
import { CacheTagProvider } from './CacheTagProvider';
import { ThreadsViewModel } from '../view-models/ThreadsViewModel';

export class GetAllThreadsCacheTagProvider extends CacheTagProvider<GetSelfAllThreadsApiArg, ThreadsViewModel> {
  constructor(private readonly getThreadMessagesCacheTagProvider: CacheTagProvider<any, any>) {
    super();
  }

  protected generateTags(arg: GetSelfAllThreadsApiArg, result: ThreadsViewModel) {
    return [
      { type: 'SelfAllThreadsList' as const },
      ...this.getThreadMessagesCacheTagProvider.provideTags(arg, result),
    ];
  }
}
