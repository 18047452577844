import { Entypo } from '@expo/vector-icons';
import { Icon, Pressable } from 'native-base';
import React, { useCallback } from 'react';
import { AddReactionButtonProps } from './AddReactionButtonControllerProps';
import { EmojiPickerPopover } from '../../../emoji/popover/EmojiPickerPopover';

export function AddMoreReactionButton({ emojiSelectedCallback, isShowingReactionsCallback }: AddReactionButtonProps) {
  const trigger = useCallback((triggerProps: any) => (
    <Pressable
      _hover={{ background: 'white', borderColor: 'muted.200' }}
      fontSize="sm"
      flexWrap="wrap"
      flexGrow={0}
      backgroundColor="muted.100"
      borderRadius={4}
      p={0}
      px="1.5"
      borderWidth={2}
      borderColor="transparent"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      {...triggerProps}
    >
      <Icon as={Entypo} name="plus" size="lg" color="muted.400" />
    </Pressable>
  ), []);

  return (
    <EmojiPickerPopover
      placement="right"
      trigger={trigger}
      emojiSelectedCallback={emojiSelectedCallback}
      emojiPickerOpenCallback={isShowingReactionsCallback}
    />
  );
}
