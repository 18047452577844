import cx from 'classnames';
import { Box } from 'native-base';
import { useRef, useState } from 'react';
import { Hoverable } from 'react-native-web-hover';
import { useUpdateThreadMutation } from '../../../../adapters/api/codegen';
import { RichTextEditor, RichTextEditorHandle } from '../../rich-text-editor/RichTextEditor';
import { ComposeToolbar } from '../../compose';
import '../../../styles/quill.slim.css';
import { useInsertEmoji } from '../../rich-text-editor/useInsertEmoji';

type ThreadScreenHeaderDescriptionProps = {
  threadId: string;
  description: string;
  showLoader: boolean;
  isDisabled: boolean;
};

export function ThreadScreenHeaderDescription(props: ThreadScreenHeaderDescriptionProps) {
  const [updateThread] = useUpdateThreadMutation();
  const [toolbarUniqueId] = useState(() => Math.floor(Math.random() * 1000));
  const [focus, setFocus] = useState(false);
  const editorRef = useRef<RichTextEditorHandle>(null);
  const { onTextChange, onSelectionChange, emojiSelectedCallback } = useInsertEmoji(editorRef);
  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);

  const updateThreadDescription = () => {
    const quill = editorRef.current;
    if (!quill) return;
    const currentDescription = quill.getSemanticHTML();
    if (currentDescription !== props.description) {
      void updateThread({
        threadId: props.threadId,
        threadUpdateRequestBody: {
          description: currentDescription,
        }
      });
    }
  };

  if (props.showLoader) return null;
  const isExpanded = (focus || emojiPickerOpen) && !props.isDisabled;

  return (
    <Hoverable>
      {({ hovered }) => (
        <Box mb={2} borderWidth={1} borderColor={isExpanded || (hovered && !props.isDisabled) ? 'dark.100' : 'transparent'} borderRadius={8}>
          <RichTextEditor
            readOnly={props.isDisabled}
            className={cx('slim', { focus: isExpanded })}
            style={{
              borderWidth: 0, borderColor: 'transparent', height: 'auto', marginBottom: '-2px'
            }}
            onSelectionChange={onSelectionChange}
            onTextChange={onTextChange}
            ref={editorRef}
            onFocus={() => setFocus(true)}
            onBlur={() => {
              setFocus(false);
              updateThreadDescription();
            }}
            defaultValueHTML={props.description}
            placeholder="Add a description to this thread"
            modules={{
              toolbar: {
                container: `#toolbar-${toolbarUniqueId}`
              },
              clipboard: {
                matchVisual: false,
              },
              emoji: true,
            }}
            formats={[
              // 'header',
              'font',
              // 'size',
              'bold',
              'italic',
              'underline',
              'strike',
              'blockquote',
              'code-block',
              'list',
              // 'indent', // enable maybe? it is enabled in compose
              'link',
              'image',
              // 'color'
            ]}
            theme="snow"
          />
          <ComposeToolbar
            emojiPickerOpenCallback={setEmojiPickerOpen}
            displayed={isExpanded}
            enabled
            sendButtonDisabled
            uniqueId={toolbarUniqueId}
            emojiPickerPlacement="right"
            emojiSelectedCallback={emojiSelectedCallback}
            hotkeysEnabled
          />
        </Box>
      )}
    </Hoverable>
  );
}
