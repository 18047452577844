import {
  MarkMessagesAsUnreadApiArg, MarkMessagesAsUnreadApiResponse
} from '../api/codegen';
import { UnreadCountMutationHandler } from './UnreadCountMutationHandler';
import { CacheTagInvalidationIntent, MutationHandlerProps } from './MutationHandler';
import { ThreadViewModel } from '../view-models/ThreadViewModel';
import { createThreadUpdatePatches } from './thread-patch-factory/createThreadUpdatePatches';
import { getCurrentChannelParams } from './utils/getCurrentChannelParams';

export class MarkMessagesAsUnreadMutationHandler extends UnreadCountMutationHandler<MarkMessagesAsUnreadApiArg, MarkMessagesAsUnreadApiResponse> {
  constructor(props: MutationHandlerProps<MarkMessagesAsUnreadApiArg, MarkMessagesAsUnreadApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: MarkMessagesAsUnreadApiArg) {
    const updateThreads = (draftedThreads: ThreadViewModel[]) => {
      this.updateThreads(draftedThreads, patch);
    };

    return [
      ...createThreadUpdatePatches(updateThreads),
      ...this.createChannelsListPatches(patch, 'unread'),
    ];
  }

  private updateThreads(draftedThreads: ThreadViewModel[], patch: MarkMessagesAsUnreadApiArg) {
    const threadIds = patch.messageIdsRequestBody.message_ids.map((messageId) => {
      const thread = draftedThreads.find((thread) => thread.lastMessageId === messageId);
      return thread?.id;
    }).filter((threadId) => threadId !== undefined) as string[];

    for (const thread of draftedThreads) {
      if (threadIds.includes(thread.id)) {
        this.updateThread(thread);
      }
    }
  }

  private updateThread(draftedThread: ThreadViewModel) {
    draftedThread.isUnread = true;
  }

  protected generateInvalidationTags(arg: MarkMessagesAsUnreadApiArg) {
    const messageTags = arg.messageIdsRequestBody.message_ids.map((messageId) => ({
      type: 'Message' as const,
      id: messageId,
      schedule: {
        delayMs: 10000,
        uniqueKey: `ChannelThreadListManagement-${getCurrentChannelParams()}`,
      }
    } as CacheTagInvalidationIntent));

    const accountTag = {
      type: 'SelfAccount' as const,
      schedule: {
        delayMs: 5000,
        uniqueKey: 'InboxUnreadThreadCount',
      }
    };

    const channelTag = {
      type: 'SelfChannels' as const,
      schedule: {
        delayMs: 5000,
        uniqueKey: 'ChannelsThreadCount',
      }
    };

    return [
      ...messageTags,
      accountTag,
      channelTag,
    ];
  }
}
