import { createStore, useStore } from 'zustand';
import { createContext, useContext } from 'react';
import { Draft, produce } from 'immer';

interface ThreadViewState {
  focusedId: string | null;
  setFocusedId: (id: string | null) => void;
  navigatedFromKeyboard: boolean;
  navigatedFromKeyboardTimeout?: ReturnType<typeof setTimeout>;
  setNavigatedFromKeyboard: () => void;
}

export function createThreadViewStore(defaultValues?: Partial<ThreadViewState>) {
  return createStore<ThreadViewState>((set) => {
    const update = (recipe: (draft: Draft<ThreadViewState>) => void) => set(produce(recipe));
    return {
      focusedId: defaultValues?.focusedId ?? null,
      setFocusedId: (focusedId) => set({ focusedId }),

      navigatedFromKeyboard: false,
      setNavigatedFromKeyboard: () => update((state) => {
        if (state.navigatedFromKeyboardTimeout) {
          clearTimeout(state.navigatedFromKeyboardTimeout);
        }
        state.navigatedFromKeyboard = true;
        state.navigatedFromKeyboardTimeout = setTimeout(() => {
          update((state) => {
            state.navigatedFromKeyboard = false;
            state.navigatedFromKeyboardTimeout = undefined;
          });
        }, 50);
      }),
    };
  });
}

type ThreadViewStore = ReturnType<typeof createThreadViewStore>;

const threadViewContext = createContext<ThreadViewStore | null>(null);

export const ThreadViewProvider = threadViewContext.Provider;

export function useThreadViewStore() {
  const store = useContext(threadViewContext);
  if (!store) {
    throw new Error('useThreadViewStore must be used within a HoveredMessageProvider');
  }
  return store;
}

export function useThreadViewStoreSelector<T>(selector: (state: ThreadViewState) => T) {
  const store = useThreadViewStore();
  return useStore(store, selector);
}
