import { Command } from 'cmdk';
import { Icon } from 'native-base';
import React, { ComponentProps } from 'react';
import { DisplayShortcut } from '../shortcuts/DisplayShortcut';

interface ItemProps extends ComponentProps<typeof Command.Item> {
  icon?: {
    name: string;
    collection?: any;
  };
  children: React.ReactNode;
  shortcut?: string;
}

export const CommandItem = ({
  icon, children, shortcut, ...rest
}: ItemProps) => {
  return (
    <Command.Item {...rest}>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <div cmdk-icon="">{icon && <Icon as={icon.collection} name={icon.name} color="inherit" size="md" />}</div>
      {children}
      {shortcut && <DisplayShortcut shortcut={shortcut} dark />}
    </Command.Item>
  );
};
