import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDependencies } from '../dependencies';
import { useGetSelfOrganizations } from '../controllers/hooks/api/useGetSelfOrganizations';

export function useOnlyIfActualUsers(callback: () => void) {
  const { isAuthenticated } = useAuth0();
  const { env } = useAppDependencies().environmentVariablesStore;
  const { selfOrganization } = useGetSelfOrganizations();

  useEffect(() => {
    const ORG_NAMES_TO_EXCLUDE = ['your channels'];
    const orgIsLoaded = !!selfOrganization;
    const isOrgNameToExclude = ORG_NAMES_TO_EXCLUDE.some((name) => selfOrganization?.name?.toLowerCase()?.includes(name));

    if (isAuthenticated && orgIsLoaded && !isOrgNameToExclude) {
      callback();
    }
  }, [isAuthenticated, env.HOTJAR_ENABLED, selfOrganization, callback]);
}
