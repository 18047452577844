import { createStore, useStore } from 'zustand';
import {
  type ComponentProps, createContext, useContext,
} from 'react';
import { original, produce, type Draft } from 'immer';
import { type LayoutRectangle } from 'react-native';
import { clamp } from 'lodash';
import type { ThreadList } from '@/infrastructure/ui/thread-list/ThreadList';
import { isInboxItemViewModel } from '@/adapters/view-models/InboxItemViewModel';

type Items = NonNullable<ComponentProps<typeof ThreadList>['items']>;

interface ThreadListState {
  refs: {
    items: Items;
    lastToggledId: string | null;
  };
  setItems: (items: Items) => void;
  focusedId: string | null;
  setFocusedId: (id: string | null) => void;
  layouts: Record<string, LayoutRectangle>;
  setLayout: (id: string, layout: LayoutRectangle) => void;
  unmounted: (id: string) => void;
  navigatedFromKeyboard: boolean;
  navigatedFromKeyboardTimeout?: ReturnType<typeof setTimeout>;
  setNavigatedFromKeyboard: () => void;
  selectedIds: string[];
  toggleSelected: (id: string, params?: { shiftKey?: boolean; }) => void;
  moveFocusPrevNext: (offset: number, params?: { shiftKey?: boolean; }) => Items[number] | undefined;
  addSelected: (ids: string[]) => void;
  clearSelected: () => void;
  isAnimatingOut: number;
  setIsAnimatingOut: (isAnimatingOut: boolean) => void;
}

const toThread = (item: Items[number]) => (isInboxItemViewModel(item) ? item.thread : item);

// todo maybe use redux toolkit createSlice and use reducer in zustand https://docs.pmnd.rs/zustand/guides/flux-inspired-practice#redux-like-patterns
export function createThreadListStore(defaultValues?: Partial<ThreadListState>) {
  return createStore<ThreadListState>((set) => {
    const update = (recipe: (draft: Draft<ThreadListState>) => void) => set(produce(recipe));
    return {
      refs: {
        items: [],
        lastToggledId: null,
      },
      setItems: (items) => update((state) => {
        state.refs.items = items;
      }),

      focusedId: defaultValues?.focusedId ?? null,
      setFocusedId: (id) => update((state) => {
        state.focusedId = id;
      }),

      layouts: {} as Record<string, LayoutRectangle>,
      setLayout: (id, layout) => update((state) => {
        state.layouts[id] = layout;
      }),

      unmounted: (id) => update((state) => {
        delete state.layouts[id];
        if (state.focusedId === id) state.focusedId = null;
        state.selectedIds = state.selectedIds.filter((selectedId) => selectedId !== id);
      }),

      navigatedFromKeyboard: false,
      setNavigatedFromKeyboard: () => update((state) => {
        if (state.navigatedFromKeyboardTimeout) {
          clearTimeout(state.navigatedFromKeyboardTimeout);
        }
        state.navigatedFromKeyboard = true;
        state.navigatedFromKeyboardTimeout = setTimeout(() => {
          update((state) => {
            state.navigatedFromKeyboard = false;
            state.navigatedFromKeyboardTimeout = undefined;
          });
        }, 50);
      }),

      selectedIds: [] as string[],
      toggleSelected: (id, { shiftKey = false } = {}) => update((state: ThreadListState) => {
        const isSelected = state.selectedIds.includes(id);
        function expandSelection() {
          const threads = state.refs.items.map(toThread);
          let startIndex = threads.findIndex((thread) => thread.id === state.refs.lastToggledId);
          let endIndex = threads.findIndex((thread) => thread.id === id);
          startIndex = startIndex === -1 ? endIndex : startIndex;
          if (isSelected) {
            if (startIndex === endIndex) return;
            // Keep clicked selected
            endIndex -= Math.sign(endIndex - startIndex);
          }
          const start = Math.min(endIndex, startIndex);
          const end = Math.max(endIndex, startIndex);
          const selectedIds = threads.slice(start, end + 1).map(({ id }) => id);
          if (isSelected) {
            state.selectedIds = state.selectedIds.filter((selectedId) => !selectedIds.includes(selectedId));
          } else {
            state.selectedIds = Array.from(new Set([...state.selectedIds, ...selectedIds]));
          }
        }
        function toggleSelection() {
          if (isSelected) {
            state.selectedIds = state.selectedIds.filter((selectedId) => selectedId !== id);
          } else {
            state.selectedIds.push(id);
          }
        }
        if (shiftKey) {
          expandSelection();
        } else {
          toggleSelection();
        }
        state.refs.lastToggledId = id;
      }),
      moveFocusPrevNext: (offset: number, { shiftKey = false } = {}) => {
        let returnNextThread: Items[number] | undefined;
        update((state) => {
          const currentFocusedId = state.focusedId;
          const threads = state.refs.items.map(toThread);
          const startIndex = threads.findIndex(({ id }) => id === currentFocusedId);
          let endIndex = startIndex === -1 ? 0 : (startIndex + offset);
          endIndex = clamp(endIndex, 0, threads.length - 1);
          if (endIndex === startIndex) return;
          const nextThread = threads[endIndex];
          returnNextThread = original(nextThread);
          state.focusedId = nextThread.id;
          if (shiftKey) {
            const isNextSelected = state.selectedIds.includes(nextThread.id);
            if (isNextSelected) {
              state.selectedIds = state.selectedIds.filter((id) => id !== threads[startIndex].id);
            } else {
              const add = [currentFocusedId, nextThread.id].filter((id): id is string => Boolean(id));
              state.selectedIds = Array.from(new Set([...state.selectedIds, ...add]));
            }
            state.refs.lastToggledId = nextThread.id;
          }
        });
        return returnNextThread;
      },
      addSelected: (ids) => update((state) => {
        state.selectedIds = Array.from(new Set([...state.selectedIds, ...ids]));
      }),
      clearSelected: () => set({ selectedIds: [] }),

      isAnimatingOut: 0,
      setIsAnimatingOut: (isAnimatingOut) => update((state) => {
        state.isAnimatingOut = isAnimatingOut ? state.isAnimatingOut + 1 : state.isAnimatingOut - 1;
      }),
    };
  });
}

export type ThreadListStore = ReturnType<typeof createThreadListStore>;

const threadListContext = createContext<ThreadListStore | null>(null);

export const ThreadListProvider = threadListContext.Provider;

export function useThreadListStore() {
  const store = useContext(threadListContext);
  if (!store) {
    throw new Error('useThreadListStore must be used within a HoveredThreadProvider');
  }
  return store;
}

export function useThreadListStoreSelector<T>(selector: (state: ThreadListState) => T) {
  const store = useThreadListStore();
  return useStore(store, selector);
}

export function useThreadListStoreSelectorMaybe<T>(selector: (state: ThreadListState | null) => T) {
  const store = useContext(threadListContext);
  if (!store) return selector(null);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useStore(store, selector);
}
