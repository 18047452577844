import { Box, HStack, Text } from 'native-base';
import React, { Fragment, memo, ReactNode } from 'react';
import { isMac } from '../../global/platform';

const platformMod = isMac ? '⌘' : 'ctrl';

const displayShortcutInstead = {
  'shift+1': '!',
} as Record<string, string | undefined>;

export const DisplayShortcut = memo(
  function DisplayShortcut({ shortcut, dark }: { shortcut: string; dark?: boolean }) {
    shortcut = shortcut.trim();
    shortcut = displayShortcutInstead[shortcut] || shortcut;
    const textColor = dark ? 'opacityWhite.500' : 'dark.300';
    return (
      <HStack alignItems="center" space={1} aria-label="shortcut">
        {separate(shortcut, ' or ', <Text color={textColor} mb={0.5}>or</Text>, (str: string) => {
          return separate(str, ',', <Text color={textColor} mb={0.5}>+</Text>, (str: string) => {
            return str.split('+').map((key) => <Kbd dark={dark} key={key}>{key}</Kbd>);
          });
        })}
      </HStack>
    );
  }
);

/**
 * Separate a string by a separator and render each part with a custom renderer
 */
function separate(str: string, separator: string, replacer: ReactNode, renderPart: (part: string, index: number) => ReactNode) {
  return str.split(separator).map((part, index) => {
    const renderedPart = renderPart(part, index);
    if (index > 0) {
      return [
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>{replacer}</Fragment>,
        renderedPart,
      ];
    }
    return [renderedPart];
  }).flat();
}

const displayKbdInstead = {
  mod: platformMod,
  escape: 'esc',
} as Record<string, string | undefined>;

function Kbd({ children: kbd, dark }: { children: string; dark?: boolean }) {
  kbd = kbd.trim();
  const singleKey = kbd.length === 1;
  kbd = singleKey ? kbd.toUpperCase() : kbd.toLowerCase();
  kbd = displayKbdInstead[kbd] || kbd;
  return (
    <Box
      pt="1px"
      px="1px"
      pb="3px"
      borderRadius={5}
      bg={dark ? 'opacityWhite.200' : '#C3C8C8'}
    >
      <Box
        px={1}
        py={0}
        bg={dark ? 'opacityWhite.200' : '#FCFCFC'}
        borderRadius={4}
        minW={5}
        alignItems="center"
        justifyContent="center"
      >
        <Text
          fontWeight={500}
          fontSize="12px"
          color={dark ? 'white' : undefined}
        >
          {kbd}
        </Text>
      </Box>
    </Box>
  );
}
