import React, {
  forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState
} from 'react';
import { ChannelData, ConfigureChannelModal } from '../../ui/modals/ConfigureChannelModal';
import { InputUncontrolledHandle } from '../../ui/InputUncontrolled';

interface ConfigureChannelModalControllerProps {
  onSubmit: (channelData: ChannelData) => void;
  defaultChannelData?: ChannelData;
  type: 'create' | 'update';
  existingChannelNames: string[];
}

export interface ConfigureChannelModalControllerHandles {
  show: () => void;
  close: () => void;
  setIsLoading: (isLoading: boolean) => void;
}

export const ConfigureChannelModalController = forwardRef<ConfigureChannelModalControllerHandles, ConfigureChannelModalControllerProps>(({
  defaultChannelData: defaultChannelDataFromProps, type, onSubmit, existingChannelNames
}, ref) => {
  const defaultChannelData = useMemo(() => ({ channelName: '', isPublic: true }), []);
  const [visible, setVisible] = useState(false);
  const [channelData, setChannelData] = useState<ChannelData>(defaultChannelDataFromProps ?? defaultChannelData);
  const [isLoading, setIsLoading] = useState(false);
  const initialRef = useRef<InputUncontrolledHandle>(null);
  const isChannelDataSet = useRef(false);

  useImperativeHandle(ref, () => ({
    show: () => {
      setVisible(true);
      setIsLoading(false);
      isChannelDataSet.current = false;
    },
    close: () => {
      handleClose();
    },
    setIsLoading,
  }));

  const handleClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (visible && !isChannelDataSet.current && initialRef.current !== null) {
      const nextChannelData = defaultChannelDataFromProps ?? defaultChannelData;
      setChannelData(nextChannelData);
      initialRef.current?.setValue(nextChannelData.channelName);
      isChannelDataSet.current = true;
    }
  }, [defaultChannelDataFromProps, defaultChannelData, visible]);

  // Handle focus on input when modal is opened.
  useEffect(() => {
    if (visible && initialRef.current !== null) {
      setTimeout(() => {
        initialRef.current?.focus();
      }, 300);
    }
  }, [visible]);

  const handleInputChange = (name: string, value: string | boolean) => {
    setChannelData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = () => {
    onSubmit(channelData);
  };

  const trimmedChannelName = channelData.channelName.trim();
  const channelNameLengthIsValid = trimmedChannelName.length >= 1 && trimmedChannelName.length <= 30;
  const isPublicChanged = channelData.isPublic !== (defaultChannelDataFromProps?.isPublic ?? true);
  const isNameChanged = channelData.channelName !== (defaultChannelDataFromProps?.channelName ?? '');
  const channelNameAlreadyTaken = existingChannelNames.includes(trimmedChannelName);

  const isValid = channelNameLengthIsValid && (!channelNameAlreadyTaken || !isNameChanged);
  const isDirty = isNameChanged || isPublicChanged;
  const canFormBeSubmitted = isValid && isDirty;

  return (
    <ConfigureChannelModal
      type={type}
      visible={visible}
      setVisible={setVisible}
      channelData={channelData}
      isLoading={isLoading}
      initialRef={initialRef}
      handleClose={handleClose}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      canFormBeSubmitted={canFormBeSubmitted}
      channelNameAlreadyTakenError={isNameChanged && channelNameAlreadyTaken}
    />
  );
});
