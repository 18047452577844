import { MutationTrackingServiceInterface } from '../api/codegen';
import { EndpointLock, LockInterface } from './EndpointLock';

/* Delay after which an endpoint is considered
 * unused if it did not receive any mutation
 * and can be deleted. */
const ENDPOINT_RECYCLING_DELAY_MS = 60000;

export interface EndpointConfig {
  endpointName: string;
  args: any;
}

export class MutationTrackingService implements MutationTrackingServiceInterface {
  private lockedEndpoints: EndpointLock[] = [];

  constructor() {
    setInterval(() => this.deleteEndpointsWithOldLatestMutationTime(), 1000);
  }

  private normalizeArgs(args?: Object): Record<string, any> {
    if (args === undefined) {
      return {};
    }

    return Object.entries(args).reduce((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {} as Record<string, any>);
  }

  private getEndpoint(endpointName: string, args: any) {
    const normalizedArgs = this.normalizeArgs(args);
    return this.lockedEndpoints.find((e) => e.isEndpoint(endpointName, normalizedArgs));
  }

  private registerEndpoint(endpointName: string, args: Record<string, any>) {
    const normalizedArgs = this.normalizeArgs(args);
    const endpoint = new EndpointLock(endpointName, normalizedArgs);
    this.lockedEndpoints.push(endpoint);
    return endpoint;
  }

  public acquireEndpointLocks(endpointNames: EndpointConfig[]): LockInterface[] {
    const locks = endpointNames.map((endpointConfig) => {
      const endpoint = this.getEndpoint(endpointConfig.endpointName, endpointConfig.args) ?? this.registerEndpoint(endpointConfig.endpointName, endpointConfig.args);
      return endpoint.acquireLock();
    });

    return locks;
  }

  public waitForEndpointAvailability(endpointName: string, args: any) {
    const endpoint = this.getEndpoint(endpointName, args);

    if (endpoint === undefined) {
      return Promise.resolve();
    }

    return endpoint.waitForEndpointAvailability();
  }

  public isQueryResultOutdated(endpointName: string, args: any, requestStartDateTime: Date) {
    const endpoint = this.getEndpoint(endpointName, args);

    if (endpoint === undefined) {
      return false;
    }

    return endpoint.isQueryResultOutdated(requestStartDateTime);
  }

  private deleteEndpointsWithOldLatestMutationTime() {
    this.lockedEndpoints = this.lockedEndpoints.filter((endpoint) => !endpoint.isOlderThan(ENDPOINT_RECYCLING_DELAY_MS) || endpoint.isLocked());
  }
}

export const mutationTrackingService = new MutationTrackingService();
