import { useCallback, useRef } from 'react';
import { Range } from 'quill';
import { type RichTextEditorHandle } from '.';
import { Emoji } from '../emoji/popover/Emoji';

export function useInsertEmoji(bodyRef: React.RefObject<RichTextEditorHandle>) {
  const lastBodySelection = useRef<Range | undefined>(undefined);

  const emojiSelectedCallback = useCallback((emoji: Emoji) => {
    if (bodyRef.current) {
      const cursorIndex = lastBodySelection.current?.index ?? 0;
      bodyRef.current.insertText(cursorIndex, emoji.native, 'user');
      bodyRef.current.setSelection(cursorIndex + emoji.native.length, 'user');
    }
  }, [bodyRef]);

  const onTextChange = useCallback(() => {
    lastBodySelection.current = bodyRef.current?.getSelection() ?? undefined;
  }, [bodyRef]);

  const onSelectionChange = useCallback((range: Range) => {
    if (range !== null) {
      lastBodySelection.current = range;
    }
  }, []);
  return { onTextChange, onSelectionChange, emojiSelectedCallback };
}
