import {
  AddThreadParticipantsApiArg, AddThreadParticipantsApiResponse, ConversationParticipant, User
} from '../api/codegen';
import { MutationHandler, MutationHandlerProps } from './MutationHandler';
import { ThreadViewModel, addThreadViewModelParticipants } from '../view-models/ThreadViewModel';
import { createThreadUpdatePatchesForThreadId } from './thread-patch-factory/createThreadUpdatePatchesForThreadId';

export class AddThreadParticipantsMutationHandler extends MutationHandler<AddThreadParticipantsApiArg, AddThreadParticipantsApiResponse> {
  constructor(props: MutationHandlerProps<AddThreadParticipantsApiArg, AddThreadParticipantsApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: AddThreadParticipantsApiArg) {
    return [
      ...createThreadUpdatePatchesForThreadId(patch.threadId, (draftedThread) => this.updateThread(draftedThread, patch)),
    ];
  }

  private updateThread(draftedThread: ThreadViewModel, patch: AddThreadParticipantsApiArg) {
    const newParticipants = this.getNewParticipants(patch);
    addThreadViewModelParticipants(draftedThread, newParticipants);
  }

  private getNewParticipants(patch: AddThreadParticipantsApiArg) {
    const newMembers = this.getOrganizationMembers().filter((member) => patch.body.some((userId) => userId === member.id));
    return newMembers.map((member) => this.createConversationParticipant(member.rawUser));
  }

  private createConversationParticipant(user: User): ConversationParticipant {
    return {
      type: 'user',
      user
    };
  }

  protected generateInvalidationTags(arg: AddThreadParticipantsApiArg) {
    const threadTag = { type: 'Thread' as const, id: arg.threadId };

    return [
      threadTag,
    ];
  }
}
