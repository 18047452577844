import { Channel } from '../api/codegen';
import { ChannelViewModel, createChannelViewModel } from './ChannelViewModel';

export type ChannelsViewModel = {
  channels: ChannelViewModel[];
};

export function createChannelsViewModel(channels: Channel[]): ChannelsViewModel {
  return {
    channels: channels.map(createChannelViewModel),
  };
}
