import { Box } from 'native-base';
import { useRoute, RouteProp } from '@react-navigation/native';
import { useMemo } from 'react';
import { HeaderTitle } from '../../ui/HeaderTitle';
import { AppScreenStackParamList } from '../../navigation/navigators/app/AppScreenProps';
import { CATEGORIES } from '../../../adapters/view-models/ChannelViewModel';

export function InboxHeaderTitleController() {
  const route = useRoute<RouteProp<AppScreenStackParamList, 'Inbox'>>();
  const categorySlug = route.params?.category;
  const title = useMemo(() => {
    if (!categorySlug) {
      return 'Inbox';
    }
    for (const value of Object.values(CATEGORIES)) {
      if (value.slug === categorySlug) {
        return value.name;
      }
    }
    return 'Inbox';
  }, [categorySlug]);
  return (
    <Box marginLeft={4}>
      <HeaderTitle>
        {title}
      </HeaderTitle>
    </Box>
  );
}
