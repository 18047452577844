import { useLayoutEffect } from 'react';
import { MentionBlotData } from './MentionBlot';

export const MentionPill = ({ data, node }: {
  data: MentionBlotData;
  node: HTMLElement;
}) => {
  useLayoutEffect(() => {
    Object.assign(node.style, {
      padding: '0 4px 0 2px',
      background: 'rgba(0, 0, 0, 0.05)',
      borderRadius: '4px',
      display: 'inline-block',
      lineHeight: '20px',
      fontWeight: '500',
    });
  }, [node.style]);
  return (
    <>
      <img
        src={data.pictureInfo.url || ''}
        // alt={data.value}
        alt=""
        style={{
          // display: 'inline-block',
          verticalAlign: 'top',
          width: '16px',
          height: '16px',
          borderRadius: '2px',
          marginRight: '4px',
          // transform: 'translateY(2px)',
          marginTop: '2px',
        }}
      />
      <span style={{ opacity: 0.8 }}>{data.value}</span>
    </>
  );
};
