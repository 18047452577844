import { useMemo } from 'react';
import { useDefaultDateOptions } from './useDefaultDateOptions';
import { DateProps } from './DateProps';
import { generateDatePropsFromPrompt } from './generateDatePropsFromPrompt';
import { determineDateFromPrompt } from './determineDateFromPrompt';
import { dateOptions } from './dateOptions';

export function useDateOptions(datePrompt: string) {
  const defaultOptions = useDefaultDateOptions();

  const options = useMemo(
    () => {
      return generateDateOptions(datePrompt, defaultOptions);
    },
    [datePrompt, defaultOptions]
  );
  return options;
}

export function generateDateOptions(datePrompt: string, defaultOptions: DateProps[]) {
  if (!datePrompt || datePrompt.length === 0) {
    return defaultOptions;
  }

  const options: DateProps[] = [];

  const twoDigitRegex = /^([Ii][nN] )?(\d{1,2})( (.+)?)?$/;
  if (datePrompt.length > 0 && twoDigitRegex.test(datePrompt)) {
    const unit = datePrompt.match(twoDigitRegex)![4]?.toLocaleLowerCase();

    const defaultUnits = ['minute', 'hour', 'day'];

    let units: string[] = [];

    if (unit) {
      units = defaultUnits.filter((defaultUnit) => defaultUnit.startsWith(unit) || `${defaultUnit}s`.startsWith(unit));
    } else {
      units = defaultUnits;
    }

    options.push(...units.map((unit) => {
      const digits = parseInt(datePrompt.match(twoDigitRegex)![2], 10);
      const unitDisplay = digits > 1 ? `${unit}s` : unit;
      return {
        dateLabel: `In ${digits} ${unitDisplay}`,
        date: determineDateFromPrompt(`in ${digits} ${unitDisplay}`) as Date
      };
    }));
  }

  if (options.length === 0 && datePrompt.length > 0 && /^[Ii]([nN] ?)?( )*$/.test(datePrompt)) {
    const defaultOptions = [
      { dateLabel: 'In 30 minutes', datePrompt: 'in 1 hour' },
      { dateLabel: 'In 4 hours', datePrompt: 'in 4 hours' },
      { dateLabel: 'In 1 day', datePrompt: 'in 1 day' }
    ];

    options.push(...defaultOptions.map((option) => {
      const date = determineDateFromPrompt(option.datePrompt) as Date;
      return {
        dateLabel: option.dateLabel,
        date
      };
    }));
  }

  if (options.length === 0) {
    const matchedOptions = dateOptions.filter((option) => option.dateLabel.toLocaleLowerCase().includes(datePrompt.toLocaleLowerCase()));

    options.push(...matchedOptions.slice(0, 5 - options.length));
  }

  if (options.length < 5) {
    const matchedDefaultOptions = defaultOptions.filter((option) => option.dateLabel.toLocaleLowerCase().includes(datePrompt.toLocaleLowerCase()));
    options.push(...matchedDefaultOptions.slice(0, 5 - options.length));
  }

  if (options.length === 0) {
    const firstOption = generateDatePropsFromPrompt(datePrompt);
    if (firstOption) {
      options.push(firstOption);
    }
  }

  return options;
}
