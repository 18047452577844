export function getBorderColor(hideToolkitWhenUnfocused: boolean | undefined, anyInputFocused: boolean, hovered: boolean) {
  if (hideToolkitWhenUnfocused && !anyInputFocused) {
    if (hovered) {
      return 'dark.10';
    }

    return 'transparent';
  }

  const borderColor = anyInputFocused ? 'dark.100' : 'dark.5';
  return borderColor;
}
