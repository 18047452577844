import { MaterialCommunityIcons } from '@expo/vector-icons';
import { ComponentProps, useCallback } from 'react';
import { type Menu } from 'native-base';
import { ItemActionButton, ItemActionButtonProps } from './ItemActionButton';
import { ComprehensiveThreadViewModel, getSnoozedUntil } from '../../../adapters/view-models/ThreadViewModel';
import { DatePickerMenu } from '../date-picker-menu/menu/DatePickerMenu';

type InterfaceMenuProps = ComponentProps<typeof Menu>;

type SnoozeItemActionButtonProps = Pick<ItemActionButtonProps, 'smaller'> & {
  thread: ComprehensiveThreadViewModel;
  updateSnoozeEndDateCallback: (thread: ComprehensiveThreadViewModel, snoozeEndDate: Date | null) => void;
  shortcutEnabled: boolean;
  menuPlacement?: InterfaceMenuProps['placement'];
  setSnoozeOpen?: (isOpen: boolean) => void;
};

export function SnoozeItemActionButton(props: SnoozeItemActionButtonProps) {
  // const [isSnoozeMenuOpen, setIsSnoozeMenuOpen] = useState(false);
  const { thread, updateSnoozeEndDateCallback } = props;

  const setSnoozeEndDate = useCallback((date: Date | null) => {
    updateSnoozeEndDateCallback(thread, date);
  }, [updateSnoozeEndDateCallback, thread]);

  const snoozeEndDateFormatted = props.thread.rawSnoozeEndDate ? getSnoozedUntil(props.thread.rawSnoozeEndDate, true) : undefined;
  const hint = snoozeEndDateFormatted ?? 'Snooze';
  const iconColor = props.thread.snoozedUntil ? 'snooze.500' : undefined;

  const trigger = useCallback((triggerProps: any, { open }: { open: boolean; }) => (
    <ItemActionButton
      smaller={props.smaller}
      iconCollection={MaterialCommunityIcons}
      shortcut="b"
      shortcutEnabled={props.shortcutEnabled}
      iconName="clock-outline"
      hint={hint}
      provideCommandId="snooze"
      backgroundColor={open ? 'light.100' : 'transparent'}
      triggerProps={triggerProps}
      iconColor={iconColor}
      hasDelay={!props.thread.snoozedUntil}
    />
  ), [hint, iconColor, props.shortcutEnabled, props.smaller, props.thread.snoozedUntil]);

  const menuTitle = snoozeEndDateFormatted ? `Snoozed until ${snoozeEndDateFormatted}` : 'Snooze until';

  return (
    <DatePickerMenu
      onSubmitDate={setSnoozeEndDate}
      menuPlacement={props.menuPlacement}
      title={menuTitle}
      trigger={trigger}
      activeDate={!props.thread.rawSnoozeEndDate ? undefined : {
        date: new Date(props.thread.rawSnoozeEndDate),
        label: snoozeEndDateFormatted!,
        isClearDateOptionEnabled: true
      }}
      onOpenStateChange={props.setSnoozeOpen}
      clearDateOptionLabel="Unsnooze"
    />
  );
}
