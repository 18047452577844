import { Message } from '../api/codegen';
import { MessageViewModel, MessageViewModelAdditionalParams, createMessageViewModel } from './MessageViewModel';

export type MessagesViewModel = {
  messages: MessageViewModel[];
};

export type MessagesViewModelAdditionalParams = MessageViewModelAdditionalParams;

export function createMessagesViewModel(messages: Message[]): MessagesViewModel {
  return {
    messages: messages.map((message) => createMessageViewModel(message)),
  };
}
