import { HeaderTitleProps } from '@react-navigation/elements';
import { Heading } from 'native-base';
import React from 'react';

export type ExtendedHeaderTitleProps = HeaderTitleProps;

export function HeaderTitle(props: ExtendedHeaderTitleProps) {
  return (
    <Heading size="md" color={props.tintColor} allowFontScaling={props.allowFontScaling} flexDirection="row" alignItems="center" bgColor="dark.50">
      {props.children}
    </Heading>
  );
}
