import React, {
  createContext, useContext, useEffect, useState
} from 'react';
import EventEmitter from 'eventemitter3';
import type { UserViewModel } from '@/adapters/view-models/UserViewModel';
import { useEffectEvent } from '@/infrastructure/hooks/useEffectEvent';

type MentionEventsContext = EventEmitter;

type MentionEventPayload = UserViewModel;

const mentionEventsContext = createContext<MentionEventsContext | null>(null);

export const useOnMentioned = (onMentioned: (payload: MentionEventPayload) => void) => {
  const emitter = useContext(mentionEventsContext);
  const onMentionedEvent = useEffectEvent(onMentioned);
  useEffect(() => {
    if (emitter) {
      emitter.on('mentioned', onMentionedEvent);
      return () => void emitter.off('mentioned', onMentionedEvent);
    }
  }, [emitter]);
};

const MentionEventsProvider = ({ children }: { children: React.ReactNode }) => {
  const providedEmitter = useContext(mentionEventsContext);
  const [emitter] = useState(() => new EventEmitter());
  return (
    <mentionEventsContext.Provider value={providedEmitter || emitter}>
      {children}
    </mentionEventsContext.Provider>
  );
};

export const useMentionEmitEvent = () => {
  const emitter = useContext(mentionEventsContext);
  return useEffectEvent((payload: MentionEventPayload) => {
    emitter?.emit('mentioned', payload);
  });
};

export default MentionEventsProvider;
