import { MessageReactionViewModelAdditionalParams } from '../MessageReactionViewModel';
import { createUserViewModel } from '../UserViewModel';

export function createExtendedMessageReactionViewModelParams(params: MessageReactionViewModelAdditionalParams, addSelfAsYou = false): MessageReactionViewModelAdditionalParams {
  const fullListOfUsers = params.users ? [
    ...(params.selfUserId && addSelfAsYou ? [createUserViewModel({
      id: params.selfUserId, display_name: 'You', picture_url: null, email: '', features: [],
    })] : []),
    ...params.users,
  ] : undefined;

  const uniqueFullListOfUsers = fullListOfUsers ? [...new Set(fullListOfUsers)] : undefined;

  const extendedParams: MessageReactionViewModelAdditionalParams = {
    ...params,
    users: uniqueFullListOfUsers,
  };
  return extendedParams;
}
