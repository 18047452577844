import { ThreadListItemPressableContainer } from './ThreadListItemPressableContainer';
import { ThreadListItemAbstractContainer } from './ThreadListItemAbstractContainer';
import { ThreadReactionsBlock } from './blocks/ThreadReactionsBlock';
import { ReactionsThreadListItemProps } from './ReactionsThreadListItemProps';

export function ReactionsThreadListItem(props: ReactionsThreadListItemProps) {
  const { inboxItem, selectThreadCallback: threadSelectedCallback } = props;
  const { thread } = inboxItem;

  return (
    <ThreadListItemPressableContainer thread={thread} selectThreadCallback={threadSelectedCallback} threadHoveredCallback={props.threadHoveredCallback}>
      <ThreadListItemAbstractContainer {...props} thread={thread}>
        <ThreadReactionsBlock inboxItem={inboxItem} />
      </ThreadListItemAbstractContainer>
    </ThreadListItemPressableContainer>
  );
}
