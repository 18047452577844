import {
  VStack, Text, Heading, Image
} from 'native-base';
import { memo } from 'react';

export const AppScreenMobile = memo(() => (
  <VStack space={4} p={5} flexGrow={1} h="100%" w="100%" bgColor="dark.50" alignItems="center" justifyContent="center">
    <Image
      source={{
        uri: '/images/secondary_logo.png'
      }}
      alt="Upstream"
      resizeMode="contain"
      h="80px"
      w="80px"
      rounded="2xl"
    />
    <Heading textAlign="center">Sorry, Upstream is not yet available on mobile devices.</Heading>
    <Text textAlign="center" fontSize="lg">The app will soon be compatible with mobile devices. In the meantime, please visit app.upstream.do on your desktop.</Text>
  </VStack>
));
