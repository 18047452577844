import { rawApiClient } from './codegen';
import {
  postMessageMutationHandler,
  createThreadMutationHandler,
  createChannelMutationHandler,
  updateChannelMutationHandler,
  deleteChannelMutationHandler,
  removeEventFromInboxMutationHandler,
  addThreadToInboxMutationHandler,
  markMessagesAsReadMutationHandler,
  markMessagesAsUnreadMutationHandler,
  markAllThreadMessagesAsReadMutationHandler,
  addThreadParticipantsMutationHandler,
  removeThreadParticipantsMutationHandler,
  updateThreadMutationHandler,
  addChannelParticipantsMutationHandler,
  removeChannelParticipantsMutationHandler,
  addMessageReactionMutationHandler,
  removeMessageReactionMutationHandler,
  addThreadChannelsMutationHandler,
  removeThreadChannelsMutationHandler,
  markInboxItemAsReadMutationHandler,
  markThreadAsSpamMutationHandler,
  markThreadAsNotSpamMutationHandler,
  snoozeThreadMutationHandler,
  updateChannelNotificationLevelMutationHandler,
  trashThreadMutationHandler,
  untrashThreadMutationHandler,
  deleteThreadsMutationHandler,
  starThreadsMutationHandler,
  unstarThreadsMutationHandler,
} from './mutation-handlers';

export function buildApiClientWithMutationHandlers() {
  return rawApiClient.enhanceEndpoints({
    endpoints: {
      postMessage: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await postMessageMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        },
      },

      createThread: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await createThreadMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        },
      },

      createChannel: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await createChannelMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        },
      },

      updateChannel: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await updateChannelMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        },
      },

      deleteChannel: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await deleteChannelMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        },
      },

      removeEventFromInbox: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await removeEventFromInboxMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        },
      },

      addThreadToInbox: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await addThreadToInboxMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        },
      },

      markEventAsRead: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await markInboxItemAsReadMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        },
      },

      markMessagesAsRead: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await markMessagesAsReadMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        },
      },

      markMessagesAsUnread: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await markMessagesAsUnreadMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        },
      },

      markAllThreadMessagesAsRead: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await markAllThreadMessagesAsReadMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        },
      },

      addThreadParticipants: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await addThreadParticipantsMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        }
      },

      removeThreadParticipants: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await removeThreadParticipantsMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        }
      },

      updateThread: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await updateThreadMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        },
      },

      addChannelParticipants: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await addChannelParticipantsMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        }
      },

      removeChannelParticipants: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await removeChannelParticipantsMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        }
      },

      addMessageReaction: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await addMessageReactionMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        }
      },

      removeMessageReaction: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await removeMessageReactionMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        }
      },

      addThreadChannels: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await addThreadChannelsMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        }
      },

      removeThreadChannels: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await removeThreadChannelsMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        }
      },

      markThreadAsNotSpam: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await markThreadAsNotSpamMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        }
      },

      markThreadAsSpam: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await markThreadAsSpamMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        }
      },

      trashThread: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await trashThreadMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        }
      },

      untrashThread: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await untrashThreadMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        }
      },

      deleteThreads: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await deleteThreadsMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        }
      },

      starThreads: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await starThreadsMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        }
      },

      unstarThreads: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await unstarThreadsMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        }
      },

      snoozeThread: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await snoozeThreadMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        }
      },

      updateChannelNotificationLevel: {
        async onQueryStarted({ ...patch }, { queryFulfilled, requestId }) {
          await updateChannelNotificationLevelMutationHandler.applyMutationUpdates(patch, queryFulfilled, requestId);
        },
      },
    },
  });
}
