import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import {
  TrashThreadApiArg,
  TrashThreadApiResponse
} from '../api/codegen';
import { MutationHandler, MutationHandlerProps } from './MutationHandler';
import { ThreadViewModel } from '../view-models/ThreadViewModel';
import { createThreadUpdatePatchesForThreadId } from './thread-patch-factory/createThreadUpdatePatchesForThreadId';
import { ThreadsViewModel } from '../view-models/ThreadsViewModel';
import { getCurrentChannelParams } from './utils/getCurrentChannelParams';
import { createThreadUpdatePatches } from './thread-patch-factory/createThreadUpdatePatches';
import { getInboxParams } from './utils/getInboxParams';
import { updateQueryData } from './utils/updateQueryData';
import { formatDate } from '../view-models/utilities';
import { draftActions, threadDraftIdPrefix } from '../../domain/state/drafts';

export class TrashThreadMutationHandler extends MutationHandler<TrashThreadApiArg, TrashThreadApiResponse> {
  constructor(props: MutationHandlerProps<TrashThreadApiArg, TrashThreadApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: TrashThreadApiArg) {
    return [
      ...this.createRemoveThreadFromEveryFolderPatches(patch),
      this.createRemoveThreadFromInboxPatch(patch),
      this.createAddThreadToTrashFolderPatch(patch),
      this.deleteDraft(patch),
      ...createThreadUpdatePatchesForThreadId(patch.threadId, this.updateThread),
    ];
  }

  private deleteDraft(patch: TrashThreadApiArg) {
    return draftActions.deleteDraft(`${threadDraftIdPrefix}${patch.threadId}`);
  }

  private createRemoveThreadFromInboxPatch(patch: TrashThreadApiArg) {
    return updateQueryData('getSelfInboxEvents', getInboxParams() ?? {}, (draftedInboxItems) => {
      const itemIndex = draftedInboxItems.inboxItems.findIndex((item) => item.thread.id === patch.threadId);

      if (itemIndex !== -1) {
        draftedInboxItems.inboxItems.splice(itemIndex, 1);
      }
    });
  }

  private createRemoveThreadFromEveryFolderPatches(patch: TrashThreadApiArg) {
    return createThreadUpdatePatches((draftedThreads) => {
      const itemIndex = draftedThreads.findIndex((item) => item.id === patch.threadId);

      if (itemIndex !== -1) {
        draftedThreads.splice(itemIndex, 1);
      }
    });
  }

  private createAddThreadToTrashFolderPatch(patch: TrashThreadApiArg) {
    const thread = this.getThreadInCurrentContext(patch.threadId);

    return this.updateQueryData('getSelfTrashedThreads', {}, (draftedThreads) => {
      if (thread) {
        this.updateThread(thread);
        removeExistingThread(draftedThreads);
        const actualInsertIndex = findIndexToInsertToThread(draftedThreads);

        draftedThreads.threads.splice(actualInsertIndex, 0, thread);

        if (draftedThreads.threads.length > 25) {
          draftedThreads.threads.pop();
        }
      }
    });

    function findIndexToInsertToThread(draftedThreads: MaybeDrafted<ThreadsViewModel>) {
      const dates = draftedThreads.threads
        .map((item) => new Date(item.lastActivity.date));

      const curentThreadLastActivityDate = thread ? new Date(thread.lastActivity.date) : new Date();
      const insertIndex = dates.findIndex((date) => date.getTime() < curentThreadLastActivityDate.getTime());
      const actualInsertIndex = insertIndex === -1 ? draftedThreads.threads.length : insertIndex;
      return actualInsertIndex;
    }

    function removeExistingThread(draftedThreads: MaybeDrafted<ThreadsViewModel>) {
      const existingThreadIndex = draftedThreads.threads.findIndex((item) => item.id === patch.threadId);
      if (existingThreadIndex !== -1) {
        draftedThreads.threads.splice(existingThreadIndex, 1);
      }
    }
  }

  private updateThread(thread: ThreadViewModel) {
    thread.isTrashed = true;
    thread.event = undefined;
    thread.eventId = undefined;
    thread.lastSenderMessageDate = formatDate(thread.lastActivity.date, false);
  }

  protected generateInvalidationTags(arg: TrashThreadApiArg) {
    return [
      {
        type: 'Thread' as const,
        id: arg.threadId,
        schedule: {
          delayMs: 10000,
          uniqueKey: `ChannelThreadListManagement-${getCurrentChannelParams()}`,
        }
      },
      {
        type: 'SelfAccount' as const,
        schedule: {
          delayMs: 5000,
          uniqueKey: 'InboxUnreadThreadCount',
        }
      },
      {
        type: 'SelfTrashedThreadsList' as const,
      }
    ];
  }
}
