import {
  Box, Divider, FlatList, Icon
} from 'native-base';
import { FlatListProps, ListRenderItemInfo } from 'react-native';
import {
  useCallback, useMemo
} from 'react';
import { Text } from 'native-base';
import {
  ParticipantListItem, ParticipantCallback, RecipientContainer, RecipientIcon
} from './ParticipantListItem';
import { ParticipantViewModel } from '../../adapters/view-models/ParticipantViewModel';

type ParticipantsListProps = Omit<Parameters<typeof FlatList>[0], 'renderItem' | 'data'> & {
  users: ParticipantViewModel[];
  selectParticipantCallback?: ParticipantCallback;
  interactingWithParticipantsListCallback?: (isInteracting: boolean) => void;
  renderItem?: FlatListProps<ParticipantViewModel>['renderItem'];
  listEmptyComponent?: FlatListProps<ParticipantViewModel>['ListEmptyComponent'];
  clearAllParticipantsCallback?: () => void;
  clearAllText?: string;
  allowExternalUsers?: boolean;
};

function ClearAllButton(props: { clearAllParticipantsCallback: () => void, clearAllText?: string }) {
  return (
    <RecipientContainer selectable onPress={props.clearAllParticipantsCallback}>
      <RecipientIcon>
        <Icon name="clear" />
      </RecipientIcon>
      <Text flexGrow={1}>{props.clearAllText ?? 'Clear all'}</Text>
    </RecipientContainer>
  );
}

type ParticipantListDivider = {
  id: 'divider';
  _type: 'divider';
};

type ParticipantListItem = ParticipantViewModel | ParticipantListDivider;

export function ParticipantsList(props: ParticipantsListProps) {
  const defaultRenderItem = useCallback(
    ({ item }: { item: ParticipantViewModel }) => <ParticipantListItem recipient={item} selectParticipantCallback={props.selectParticipantCallback} interactingWithParticipantsListCallback={props.interactingWithParticipantsListCallback} />,
    [props.selectParticipantCallback, props.interactingWithParticipantsListCallback]
  );

  const [deletableParticipants, notDeletableParticipants] = useMemo(() => {
    const deletableParticipants: ParticipantViewModel[] = [];
    const notDeletableParticipants: ParticipantViewModel[] = [];

    props.users.forEach((participant) => {
      if (participant._type == 'picture' && participant.canBeRemoved) {
        deletableParticipants.push(participant);
      } else {
        notDeletableParticipants.push(participant);
      }
    });

    return [deletableParticipants, notDeletableParticipants];
  }, [props.users]);

  const renderItem = useCallback((info: ListRenderItemInfo<ParticipantListItem>) => {
    const { item } = info;
    if (item._type === 'divider') {
      return (
        <Box my={2} alignItems="center">
          <Divider maxWidth="408px" />
        </Box>
      );
    }

    const renderFn = props.renderItem ?? defaultRenderItem;
    return renderFn(info as ListRenderItemInfo<ParticipantViewModel>);
  }, [defaultRenderItem, props.renderItem]);

  const items = useMemo(() => {
    const items: ParticipantListItem[] = [];

    items.push(...deletableParticipants);
    if (deletableParticipants.length > 0 && notDeletableParticipants.length > 0) {
      items.push({ id: 'divider', _type: 'divider' });
    }
    items.push(...notDeletableParticipants);

    return items;
  }, [deletableParticipants, notDeletableParticipants]);

  const passedProps: FlatListProps<ParticipantListItem> = {
    ...props as Omit<FlatListProps<ParticipantListItem>, 'renderItem' | 'data'>,
    data: items,
    renderItem,
    keyExtractor: (item) => item.id,
    ListEmptyComponent: props.listEmptyComponent,
    ListHeaderComponent: props.clearAllParticipantsCallback !== undefined ? <ClearAllButton clearAllParticipantsCallback={props.clearAllParticipantsCallback} clearAllText={props.clearAllText} /> : undefined,

    /* Not all rows are rendered in the Add Channel modal view,
     * even after scrolling to the bottom the number stays at initialNumToRender.
     * Putting a high number here to make sure all rows are rendered, but this
     * is a workaround that should be addressed later/ */
    initialNumToRender: 200,
  };

  return (
    <FlatList
      {...passedProps}
    />
  );
}
