import {
  Box, HStack, useToken, VStack,
} from 'native-base';
import { memo, useMemo } from 'react';
import { AppScreenNavigator } from '../../navigation/navigators/app/AppScreenNavigator';
import { AppHeaderController } from '../../controllers/headers/AppHeaderController';
import { MenuScreenSectionController } from '../../controllers/screens/MenuScreenSectionController';
import { customShadow } from '../../styles/customShadow';
import { ShortcutsModal } from '../modals/ShortcutsModal';
import { CommandMenu } from '../CommandMenu/CommandMenu';
import { hexColorToRgba } from '@/infrastructure/styles/main-theme/colors';

function useDeepShadow() {
  const color = useToken('colors', 'primary.800');
  return useMemo(() => {
    const shadow1 = `0px 13px 18px -7px ${hexColorToRgba(color, 0.22)}`;
    const shadow2 = `0px 16px 30px 3px ${hexColorToRgba(color, 0.17)}`;
    const shadow3 = `0px 7px 29px 8px ${hexColorToRgba(color, 0.07)}`;
    return `${shadow1}, ${shadow2}, ${shadow3}`;
  }, [color]);
}

export const AppScreen = memo(() => {
  const boxShadow = useDeepShadow();
  return (
    <>
      <VStack
        space={0}
        flexGrow={1}
        h="100%"
        w="100%"
        // bgColor="dark.50"
        aria-label="App Screen"
        role="presentation"
      >
        <AppHeaderController />
        <HStack space={0} flexGrow={1} flexShrink={1}>
          <Box w={224} flexGrow={0} flexShrink={0}>
            <MenuScreenSectionController />
          </Box>
          <Box
            flexGrow={8}
            marginRight={2}
            marginBottom={2}
            borderRadius={16}
            style={customShadow}
            bgColor="rgba(255, 255, 255, 0.9)"
            overflow="hidden"
            // @ts-ignore
            _web={{ style: { boxShadow } }}
          >
            <AppScreenNavigator />
          </Box>
        </HStack>
      </VStack>
      <ShortcutsModal />
      <CommandMenu />
    </>
  );
});
