import {
  Box, Button, Text, VStack, Image,
} from 'native-base';
import { customShadow } from '../../styles/customShadow';

type ScreenProps = {
  signInCallback: () => void;
  isLoding: boolean;
  errorMessage: string | null;
  openPrivacyPolicyCallback: () => void;
};

export function SignInScreen(props: ScreenProps) {
  return (
    <Box flexGrow={1} justifyContent="center" alignItems="center" style={customShadow} bgColor="dark.50">
      <VStack width="90%" maxW="300px" borderColor="muted.300" borderWidth={1} p={7} py={5} space={4} borderRadius={10} style={customShadow} bgColor="white">
        <Image source={{ uri: 'images/logo_whitescreen.png' }} alt="Upstream" borderRadius={30} size="xl" w="100%" mx="auto" resizeMode="contain" h="30px" alignSelf="stretch" my={2} mb={1} />
        <Text>Upstream is a messaging app for teams. Sign in by pressing the button below to access the application.</Text>
        <Button isLoading={props.isLoding} onPress={props.signInCallback} variant="secondary">
          Sign in
        </Button>
        <Text textAlign="center" fontSize="xs" color="secondary.700" bold display={props.errorMessage ? 'flex' : 'none'}>
          {props.errorMessage}
        </Text>
      </VStack>
      <Button variant="link" onPress={props.openPrivacyPolicyCallback} mt={4} _text={{ color: 'gray.400' }} position="absolute" bottom={5}>
        Privacy Policy
      </Button>
    </Box>
  );
}
