import { useCallback, useMemo } from 'react';

const SESSION_STORAGE_NAVIGATION_STATE_KEY = 'navigation_state';

export function usePreserveStateOnRefresh() {
  const initialState = useGetInitialState();

  const onStateChange = useCallback((state: any) => {
    try {
      sessionStorage.setItem(SESSION_STORAGE_NAVIGATION_STATE_KEY, JSON.stringify(state));
    } catch (error) {
      console.error('Error saving navigation state', error);
    }
  }, []);

  return { onStateChange, initialState };
}

function useGetInitialState() {
  return useMemo(() => {
    const perfEntries = performance.getEntriesByType('navigation');

    if (perfEntries.length > 0 && perfEntries[0].toJSON().type === 'reload') {
      try {
        const initialNavigationStateRawData = sessionStorage.getItem(SESSION_STORAGE_NAVIGATION_STATE_KEY);
        const initialState = initialNavigationStateRawData ? JSON.parse(initialNavigationStateRawData) : undefined;
        return initialState;
      } catch (error) {
        console.error('Error getting navigation state', error);
        sessionStorage.removeItem(SESSION_STORAGE_NAVIGATION_STATE_KEY);
      }
    }
  }, []);
}
