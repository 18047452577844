import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { UpdateChannelApiArg, UpdateChannelApiResponse } from '../api/codegen';
import { MutationHandler, MutationHandlerProps } from './MutationHandler';
import { ChannelsViewModel } from '../view-models/ChannelsViewModel';
import { ChannelViewModel, determineRegularChannelIconSetName } from '../view-models/ChannelViewModel';

export class UpdateChannelMutationHandler extends MutationHandler<UpdateChannelApiArg, UpdateChannelApiResponse> {
  constructor(props: MutationHandlerProps<UpdateChannelApiArg, UpdateChannelApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: UpdateChannelApiArg) {
    return [
      this.createGetChannelPatch(patch),
      this.createSelfChannelsPatch(patch),
      this.createOrganizationChannelsPatch(patch)
    ];
  }

  private createGetChannelPatch(patch: UpdateChannelApiArg) {
    return this.updateQueryData('getChannel', { channelId: patch.channelId }, (draft) => {
      this.updateChannel(draft, patch);
    });
  }

  private createSelfChannelsPatch(patch: UpdateChannelApiArg) {
    return this.updateQueryData('getSelfChannels', undefined, (draft) => {
      this.updateChannelInChannelsList(draft, patch);
    });
  }

  private createOrganizationChannelsPatch(patch: UpdateChannelApiArg) {
    return this.updateQueryData('getOrganizationChannels', { organizationId: this.getSelfOrganization()?.id! }, (draft) => {
      this.updateChannelInChannelsList(draft, patch);
    });
  }

  private updateChannelInChannelsList(draft: MaybeDrafted<ChannelsViewModel>, patch: UpdateChannelApiArg) {
    const channel = draft.channels.find((c) => c.id === patch.channelId);
    if (channel) {
      this.updateChannel(channel, patch);
    }
  }

  private updateChannel(channel: ChannelViewModel, patch: UpdateChannelApiArg) {
    channel.name = patch.channelUpdateRequestBody.name;
    channel.iconSetName = determineRegularChannelIconSetName(patch.channelUpdateRequestBody.is_public);
    channel.isPublic = patch.channelUpdateRequestBody.is_public;
  }

  protected generateInvalidationTags(_arg: UpdateChannelApiArg) {
    return [{ type: 'SelfChannels' as const }, { type: 'OrganizationChannels' as const }];
  }
}
