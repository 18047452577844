import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { OutdatedErrorName, PlatformErrorTypeEnum, PlatformError } from '../api/codegen';

export function createStandardErrorIfAny(rawError: FetchBaseQueryError | SerializedError | undefined): StandardError | undefined {
  if (!rawError) {
    return undefined;
  }

  return createStandardError(rawError);
}

export function createStandardError(rawError: FetchBaseQueryError | SerializedError): StandardError {
  const standardErrors = createStandardErrors(rawError);
  return standardErrors[0];
}

export function createStandardErrors(rawError: FetchBaseQueryError | SerializedError): StandardError[] {
  const fetchBaseQueryError = 'status' in rawError ? rawError as FetchBaseQueryError : undefined;
  const serializedError = !fetchBaseQueryError ? rawError as SerializedError : undefined;

  if (fetchBaseQueryError) {
    if (fetchBaseQueryError.status === 'FETCH_ERROR' || fetchBaseQueryError.status === 'TIMEOUT_ERROR') {
      return [{
        type: StandardErrorTypeEnum.Network,
        message: `A network error occurred: ${fetchBaseQueryError.error}`,
        displayMessage: 'A network error occurred. Please check your internet connection and try again.',
      }];
    }

    if (fetchBaseQueryError.status === 'CUSTOM_ERROR' && fetchBaseQueryError.error === OutdatedErrorName) {
      return [{
        type: StandardErrorTypeEnum.Outdated,
        message: 'The query result is outdated.',
        displayMessage: 'An error occurred, please try again later.',
      }];
    }

    if (Array.isArray(fetchBaseQueryError.data)) {
      return fetchBaseQueryError.data.map((error) => ({
        ...error,
        displayMessage: 'An error occurred, please try again later.',
      }));
    }

    return [{
      type: PlatformErrorTypeEnum.Internal,
      message: `An unknown error occurred: ${(fetchBaseQueryError as any)?.error}`,
      displayMessage: 'An error occurred, please try again later.',
    }];
  }

  return (serializedError as PlatformError[]).map((error) => ({
    ...error,
    displayMessage: 'An error occurred, please try again later.',
  })) as StandardError[];
}

export const StandardErrorTypeEnum = {
  Network: 'network',
  Outdated: 'aborted',
  ...PlatformErrorTypeEnum,
} as const;

export type StandardErrorTypeEnum = typeof StandardErrorTypeEnum[keyof typeof StandardErrorTypeEnum];

export type StandardError = Omit<PlatformError, 'type'> & {
  type: StandardErrorTypeEnum;
  displayMessage: string;
};
