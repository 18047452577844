import React, { forwardRef, useImperativeHandle, useMemo } from 'react';
import { NavigationProp, StackActions, useNavigation } from '@react-navigation/native';
import { useCreateChannelMutation } from '../../../adapters/api/codegen';
import { RootStackParamList } from '../../navigation/navigators/root/RootStackProps';
import { ChannelData } from '../../ui/modals/ConfigureChannelModal';
import { ConfigureChannelModalControllerHandles, ConfigureChannelModalController } from './ConfigureChannelModalController';
import { useGetSelfOrganizations } from '../hooks/api/useGetSelfOrganizations';
import { useGetAllAccessibleChannels } from '../hooks/api/useGetAllAccessibleChannels';

export interface CreateChannelModalControllerHandles {
  show: () => void;
}

export const CreateChannelModalController = forwardRef<CreateChannelModalControllerHandles>((_props, ref) => {
  const [createChannel] = useCreateChannelMutation();
  const configureModalRef = React.useRef<ConfigureChannelModalControllerHandles>(null);
  const { selfOrganization: organization, currentData: selfOrganizations } = useGetSelfOrganizations();
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();

  const channels = useGetAllAccessibleChannels(selfOrganizations);
  const channelNames = useMemo(() => {
    return channels.allChannels?.map((channel) => channel.name) || [];
  }, [channels]);

  useImperativeHandle(ref, () => ({
    show: () => {
      configureModalRef.current?.show();
    },
  }));

  const handleSubmit = async (channelData: ChannelData) => {
    if (!organization) {
      return;
    }

    configureModalRef.current?.setIsLoading(true);
    try {
      const channel = await createChannel({ organizationId: organization.id, channelCreateRequestBody: { name: channelData.channelName, is_public: channelData.isPublic } }).unwrap();
      configureModalRef.current?.close();
      navigation.dispatch(StackActions.replace('Channel', { channelId: channel.id }));
    } catch (error) {
      configureModalRef.current?.setIsLoading(false);
    }
  };

  return <ConfigureChannelModalController ref={configureModalRef} onSubmit={handleSubmit} existingChannelNames={channelNames} type="create" />;
});
