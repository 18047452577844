import { MarkEventAsReadApiArg, MarkEventAsReadApiResponse } from '../api/codegen';
import { UnreadCountMutationHandler } from './UnreadCountMutationHandler';
import { MutationHandlerProps } from './MutationHandler';
import { ThreadViewModel } from '../view-models/ThreadViewModel';
import { createThreadUpdatePatches } from './thread-patch-factory/createThreadUpdatePatches';
import { createThreadPatch } from './thread-patch-factory/factories/createThreadPatch';

export class MarkInboxItemAsReadMutationHandler extends UnreadCountMutationHandler<MarkEventAsReadApiArg, MarkEventAsReadApiResponse> {
  constructor(props: MutationHandlerProps<MarkEventAsReadApiArg, MarkEventAsReadApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: MarkEventAsReadApiArg) {
    return [
      ...createThreadUpdatePatches((draftedThreads) => this.updateThreads(draftedThreads, patch)),
      createThreadPatch(this.getCurrentThreadId()!, this.updateThread),
      ...this.createChannelsListPatches(patch, 'read'),
    ];
  }

  private updateThreads(draftedThreads: ThreadViewModel[], patch: MarkEventAsReadApiArg) {
    for (const thread of draftedThreads) {
      if (thread.inboxItemId === patch.inboxItemId) {
        this.updateThread(thread);
      }
    }
  }

  private updateThread(draftedThread: ThreadViewModel) {
    draftedThread.isUnread = draftedThread.numberOfUnreadMessages > 0;
    draftedThread.inboxItemIsUnread = false;
  }

  protected generateInvalidationTags(_arg: MarkEventAsReadApiArg) {
    return [];
  }
}
