import { GetOrganizationChannelsApiArg } from '../api/codegen';
import { CacheTagProvider } from './CacheTagProvider';
import { CacheTagDescription } from './cache-tag-types';
import { ChannelsViewModel } from '../view-models/ChannelsViewModel';

export class GetOrganizationChannelsCacheTagProvider extends CacheTagProvider<GetOrganizationChannelsApiArg, ChannelsViewModel> {
  protected generateTags(_arg: GetOrganizationChannelsApiArg, result: ChannelsViewModel) {
    return this.getChannelThreadTags(result);
  }

  private getChannelThreadTags(result: ChannelsViewModel) {
    const tags = result.channels.map((channel) => ({ type: 'Channel', id: channel.id })) as CacheTagDescription[];
    tags.push({ type: 'OrganizationChannels' });
    return tags;
  }
}
