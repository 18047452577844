import { useEffect, useState } from 'react';

export function useForceDisabledStateRerenderAtInterval(timeIntervalMs: number, enableRerender: boolean) {
  const [_count, setCount] = useState(0);

  useEffect(() => {
    if (enableRerender) {
      const intervalId = setInterval(() => {
        setCount((prevCount) => prevCount + 1);
      }, timeIntervalMs);

      return () => clearInterval(intervalId);
    }
  }, [enableRerender, timeIntervalMs]);
}
