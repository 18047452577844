import { MessageCreateRequestBodyWithId, SendingViewModel } from '../../domain/state/drafts';
import {
  Message, MessageReaction, User
} from '../api/codegen';
import { createPictureViewModelFromParticipant, PictureViewModel } from './PictureViewModel';
import { formatDate } from './utilities';
import { getConversationParticipantDisplayName } from './utilities/getConversationParticipantDisplayName';
import { MessageReactionViewModel, MessageReactionViewModelAdditionalParams, createMessageReactionViewModel } from './MessageReactionViewModel';
import { AttachmentViewModel, createAttachmentViewModel } from './AttachmentViewModel';
import { createExtendedMessageReactionViewModelParams } from './utilities/createExtendedMessageReactionViewModelParams';

export type MessageViewModel = {
  senderDisplayName: string;
  body: string;
  date: string;
  rawDate: number;
  id: string;
  senderInfo: PictureViewModel;
  isUnread: boolean;
  attachments: AttachmentViewModel[];
  rawReactions: MessageReaction[];
};

export type ComprehensiveMessageViewModel = MessageViewModel & {
  reactions: MessageReactionViewModel[];
};

export type MessageViewModelAdditionalParams = MessageReactionViewModelAdditionalParams;

export function createMessageViewModel(message: Message): MessageViewModel {
  return {
    senderDisplayName: getConversationParticipantDisplayName(message.sender),
    senderInfo: createPictureViewModelFromParticipant(message.sender),
    body: message.body,
    date: formatDate(message.creation_date),
    id: message.id,
    isUnread: message.is_unread,
    rawReactions: message.reactions,
    attachments: message.attachments.map(createAttachmentViewModel),
    rawDate: new Date(message.creation_date).getTime(),
  };
}

export function createComprehensiveMessageViewModel(message: MessageViewModel, params: MessageViewModelAdditionalParams): ComprehensiveMessageViewModel {
  const extendedParams = createExtendedMessageReactionViewModelParams(params);
  const curriedCreateMessageReactionViewModel = (messageReaction: MessageReaction) => createMessageReactionViewModel(messageReaction, extendedParams);

  return {
    ...message,
    reactions: message.rawReactions.map(curriedCreateMessageReactionViewModel),
  };
}

export interface ComprehensiveSendingMessageViewModel extends ComprehensiveMessageViewModel {
  isSending: boolean;
  resendMessageCreateRequestBody: MessageCreateRequestBodyWithId;
  resendCount?: SendingViewModel['resendCount'];
}

export function createComprehensiveSendingMessageViewModel(message: SendingViewModel, user: User): ComprehensiveSendingMessageViewModel {
  const messageViewModel = createMessageViewModel({
    id: message.id,
    body: message.messageCreateRequestBody.message_body,
    is_unread: false,
    creation_date: new Date().toISOString(),
    sender: { type: 'user', user },
    reactions: [],
    attachments: [],
    state: 'staged',
  });
  return {
    ...messageViewModel,
    reactions: [],
    isSending: true,
    resendCount: message.resendCount,
    resendMessageCreateRequestBody: message.messageCreateRequestBody,
  };
}
