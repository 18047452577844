import React, { useLayoutEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { OptionsOrDependencyArray } from 'react-hotkeys-hook/dist/types';
import { useIsFocused } from '@react-navigation/native';
import { Box } from 'native-base';
import {
  createThreadListStore, ThreadListProvider, useThreadListStore, useThreadListStoreSelector
} from './context';
import { ComprehensiveThreadOrInboxItemViewModel, isInboxItemViewModel } from '../../../adapters/view-models/InboxItemViewModel';
import { usePreviousValue } from '../../hooks/usePreviousValue';
import { useMenuStore } from '../date-picker-menu/menu/useMenuStore';
import { ThreadListMultiSelectActionBar } from './ThreadListMultiSelectActionBar';
import { AnimatedCheckboxesProvider } from '../thread-list-items/blocks/ThreadListItemCheckbox';
import { useSelectThreadCallback } from '../../controllers/hooks/channelScreenHooks';
import { getCurrentScreenParams, RouteNotInStack } from '../../navigation/getCurrentScreenParams';
import { rootNavigationContainerRef } from '../../navigation/navigators/root/RootNavigationContainerRef';

export function ThreadListContainer({ children, items }: {
  children: React.ReactNode;
  items: ComprehensiveThreadOrInboxItemViewModel[] | undefined;
}) {
  const isScreenFocused = useIsFocused();
  const [threadListStore] = useState(() => createThreadListStore());
  useLayoutEffect(() => {
    // Set the first thread as hovered when the list is loaded
    if (!threadListStore.getState().focusedId && items?.length) {
      const firstThread = items[0];
      const thread = isInboxItemViewModel(firstThread) ? firstThread.thread : firstThread;
      threadListStore.getState().setFocusedId(thread.id);
    }
    // Store reference to items in the store
    threadListStore.getState().setItems(items ?? []);
  }, [threadListStore, items]);

  const selectThreadCallback = useSelectThreadCallback(items);
  const isInThreadView = () => {
    const navState = rootNavigationContainerRef.current?.getState();
    return RouteNotInStack !== getCurrentScreenParams(navState, 'Thread');
  };
  function moveFocusPrevNext(offset: number, { shiftKey = false, open = false } = {}) {
    const anyMenuOpen = useMenuStore.getState().open;
    if (!items || items.length === 0 || anyMenuOpen) return;
    const hoveredThreadState = threadListStore.getState();
    const nextItem = hoveredThreadState.moveFocusPrevNext(offset, { shiftKey });
    if (!nextItem) return;
    hoveredThreadState.setNavigatedFromKeyboard();
    if (open && isInThreadView()) void selectThreadCallback(nextItem);
  }
  const toggleSelected = (shiftKey: boolean = false) => {
    const { focusedId } = threadListStore.getState();
    if (!focusedId) return;
    threadListStore.getState().toggleSelected(focusedId, { shiftKey });
  };
  const selectAll = () => {
    if (!items) return;
    threadListStore.getState().addSelected(items.map((item) => {
      const thread = isInboxItemViewModel(item) ? item.thread : item;
      return thread.id;
    }));
  };

  const hotkeyOptions: OptionsOrDependencyArray = {
    enabled: isScreenFocused,
    preventDefault: true,
    enableOnFormTags: true,
  };
  useHotkeys(['k'], () => moveFocusPrevNext(-1, { open: true }), {
    ...hotkeyOptions,
    enabled: true,
  });
  useHotkeys(['j'], () => moveFocusPrevNext(1, { open: true }), {
    ...hotkeyOptions,
    enabled: true,
  });
  useHotkeys(['ArrowUp'], () => moveFocusPrevNext(-1), hotkeyOptions);
  useHotkeys(['ArrowDown'], () => moveFocusPrevNext(1), hotkeyOptions);
  useHotkeys(['Shift+ArrowUp', 'Shift+k'], () => moveFocusPrevNext(-1, { shiftKey: true }), hotkeyOptions);
  useHotkeys(['Shift+ArrowDown', 'Shift+j'], () => moveFocusPrevNext(1, { shiftKey: true }), hotkeyOptions);
  useHotkeys(['x', 'Space'], () => toggleSelected(), hotkeyOptions);
  useHotkeys(['Shift+x', 'Shift+Space'], () => toggleSelected(true), hotkeyOptions);
  useHotkeys('mod+a', selectAll, hotkeyOptions);

  return (
    <ThreadListProvider value={threadListStore}>
      <AnimatedCheckboxesProvider>
        <Box flexShrink={1} flexGrow={1}>
          {children}
          <SelectNextThreadAfterRemoval items={items} />
          <ThreadListMultiSelectActionBar items={items} />
        </Box>
      </AnimatedCheckboxesProvider>
    </ThreadListProvider>
  );
}

// Select next thread (at same index) after the current one is removed from list (archived or moved to spam etc.)
function SelectNextThreadAfterRemoval(props: { items: ComprehensiveThreadOrInboxItemViewModel[] | undefined }) {
  const index = useThreadListStoreSelector((state) => {
    if (!props.items) return -1;
    return props.items.findIndex((item) => {
      const thread = isInboxItemViewModel(item) ? item.thread : item;
      return thread.id === state.focusedId;
    });
  });
  const previousIndex = usePreviousValue(index);
  const threadListStore = useThreadListStore();
  useLayoutEffect(() => {
    if (props.items?.length) {
      const wasSelected = previousIndex !== -1 && typeof previousIndex === 'number';
      if (index === -1 && wasSelected) {
        const nextThread = props.items[Math.min(previousIndex, props.items.length - 1)];
        const thread = isInboxItemViewModel(nextThread) ? nextThread.thread : nextThread;
        threadListStore.getState().setFocusedId(thread.id);
      }
    }
  }, [threadListStore, previousIndex, index, props.items]);
  return null;
}
