import { useHotkeys } from 'react-hotkeys-hook';
import { useRef } from 'react';

export function useTwoHotkeys(
  hotkey1: Parameters<typeof useHotkeys>[0],
  hotkey2: Parameters<typeof useHotkeys>[0],
  callback: Parameters<typeof useHotkeys>[1],
  options?: Parameters<typeof useHotkeys>[2],
  dependencies?: Parameters<typeof useHotkeys>[3]
) {
  const firstKeyPressed = useRef(false);
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  useHotkeys(hotkey1, () => {
    if (firstKeyPressed.current) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      firstKeyPressed.current = false;
    } else {
      firstKeyPressed.current = true;
      timerRef.current = setTimeout(() => {
        firstKeyPressed.current = false;
      }, 500);
    }
  }, options, dependencies);

  useHotkeys(hotkey2, (...args) => {
    if (firstKeyPressed.current) {
      callback(...args);
      firstKeyPressed.current = false;
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    }
  }, options, dependencies);
}
