import { HeaderTitleProps } from '@react-navigation/elements';
import React, { useCallback, useRef, useState } from 'react';
import { useNavigation, NavigationProp } from '@react-navigation/native';
import { buildChannelJoinedMutationDescription } from '../UndoMutationDescriptions';
import { useCurrentChannel } from '../hooks/routes/useCurrentChannel';
import { ChannelScreenHeaderTitle } from '../../ui/screen-sections/channel/ChannelScreenHeaderTitle';
import { UpdateChannelModalController, UpdateChannelModalControllerHandles } from '../modals/UpdateChannelModalController';
import { DeleteChannelModalControllerHandles, DeleteChannelModalController } from './DeleteChannelModalController';
import { RootStackParamList } from '../../navigation/navigators/root/RootStackProps';
import { useGetSelfChannels } from '../hooks/api/useGetSelfChannels';
import { useGetChannelParticipants } from '../hooks/api/useGetChannelParticipants';
import { useGetSelfUser } from '../hooks/api/useGetSelfUser';
import { LeavePrivateChannelAlertDialog } from '../modals/LeavePrivateChannelAlertDialog';
import { useRemoveChannelParticipantsMutation } from '../../../adapters/api/codegen';
import { useWithMutationMetadata } from '../../../adapters/mutation-cancellation/mutationCancellation';

export function ChannelScreenHeaderTitleController(props: HeaderTitleProps) {
  const { channel, isLoading } = useCurrentChannel();
  const updateChannelModalRef = useRef<UpdateChannelModalControllerHandles>(null);
  const deleteChannelModalRef = useRef<DeleteChannelModalControllerHandles>(null);
  const { currentData: selfChannels } = useGetSelfChannels();
  const { currentData: participants } = useGetChannelParticipants(channel);
  const { currentData: selfUser } = useGetSelfUser();
  const selfUserJoinedChannel = participants?.some((p) => p.id === selfUser?.id) ?? false;
  const navigation = useNavigation<NavigationProp<RootStackParamList, 'Channel'>>();
  const [removeParticipants] = useWithMutationMetadata(useRemoveChannelParticipantsMutation());
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const leaveChannel = useCallback(() => {
    void removeParticipants({ channelId: channel!.id, body: [selfUser!.id] }, {
      initiator: 'user',
      description: buildChannelJoinedMutationDescription(false),
    });
    if (!channel?.isPublic) {
      navigation.reset({
        index: 0,
        routes: [{ name: 'Inbox' }],
      });
    }
  }, [removeParticipants, channel, selfUser, navigation]);

  const leaveCallback = useCallback(() => {
    if (!channel?.isPublic) {
      setIsAlertVisible(true);
    } else {
      leaveChannel();
    }
  }, [channel?.isPublic, leaveChannel]);

  const didDeleteChannelCallback = useCallback(
    () => {
      const inboxChannel = selfChannels?.channels.find((channel) => channel.folderType === 'inbox');
      if (inboxChannel) {
        navigation.navigate('Channel', { channelId: inboxChannel.id });
      } else if (selfChannels?.channels.length) {
        navigation.navigate('Channel', { channelId: selfChannels?.channels[0].id });
      }
    },
    [navigation, selfChannels?.channels]
  );

  const isLoadingForFirstTime = isLoading && !channel;

  return (
    <>
      <ChannelScreenHeaderTitle
        channel={channel}
        showLoader={isLoadingForFirstTime}
        onInitiateUpdateCallback={() => updateChannelModalRef.current?.show()}
        onInitiateDeleteCallback={() => deleteChannelModalRef.current?.show()}
        pressable={!channel?.isFolder}
        tintColor={props.tintColor}
        onLeave={selfUserJoinedChannel ? leaveCallback : undefined}
      />
      <UpdateChannelModalController ref={updateChannelModalRef} channel={channel!} />
      <DeleteChannelModalController ref={deleteChannelModalRef} channel={channel!} didDeleteChannelCallback={didDeleteChannelCallback} />
      <LeavePrivateChannelAlertDialog submitCallback={leaveChannel} visible={isAlertVisible} cancelCallback={() => setIsAlertVisible(false)} />
    </>
  );
}
