import { CommonActions } from '@react-navigation/native';
import { useCallback } from 'react';
import { rootNavigationContainerRef } from '../RootNavigationContainerRef';

export function useInsertInboxInNavigationStackCallbackIfNeeded() {
  return useCallback(() => {
    if (isCurrentRouteThreadWithNoParent()) {
      insertInboxScreenInNavigationStack();
    }
  }, []);
}

function insertInboxScreenInNavigationStack() {
  rootNavigationContainerRef.current?.dispatch((state) => {
    const routes = [
      {
        name: 'Inbox'
      },
      ...state.routes,
    ];

    return CommonActions.reset({
      ...state,
      routes,
      index: routes.length - 1,
    });
  });
}

function isCurrentRouteThreadWithNoParent() {
  if (!rootNavigationContainerRef.current) {
    return false;
  }

  const navigationState = rootNavigationContainerRef.current.getState();

  if (!navigationState || navigationState.routes.length === 0) {
    return false;
  }

  const appRoute = navigationState.routes[0];

  if (!appRoute.state || appRoute.name !== 'App') {
    return false;
  }

  const appSubRoutes = appRoute.state.routes;

  if (appSubRoutes.length !== 1 || appSubRoutes[0].name !== 'Thread') {
    return false;
  }

  return true;
}
