import {
  Dispatch,
  MutableRefObject,
  SetStateAction, useEffect
} from 'react';
import { TextInput } from 'react-native';
import { isPrintableCharacter } from './isPrintableCharacter';

export function useFocusOnKeyPress(promptInputFocused: boolean, promptInputRef: MutableRefObject<TextInput | null>, setDatePrompt: Dispatch<SetStateAction<string>>) {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const isPrintableKey = isPrintableCharacter(event.key);

      if (!promptInputFocused && isPrintableKey) {
        promptInputRef.current?.focus();
        setDatePrompt((prev) => prev + event.key);
        event.preventDefault();
        event.stopPropagation();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [promptInputFocused, promptInputRef, setDatePrompt]);
}
