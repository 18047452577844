import React from 'react';
import { Paginator, PaginatorProps } from '../../ui/Paginator';

type ThreadsListHeaderControllerProps = PaginatorProps;

export function SelfThreadsListHeaderController(props: ThreadsListHeaderControllerProps) {
  return (
    <Paginator {...props} />
  );
}
