import { create } from 'zustand';
import { Draft, produce } from 'immer';
import { ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ProvidedCommandIds } from './types';

type RemoveProvidedCommands = (id: string) => void;

interface CommandState {
  open: boolean;
  setOpen: (open: boolean) => void;
  providedCommands: Record<string, {
    uid: string;
    commands: ReactNode;
  }[]>;
  provideCommands: (id: string, commands: ReactNode) => RemoveProvidedCommands;
}

export const useCommandStore = create<CommandState>((set) => {
  const update = (recipe: (draft: Draft<CommandState>) => void | CommandState) => set(produce(recipe));
  return {
    open: false,
    setOpen: (open) => set({ open }),
    providedCommands: {},
    provideCommands: (id, commands) => {
      const uid = uuidv4();
      const commandsRef = { uid, commands };
      update((state) => {
        state.providedCommands[id] ??= [];
        state.providedCommands[id].push(commandsRef);
      });
      return () => update((state) => {
        state.providedCommands[id] = state.providedCommands[id].filter((ref) => ref.uid !== uid);
      });
    },
  };
});

export const closeCommandMenu = () => useCommandStore.getState().setOpen(false);

export const selectProvidedCommand = (state: CommandState, id: ProvidedCommandIds): ReactNode | undefined => {
  const commands = state.providedCommands[id];
  return commands?.[commands.length - 1]?.commands;
};
