export const API_TAGS = [
  'Channel',
  'Event',
  'InboxEventsList',
  'Message',
  'OrganizationChannels',
  'SelfAccount',
  'SelfAllThreadsList',
  'SelfChannels',
  'SelfSentThreadsList',
  'SelfSnoozedThreadsList',
  'SelfSpamThreadsList',
  'SelfTrashedThreadsList',
  'SelfStarredThreadsList',
  'Thread',
] as const;
type CacheTagName = typeof API_TAGS[number];
export type CacheTagDescription = { type: CacheTagName; id?: string; };
