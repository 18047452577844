import { PatchWrapper } from '../MutationHandler';
import { ThreadViewModel } from '../../view-models/ThreadViewModel';
import { MessageViewModel } from '../../view-models/MessageViewModel';
import { createThreadUpdatePatches } from './createThreadUpdatePatches';
import { createThreadPatch } from './factories/createThreadPatch';
import { createMessagesListPatch } from './factories/createMessagesListPatch';

export function createThreadUpdatePatchesForThreadId(threadId: string, threadUpdateHandler: (draftedThread: ThreadViewModel) => void, updateMessagesHandler?: (draftedMessages: MessageViewModel[]) => void): PatchWrapper<any>[] {
  const threadsUpdateHandler = (draftedThreads: ThreadViewModel[]) => {
    for (const thread of draftedThreads) {
      if (thread.id === threadId) {
        threadUpdateHandler(thread);
      }
    }
  };

  const patches = createThreadUpdatePatches(threadsUpdateHandler)
    .concat(createThreadPatch(threadId, threadUpdateHandler));

  if (updateMessagesHandler) {
    patches.push(createMessagesListPatch(threadId, updateMessagesHandler));
  }

  return patches;
}
