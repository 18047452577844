import { useMemo } from 'react';
import { THREADS_QUERY_OPTIONS } from '../../../api/query-parameters';
import { UserViewModel } from '../../../../adapters/view-models/UserViewModel';
import { ThreadViewModelAdditionalParams } from '../../../../adapters/view-models/ThreadViewModel';
import { ChannelViewModel } from '../../../../adapters/view-models/ChannelViewModel';
import { useGetSelfStarredThreadsQuery } from '../../../../adapters/api';
import { createComprehensiveThreadsViewModel } from '../../../../adapters/view-models/ThreadsViewModel';

export const useGetSelfStarredThreads = (selfUserId?: string, users?: UserViewModel[], channels?: ChannelViewModel[], pageToken?: string) => {
  const params: ThreadViewModelAdditionalParams = useMemo(() => ({
    selfUserId, users, channels, channelIdsToRemove: undefined,
  }), [selfUserId, users, channels]);

  const rawThreads = useGetSelfStarredThreadsQuery({ pageToken }, { ...THREADS_QUERY_OPTIONS });

  return {
    ...rawThreads,
    currentData: rawThreads.currentData ? createComprehensiveThreadsViewModel(rawThreads.currentData, params) : undefined
  };
};
