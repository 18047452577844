import { ComponentProps, JSX, JSXElementConstructor } from 'react';
import { Tooltip } from 'native-base';
import { getTooltipLabelWithShortcut } from './getTooltipLabelWithShortcut';
import { getDisplayName } from '../../global/getDisplayName';

interface WithTooltipProps {
  tooltip?: [string, string?];
}

export function withTooltip<
  C extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>,
>(WrappedComponent: C) {
  type Props = ComponentProps<C>;
  function WithTooltip({ tooltip, ...props }: Props & WithTooltipProps) {
    const [hint, shortcut] = tooltip || [];
    const element = <WrappedComponent {...props as Props} />;
    if (hint) {
      return (
        <Tooltip label={getTooltipLabelWithShortcut(hint, shortcut)} openDelay={100} placement="top">
          {element}
        </Tooltip>
      );
    }
    return element;
  }
  WithTooltip.displayName = `WithTooltip(${getDisplayName(WrappedComponent)})`;
  return WithTooltip;
}
