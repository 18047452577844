import React, { useRef, useCallback } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  buildThreadParticipantMutationDescription
} from '../UndoMutationDescriptions';
import {
  MutationMetadata,
  useWithMutationMetadata
} from '../../../adapters/mutation-cancellation/mutationCancellation';
import { useAddThreadParticipantsMutation, useRemoveThreadParticipantsMutation } from '../../../adapters/api/codegen';
import { useCurrentThread } from '../hooks/routes/useCurrentThread';

import { AddParticipantsModalControllerHandles } from '../modals/AddParticipantsModalController';
import { ThreadScreenParticipantsHeader } from '../../ui/screen-sections/thread/ThreadScreenParticipantsHeader';
import { useToggleSelfUserJoinThreadCallback } from '../hooks/useToggleSelfUserJoinThreadCallback';
import { ParticipantViewModel } from '../../../adapters/view-models/ParticipantViewModel';
import { ThreadViewModel } from '../../../adapters/view-models/ThreadViewModel';

export function ThreadScreenParticipantsHeaderController() {
  const { thread, isLoading: isCurrentlyLoadingThread } = useCurrentThread();

  const manageParticipantsModalControllerRef = useRef<AddParticipantsModalControllerHandles>(null);

  const submitNewParticipants = useUpdateThreadParticipantCallback(thread, 'add');
  const removeParticipants = useUpdateThreadParticipantCallback(thread, 'remove');

  const toggleSelfUserJoinThreadCallback = useToggleSelfUserJoinThreadCallback();
  const joinCallback = useCallback(() => {
    if (thread) {
      toggleSelfUserJoinThreadCallback(thread);
    }
  }, [thread, toggleSelfUserJoinThreadCallback]);

  useHotkeys('m', joinCallback, {}, [joinCallback]);

  const openParticipantsModal = useCallback(() => {
    manageParticipantsModalControllerRef.current?.show();
  }, []);

  const isLoadingThread = isCurrentlyLoadingThread && !thread;

  if (isLoadingThread) {
    return null;
    // return (
    //   <Box flexDir="row" justifyContent="flex-end" mr={3} mb={2} mt={5}>
    //     {/* <Skeleton.Text lines={1} h={3} width="230px" /> */}
    //   </Box>
    // );
  }

  if (!thread) {
    return null;
  }

  return (
    <ThreadScreenParticipantsHeader
      participants={thread.memberPictures}
      selfUserJoinedThread={thread.selfUserJoined}
      joinCallback={joinCallback}
      participantsListPressedCallback={openParticipantsModal}
      showSpinner={isLoadingThread}
      manageParticipantsModalControllerRef={manageParticipantsModalControllerRef}
      didSubmitNewParticipantsCallback={submitNewParticipants}
      didDeleteParticipantsCallback={removeParticipants}
      isDisabled={thread.isTrashed}
    />
  );
}

function useUpdateThreadParticipantCallback(thread: ThreadViewModel | undefined, type: 'add' | 'remove') {
  const [addParticipants] = useWithMutationMetadata(useAddThreadParticipantsMutation());
  const [removeParticipants] = useWithMutationMetadata(useRemoveThreadParticipantsMutation());

  const submitNewParticipants = useCallback(async (newParticipants: ParticipantViewModel[]) => {
    const userIds = newParticipants.map((p) => p.id);

    const mutationMetadata: MutationMetadata = {
      initiator: 'user',
      description: buildThreadParticipantMutationDescription(type === 'add', newParticipants),
    };

    if (type === 'add') {
      void addParticipants({
        threadId: thread!.id,
        body: userIds
      }, mutationMetadata);
    } else {
      void removeParticipants({
        threadId: thread!.id,
        body: userIds
      }, mutationMetadata);
    }
  }, [addParticipants, removeParticipants, thread, type]);

  return submitNewParticipants;
}
