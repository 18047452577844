import { useNavigation } from '@react-navigation/native';
import { useHotkeys } from 'react-hotkeys-hook';
import { Toast } from 'native-base';
import { ComprehensiveThreadViewModel } from '@/adapters/view-models/ThreadViewModel';

export function useArchiveThreadHotkey(thread: ComprehensiveThreadViewModel | undefined, archiveThreadAndGoBackCallback: (thread: ComprehensiveThreadViewModel) => void) {
  const navigation = useNavigation();

  useHotkeys(
    'e',
    () => {
      const canArchive = !thread!.showMoveToInboxButton;
      const canGoBack = navigation.canGoBack();
      if (canArchive) {
        archiveThreadAndGoBackCallback(thread!);
      }
      if (!canArchive || !canGoBack) {
        Toast.show({
          title: 'Conversation archived',
        });
      }
    },
    [thread?.id, !thread?.showMoveToInboxButton, archiveThreadAndGoBackCallback],
    {
      enabled: Boolean(thread),
      enableOnFormTags: false,
      enableOnContentEditable: false
    }
  );
}
