import React from 'react';
import {
  NavigationProp, RouteProp, useNavigation, useRoute
} from '@react-navigation/native';
import { SelfThreadsListHeaderController } from './SelfThreadsListHeaderController';
import { RootStackParamList } from '../../navigation/navigators/root/RootStackProps';
import { useGetAllAccessibleChannels } from '../hooks/api/useGetAllAccessibleChannels';
import { useGetSelfOrganizationMembers } from '../hooks/api/useGetSelfOrganizationMembers';
import { useGetSelfOrganizations } from '../hooks/api/useGetSelfOrganizations';
import { useGetSelfUser } from '../hooks/api/useGetSelfUser';
import { apiClient } from '../../../adapters/api';
import { useGetSelfAllThreads } from '../hooks/api/useGetSelfAllThreads';

export function AllMessagesHeaderController() {
  const route = useRoute<RouteProp<RootStackParamList, 'AllMessages'>>();
  const { page } = route.params ?? {};
  const { currentData: selfUser } = useGetSelfUser();
  const { currentData: selfOrganizations, organizationId } = useGetSelfOrganizations();
  const { currentData: organizationMembers } = useGetSelfOrganizationMembers(organizationId);
  const { allRawChannels } = useGetAllAccessibleChannels(selfOrganizations);
  const { currentData: threads } = useGetSelfAllThreads(selfUser?.id, organizationMembers?.users, allRawChannels, page);

  const navigation = useNavigation<NavigationProp<RootStackParamList, 'AllMessages'>>();
  const prefetchPage = apiClient.usePrefetch('getSelfAllThreads');

  if (!threads) {
    return null;
  }

  return (
    <SelfThreadsListHeaderController
      meta={threads.meta}
      onPageChange={(page) => navigation.setParams({ page })}
      onPrefetchPage={(pageToken) => prefetchPage({ pageToken })}
    />
  );
}
