// @ts-nocheck

// This file is generated by the api/generator/schema/create-schema.mjs script. Do not update manually.

import { OpenAPIV3 } from 'openapi-types';

export function getApiSchema(): OpenAPIV3.Document {
  return {
    paths: {
      '/channels/{channel_id}/threads': {
        get: {
          operationId: 'GetChannelThreads',
          parameters: [
            {
              name: 'limit',
              required: false,
              in: 'query',
              description: 'Maximum number of items to return',
              example: 20,
              schema: {
                minimum: 1,
                maximum: 30,
                type: 'number'
              }
            },
            {
              name: 'page_token',
              required: false,
              in: 'query',
              description: 'Page token for pagination (returned from previous request in `meta.next_page_token` or `meta.previous_page_token`)',
              schema: {
                type: 'string'
              }
            },
            {
              name: 'channel_id',
              required: true,
              in: 'path',
              description: 'The ID of the channel',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/channels/{channel_id}': {
        get: {
          operationId: 'GetChannel',
          parameters: [
            {
              name: 'channel_id',
              required: true,
              in: 'path',
              description: 'The ID of the channel',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        delete: {
          operationId: 'DeleteChannel',
          parameters: [
            {
              name: 'channel_id',
              required: true,
              in: 'path',
              description: 'The ID of the channel',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        put: {
          operationId: 'UpdateChannel',
          parameters: [
            {
              name: 'channel_id',
              required: true,
              in: 'path',
              description: 'The ID of the channel',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/channels/{channel_id}/participants': {
        get: {
          operationId: 'GetChannelParticipants',
          parameters: [
            {
              name: 'channel_id',
              required: true,
              in: 'path',
              description: 'The ID of the channel',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/channels/{channel_id}/participants:add': {
        patch: {
          operationId: 'AddChannelParticipants',
          parameters: [
            {
              name: 'channel_id',
              required: true,
              in: 'path',
              description: 'The ID of the channel',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/channels/{channel_id}/participants:remove': {
        patch: {
          operationId: 'RemoveChannelParticipants',
          parameters: [
            {
              name: 'channel_id',
              required: true,
              in: 'path',
              description: 'The ID of the channel',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/channels/{channel_id}/notification_level': {
        patch: {
          operationId: 'UpdateChannelNotificationLevel',
          parameters: [
            {
              name: 'channel_id',
              required: true,
              in: 'path',
              description: 'The ID of the channel',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/gmail-watch-notifications': {
        post: {
          operationId: 'ProcessGmailWatchNotification',
          parameters: []
        },
        parameters: []
      },
      '/events/{inbox_item_id}/mark_as_read': {
        patch: {
          operationId: 'MarkEventAsRead',
          parameters: [
            {
              name: 'inbox_item_id',
              required: true,
              in: 'path',
              description: 'The ID of the inbox item',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/events/{inbox_item_id}/remove_from_inbox': {
        patch: {
          operationId: 'RemoveEventFromInbox',
          parameters: [
            {
              name: 'inbox_item_id',
              required: true,
              in: 'path',
              description: 'The ID of the inbox item',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/messages/{message_id}/add_reaction': {
        patch: {
          operationId: 'AddMessageReaction',
          parameters: [
            {
              name: 'message_id',
              required: true,
              in: 'path',
              description: 'The ID of the message',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/messages/{message_id}/remove_reaction': {
        patch: {
          operationId: 'RemoveMessageReaction',
          parameters: [
            {
              name: 'message_id',
              required: true,
              in: 'path',
              description: 'The ID of the message',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/messages:mark_as_read': {
        patch: {
          operationId: 'MarkMessagesAsRead',
          parameters: []
        },
        parameters: []
      },
      '/messages:mark_as_unread': {
        patch: {
          operationId: 'MarkMessagesAsUnread',
          parameters: []
        },
        parameters: []
      },
      '/messages/{message_id}': {
        delete: {
          operationId: 'DeleteMessage',
          parameters: [
            {
              name: 'message_id',
              required: true,
              in: 'path',
              description: 'The ID of the message',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/messages/{message_id}/send_now': {
        patch: {
          operationId: 'SendMessageNow',
          parameters: [
            {
              name: 'message_id',
              required: true,
              in: 'path',
              description: 'The ID of the message',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/organizations/{organization_id}/channels': {
        get: {
          operationId: 'GetOrganizationChannels',
          parameters: [
            {
              name: 'organization_id',
              required: true,
              in: 'path',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        post: {
          operationId: 'CreateChannel',
          parameters: [
            {
              name: 'organization_id',
              required: true,
              in: 'path',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/organizations/{organization_id}/members': {
        get: {
          operationId: 'GetOrganizationMembers',
          parameters: [
            {
              name: 'organization_id',
              required: true,
              in: 'path',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/organizations': {
        post: {
          operationId: 'CreateOrganization',
          parameters: []
        },
        parameters: []
      },
      '/self': {
        get: {
          operationId: 'GetSelfUser',
          parameters: []
        },
        parameters: []
      },
      '/self/account': {
        get: {
          operationId: 'GetSelfAccount',
          parameters: []
        },
        parameters: []
      },
      '/self/channels': {
        get: {
          operationId: 'GetSelfChannels',
          parameters: []
        },
        parameters: []
      },
      '/self/organizations': {
        get: {
          operationId: 'GetSelfOrganizations',
          parameters: []
        },
        parameters: []
      },
      '/self/inbox_events': {
        get: {
          operationId: 'GetSelfInboxEvents',
          parameters: [
            {
              name: 'limit',
              required: false,
              in: 'query',
              description: 'Maximum number of items to return',
              example: 20,
              schema: {
                minimum: 1,
                maximum: 30,
                type: 'number'
              }
            },
            {
              name: 'page_token',
              required: false,
              in: 'query',
              description: 'Page token for pagination (returned from previous request in `meta.next_page_token` or `meta.previous_page_token`)',
              schema: {
                type: 'string'
              }
            },
            {
              name: 'category',
              required: false,
              in: 'query',
              description: 'The category of the inbox items to retrieve.',
              example: 'CATEGORY_PRIMARY',
              schema: {
                enum: [
                  'CATEGORY_PRIMARY',
                  'CATEGORY_PERSONAL',
                  'CATEGORY_PROMOTIONS',
                  'CATEGORY_SOCIAL',
                  'CATEGORY_UPDATES',
                  'CATEGORY_FORUMS'
                ],
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/self/sent_threads': {
        get: {
          operationId: 'GetSelfSentThreads',
          parameters: [
            {
              name: 'limit',
              required: false,
              in: 'query',
              description: 'Maximum number of items to return',
              example: 20,
              schema: {
                minimum: 1,
                maximum: 30,
                type: 'number'
              }
            },
            {
              name: 'page_token',
              required: false,
              in: 'query',
              description: 'Page token for pagination (returned from previous request in `meta.next_page_token` or `meta.previous_page_token`)',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/self/trashed_threads': {
        get: {
          operationId: 'GetSelfTrashedThreads',
          parameters: [
            {
              name: 'limit',
              required: false,
              in: 'query',
              description: 'Maximum number of items to return',
              example: 20,
              schema: {
                minimum: 1,
                maximum: 30,
                type: 'number'
              }
            },
            {
              name: 'page_token',
              required: false,
              in: 'query',
              description: 'Page token for pagination (returned from previous request in `meta.next_page_token` or `meta.previous_page_token`)',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/self/snoozed_threads': {
        get: {
          operationId: 'GetSelfSnoozedThreads',
          parameters: [
            {
              name: 'limit',
              required: false,
              in: 'query',
              description: 'Maximum number of items to return',
              example: 20,
              schema: {
                minimum: 1,
                maximum: 30,
                type: 'number'
              }
            },
            {
              name: 'page_token',
              required: false,
              in: 'query',
              description: 'Page token for pagination (returned from previous request in `meta.next_page_token` or `meta.previous_page_token`)',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/self/spam_threads': {
        get: {
          operationId: 'GetSelfSpamThreads',
          parameters: [
            {
              name: 'limit',
              required: false,
              in: 'query',
              description: 'Maximum number of items to return',
              example: 20,
              schema: {
                minimum: 1,
                maximum: 30,
                type: 'number'
              }
            },
            {
              name: 'page_token',
              required: false,
              in: 'query',
              description: 'Page token for pagination (returned from previous request in `meta.next_page_token` or `meta.previous_page_token`)',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/self/starred_threads': {
        get: {
          operationId: 'GetSelfStarredThreads',
          parameters: [
            {
              name: 'limit',
              required: false,
              in: 'query',
              description: 'Maximum number of items to return',
              example: 20,
              schema: {
                minimum: 1,
                maximum: 30,
                type: 'number'
              }
            },
            {
              name: 'page_token',
              required: false,
              in: 'query',
              description: 'Page token for pagination (returned from previous request in `meta.next_page_token` or `meta.previous_page_token`)',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/self/filtered_threads': {
        get: {
          operationId: 'GetSelfFilteredThreads',
          parameters: [
            {
              name: 'thread_ids',
              required: false,
              in: 'query',
              description: 'IDs of the threads to filter by.',
              example: [
                '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
                '98fd1840-f787-462f-8268-0dd4ca3cb3c6'
              ],
              schema: {
                type: 'array',
                items: {
                  type: 'string'
                }
              }
            },
            {
              name: 'query',
              required: false,
              in: 'query',
              description: 'Search query',
              example: 'All hands meeting notes',
              schema: {
                minLength: 1,
                type: 'string'
              }
            },
            {
              name: 'limit',
              required: false,
              in: 'query',
              description: 'Maximum number of items to return',
              example: 20,
              schema: {
                minimum: 1,
                maximum: 30,
                type: 'number'
              }
            },
            {
              name: 'page_token',
              required: false,
              in: 'query',
              description: 'Page token for pagination (returned from previous request in `meta.next_page_token` or `meta.previous_page_token`)',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/self/all_threads': {
        get: {
          operationId: 'GetSelfAllThreads',
          parameters: [
            {
              name: 'limit',
              required: false,
              in: 'query',
              description: 'Maximum number of items to return',
              example: 20,
              schema: {
                minimum: 1,
                maximum: 30,
                type: 'number'
              }
            },
            {
              name: 'page_token',
              required: false,
              in: 'query',
              description: 'Page token for pagination (returned from previous request in `meta.next_page_token` or `meta.previous_page_token`)',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/self/contacts': {
        get: {
          operationId: 'GetSelfContacts',
          parameters: []
        },
        parameters: []
      },
      '/threads/{thread_id}': {
        get: {
          operationId: 'GetThread',
          parameters: [
            {
              name: 'thread_id',
              required: true,
              in: 'path',
              description: 'The ID of the thread',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        put: {
          operationId: 'UpdateThread',
          parameters: [
            {
              name: 'thread_id',
              required: true,
              in: 'path',
              description: 'The ID of the thread',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/threads/{thread_id}/messages': {
        get: {
          operationId: 'GetThreadMessages',
          parameters: [
            {
              name: 'thread_id',
              required: true,
              in: 'path',
              description: 'The ID of the thread',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        post: {
          operationId: 'PostMessage',
          parameters: [
            {
              name: 'thread_id',
              required: true,
              in: 'path',
              description: 'The ID of the thread',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/threads/{thread_id}/add_participants': {
        patch: {
          operationId: 'AddThreadParticipants',
          parameters: [
            {
              name: 'thread_id',
              required: true,
              in: 'path',
              description: 'The ID of the thread',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/threads/{thread_id}/remove_participants': {
        patch: {
          operationId: 'RemoveThreadParticipants',
          parameters: [
            {
              name: 'thread_id',
              required: true,
              in: 'path',
              description: 'The ID of the thread',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/threads/{thread_id}/add_to_inbox': {
        patch: {
          operationId: 'AddThreadToInbox',
          parameters: [
            {
              name: 'thread_id',
              required: true,
              in: 'path',
              description: 'The ID of the thread',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/threads:delete': {
        patch: {
          operationId: 'DeleteThreads',
          parameters: []
        },
        parameters: []
      },
      '/threads:star': {
        patch: {
          operationId: 'StarThreads',
          parameters: []
        },
        parameters: []
      },
      '/threads:unstar': {
        patch: {
          operationId: 'UnstarThreads',
          parameters: []
        },
        parameters: []
      },
      '/threads/{thread_id}/snooze': {
        patch: {
          operationId: 'SnoozeThread',
          parameters: [
            {
              name: 'thread_id',
              required: true,
              in: 'path',
              description: 'The ID of the thread',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/threads/{thread_id}/mark_all_messages_as_read': {
        patch: {
          operationId: 'MarkAllThreadMessagesAsRead',
          parameters: [
            {
              name: 'thread_id',
              required: true,
              in: 'path',
              description: 'The ID of the thread',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/threads/{thread_id}/mark_as_spam': {
        patch: {
          operationId: 'MarkThreadAsSpam',
          parameters: [
            {
              name: 'thread_id',
              required: true,
              in: 'path',
              description: 'The ID of the thread',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/threads/{thread_id}/mark_as_not_spam': {
        patch: {
          operationId: 'MarkThreadAsNotSpam',
          parameters: [
            {
              name: 'thread_id',
              required: true,
              in: 'path',
              description: 'The ID of the thread',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/threads/{thread_id}/trash': {
        patch: {
          operationId: 'TrashThread',
          parameters: [
            {
              name: 'thread_id',
              required: true,
              in: 'path',
              description: 'The ID of the thread',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/threads/{thread_id}/untrash': {
        patch: {
          operationId: 'UntrashThread',
          parameters: [
            {
              name: 'thread_id',
              required: true,
              in: 'path',
              description: 'The ID of the thread',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/threads/{thread_id}/add_channels': {
        patch: {
          operationId: 'AddThreadChannels',
          parameters: [
            {
              name: 'thread_id',
              required: true,
              in: 'path',
              description: 'The ID of the thread',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/threads/{thread_id}/remove_channels': {
        patch: {
          operationId: 'RemoveThreadChannels',
          parameters: [
            {
              name: 'thread_id',
              required: true,
              in: 'path',
              description: 'The ID of the thread',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/threads': {
        post: {
          operationId: 'CreateThread',
          parameters: []
        },
        parameters: []
      },
      '/threads/{thread_id}/send_now': {
        patch: {
          operationId: 'SendThreadNow',
          parameters: [
            {
              name: 'thread_id',
              required: true,
              in: 'path',
              description: 'The ID of the thread',
              example: '98fd1840-f787-462f-8268-0dd4ca3cb3c6',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/users': {
        post: {
          operationId: 'CreateUser',
          parameters: []
        },
        parameters: []
      },
      '/users/{user_id}/provider_connections': {
        post: {
          operationId: 'CreateProviderConnection',
          parameters: [
            {
              name: 'user_id',
              required: true,
              in: 'path',
              schema: {
                type: 'string'
              }
            }
          ]
        },
        parameters: []
      },
      '/command:cancel': {
        post: {
          operationId: 'CancelCommand',
          parameters: []
        },
        parameters: []
      }
    }
  };
}
