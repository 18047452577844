import {
  createContext, useContext, useEffect, useRef,
} from 'react';
import Mark from 'mark.js';

const markJsContext = createContext<{ query: string } | undefined>(undefined);

export const MarkJsProvider = markJsContext.Provider;

export const useMarkJs = () => {
  const query = useContext(markJsContext)?.query;
  const ref = useRef<HTMLElement | null>(null);
  useEffect(() => {
    const { current } = ref;
    if (current && query) {
      const mark = new Mark(current);
      mark.mark(query);
      return () => mark.unmark();
    }
  }, [query]);
  return ref;
};
