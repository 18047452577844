import React, {
  forwardRef, useImperativeHandle, useRef, useState
} from 'react';
import {
  AlertDialog, Button, Text, VStack
} from 'native-base';
import { useDeleteChannelMutation } from '../../../adapters/api/codegen';
import { ChannelViewModel } from '../../../adapters/view-models/ChannelViewModel';

export interface DeleteChannelModalControllerHandles {
  show: () => void;
}

export type DeleteChannelModalControllerProps = {
  channel: ChannelViewModel;
  didDeleteChannelCallback: () => void;
};

export const DeleteChannelModalController = forwardRef<DeleteChannelModalControllerHandles, DeleteChannelModalControllerProps>((props, ref) => {
  const [deleteChannel, { isLoading }] = useDeleteChannelMutation();
  const [isVisible, setIsVisible] = useState(false);
  const cancelRef = useRef(null);

  useImperativeHandle(ref, () => ({
    show: () => {
      setIsVisible(true);
    },
  }));

  const handleClose = () => {
    setIsVisible(false);
  };

  const handleSubmit = async () => {
    const result = await deleteChannel({ channelId: props.channel.id });

    if (!('error' in result) || !result.error) {
      props.didDeleteChannelCallback();
      handleClose();
    }
  };

  return (
    <AlertDialog leastDestructiveRef={cancelRef} isOpen={isVisible} onClose={handleClose} closeOnOverlayClick={!isLoading}>
      <AlertDialog.Content>
        <AlertDialog.CloseButton disabled={isLoading} />
        <AlertDialog.Header>Delete channel</AlertDialog.Header>
        <AlertDialog.Body>
          <VStack space={2}>
            <Text>Are you sure you want to delete this channel? This action cannot be undone. </Text>
            <Text>Threads won&apos;t be deleted, you will still be able to access those you participated in.</Text>
          </VStack>
        </AlertDialog.Body>
        <AlertDialog.Footer>
          <Button.Group space={2}>
            <Button variant="ghost" onPress={handleClose} ref={cancelRef} disabled={isLoading}>
              Cancel
            </Button>
            <Button colorScheme="danger" onPress={handleSubmit} isLoading={isLoading}>
              Delete
            </Button>
          </Button.Group>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
});
