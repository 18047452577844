import { useCallback } from 'react';
import { buildStarMutationDescription } from '../../UndoMutationDescriptions';
import { useWithMutationMetadata } from '@/adapters/mutation-cancellation/mutationCancellation';
import { ThreadViewModel } from '@/adapters/view-models/ThreadViewModel';
import { useStarThreadsMutation } from '@/adapters/api/codegen';

export function useStarThreadCallback() {
  const [starThread] = useWithMutationMetadata(useStarThreadsMutation());
  const starThreadCallback = useCallback((thread: ThreadViewModel) => {
    void starThread({ threadIdsRequestBody: { thread_ids: [thread.id] } }, {
      initiator: 'user',
      description: buildStarMutationDescription(true),
    });
  }, [starThread]);
  return starThreadCallback;
}
