import React, { useCallback } from 'react';
import {
  HStack, Text, Icon, Pressable, Skeleton
} from 'native-base';
import { CHANNEL_ICON_SETS, ChannelViewModel } from '../../adapters/view-models/ChannelViewModel';
import { ChannelSelectedCallback } from './screen-sections/menu/MenuScreenSection';
import { useDelayedHoverProps } from '../hooks/useDelayedHoverProps';
import { CHANNEL_LINK_LEFT_PADDING } from './screen-sections/menu/Constants';

type ChannelListItemProps = ({
  showLoader: true;
  item: null;
} | {
  showLoader: false;
  selectedChannelId?: string;
  item: ChannelViewModel;
  channelSelectedCallback: ChannelSelectedCallback;
  channelHoveredCallback: ((channel: ChannelViewModel) => void);
});

export function ChannelListItem(props: ChannelListItemProps) {
  const isSelected = !props.showLoader && props.selectedChannelId === props.item.id;
  const channelNameTextColor = isSelected ? 'primary.900' : 'opacityBlack.700';
  const applyBadgeHighlightedStyle = !props.item?.unreadCountLightStyle && !isSelected;
  const unreadBadgeBackgroundColor = applyBadgeHighlightedStyle ? 'primary.400' : 'transparent';
  const unreadBadgeTextColor = applyBadgeHighlightedStyle ? 'dark.900' : channelNameTextColor;

  // The currently selected channel gets a different icon, usually the non-outlined variant.
  const channelIconSet = CHANNEL_ICON_SETS[props.item?.iconSetName ?? 'publicChannel'];
  const iconCollection = isSelected ? channelIconSet.selected.collection : channelIconSet.default.collection;
  const iconName = isSelected ? channelIconSet.selected.name : channelIconSet.default.name;

  const channelHoveredCallbackProps = props.showLoader ? undefined : props.channelHoveredCallback;
  const channelSelectedCallbackProps = props.showLoader ? undefined : props.channelSelectedCallback;
  const item = props.showLoader ? undefined : props.item;
  const isUnread = Boolean(item?.unreadCount);

  const channelHoveredCallback = useCallback(() => {
    if (item) channelHoveredCallbackProps?.(item);
  }, [item, channelHoveredCallbackProps]);

  const { onHoverIn, onHoverOut } = useDelayedHoverProps(channelHoveredCallback);

  const channelSelectedCallback = useCallback(() => {
    if (item) channelSelectedCallbackProps?.(item);
  }, [channelSelectedCallbackProps, item]);

  return (
    <Pressable
      onPress={channelSelectedCallback}
      variant={isSelected ? 'menu-selected' : 'menu'}
      borderRadius={12}
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}
      w="100%"
      alignSelf="center"
      disabled={props.showLoader}
    >
      <HStack space={2} alignItems="center" h={8} px={2} pl={CHANNEL_LINK_LEFT_PADDING}>
        <Icon as={iconCollection} name={iconName ?? 'tag'} color={channelNameTextColor} size="sm" opacity={0.8} />
        <Skeleton isLoaded={!props.showLoader} rounded="full" width="80%" h="2" startColor="dark.200">
          <Text bold={isUnread} isTruncated flexGrow={1} color={channelNameTextColor} selectable={false}>
            {item?.name}
          </Text>
          <Text fontFamily="badge" fontSize="12px" color={unreadBadgeTextColor} bold rounded="full" bg={unreadBadgeBackgroundColor} p="1px" px={2} display={isUnread ? 'flex' : 'none'} minWidth="6" height={6} my={-3} textAlign="center" flexDir="row" justifyContent="center" alignItems="center" selectable={false}>
            {item?.unreadCount}
          </Text>
        </Skeleton>
      </HStack>
    </Pressable>
  );
}
