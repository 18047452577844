import { Box, Icon } from 'native-base';
import { useEffect, useState } from 'react';
import { Animated } from 'react-native';
import { CHANNEL_ICON_SETS } from '@/adapters/view-models/ChannelViewModel';
import { usePreviousValue } from '../../../hooks/usePreviousValue';

export function ThreadListItemStar({ isStarred }: { isStarred: boolean }) {
  const [opacity] = useState(() => new Animated.Value(isStarred ? 1 : 0));
  const previousIsStarred = usePreviousValue(isStarred) ?? isStarred;
  const iconSet = CHANNEL_ICON_SETS.starred.selected;

  useEffect(() => {
    if (isStarred === previousIsStarred) return;
    Animated.spring(opacity, {
      toValue: isStarred ? 1 : 0,
      speed: 30,
      bounciness: 15,
      useNativeDriver: false,
    }).start();
  }, [isStarred, previousIsStarred, opacity]);
  return (
    <Box
      position="absolute"
      left={0}
      right={0}
      mt="-6px"
    >
      <Animated.View
        style={{
          opacity,
          transform: [{ scale: opacity.interpolate({ inputRange: [0, 1], outputRange: [0.2, 1] }) }],
        }}
      >
        <Icon
          as={iconSet.collection}
          name={iconSet.name}
          size="12px"
          color="starred.500"
        />
      </Animated.View>
    </Box>
  );
}
