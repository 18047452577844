import { CacheTagProvider } from './CacheTagProvider';
import { ThreadsViewModel } from '../view-models/ThreadsViewModel';
import { ThreadViewModel } from '../view-models/ThreadViewModel';

export class GetThreadsCacheTagProvider extends CacheTagProvider<any, ThreadsViewModel> {
  protected generateTags(_arg: any, result: ThreadsViewModel) {
    return [
      ...this.getThreadTags(result.threads),
      ...this.getLastMessagesTags(result.threads),
      ...this.getEventTags(result.threads),
    ];
  }

  private getThreadTags(result: ThreadViewModel[]) {
    return result.map((thread) => ({ type: 'Thread' as const, id: thread.id }));
  }

  private getLastMessagesTags(result: ThreadViewModel[]) {
    return result.map((thread) => ({ type: 'Message' as const, id: thread.lastMessageId }));
  }

  private getEventTags(result: ThreadViewModel[]) {
    return result
      .filter((thread) => thread.eventId)
      .map((thread) => ({ type: 'Event' as const, id: thread.eventId }));
  }
}
