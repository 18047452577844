import { PatchCollection } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import React, { useCallback } from 'react';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition
} from '@reduxjs/toolkit/query';
import { RetryOptions } from '@reduxjs/toolkit/dist/query/retry';
import { UndoMutationToast, UndoMutationToastHandle } from './UndoMutationToast';
import { store } from '../../domain/state/store';
import { rawApiClient } from '../api/codegen';
import { PatchWrapper } from '../mutation-handlers/MutationHandler';
import { mutationTrackingService } from '../mutation-tracking/MutationTrackingService';
import { SingleToastService } from './SingleToastService';

export const TOAST_DISCRIMINANT = 'undo';

enum MutationStatus {
  InProgress = 'inProgress',
  CancelRequested = 'cancelRequested',
  Completed = 'completed',
  Cancelled = 'cancelled',
}

type CancellableMutation = {
  requestId: string;
  status: MutationStatus;
  patchCollections: PatchCollection[];
  patchWrappers: PatchWrapper<any>[];
  invalidateCache: () => void;
  cancelToken?: string;
};

export type MutationInitiator = 'user' | 'app';
export type MutationDescription = {
  action: string;
  undoneAction: string;
};
export type MutationMetadata = {
  initiator: MutationInitiator;
  cancellable?: boolean;
  description?: MutationDescription;
};

type UseMutationType<TArg, TResult> = UseMutation<MutationDefinition<TArg, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, RetryOptions, FetchBaseQueryMeta>, never, TResult, 'api'>>;
type UseMutationResult<TArg, TResult> = ReturnType<UseMutationType<TArg, TResult>>;
type UseMutationTrigger<TArg, TResult> = UseMutationResult<TArg, TResult>[0];
type UseMutationTriggerResult<TArg, TResult> = ReturnType<UseMutationTrigger<TArg, TResult>>;
type WrappedMutationTrigger<TArg, TResult> = (arg: TArg, metadata?: MutationMetadata) => UseMutationTriggerResult<TArg, TResult>;
type WrappedMutationResult<TArg, TResult> = [WrappedMutationTrigger<TArg, TResult>, UseMutationResult<TArg, TResult>[1]];

type RequestTrackingInfo = {
  requestId: string;
  metadata: MutationMetadata;
  initiatedAt: number;
};

const lastMutationInitiatorByRequestId: Map<string, RequestTrackingInfo> = new Map();

export function useWithMutationMetadata<TArg, TResult>(useMutationResult: UseMutationResult<TArg, TResult>): WrappedMutationResult<TArg, TResult> {
  const [triggerMutation, stateResult] = useMutationResult;
  const wrappedTrigger = useCallback(
    (arg: TArg, metadata?: MutationMetadata): UseMutationTriggerResult<TArg, TResult> => {
      const result = triggerMutation(arg);
      if (!metadata) {
        return result;
      }

      cleanupOldRequestsIfNeeded();
      lastMutationInitiatorByRequestId.set(result.requestId, {
        requestId: result.requestId,
        metadata,
        initiatedAt: Date.now(),
      });
      if (metadata && metadata.description) {
        showUndoToast(metadata);
      }

      return result;
    },
    [triggerMutation],
  );

  return [wrappedTrigger, stateResult];
}

export function getRequestMetadata(requestId: string): MutationMetadata | undefined {
  return lastMutationInitiatorByRequestId.get(requestId)?.metadata;
}

function cleanupOldRequestsIfNeeded() {
  if (lastMutationInitiatorByRequestId.size < 100) {
    return;
  }

  const now = Date.now();
  lastMutationInitiatorByRequestId.forEach((trackingInfo, requestId) => {
    // Cleanup requests that are older than 2 minutes.
    if (trackingInfo.initiatedAt < now - 2 * 60 * 1000) {
      lastMutationInitiatorByRequestId.delete(requestId);
    }
  });
}

let lastMutation: CancellableMutation | null = null;
const undoToastRef = React.createRef<UndoMutationToastHandle>();

export function declareMutation(
  requestId: string,
  patchCollections: PatchCollection[],
  patchWrappers: PatchWrapper<any>[],
  invalidateCache: () => void,
) {
  if (lastMutation?.requestId === requestId) {
    return;
  }

  lastMutation = {
    requestId,
    patchCollections,
    patchWrappers,
    invalidateCache,
    status: MutationStatus.InProgress,
  };
}

export async function cancelLastMutation() {
  if (!lastMutation
    || lastMutation.status === MutationStatus.CancelRequested
    || lastMutation.status === MutationStatus.Cancelled
    || !isLastMutationCancellable()) {
    return;
  }

  if (lastMutation.status === MutationStatus.InProgress) {
    lastMutation.status = MutationStatus.CancelRequested;
  } else {
    lastMutation.status = MutationStatus.Cancelled;
  }

  undoToastRef.current?.actionCancelled();
  optimisticUndo();
  if (lastMutation.status === MutationStatus.Cancelled) {
    await backendUndo();
  }
}

export function showUndoToast(metadata: MutationMetadata) {
  const onUndoPressed = async () => void cancelLastMutation();
  const onClosePressed = async () => {
    await SingleToastService.close(TOAST_DISCRIMINANT);
  };

  void SingleToastService.show(TOAST_DISCRIMINANT, {
    render: () => (
      <UndoMutationToast
        handleRef={undoToastRef}
        metadata={metadata}
        onUndoPressed={onUndoPressed}
        onClosePressed={onClosePressed}
      />
    ),
    duration: null,
    placement: 'bottom-left',
    bgColor: 'white',
    marginLeft: 12,
  });
}

export async function declareMutationCompletion(requestId: string, cancelToken: string) {
  if (!lastMutation || lastMutation.requestId !== requestId) {
    return;
  }

  const inProgress = lastMutation.status === MutationStatus.InProgress;
  const cancelRequested = lastMutation.status === MutationStatus.CancelRequested;
  if (!inProgress && !cancelRequested) {
    return;
  }

  lastMutation.cancelToken = cancelToken;
  if (cancelRequested) {
    lastMutation.status = MutationStatus.Cancelled;
    await backendUndo();
  } else {
    lastMutation.status = MutationStatus.Completed;
  }
}

function isLastMutationCancellable(): boolean {
  if (!lastMutation) {
    return false;
  }

  const metadata = getRequestMetadata(lastMutation?.requestId);
  if (!metadata) {
    return false;
  }

  return !(metadata.cancellable === false || metadata.initiator === 'app');
}

function optimisticUndo() {
  if (!lastMutation) {
    return;
  }

  // Failing to apply optimistic patches shouldn't prevent the cancel request from being sent to the backend.
  try {
    lastMutation.patchCollections.reverse().forEach((patchResult) => patchResult.undo());
  } catch (e) {
    console.error('Optimistic undo failed', e);
  }
}

async function backendUndo() {
  if (!lastMutation?.cancelToken) {
    return;
  }

  const endpointNames = lastMutation.patchWrappers.filter((wrapper) => wrapper.endpointName);
  const locks = mutationTrackingService.acquireEndpointLocks(endpointNames);

  try {
    await store.dispatch(rawApiClient.endpoints.cancelCommand.initiate({
      cancelRequestBody: {
        cancel_token: lastMutation.cancelToken,
      }
    }));
    lastMutation.invalidateCache();
  } catch (e) {
    // Optimistic redo: if the backend undo call fails, we want to reapply the original optimistic update.
    lastMutation.patchWrappers.forEach((wrapper) => store.dispatch(wrapper.patch));
  } finally {
    locks.forEach((lock) => lock.release());
  }
}
