import { User } from '../api/codegen';
import { createPictureViewModelFromUser, PictureViewModel } from './PictureViewModel';

export type UserViewModel = {
  id: string;
  displayName: string;
  pictureInfo: PictureViewModel;
  email: string;
  rawUser: User;
  features: User['features'];
  _type: 'user';
};

export function createUserViewModel(user: User): UserViewModel {
  return {
    id: user.id,
    displayName: user.display_name,
    pictureInfo: createPictureViewModelFromUser(user),
    email: user.email,
    features: user.features,
    rawUser: user,
    _type: 'user',
  };
}
