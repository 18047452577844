import { GetSelfFilteredThreadsApiArg } from '../../api/codegen';
import { getCurrentScreenParams } from '../../../infrastructure/navigation/getCurrentScreenParams';
import { rootNavigationContainerRef } from '../../../infrastructure/navigation/navigators/root/RootNavigationContainerRef';

export function getSearchParams(): GetSelfFilteredThreadsApiArg | undefined {
  const sentParams = getCurrentScreenParams(rootNavigationContainerRef.current?.getState(), 'Search');

  if (sentParams) {
    return { pageToken: sentParams.page, query: sentParams.query };
  }

  return undefined;
}
