import { OpenAPIV3 } from 'openapi-types';
import { getApiSchema } from '../schema/api-schema';

export type FetchArgs = {
  url: string;
  params?: Record<string, any>;
};

export class ApiParamsHelper {
  private static schema: OpenAPIV3.Document = getApiSchema();

  private static extractPathParams(url: string, pathTemplate: string): Record<string, string> {
    const urlParts = url.split('/');
    const templateParts = pathTemplate.split('/');
    const params: Record<string, string> = {};

    templateParts.forEach((part, index) => {
      if (part.startsWith('{') && part.endsWith('}')) {
        const paramName = part.slice(1, -1);
        params[paramName] = urlParts[index];
      }
    });

    return params;
  }

  private static toCamelCase(s: string): string {
    return s.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
  }

  public static buildOriginalArgs(endpointName: string, args: FetchArgs): Record<string, any> {
    const { url, params } = args;
    const operationId = endpointName.charAt(0).toUpperCase() + endpointName.slice(1);
    const operationPath = Object.entries(ApiParamsHelper.schema.paths).find(([_path, operations]) => Object.values(operations!).some((operation) => typeof operation === 'object' && 'operationId' in operation && operation.operationId === operationId));

    if (!operationPath) {
      // Should not happen
      throw new Error(`Endpoint ${endpointName} not found`);
    }

    const [pathTemplate] = operationPath;
    const pathParams = ApiParamsHelper.extractPathParams(url, pathTemplate);

    const transformedParams: Record<string, any> = {};

    for (const key in pathParams) {
      if (Object.prototype.hasOwnProperty.call(pathParams, key)) {
        transformedParams[ApiParamsHelper.toCamelCase(key)] = pathParams[key];
      }
    }

    if (params) {
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          transformedParams[ApiParamsHelper.toCamelCase(key)] = params[key];
        }
      }
    }

    return transformedParams;
  }
}
