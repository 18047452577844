import {
  RefObject, useCallback, useRef, useState
} from 'react';
import {
  CellRendererProps, FlatListProps, ListRenderItemInfo, View
} from 'react-native';
import { Box, FlatList } from 'native-base';
import { useHotkeys } from 'react-hotkeys-hook';
import { useIsFocused } from '@react-navigation/native';
import { clamp } from 'lodash';
import { MessagesListItem, ReactionSelectedCallback, ThreadMessageItem } from './ThreadMessageItem';
import { ComprehensiveThreadViewModel } from '../../../../adapters/view-models/ThreadViewModel';
import { ComposeAreaHandles } from '../../compose';
import { useThreadMessagesAutoScroll } from '../../../hooks/useThreadMessagesAutoScroll';
import { createThreadViewStore, ThreadViewProvider, useThreadViewStore } from './context';
import { useMenuStore } from '../../date-picker-menu/menu/useMenuStore';

type RenderListItem = FlatListProps<MessagesListItem>['renderItem'];

type Props = {
  messages: MessagesListItem[];
  thread: ComprehensiveThreadViewModel;
  composeAreaRef: RefObject<ComposeAreaHandles>;
  reactionSelectedCallback?: ReactionSelectedCallback;
};

const listFooter = <Box height="4" />;

function ThreadMessagesList(props: Props) {
  const { messages } = props;
  const {
    onRenderedMessageCallback, flatListRef, flatListProps
  } = useThreadMessagesAutoScroll(messages);

  const messageIdToMarkAsNew = useMessageIdToMarkAsNew(messages ?? []);

  const isScreenFocused = useIsFocused();
  const threadViewStore = useThreadViewStore();

  function goToPrevNextMessage(offset: number) {
    const anyMenuOpen = useMenuStore.getState().open;
    if (!messages || messages.length === 0 || anyMenuOpen) return;
    const state = threadViewStore.getState();
    const hoveredId = state.focusedId;
    const messageIndex = messages.findIndex((item) => item.id === hoveredId);
    let nextIndex = messageIndex !== -1 ? (messageIndex + offset) : 0;
    nextIndex = clamp(nextIndex, 0, messages.length - 1);
    state.setFocusedId(messages[nextIndex].id);
    state.setNavigatedFromKeyboard();
  }

  const hotkeysOptions = {
    enabled: isScreenFocused,
    preventDefault: true,
  };
  useHotkeys(['n', 'ArrowDown'], () => goToPrevNextMessage(-1), hotkeysOptions);
  useHotkeys(['p', 'ArrowUp'], () => goToPrevNextMessage(1), hotkeysOptions);

  const renderItem: RenderListItem = useCallback((item: ListRenderItemInfo<MessagesListItem>) => {
    return (
      <ThreadMessageItem
        threadId={props.thread.id}
        message={item.item}
        emojiSelectedCallback={props.reactionSelectedCallback}
        replyComposeAreaRef={props.composeAreaRef}
        onRendered={onRenderedMessageCallback}
        displayAsNew={messageIdToMarkAsNew === item.item.id}
      />
    );
  }, [messageIdToMarkAsNew, props.thread.id, props.reactionSelectedCallback, props.composeAreaRef, onRenderedMessageCallback]);

  const renderCell = useCellRenderer(messageIdToMarkAsNew);

  return (
    <FlatList
      ref={flatListRef}
      ListFooterComponent={listFooter}
      inverted
      CellRendererComponent={renderCell}
      renderItem={renderItem}
      data={messages}
      flexShrink={1}
      {...flatListProps}
    />
  );
}

function ThreadMessagesListContainer(props: Props) {
  const [threadViewStore] = useState(() => createThreadViewStore());

  return (
    <ThreadViewProvider value={threadViewStore}>
      <ThreadMessagesList {...props} />
    </ThreadViewProvider>
  );
}

export { ThreadMessagesListContainer as ThreadMessagesList };

function useMessageIdToMarkAsNew(messages: MessagesListItem[]) {
  const allMessagesAreUnread = messages.every((message) => message.isUnread);
  const earliestUnreadMessage = messages.findLast((message) => message.isUnread);
  const savedTimestamp = useRef(calculateTimestamp());

  if (savedTimestamp.current === undefined && messages.length > 0) {
    savedTimestamp.current = calculateTimestamp();
  }

  const messageIdToMarkAsNew = messages.findLast((message) => {
    if (!message.isUnread || !savedTimestamp.current) return false;
    const messageDate = message.rawDate;
    return savedTimestamp.current.operator === '>=' ? messageDate >= savedTimestamp.current.date : messageDate > savedTimestamp.current.date;
  })?.id;

  const messageIdToMarkAsNewRef = useRef(messageIdToMarkAsNew);
  const shouldRedetermineMessageId = messageIdToMarkAsNewRef.current === undefined || !messages.some((message) => message.id === messageIdToMarkAsNewRef.current);

  if (shouldRedetermineMessageId) {
    messageIdToMarkAsNewRef.current = messageIdToMarkAsNew;
  }

  return messageIdToMarkAsNewRef.current;

  function calculateTimestamp() {
    if (messages.length === 0) {
      return undefined;
    }

    if (allMessagesAreUnread || !earliestUnreadMessage) {
      return createTimestampValue(messages[0].rawDate, false);
    }

    return createTimestampValue(earliestUnreadMessage.rawDate, true);
  }

  function createTimestampValue(date: number, isTimestampOfNewMessage: boolean) {
    return {
      date,
      operator: isTimestampOfNewMessage ? '>=' : '>'
    };
  }
}

/**
 * This cell renderer sets a higher zIndex for the "New message" divider
 * to ensure it is rendered above other messages. Without this,
 * the "New" label could be obscured by the background color of
 * a previous message when it is hovered over, due to the inverted list order.
 */
function useCellRenderer(messageIdToMarkAsNew: string | undefined): React.ComponentType<CellRendererProps<MessagesListItem>> {
  return useCallback(({ style, item, ...props }) => {
    const containsNewDivider = messageIdToMarkAsNew === item.id;
    const zIndex = {
      zIndex: containsNewDivider ? 1 : 0,
    };

    return <View style={[style, zIndex]} {...props} />;
  }, [messageIdToMarkAsNew]);
}
