import { Box, KeyboardAvoidingView } from 'native-base';
import {
  ForwardedRef, forwardRef, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState
} from 'react';
import { Platform } from 'react-native';
import { InterfaceBoxProps } from 'native-base/lib/typescript/components/primitives/Box';
import { Hoverable } from 'react-native-web-hover';
import { throttle } from 'lodash';
import isEqual from 'react-fast-compare';
import { useHotkeys } from 'react-hotkeys-hook';
import { ComposeToolbar } from './ComposeToolbar';
import { getBorderColor } from '../utils/getBorderColor';
import { MessageViewModel } from '@/adapters/view-models/MessageViewModel';
import { ParticipantScreenSectionController } from '../../../controllers/screen-sections/ParticipantScreenSectionController';
import { ParticipantViewModel } from '@/adapters/view-models/ParticipantViewModel';
import { useHasEmailSync } from '../../../hooks/useHasEmailSync';
import { useGetSelfOrganizations } from '../../../controllers/hooks/api/useGetSelfOrganizations';
import { isEmptyDeltaOps, RichTextEditor, type RichTextEditorHandle } from '../../rich-text-editor';
import { useAppDispatch } from '../../../state/hooks';
import { draftActions, selectThreadDraft } from '@/domain/state/drafts';
import { store } from '@/domain/state/store';
import { InputUncontrolled, InputUncontrolledHandle } from '../../InputUncontrolled';
import { preventUnload } from '../../../global/preventUnload';
import { addLineBreaks } from '../utils/addLineBreaks';
import { useInsertEmoji } from '../../rich-text-editor/useInsertEmoji';
import MentionEventsProvider from '@/infrastructure/ui/rich-text-editor/mention/MentionEventsProvider';

let currentToolbarUniqueId = 0;

export type QuoteMessageCallback = (message: MessageViewModel) => void;

export interface ComposeAreaHandles {
  quoteMessage: QuoteMessageCallback;
}

export type ComposeAreaSubmitCallback = (props: { body: string, title?: string, recipients?: ParticipantViewModel[] }) => Promise<{
  data?: any;
  error?: any;
}>;

type ComposeAreaProps = Omit<InterfaceBoxProps, 'flexGrow'> & {
  bodyPlaceholder?: string;
  titlePlaceholder?: string;
  submitCallback?: ComposeAreaSubmitCallback;
  showTitle: boolean;
  showBody?: boolean;
  hideToolbarAndOutlineWhenUnfocused?: boolean;
  defaultBody?: string;
  defaultTitle?: string;
  titleHasHeaderStyle?: boolean;
  onTitleBlur?: (title: string) => void;
  readOnly?: boolean;
  fontFamily?: string;
  flexGrow?: number;
  freezeParticipants?: boolean;
  emailRecipients: ParticipantViewModel[] | null;
  participantsChangedCallback?: (participants: ParticipantViewModel[]) => void;
  draftKey?: string;
};

export const ComposeArea = forwardRef(function ComposeArea({ onTitleBlur, ...props }: ComposeAreaProps, ref: ForwardedRef<ComposeAreaHandles>) {
  const titleRef = useRef<InputUncontrolledHandle>(null);
  const bodyRef = useRef<RichTextEditorHandle>(null);
  const [toolbarUniqueId] = useState(() => currentToolbarUniqueId++);
  const bodyEnabled = props.showBody ?? true;
  const [displayParticipantsList, setDisplayParticipantsList] = useState(false);
  const { currentData: selfOrganizations } = useGetSelfOrganizations();
  const { shouldHideEmailFeatures } = useHasEmailSync(selfOrganizations);
  const allowModifyParticipantList = !shouldHideEmailFeatures && !props.freezeParticipants;
  const { onTextChange, onSelectionChange, emojiSelectedCallback } = useInsertEmoji(bodyRef);

  useImperativeHandle(ref, () => ({
    quoteMessage: (message: MessageViewModel) => {
      const quill = bodyRef.current;
      if (!quill) return;
      const { Delta } = quill;
      const messageDelta = quill.clipboard.convert({ html: `<blockquote>${message.body}</blockquote>` });
      const length = quill.getLength();
      const empty = length === 1 && quill.getText().trim() === '';
      const startDelta = empty ? new Delta() : new Delta().retain(length);
      const addBlockquote = startDelta.concat(messageDelta).insert(empty ? '' : '\n');
      quill.updateContents(addBlockquote);
      quill.setSelection(quill.getLength() - 1, 0);
    },
  }));

  const [titleIsFocused, setTitleIsFocused] = useState(false);
  const [bodyIsFocused, setBodyIsFocused] = useState(false);

  const COMPOSE_AREA_BG_COLOR = 'white';
  const toolbarDisplayed = (!props.hideToolbarAndOutlineWhenUnfocused || bodyIsFocused) && bodyEnabled;
  const composeAreaIsFocused = (bodyIsFocused || titleIsFocused) && !props.readOnly;

  const { draftKey } = props;
  const dispatch = useAppDispatch();
  const handleSaveDraft = () => {
    if (!draftKey || !titleRef.current || !bodyRef.current) return;
    // todo delete draft if empty
    const draftPayload = {
      id: draftKey,
      title: titleRef.current.value ?? '',
      content: bodyRef.current.getContents().ops,
      updatedAt: Date.now(),
    };
    const isDraftContentEmpty = isEmptyDeltaOps(draftPayload.content);
    const isEqualToSavedDraft = () => {
      const savedDraft = selectThreadDraft(store.getState(), draftKey);
      const savedTitle = savedDraft?.title ?? '';
      const isSameTitle = draftPayload.title === savedTitle;
      const isSameContent = () => {
        const savedContent = savedDraft?.content ?? [];
        const isSavedContentEmpty = isEmptyDeltaOps(savedContent);
        if (isSavedContentEmpty && isDraftContentEmpty) return true;
        if (isSavedContentEmpty || isDraftContentEmpty) return false;
        return isEqual(savedContent, draftPayload.content);
      };
      return isSameTitle && isSameContent();
    };
    if (isEqualToSavedDraft()) {
      return;
    }
    const isEmptyDraft = !draftPayload.title && isDraftContentEmpty;
    if (isEmptyDraft) {
      dispatch(draftActions.deleteDraft(draftKey));
    } else {
      dispatch(draftActions.saveDraft(draftPayload));
    }
  };

  useLayoutEffect(() => {
    if (!draftKey) return;
    const foundDraft = selectThreadDraft(store.getState(), draftKey);
    if (foundDraft) {
      titleRef.current?.setValue(foundDraft.title);
      bodyRef.current?.setContents(foundDraft.content);
      bodyRef.current?.history.clear();
    }
  }, [draftKey]);

  const [isEmptyBody, setIsEmptyBody] = useState(true);
  useLayoutEffect(() => {
    const quill = bodyRef.current;
    if (!quill) return;
    const onTextChange = throttle(() => {
      setIsEmptyBody(isEmptyDeltaOps(quill.getContents().ops));
    }, 100);
    setIsEmptyBody(isEmptyDeltaOps(quill.getContents().ops));
    quill.on('text-change', onTextChange);
    return () => {
      quill.off('text-change', onTextChange);
    };
  }, []);
  const sendButtonDisabled = isEmptyBody;

  const handleSubmit = async () => {
    await preventUnload(async () => {
      setDisplayParticipantsList(false);
      const submitPromise = props.submitCallback?.({
        title: titleRef.current?.value ?? '',
        body: addLineBreaks(bodyRef.current?.getSemanticHTML() ?? ''),
        recipients: props.emailRecipients || [],
      });
      titleRef.current?.setValue('');
      bodyRef.current?.setText('');
      bodyRef.current?.blur();
      return submitPromise;
    });
  };

  useHotkeys(['r', 'a', 'Enter'], () => bodyRef.current?.focus(), { preventDefault: true });

  const participantsChangedCallback = useMemo(() => {
    const original = props.participantsChangedCallback;
    if (!original) return undefined;
    return ((...args) => {
      original(...args);
      setDisplayParticipantsList(true);
    }) satisfies typeof original;
  }, [props.participantsChangedCallback]);

  return (
    <>
      <Hoverable style={{ flexGrow: props.flexGrow }}>
        {({ hovered }) => {
          const borderColor = getBorderColor(props.hideToolbarAndOutlineWhenUnfocused, composeAreaIsFocused, hovered && !props.readOnly);

          return (
            <Box
              pb={bodyEnabled ? 2 : 1}
              pt={bodyEnabled ? 0 : 1}
              flexDirection="row"
              alignItems="center"
              flexShrink={1}
              {...props}
            >
              <Box
                flexGrow={1}
                borderRadius={8}
                borderColor={borderColor}
                borderWidth={1}
                bgColor={COMPOSE_AREA_BG_COLOR}
                width="100%"
                overflow="hidden"
                shadow={bodyEnabled ? '2' : undefined}
              >
                <MentionEventsProvider>
                  {participantsChangedCallback && props.emailRecipients && (
                    <Box display={displayParticipantsList ? undefined : 'none'}>
                      <ParticipantScreenSectionController
                        selectedParticipantsListChangedCallback={participantsChangedCallback}
                        includeChannels={false}
                        includeUsers
                        type="recipients"
                        suggestionsOnTop
                        maxNumberOfUserSuggestions={3}
                        initialParticipants={props.emailRecipients}
                        minimumLettersForSuggestions={1}
                      />
                    </Box>
                  )}
                  <InputUncontrolled
                    ref={titleRef}
                    fontFamily={props.fontFamily}
                    defaultValue={props.defaultTitle}
                    bgColor="transparent"
                    outlineColor="transparent"
                    focusOutlineColor="transparent"
                    borderStyle="none"
                    _focus={{ borderWidth: 0, borderColor: 'transparent' }}
                    _hover={{ borderWidth: 0, borderColor: 'transparent' }}
                    _input={{ borderWidth: 0, borderColor: 'transparent', _focus: { borderWidth: 0, borderColor: 'transparent' } }}
                    borderWidth={0}
                    isReadOnly={props.readOnly}
                    borderColor="transparent"
                    onFocus={() => setTitleIsFocused(true)}
                    onBlur={(e) => {
                      setTitleIsFocused(false);
                      onTitleBlur?.(e.nativeEvent.text);
                    }}
                    display={props.showTitle ? null : 'none'}
                    onChangeText={handleSaveDraft}
                    fontSize={!props.titleHasHeaderStyle ? 'sm' : 'xl'}
                    fontWeight={!props.titleHasHeaderStyle ? 'normal' : 'bold'}
                    placeholder={props.titlePlaceholder ?? 'Optional thread title'}
                    placeholderTextColor="gray.400"
                    mt={bodyEnabled ? 1 : 0}
                    mb={0}
                    backgroundColor="light.50"
                    onKeyPress={(event) => {
                      if (event.nativeEvent.key === 'Enter') {
                        event.preventDefault();
                        bodyRef.current?.focus();
                      }
                      if (event.nativeEvent.key === 'Escape') {
                        titleRef.current?.blur();
                      }
                    }}
                  />
                  {toolbarDisplayed ? (
                    <>
                      <RichTextEditor
                        style={{ borderWidth: 0, borderColor: 'transparent', display: bodyEnabled ? undefined : 'none' }}
                        ref={bodyRef}
                        onTextChange={() => {
                          onTextChange();
                          handleSaveDraft();
                        }}
                        onFocus={() => setBodyIsFocused(true)}
                        onBlur={() => setBodyIsFocused(false)}
                        onSelectionChange={onSelectionChange}
                        readOnly={props.readOnly}
                        defaultValueHTML={props.defaultBody}
                        placeholder={props.bodyPlaceholder}
                        modules={{
                          toolbar: {
                            container: `#toolbar-${toolbarUniqueId}`,
                          },
                          clipboard: {
                            matchVisual: false,
                          },
                          emoji: true,
                        }}
                        formats={[
                          // 'header',
                          'font',
                          // 'size',
                          'bold',
                          'italic',
                          'underline',
                          'strike',
                          'blockquote',
                          'code-block',
                          'list',
                          'indent',
                          'link',
                          'image',
                          'mention',
                          // 'color',
                        ]}
                        theme="snow"
                      />
                      <ComposeToolbar
                        displayed={toolbarDisplayed}
                        enabled={bodyIsFocused && !props.readOnly}
                        submit={handleSubmit}
                        sendButtonDisabled={sendButtonDisabled}
                        uniqueId={toolbarUniqueId}
                        hotkeysEnabled={bodyIsFocused}
                        emailRecipients={props.emailRecipients}
                        allowModifyParticipants={allowModifyParticipantList}
                        onModifyParticipants={() => setDisplayParticipantsList((previous) => !previous)}
                        emojiSelectedCallback={emojiSelectedCallback}
                        emojiPickerPlacement="top"
                      />
                    </>
                  ) : null}
                </MentionEventsProvider>
              </Box>
            </Box>
          );
        }}
      </Hoverable>
      <KeyboardAvoidingView keyboardVerticalOffset={90} behavior={Platform.OS === 'ios' ? 'padding' : 'height'} />
    </>
  );
});
