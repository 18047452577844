import { HStack, IInputProps, Input } from 'native-base';
import { ForwardedRef, forwardRef } from 'react';
import { TextInput } from 'react-native';

export type DatePromptInputProps = Pick<IInputProps, 'onChangeText' | 'value'> & {
  onArrowDown: () => void;
  onArrowUp: () => void;
  onEscape: () => void;
  onFocusChange: (focused: boolean) => void;
  onEnter: () => void;
};

const DatePromptInput = forwardRef(({
  onChangeText, onArrowDown, onArrowUp, onEscape, onFocusChange, onEnter, value
}: DatePromptInputProps, ref: ForwardedRef<TextInput>) => {
  return (
    <HStack mx={3} space={4} px={1} pt={1} justifyContent="space-between" flexGrow={1} alignContent="center">
      <Input
        ref={ref ?? undefined}
        backgroundColor="transparent"
        borderColor="transparent"
        borderWidth={0}
        outlineColor="transparent"
        focusOutlineColor="transparent"
        placeholder="Try: 10 am, 2 days,  jul 14"
        fontSize="sm"
        alignSelf="center"
        onKeyPress={(e) => {
          if (e.nativeEvent.key === 'ArrowDown') {
            onArrowDown();
          } else if (e.nativeEvent.key === 'ArrowUp') {
            onArrowUp();
          } else if (e.nativeEvent.key === 'Escape') {
            onEscape();
          } else if (e.nativeEvent.key === 'Enter') {
            onEnter();
          }
        }}
        onFocus={() => onFocusChange(true)}
        onBlur={() => onFocusChange(false)}
        onChangeText={onChangeText}
        value={value}
        pl={0}
        w="100%"
      />
    </HStack>
  );
});

export { DatePromptInput };
