import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  HStack, Text, Icon, Pressable, Box, Menu
} from 'native-base';
import React, { memo, useCallback } from 'react';

import { ManageChannelsModalControllerHandles, ManageChannelsModalController } from '../../../controllers/modals/ManageChannelsModalController';
import { CHANNEL_LINK_LEFT_PADDING, MENU_HORIZONTAL_PADDING } from './Constants';
import { CreateChannelModalControllerHandles, CreateChannelModalController } from '../../../controllers/modals/CreateChannelModalController';
import { CustomMenuItem } from '../../menu/CustomMenuItem';

export const AddChannelButton = memo(() => {
  const manageChannelsModalControllerRef = React.useRef<ManageChannelsModalControllerHandles>(null);
  const createChannelModalControllerRef = React.useRef<CreateChannelModalControllerHandles>(null);
  const textColor = 'dark.700';
  // const textColor = undefined;

  const trigger = useCallback((triggerProps: any) => (
    <Box px={MENU_HORIZONTAL_PADDING} key="add_channel">
      <Pressable
        variant="menu"
        borderRadius={10}
        w="100%"
        alignSelf="center"
        mb={2}
        mt={-2}
        {...triggerProps}
      >
        <HStack space={2} alignItems="center" p={2} pl={CHANNEL_LINK_LEFT_PADDING}>
          <Icon as={MaterialCommunityIcons} name="plus" color={textColor} size="md" />
          <Text color={textColor}>
            Add a channel
          </Text>
        </HStack>
      </Pressable>
      <ManageChannelsModalController ref={manageChannelsModalControllerRef} />
      <CreateChannelModalController ref={createChannelModalControllerRef} />
    </Box>
  ), []);

  return (
    <Menu
      shouldOverlapWithTrigger={false}
      placement="bottom"
      trigger={trigger}
    >
      <CustomMenuItem onPress={() => manageChannelsModalControllerRef.current?.show()}><Text>Browse channels</Text></CustomMenuItem>
      <CustomMenuItem onPress={() => createChannelModalControllerRef.current?.show()}><Text>Create a new channel</Text></CustomMenuItem>
    </Menu>
  );
});
