import { Avatar } from 'native-base';
import { ThemeComponentSizeType } from 'native-base/lib/typescript/components/types';
import { InterfaceAvatarProps } from 'native-base/lib/typescript/components/composites/Avatar/types';
import { memo } from 'react';
import { PictureViewModel } from '../../adapters/view-models/PictureViewModel';

function ParticipantAvatars(props: { participants: PictureViewModel[]; maxNumberOfAvatars: number; size: ThemeComponentSizeType<'Avatar'> }) {
  return (
    <Avatar.Group
      space={-2}
      _avatar={{
        borderRadius: props.size === 'xs' ? '8px' : '10px',
        borderWidth: 2,
        size: props.size,
      }}
      max={props.maxNumberOfAvatars}
    >
      {props.participants.map((pictureInfo) => (
        <Avatar
          key={pictureInfo.id}
          _image={{
            borderRadius: props.size === 'xs' ? '6px' : '8px',
            alt: pictureInfo.name
          }}
          bg={pictureInfo.backgroundColor}
          _text={{ color: 'white' }}
          source={{
            uri: pictureInfo.url ?? undefined,
          }}
        >
          {pictureInfo.initials}
        </Avatar>
      ))}
    </Avatar.Group>
  );
}

const ParticipantAvatarsMemo = memo(ParticipantAvatars);
export { ParticipantAvatarsMemo as ParticipantAvatars };

type ParticipantAvatarProps = InterfaceAvatarProps & {
  pictureInfo: PictureViewModel;
  size?: ThemeComponentSizeType<'Avatar'>;
};

function ParticipantAvatar(props: ParticipantAvatarProps) {
  return (
    <Avatar
      size={props.size}
      _image={{
        alt: props.pictureInfo.name,
        borderRadius: props.size === 'xs' ? '6px' : '8px',
      }}
      bg={props.pictureInfo.backgroundColor}
      _text={{ color: 'white' }}
      source={{
        uri: props.pictureInfo.url ?? undefined,
      }}
      {...props}
    >
      {props.pictureInfo.initials}
    </Avatar>
  );
}

const ParticipantAvatarMemo = memo(ParticipantAvatar);

export { ParticipantAvatarMemo as ParticipantAvatar };
