import {
  GetChannelThreadsApiArg
} from '../api/codegen';
import { CacheTagProvider } from './CacheTagProvider';
import { ThreadsViewModel } from '../view-models/ThreadsViewModel';

export class GetChannelThreadsCacheTagProvider extends CacheTagProvider<GetChannelThreadsApiArg, ThreadsViewModel> {
  constructor(private readonly getThreadMessagesCacheTagProvider: CacheTagProvider<any, any>) {
    super();
  }

  protected generateTags(arg: GetChannelThreadsApiArg, result: ThreadsViewModel) {
    return [
      this.getChannelTag(arg),
      ...this.getThreadMessagesCacheTagProvider.provideTags(arg, result),
    ];
  }

  private getChannelTag(arg: GetChannelThreadsApiArg) {
    return { type: 'Channel' as const, id: arg.channelId };
  }
}
