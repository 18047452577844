import { store } from '../../domain/state/store';
import { AddChannelParticipantsMutationHandler } from '../mutation-handlers/AddChannelParticipantsMutationHandler';
import { AddMessageReactionMutationHandler } from '../mutation-handlers/AddMessageReactionMutationHandler';
import { AddThreadParticipantsMutationHandler } from '../mutation-handlers/AddThreadParticipantsMutationHandler';
import { AddThreadToInboxMutationHandler } from '../mutation-handlers/AddThreadToInboxMutationHandler';
import { ChangeThreadChannelsMutationHandler } from '../mutation-handlers/ChangeThreadChannelsMutationHandler';
import { CreateChannelMutationHandler } from '../mutation-handlers/CreateChannelMutationHandler';
import { CreateThreadMutationHandler } from '../mutation-handlers/CreateThreadMutationHandler';
import { DeleteChannelMutationHandler } from '../mutation-handlers/DeleteChannelMutationHandler';
import { MarkAllThreadMessagesAsReadMutationHandler } from '../mutation-handlers/MarkAllThreadMessagesAsReadMutationHandler';
import { MarkInboxItemAsReadMutationHandler } from '../mutation-handlers/MarkInboxItemAsReadMutationHandler';
import { MarkMessagesAsReadMutationHandler } from '../mutation-handlers/MarkMessagesAsReadMutationHandler';
import { MarkMessagesAsUnreadMutationHandler } from '../mutation-handlers/MarkMessagesAsUnreadMutationHandler';
import { MarkThreadAsNotSpamMutationHandler } from '../mutation-handlers/MarkThreadAsNotSpamMutationHandler';
import { MarkThreadAsSpamMutationHandler } from '../mutation-handlers/MarkThreadAsSpamMutationHandler';
import { PostMessageMutationHandler } from '../mutation-handlers/PostMessageMutationHandler';
import { RemoveChannelParticipantsMutationHandler } from '../mutation-handlers/RemoveChannelParticipantsMutationHandler';
import { RemoveEventFromInboxMutationHandler } from '../mutation-handlers/RemoveEventFromInboxMutationHandler';
import { RemoveMessageReactionMutationHandler } from '../mutation-handlers/RemoveMessageReactionMutationHandler';
import { RemoveThreadParticipantsMutationHandler } from '../mutation-handlers/RemoveThreadParticipantsMutationHandler';
import { SnoozeThreadMutationHandler } from '../mutation-handlers/SnoozeThreadMutationHandler';
import { UpdateChannelMutationHandler } from '../mutation-handlers/UpdateChannelMutationHandler';
import { UpdateThreadMutationHandler } from '../mutation-handlers/UpdateThreadMutationHandler';
import { UpdateChannelNotificationLevelMutationHandler } from '../mutation-handlers/UpdateChannelNotificationLevelMutationHandler';
import { mutationTrackingService } from '../mutation-tracking/MutationTrackingService';
import { apiClient } from './configure-view-models';
import { TrashThreadMutationHandler } from '../mutation-handlers/TrashThreadMutationHandler';
import { UntrashThreadMutationHandler } from '../mutation-handlers/UntrashThreadMutationHandler';
import { DeleteThreadsMutationHandler } from '../mutation-handlers/DeleteThreadsMutationHandler';
import { StarThreadsMutationHandler } from '../mutation-handlers/StarThreadsMutationHandler';
import { UnstarThreadsMutationHandler } from '../mutation-handlers/UnstarThreadsMutationHandler';

const mutationHandlerProps = {
  apiClient, getState: store.getState, dispatch: store.dispatch, mutationTrackingService
};

export const createThreadMutationHandler = new CreateThreadMutationHandler(mutationHandlerProps);
export const postMessageMutationHandler = new PostMessageMutationHandler(mutationHandlerProps);
export const markInboxItemAsReadMutationHandler = new MarkInboxItemAsReadMutationHandler(mutationHandlerProps);
export const markMessagesAsReadMutationHandler = new MarkMessagesAsReadMutationHandler(mutationHandlerProps);
export const markMessagesAsUnreadMutationHandler = new MarkMessagesAsUnreadMutationHandler(mutationHandlerProps);
export const removeEventFromInboxMutationHandler = new RemoveEventFromInboxMutationHandler(mutationHandlerProps);
export const addThreadToInboxMutationHandler = new AddThreadToInboxMutationHandler(mutationHandlerProps);
export const addThreadParticipantsMutationHandler = new AddThreadParticipantsMutationHandler(mutationHandlerProps);
export const removeThreadParticipantsMutationHandler = new RemoveThreadParticipantsMutationHandler(mutationHandlerProps);
export const updateThreadMutationHandler = new UpdateThreadMutationHandler(mutationHandlerProps);
export const addChannelParticipantsMutationHandler = new AddChannelParticipantsMutationHandler(mutationHandlerProps);
export const removeChannelParticipantsMutationHandler = new RemoveChannelParticipantsMutationHandler(mutationHandlerProps);
export const addMessageReactionMutationHandler = new AddMessageReactionMutationHandler(mutationHandlerProps);
export const removeMessageReactionMutationHandler = new RemoveMessageReactionMutationHandler(mutationHandlerProps);
export const createChannelMutationHandler = new CreateChannelMutationHandler(mutationHandlerProps);
export const updateChannelMutationHandler = new UpdateChannelMutationHandler(mutationHandlerProps);
export const deleteChannelMutationHandler = new DeleteChannelMutationHandler(mutationHandlerProps);
export const markThreadAsSpamMutationHandler = new MarkThreadAsSpamMutationHandler(mutationHandlerProps);
export const markThreadAsNotSpamMutationHandler = new MarkThreadAsNotSpamMutationHandler(mutationHandlerProps);
export const markAllThreadMessagesAsReadMutationHandler = new MarkAllThreadMessagesAsReadMutationHandler(mutationHandlerProps);
export const addThreadChannelsMutationHandler = new ChangeThreadChannelsMutationHandler(mutationHandlerProps, 'add');
export const removeThreadChannelsMutationHandler = new ChangeThreadChannelsMutationHandler(mutationHandlerProps, 'remove');
export const snoozeThreadMutationHandler = new SnoozeThreadMutationHandler(mutationHandlerProps);
export const updateChannelNotificationLevelMutationHandler = new UpdateChannelNotificationLevelMutationHandler(mutationHandlerProps);
export const trashThreadMutationHandler = new TrashThreadMutationHandler(mutationHandlerProps);
export const untrashThreadMutationHandler = new UntrashThreadMutationHandler(mutationHandlerProps);
export const deleteThreadsMutationHandler = new DeleteThreadsMutationHandler(mutationHandlerProps);
export const starThreadsMutationHandler = new StarThreadsMutationHandler(mutationHandlerProps);
export const unstarThreadsMutationHandler = new UnstarThreadsMutationHandler(mutationHandlerProps);
