import {
  Box, HStack, Input, VStack
} from 'native-base';
import { NativeSyntheticEvent, TextInput, TextInputKeyPressEventData } from 'react-native';
import { ParticipantsList } from '../../ParticipantsList';
import { SelectedRecipient } from './SelectedRecipient';
import { ParticipantCallback } from '../../ParticipantListItem';
import {
  ParticipantViewModel
} from '../../../../adapters/view-models/ParticipantViewModel';

export type AddParticipantScreenSectionType = 'recipients' | 'add_people' | 'add_channels' | 'set_assignee';

export type ParticipantScreenSectionProps = {
  selectedRecipients: ParticipantViewModel[];
  removeRecipientCallback: ParticipantCallback;
  recipientFilterChangedCallback: ((text: string) => void) | undefined;
  filterInputRef: React.RefObject<TextInput>;
  filterIsFocusedCallback: (focused: boolean) => void;
  showFilteredRecipientsList: boolean;
  interactingWithFilteredRecipientsListCallback: ((isInteracting: boolean) => void) | undefined;
  users: ParticipantViewModel[];
  selectRecipientCallback: ParticipantCallback | undefined;
  includeChannels: boolean;
  includeUsers: boolean;
  singleParticipant?: boolean;
  clearAllParticipantsCallback?: () => void;
  clearAllText?: string;
  type: AddParticipantScreenSectionType;
  allowExternalUsers?: boolean;
  onKeyPress?: (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void;
  suggestionsOnTop?: boolean;
};

let timeoutid: any;

export function ParticipantScreenSection(props: ParticipantScreenSectionProps) {
  const { labelText, placeholderText } = getTexts(props.includeChannels, props.includeUsers, props.type);

  const participantList = props.users.length > 0
    ? (
      <ParticipantsList
        py={1}
        display={props.showFilteredRecipientsList ? 'flex' : 'none'}
        flexGrow={1}
        flexShrink={1}
        interactingWithParticipantsListCallback={props.interactingWithFilteredRecipientsListCallback}
        users={props.users}
        selectParticipantCallback={props.selectRecipientCallback}
        clearAllText={props.clearAllText}
        clearAllParticipantsCallback={props.clearAllParticipantsCallback}
        allowExternalUsers={props.allowExternalUsers}
      />
    ) : null;

  return (
    <VStack flexGrow={1} flexShrink={0} flex={1}>
      {props.suggestionsOnTop ? participantList : null}
      <HStack flexShrink={0} space={2} p={2} px={4} alignItems="stretch">
        <Box color="gray.300" mr={2} my={1.5}>{labelText}</Box>
        <HStack flexShrink={0} maxH="100%" maxW="90%" alignItems="center" flexWrap="wrap" flexGrow={1}>
          {!props.singleParticipant ? props.selectedRecipients.map((recipient) => <SelectedRecipient key={recipient.id} recipient={recipient} removeRecipientCallback={props.removeRecipientCallback} />) : null}
          <Input
            ml={0}
            pl={0}
            py={0}
            mr={2}
            my={2}
            placeholder={placeholderText}
            borderStyle="none"
            flexGrow={1}
            flexShrink={0}
            outlineColor="transparent"
            focusOutlineColor="transparent"
            backgroundColor="transparent"
            fontSize="sm"
            onChangeText={props.recipientFilterChangedCallback}
            ref={props.filterInputRef}
            autoFocus
            onFocus={() => {
              clearTimeout(timeoutid);
              props.filterIsFocusedCallback(true);
            }}
            onBlur={() => {
              timeoutid = setTimeout(() => props.filterIsFocusedCallback(false), 100);
            }}
            onKeyPress={props.onKeyPress}
          />
        </HStack>
      </HStack>
      {props.suggestionsOnTop ? null : participantList}
    </VStack>
  );
}

function getTexts(includeChannels: boolean, includeUsers: boolean, type: AddParticipantScreenSectionType) {
  let placeholderText = '';
  if (includeChannels && includeUsers) {
    placeholderText = 'Type a name or channel';
  } else if (includeChannels) {
    placeholderText = 'Type a channel';
  } else {
    placeholderText = 'Type a name';
  }
  let labelText = '';
  switch (type) {
    case 'recipients': {
      labelText = 'To:';
      break;
    }
    case 'add_people': {
      labelText = 'People:';
      break;
    }
    case 'set_assignee': {
      labelText = 'Assignee:';
      break;
    }
    case 'add_channels': {
      labelText = 'Channels:';
      break;
    }
    default: {
      throw new Error(`Unknown type ${type}`);
    }
  }
  return { labelText, placeholderText };
}
