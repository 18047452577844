import { intlFormat } from 'date-fns';
import { determineDateFromPrompt } from './determineDateFromPrompt';
import { DateProps } from './DateProps';

export function generateDatePropsFromPrompt(datePrompt: string): DateProps | undefined {
  const initialDate = determineDateFromPrompt(datePrompt);

  if (!initialDate) {
    return;
  }

  if (initialDate > new Date()) {
    return generateProps(initialDate);
  }

  const adjustedYear = getAdjustedYear(initialDate);
  const adjustedPrompt = `${datePrompt} ${adjustedYear}`;
  const adjustedDate = determineDateFromPrompt(adjustedPrompt, true);

  if (adjustedDate && adjustedDate > new Date()) {
    return generateProps(adjustedDate);
  }
}

function getAdjustedYear(initialDate: Date) {
  const today = new Date();
  if (initialDate.getMonth() > today.getMonth() || (initialDate.getMonth() === today.getMonth() && initialDate.getDate() > today.getDate())) {
    return today.getFullYear();
  }

  return today.getFullYear() + 1;
}

function generateProps(date: Date): DateProps | undefined {
  return {
    dateLabel: intlFormat(date, {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }),
    date,
  };
}
