import { Box, Divider, VStack } from 'native-base';
import { useCallback, useRef } from 'react';
import { ComposeArea, ComposeAreaSubmitCallback } from '../compose';
import { AddParticipantScreenSectionControllerHandles, ParticipantScreenSectionController } from '../../controllers/screen-sections/ParticipantScreenSectionController';
import { ParticipantViewModel } from '@/adapters/view-models/ParticipantViewModel';
import MentionEventsProvider from '@/infrastructure/ui/rich-text-editor/mention/MentionEventsProvider';

type ComposeScreenSectionProps = {
  submitCallback: ComposeAreaSubmitCallback;
  selectedParticipantsListChangedCallback: (selectedParticipants: ParticipantViewModel[]) => void;
  maxNumberOfUserSuggestions?: number;
  maxNumberOfChannelSuggestions?: number;
};

export function ComposeScreenSection({
  submitCallback: submitCallbackFromProps, selectedParticipantsListChangedCallback, maxNumberOfUserSuggestions, maxNumberOfChannelSuggestions
}: ComposeScreenSectionProps) {
  const addParticipantScreenSectionControllerRef = useRef<AddParticipantScreenSectionControllerHandles>(null);

  const submitCallback: ComposeAreaSubmitCallback = useCallback(async ({ body, title }) => {
    addParticipantScreenSectionControllerRef.current?.clear();
    return await submitCallbackFromProps({ body, title });
  }, [submitCallbackFromProps]);

  return (
    <VStack
      flexGrow={1}
      flexShrink={1}
    >
      <Divider />
      <MentionEventsProvider>
        <ParticipantScreenSectionController
          ref={addParticipantScreenSectionControllerRef}
          selectedParticipantsListChangedCallback={selectedParticipantsListChangedCallback}
          type="recipients"
          includeChannels
          includeUsers
          maxNumberOfUserSuggestions={maxNumberOfUserSuggestions}
          maxNumberOfChannelSuggestions={maxNumberOfChannelSuggestions}
          allowExternalUsers
        />
        <Box px={3}>
          <ComposeArea
            bodyPlaceholder="Type a message to create a new thread"
            submitCallback={submitCallback}
            showTitle
            freezeParticipants
            emailRecipients={null}
            draftKey="compose"
          />
        </Box>
      </MentionEventsProvider>
    </VStack>
  );
}
