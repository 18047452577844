import { HStack, Text } from 'native-base';
import { intlFormat } from 'date-fns';
import { forwardRef } from 'react';
import { CustomMenuItem, CustomMenuItemProps } from '../../menu/CustomMenuItem';

export type DateMenuItemProps = {
  setDate: (date: Date | null) => void;
  dateLabel: string;
  date: Date;
  isDisabled?: boolean;
  focused?: boolean;
  onFocusChange?: CustomMenuItemProps['onFocusedChange'];
};

const DateMenuItem = forwardRef(({
  dateLabel, date, setDate, isDisabled, focused, onFocusChange
}: DateMenuItemProps, ref: any) => {
  return (
    <CustomMenuItem
      onFocusedChange={onFocusChange}
      focused={focused}
      onPress={() => setDate(date)}
      ref={ref}
      isDisabled={isDisabled}
    >
      <HStack space={5} justifyContent="space-between" flexGrow={1}>
        <Text>{dateLabel}</Text>
        <Text color="dark.400">
          {intlFormat(date, {
            weekday: 'short',
            hour: 'numeric',
            minute: 'numeric'
          })}
        </Text>
      </HStack>
    </CustomMenuItem>
  );
});

export { DateMenuItem };
