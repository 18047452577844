import { useCallback } from 'react';
import { useAppDispatch } from '../../../state/hooks';
import { ThreadViewModel } from '../../../../adapters/view-models/ThreadViewModel';
import { rootNavigationContainerRef } from '../../../navigation/navigators/root/RootNavigationContainerRef';
import { getCurrentScreenParams, RouteNotInStack } from '../../../navigation/getCurrentScreenParams';
import { AppDispatch, store } from '../../../../domain/state/store';
import { apiClient } from '../../../../adapters/api';

function useThreadsInCurrentChannel() {
  const navgationState = rootNavigationContainerRef.current?.getState();
  const inboxParams = getCurrentScreenParams(navgationState, 'Inbox');
  const state = store.getState();

  if (inboxParams !== RouteNotInStack) {
    return apiClient.endpoints.getSelfInboxEvents.select({ pageToken: inboxParams?.page })(state).data?.inboxItems.map((item) => item.thread);
  }

  const channelParams = getCurrentScreenParams(navgationState, 'Channel');
  if (channelParams !== RouteNotInStack && channelParams?.channelId) {
    return apiClient.endpoints.getChannelThreads.select({ channelId: channelParams?.channelId, pageToken: channelParams?.page })(state).data?.threads;
  }
}

export async function upsertThreadInCache(threads: ThreadViewModel[] | undefined, thread: ThreadViewModel, dispatch: AppDispatch) {
  const foundThread = threads?.find((t) => t.id === thread.id);
  if (foundThread) {
    await dispatch(
      apiClient.util.upsertQueryData('getThread', { threadId: thread.id }, foundThread)
    );
  }
}

export function usePrefetchThreadCallback() {
  const prefetchThreadMessages = apiClient.usePrefetch('getThreadMessages');
  const dispatch = useAppDispatch();
  const threads = useThreadsInCurrentChannel();

  const threadHoveredCallback = useCallback(
    async (thread: ThreadViewModel) => {
      prefetchThreadMessages({ threadId: thread.id });
      await upsertThreadInCache(threads, thread, dispatch);
    },
    [threads, prefetchThreadMessages, dispatch]
  );
  return threadHoveredCallback;
}
