import { RemoveThreadParticipantsApiArg, RemoveThreadParticipantsApiResponse } from '../api/codegen';
import { MutationHandler, MutationHandlerProps } from './MutationHandler';
import { ThreadViewModel, removeThreadViewModelParticipants } from '../view-models/ThreadViewModel';
import { createThreadUpdatePatchesForThreadId } from './thread-patch-factory/createThreadUpdatePatchesForThreadId';

export class RemoveThreadParticipantsMutationHandler extends MutationHandler<RemoveThreadParticipantsApiArg, RemoveThreadParticipantsApiResponse> {
  constructor(props: MutationHandlerProps<RemoveThreadParticipantsApiArg, RemoveThreadParticipantsApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: RemoveThreadParticipantsApiArg) {
    return [
      ...createThreadUpdatePatchesForThreadId(patch.threadId, (draftedThread) => this.updateThread(draftedThread, patch)),
    ];
  }

  private updateThread(draftedThread: ThreadViewModel, patch: RemoveThreadParticipantsApiArg) {
    removeThreadViewModelParticipants(draftedThread, patch.body);
  }

  protected generateInvalidationTags(arg: RemoveThreadParticipantsApiArg) {
    const threadTag = { type: 'Thread' as const, id: arg.threadId };

    return [
      threadTag,
    ];
  }
}
