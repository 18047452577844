import { fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { MutationTrackingServiceInterface } from './MutationTrackingServiceInterface';
import { ApiParamsHelper } from '../ApiParamsHelper';

const RUNNING_BACKEND_APP = !!process.env.IS_OFFLINE || !!process.env.TERM_PROGRAM || !!process.env.RUNNING_BACKEND_LOCALLY;
export const OutdatedErrorName = 'Outdated';

let customFetchBaseQueryArguments: typeof fetchBaseQuery extends (args: infer T) => any ? T : never = {};
let mutationTrackingService: MutationTrackingServiceInterface | undefined;

export const setCustomFetchBaseQueryArguments = (args: typeof customFetchBaseQueryArguments) => {
  customFetchBaseQueryArguments = args;
};

export const setMutationTrackingService = (service: MutationTrackingServiceInterface) => {
  mutationTrackingService = service;
};

type FetchBaseQueryFunction = ReturnType<typeof fetchBaseQuery>;

export const dynamicBaseQuery: FetchBaseQueryFunction = async (args, api, extraOptions) => {
  const customFetchBaseQuery = fetchBaseQuery({
    ...makeSureWarningIsNotRaisedWhenRunningInNodeJs(),
    ...customFetchBaseQueryArguments,
  });

  const canCheckEndpointAvailability = typeof args !== 'string' && mutationTrackingService && api.type === 'query';
  const queryOriginalArgs = canCheckEndpointAvailability ? ApiParamsHelper.buildOriginalArgs(api.endpoint, args) : undefined;

  if (canCheckEndpointAvailability) {
    await mutationTrackingService!.waitForEndpointAvailability(api.endpoint, queryOriginalArgs);
  }

  const queryStartDateTime = new Date();
  const result = await customFetchBaseQuery(args, api, extraOptions);

  if (canCheckEndpointAvailability) {
    if (mutationTrackingService!.isQueryResultOutdated(api.endpoint, queryOriginalArgs, queryStartDateTime)) {
      return {
        error: {
          status: 'CUSTOM_ERROR',
          error: OutdatedErrorName
        }
      };
    }
  }

  return result;
};

function makeSureWarningIsNotRaisedWhenRunningInNodeJs() {
  if (RUNNING_BACKEND_APP) {
    return {
      fetchFn: (() => { throw new Error('fetchFn not defined'); })
    };
  }

  return {};
}

export const dynamicBaseQueryWithRetry = retry(dynamicBaseQuery, {
  retryCondition: (_error, _request, extraArgs) => {
    if (extraArgs.baseQueryApi.type !== 'query') {
      return false;
    }
    return extraArgs.attempt < 2;
  }
});
