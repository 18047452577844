import {
  HStack, VStack,
} from 'native-base';
import { AccountAvatarButton, AccountAvatarButtonProps } from './AccountAvatarButton';
import { AppLogo } from './AppLogo';
import { SearchBar } from './SearchBar';

type AppHeaderProps = AccountAvatarButtonProps & {
  navigateToInbox?: () => void;
};

export function AppHeader(props: AppHeaderProps) {
  return (
    <VStack flexShrink={0} pl={2}>
      <HStack pl={0} space={0} flexGrow={0} flexShrink={1} h="12">
        <AppLogo navigateToInbox={props.navigateToInbox} />
        <SearchBar />
        <AccountAvatarButton isLoadingSelfUser={props.isLoadingSelfUser} user={props.user} logoutCallback={props.logoutCallback} />
      </HStack>
    </VStack>
  );
}
