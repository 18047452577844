import React, { useCallback } from 'react';
import { useUpdateThreadMutation } from '../../../../adapters/api/codegen';
import { SCREEN_SECTION_DEFAULT_QUERY_PARAMETERS, THREAD_POLLING_INTERVAL } from '../../../api/query-parameters';
import { ComprehensiveThreadViewModel } from '../../../../adapters/view-models/ThreadViewModel';
import { ComposeArea } from '../../compose';

export const THREAD_QUERY_OPTIONS = { ...SCREEN_SECTION_DEFAULT_QUERY_PARAMETERS, pollingInterval: THREAD_POLLING_INTERVAL };

type ThreadScreenHeaderTitleProps = {
  thread?: ComprehensiveThreadViewModel;
  showLoader: boolean;
};

export function ThreadScreenHeaderTitle(props: ThreadScreenHeaderTitleProps) {
  const [updateThread, { isLoading: isUpdatingThread }] = useUpdateThreadMutation();

  const updateThreadTitle = useCallback(
    (title: string) => {
      const actualTitle = title.length > 0 ? title : null;

      if (actualTitle === props.thread?.title) {
        return;
      }

      void updateThread({
        threadId: props.thread!.id,
        threadUpdateRequestBody: {
          title: actualTitle,
        }
      });
    },
    [updateThread, props.thread]
  );

  if (props.showLoader) {
    return null;
    // return <Skeleton.Text lines={1} h={3} width="230px" />;
  }

  if (!props.thread) {
    return null;
  }

  const defaultTitle = props.thread?.title ?? undefined;

  // compose area?
  return (
    <ComposeArea
      onTitleBlur={updateThreadTitle}
      px={0}
      titlePlaceholder="Untitled thread"
      showTitle
      showBody={false}
      defaultTitle={defaultTitle}
      titleHasHeaderStyle
      hideToolbarAndOutlineWhenUnfocused
      readOnly={isUpdatingThread || props.thread.isTrashed}
      fontFamily="heading"
      flexGrow={1}
      emailRecipients={null}
    />
  );
}
