import { CacheTagDescription } from './cache-tag-types';

export abstract class CacheTagProvider<TArg, TResponse> {
  public provideTags(arg: TArg, result?: TResponse): CacheTagDescription[] {
    if (!result) {
      return [];
    }

    return this.generateTags(arg, result);
  }

  protected abstract generateTags(arg: TArg, result: TResponse): CacheTagDescription[];
}
