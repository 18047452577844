import { ConversationParticipant, User } from '../api/codegen';
import { getConversationParticipantDisplayName, getConversationParticipantDisplayNameForInitials } from './utilities/getConversationParticipantDisplayName';

export type PictureViewModel = {
  url: string | null;
  initials: string;
  name: string;
  backgroundColor?: string;
  userId: string | null;
  id: string;
  canBeRemoved: boolean;
  _type: 'picture';
};

export function createPictureViewModelFromParticipant(participant: ConversationParticipant): PictureViewModel {
  const displayNameForInitial = getConversationParticipantDisplayNameForInitials(participant);
  const displayName = getConversationParticipantDisplayName(participant);
  return {
    url: participant.user?.picture_url ?? null,
    initials: getNameInitials(displayNameForInitial),
    backgroundColor: generateUniqueColorBasedOnInitials(displayName),
    name: displayName,
    userId: participant.user?.id ?? null,
    id: participant.user?.id ?? participant.email_participant?.email!,
    canBeRemoved: !!participant.user,
    _type: 'picture',
  };
}

export function createPictureViewModelFromUser(user: User): PictureViewModel {
  return {
    url: user.picture_url ?? null,
    initials: getNameInitials(user.display_name),
    backgroundColor: generateUniqueColorBasedOnInitials(user.display_name),
    name: user.display_name,
    userId: user.id,
    id: user.id,
    canBeRemoved: true,
    _type: 'picture',
  };
}

function generateUniqueColorBasedOnInitials(initials: string) {
  const colors = ['#FFB74D', '#FF8A65', '#81C784', '#4DD0E1', '#7986CB', '#BA68C8', '#FF80AB', '#A1887F', '#90A4AE'];

  const hash = initials.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
  const index = hash % colors.length;

  return colors[index];
}
function getNameInitials(name: string) {
  const nameParts = name.split(' ');
  const firstInitial = nameParts[0].charAt(0) ?? '';
  const secondInitial = nameParts[1] ? nameParts[1].charAt(0) : '';

  return (firstInitial + secondInitial).toUpperCase();
}
