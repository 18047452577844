import { Attachment } from '../api/codegen';

export type AttachmentViewModel = {
  name: string;
  download_url: string;
  size: string;
  iconName: string;
  uniqueKey: string;
};

export function createAttachmentViewModel(attachment: Attachment, index: number): AttachmentViewModel {
  return {
    name: attachment.name,
    download_url: attachment.download_url,
    size: getDisplayableSizeFromNumberOfBytes(attachment.size),
    iconName: determineIconNameForMimeType(attachment.mime_type),
    uniqueKey: `${attachment.download_url}-${index}`,
  };
}

function getDisplayableSizeFromNumberOfBytes(numberOfBytes: number): string {
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;
  const gigabyte = megabyte * 1024;

  if (numberOfBytes < kilobyte) {
    return `${numberOfBytes} B`;
  }

  if (numberOfBytes < megabyte) {
    return `${(numberOfBytes / kilobyte).toFixed(2)} KB`;
  }

  if (numberOfBytes < gigabyte) {
    return `${(numberOfBytes / megabyte).toFixed(2)} MB`;
  }

  return `${(numberOfBytes / gigabyte).toFixed(2)} GB`;
}

function determineIconNameForMimeType(mimeType: string): string {
  if (mimeType.startsWith('image/')) {
    return 'image';
  }

  switch (mimeType) {
    case 'application/pdf':
      return 'file-pdf-box';
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'file-word-box';
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'file-excel-box';
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'file-powerpoint-box';
    default:
      return 'file';
  }
}
