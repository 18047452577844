import { Pressable } from 'native-base';
import { forwardRef } from 'react';

function PressableListElement(props: Parameters<typeof Pressable>[0], ref: any) {
  return (
    <Pressable
      ref={ref}
      variant="list-item"
      mx={2}
      borderRadius={10}
      flexGrow={1}
      flexDirection="row"
      _pressed={{ bgColor: 'light.200' }}
      {...props}
    >
      {props.children}
    </Pressable>
  );
}

const PressableListElementRef = forwardRef(PressableListElement);

export { PressableListElementRef as PressableListElement };
