import { useHotkeys } from 'react-hotkeys-hook';
import { useEffect, useState } from 'react';
import {
  cancelLastMutation
} from '@/adapters/mutation-cancellation/mutationCancellation';

export function useUndoHotkey() {
  const [hotkeyPressed, setHotkeyPressed] = useState(false);

  useEffect(() => {
    if (hotkeyPressed) {
      setHotkeyPressed(false);
      void cancelLastMutation();
    }
  }, [hotkeyPressed]);

  useHotkeys(
    ['mod+z', 'z'],
    () => {
      setHotkeyPressed(true);
    },
    [],
    {
      enabled: true,
      enableOnFormTags: false,
      enableOnContentEditable: false
    }
  );
}
