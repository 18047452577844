import { Button, Divider } from 'native-base';
import { ComponentProps } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

type ThreadTextActionButtonProps = ComponentProps<typeof Button> & {
  shortcut?: string;
  onPress: () => void;
};

export function ThreadTextActionButton({ children, onPress, shortcut }: ThreadTextActionButtonProps) {
  useHotkeys(shortcut || '', onPress, { enabled: Boolean(shortcut) });

  return (
    <>
      <Button
        size="md"
        variant="light-text"
        _text={{ color: 'dark.700' }}
        onPress={onPress}
      >
        {children}
      </Button>
      <Divider orientation="vertical" h="50%" ml={2} mr={1.5} backgroundColor="dark.100" />
    </>
  );
}
