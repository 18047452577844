import {
  AddThreadChannelsApiArg, AddThreadChannelsApiResponse, RemoveThreadChannelsApiArg, RemoveThreadChannelsApiResponse
} from '../api/codegen';
import { MutationHandler, MutationHandlerProps } from './MutationHandler';
import { ThreadViewModel } from '../view-models/ThreadViewModel';
import { ThreadsViewModel } from '../view-models/ThreadsViewModel';
import { getCurrentChannelParams } from './utils/getCurrentChannelParams';
import { getSearchParams } from './utils/getSearchParams';
import { getInboxParams } from './utils/getInboxParams';
import { getSentParams } from './utils/getSentParams';

type Arg = RemoveThreadChannelsApiArg | AddThreadChannelsApiArg;
type Response = RemoveThreadChannelsApiResponse | AddThreadChannelsApiResponse;

export class ChangeThreadChannelsMutationHandler extends MutationHandler<Arg, Response> {
  constructor(props: MutationHandlerProps<Arg, Response>, private readonly type: 'add' | 'remove') {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: Arg) {
    return [
      ...this.createThreadsListPatch(patch),
      this.createThreadPatch(patch),
      this.createInboxPatch(patch),
      this.createSentFolderPatch(patch),
      this.createSelfFilteredThreadsPatch(patch),
    ];
  }

  private* createThreadsListPatch(patch: Arg) {
    yield this.updateQueryData('getChannelThreads', getCurrentChannelParams()!, (draftedThreads) => {
      this.updateThreads(draftedThreads, patch);
    });
    for (const channel of this.getAllAccessibleChannels()) {
      yield this.updateQueryData('getChannelThreads', { channelId: channel.id }, (draftedThreads) => {
        this.updateThreads(draftedThreads, patch);
      });
    }
  }

  private createSelfFilteredThreadsPatch(patch: Arg) {
    return this.updateQueryData('getSelfFilteredThreads', getSearchParams()!, (draftedThreads) => {
      this.updateThreads(draftedThreads, patch);
    });
  }

  private createInboxPatch(patch: Arg) {
    return this.updateQueryData('getSelfInboxEvents', getInboxParams()!, (draftedEvents) => {
      const threads = draftedEvents.inboxItems.map((item) => item.thread);
      threads.forEach((thread) => {
        if (thread.id === patch.threadId) {
          this.updateThread(thread, patch);
        }
      });
    });
  }

  private createSentFolderPatch(patch: Arg) {
    return this.updateQueryData('getSelfSentThreads', getSentParams()!, (draftedThreads) => {
      this.updateThreads(draftedThreads, patch);
    });
  }

  private updateThreads(draftedThreads: ThreadsViewModel, patch: Arg) {
    const thread = draftedThreads.threads.find((thread) => thread.id === patch.threadId);
    if (thread) {
      this.updateThread(thread, patch);
    }
  }

  private createThreadPatch(patch: Arg) {
    return this.updateQueryData('getThread', { threadId: patch.threadId }, (draftedThread) => {
      this.updateThread(draftedThread, patch);
    });
  }

  private updateThread(draftedThread: ThreadViewModel, patch: Arg) {
    draftedThread.channelIds = this.getUpdatedParticipants(draftedThread.channelIds, patch);
  }

  private getUpdatedParticipants(channels: ThreadViewModel['channelIds'], patch: Arg) {
    if (this.type === 'remove') {
      return this.buildListWithRemovedParticipants(channels, patch);
    }

    return this.buildListWithNewParticipants(patch, channels);
  }

  private buildListWithRemovedParticipants(channelIds: string[], patch: Arg) {
    return channelIds.filter((theChannelId) => !patch.body.some((channelId) => channelId === theChannelId));
  }

  private buildListWithNewParticipants(patch: Arg, channelIds: string[]) {
    const newChannelIds = patch.body.filter((channelId) => !channelIds.some((theChannelId) => theChannelId === channelId));

    return [
      ...channelIds,
      ...newChannelIds,
    ];
  }

  protected generateInvalidationTags(arg: Arg) {
    const threadTag = {
      type: 'Thread' as const,
      id: arg.threadId,
      schedule: {
        delayMs: 20000,
        uniqueKey: `ChangeThreadChannels${arg.threadId}}`,
      }
    };

    return [
      threadTag,
    ];
  }
}
