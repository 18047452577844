import React, { useCallback } from 'react';
import { formatDistance } from 'date-fns';
import { ComprehensiveThreadOrInboxItemViewModel, isInboxItemViewModel } from '../../../../../adapters/view-models/InboxItemViewModel';
import { SnoozeHintText } from '../../../thread-list-items/blocks/SnoozeHintText';

export function useThreadCustomDateHint() {
  return useCallback((item: ComprehensiveThreadOrInboxItemViewModel) => {
    if (isInboxItemViewModel(item) && item.snoozeStartDate) {
      const distance = getSnoozeStartDateDistance(item.snoozeStartDate);
      return (
        <SnoozeHintText>
          {`Snoozed ${distance} ago`}
        </SnoozeHintText>
      );
    }
  }, []);
}
function getSnoozeStartDateDistance(rawDate: string) {
  const snoozeStartDate = new Date(rawDate);
  const currentDate = new Date();

  const distance = formatDistance(snoozeStartDate, currentDate, {
    includeSeconds: false,
  });

  return distance
    .replace('about ', '')
    .replace('minutes', 'min')
    .replace('minute', 'min');
}
