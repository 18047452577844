import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { MutationHandler } from './MutationHandler';
import { UpdateChannelNotificationLevelApiArg, UpdateChannelNotificationLevelApiResponse } from '../api/codegen';
import { ChannelsViewModel } from '../view-models/ChannelsViewModel';
import { ChannelViewModel } from '../view-models/ChannelViewModel';

export class UpdateChannelNotificationLevelMutationHandler extends MutationHandler<UpdateChannelNotificationLevelApiArg, UpdateChannelNotificationLevelApiResponse> {
  protected createOptimisticUpdatePatchWrappers(patch: UpdateChannelNotificationLevelApiArg) {
    return [
      this.createGetChannelPatch(patch),
      this.createSelfChannelsPatch(patch),
      this.createOrganizationChannelsPatch(patch)
    ];
  }

  private createGetChannelPatch(patch: UpdateChannelNotificationLevelApiArg) {
    return this.updateQueryData('getChannel', { channelId: patch.channelId }, (draft) => {
      this.updateChannelNotificationLevel(draft, patch);
    });
  }

  private createSelfChannelsPatch(patch: UpdateChannelNotificationLevelApiArg) {
    return this.updateQueryData('getSelfChannels', undefined, (draft) => {
      this.updateChannelNotificationLevelInChannelsList(draft, patch);
    });
  }

  private createOrganizationChannelsPatch(patch: UpdateChannelNotificationLevelApiArg) {
    return this.updateQueryData('getOrganizationChannels', { organizationId: this.getSelfOrganization()?.id! }, (draft) => {
      this.updateChannelNotificationLevelInChannelsList(draft, patch);
    });
  }

  private updateChannelNotificationLevelInChannelsList(draft: MaybeDrafted<ChannelsViewModel>, patch: UpdateChannelNotificationLevelApiArg) {
    const channel = draft.channels.find((c) => c.id === patch.channelId);
    if (channel) {
      this.updateChannelNotificationLevel(channel, patch);
    }
  }

  private updateChannelNotificationLevel(channel: ChannelViewModel, patch: UpdateChannelNotificationLevelApiArg) {
    channel.notificationLevel = patch.channelNotificationLevelUpdate.notification_level;
  }

  protected generateInvalidationTags(arg: UpdateChannelNotificationLevelApiArg) {
    return [
      { type: 'SelfChannels' as const },
      { type: 'OrganizationChannels' as const },
      { type: 'Channel' as const, id: arg.channelId }
    ];
  }
}
