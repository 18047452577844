import {
  ComponentProps, forwardRef, Ref, useImperativeHandle, useLayoutEffect, useRef, useState,
} from 'react';
import { EmojiPicker } from './EmojiPicker';
import { KEYS } from '../rich-text-editor/constants';
import { useEffectEvent } from '../../hooks/useEffectEvent';
import { rafGenerator } from '../../global/rafGenerator';

const injectCSS = `
#nav, #nav + div {
  display: none;
}
.category button .background {
  transition: none;
}
.category {
  padding-bottom: 12px;
}
`;

export interface EmojiSearchHandle {
  selectActiveEmoji: () => void;
  handleArrowKey: (key: KEYS) => void;
}

interface Props extends ComponentProps<typeof EmojiPicker> {
  query: string;
}

function EmojiSearchRef({ query, ...props }: Props, handleRef: Ref<EmojiSearchHandle>) {
  const ref = useRef<HTMLDivElement>(null);
  const emShadowRoot = usePollWaitFor<ShadowRoot>(
    (poll) => poll(() => ref.current?.querySelector('em-emoji-picker')?.shadowRoot)
  );
  useInjectStyle(emShadowRoot);

  const inputElement = usePollWaitFor<HTMLInputElement>((poll) => {
    if (!emShadowRoot) return;
    return poll(() => emShadowRoot.querySelector('input'));
  }, [emShadowRoot]);
  useEmulateTyping(inputElement, query);

  useImperativeHandle(handleRef, () => ({
    selectActiveEmoji: () => {
      const activeEmoji = emShadowRoot?.querySelector('button[aria-selected="true"]');
      activeEmoji?.dispatchEvent(new MouseEvent('click', { bubbles: true }));
    },
    handleArrowKey: (arrowKey: KEYS) => {
      const activeEmoji = emShadowRoot?.querySelector('button[aria-selected="true"]');
      const nextEmoji = getNextEmoji(arrowKey, activeEmoji);
      if (isHTMLButton(nextEmoji)) {
        nextEmoji.dispatchEvent(new MouseEvent('mouseenter', { bubbles: true }));
        scrollTo(nextEmoji);
      }
    },
  }), [emShadowRoot]);

  return (
    <div ref={ref}>
      <EmojiPicker {...props} />
    </div>
  );
}

function useInjectStyle(emShadowRoot: ShadowRoot | null) {
  useLayoutEffect(() => {
    if (!emShadowRoot) {
      return;
    }
    const attr = 'data-upstream-search-style';
    const existingStyle = emShadowRoot.querySelector(`style[${attr}]`);
    const style = existingStyle || document.createElement('style');
    style.setAttribute(attr, '');
    style.innerHTML = injectCSS;
    emShadowRoot.appendChild(style);
    return () => {
      style.remove();
    };
  }, [emShadowRoot]);
}

type PollFn<T> = (lookupFn: () => T | null | undefined) => Promise<void>;

function usePollWaitFor<T>(
  cb: (poll: PollFn<T>) => Promise<void> | T | null | undefined,
  deps: unknown[] = [],
): T | null {
  const [found, setFound] = useState<T | null>(null);
  const onLookup = useEffectEvent(cb);
  useLayoutEffect(() => {
    let canceled = false;
    const poll: PollFn<T> = async (lookupFn) => {
      for await (const _ of rafGenerator()) { // mutationObserver(ref.current!)
        if (canceled) {
          break;
        }
        const lookupResult = lookupFn();
        if (lookupResult) {
          setFound(lookupResult);
          break;
        }
      }
    };
    const result = onLookup(poll);
    if (result instanceof Promise) {
      result.catch(console.error);
    } else if (result) {
      setFound(result);
    }
    return () => {
      canceled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  return found;
}

function useEmulateTyping(inputElement: HTMLInputElement | null, query: string) {
  useLayoutEffect(() => {
    if (inputElement) {
      inputElement.value = query;
      inputElement.dispatchEvent(new Event('input', { bubbles: true }));
    }
  }, [inputElement, query]);
}

function isHTMLButton(element?: Element | null): element is HTMLButtonElement {
  return element?.tagName === 'BUTTON';
}

function getNextEmoji(arrowKey: KEYS, activeEmoji?: Element | null): Element | null | undefined {
  if (!activeEmoji) return;
  if (arrowKey === KEYS.LEFT) {
    if (activeEmoji.previousElementSibling) {
      return activeEmoji.previousElementSibling;
    }
    return activeEmoji.parentElement?.previousElementSibling?.lastElementChild;
  }
  if (arrowKey === KEYS.RIGHT) {
    if (activeEmoji.nextElementSibling) {
      return activeEmoji.nextElementSibling;
    }
    return activeEmoji.parentElement?.nextElementSibling?.firstElementChild;
  }
  if (arrowKey === KEYS.UP) {
    const row = activeEmoji.parentElement;
    if (!row) return;
    const index = Array.from(row.children).indexOf(activeEmoji);
    const prevRow = row.previousElementSibling;
    return prevRow?.children[index];
  }
  if (arrowKey === KEYS.DOWN) {
    const row = activeEmoji.parentElement;
    if (!row) return;
    const index = Array.from(row.children).indexOf(activeEmoji);
    const nextRow = row.nextElementSibling;
    return nextRow?.children[index] || nextRow?.lastElementChild;
  }
}

function scrollTo(nextEmoji: HTMLButtonElement) {
  const scrollContainer = nextEmoji.closest('div.scroll') as HTMLDivElement | null;
  const categoryStickyHeadingHeight = 30;
  const scrollPadding = 10;
  const scrollPaddingTop = scrollPadding + categoryStickyHeadingHeight;
  if (scrollContainer) {
    const elementRect = nextEmoji.getBoundingClientRect();
    const containerRect = scrollContainer.getBoundingClientRect();
    const relativeTop = elementRect.top - scrollPaddingTop - containerRect.top;
    const relativeBottom = containerRect.bottom - scrollPadding - elementRect.bottom;
    if (relativeTop < 0) {
      scrollContainer.scrollTop += relativeTop;
    } else if (relativeBottom < 0) {
      scrollContainer.scrollTop -= relativeBottom;
    }
  }
}

export const EmojiSearch = forwardRef(EmojiSearchRef);
