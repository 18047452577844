import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { RootStackParamList } from './navigators/root/RootStackProps';

export const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      SignIn: 'signin',
      SignInWithEmailAccess: 'signin-with-email-access',
      SignInConfirmation: 'signin_confirmation',
      PrivacyPolicy: 'privacy_policy',
      AdminConsoleBackupManagement: 'admin/demo_backup_management',
      App: {
        screens: {
          Loading: 'app',
          Inbox: 'inbox/:category?',
          Sent: 'sent',
          Drafts: 'drafts',
          Spam: 'spam',
          Starred: 'starred',
          Snoozed: 'snoozed',
          Trash: 'trash',
          Thread: 'threads/:threadId',
          Channel: 'channels/:channelId',
          Settings: 'settings',
          Compose: 'compose',
          Search: 'search',
          AllMessages: 'all',
        },
      },
    },
  },
};
