import { Box, Icon, Text } from 'native-base';
import {
  ComponentPropsWithoutRef, useCallback, useEffect, useState
} from 'react';
import { useDropzone } from 'react-dropzone';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { ComposeAreaSubmitCallback } from './compose';

type BoxProps = ComponentPropsWithoutRef<typeof Box>;

interface DropZoneProps extends BoxProps {
  dropCallback?: ComposeAreaSubmitCallback;
  errorCallback?: () => void;
  submitItem: 'message' | 'thread';
}

export function DropZone({
  dropCallback, errorCallback, submitItem, ...props
}: DropZoneProps) {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        const htmlBody = `<img src="${reader.result}" alt="Uploaded Image" />`;
        void dropCallback?.({ body: htmlBody, title: 'Image' });
      };

      reader.onerror = (_event) => {
        errorCallback?.();
      };

      reader.readAsDataURL(file);
    });
  }, [dropCallback, errorCallback]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/gif': ['.gif'],
      'image/bmp': ['.bmp'],
    },
    noClick: true,
  });

  const [dropZoneEnabled, setDropZoneEnabled] = useState(false);

  useEffect(() => {
    const handleDragOver = (e: Event) => {
      e.preventDefault();
      setDropZoneEnabled(true);
    };

    const handleDrop = (e: Event) => {
      e.preventDefault();
      setDropZoneEnabled(false);
    };

    const handleDragLeave = (_event: Event) => {
      setDropZoneEnabled(false);
    };

    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('drop', handleDrop);
    window.addEventListener('dragleave', handleDragLeave);

    return () => {
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('drop', handleDrop);
      window.removeEventListener('dragleave', handleDragLeave);
    };
  }, []);

  const overlayBackgroundColor = 'rgba(255,255,255,0.9)';
  const overlayBorderColor = isDragActive ? 'gray.700' : 'gray.400';
  const textColor = isDragActive ? 'black' : 'gray.400';
  const isDropZoneEnabled = dropZoneEnabled && dropCallback !== undefined;

  return (
    <Box {...props}>
      <div
        {...getRootProps(
          {
            style: {
              position: 'absolute',
              zIndex: 2,
              width: '100%',
              height: '100%',
              pointerEvents: isDropZoneEnabled ? 'all' : 'none',
            },
          }
        )}
      >
        <input {...getInputProps()} style={{ display: 'none', visibility: 'hidden' }} disabled />
        <Box zIndex={1} width="100%" height="100%" display={isDropZoneEnabled ? 'flex' : 'none'} alignItems="center" justifyContent="center" bgColor={overlayBackgroundColor} pointerEvents="none" borderWidth={3} borderColor={overlayBorderColor} borderStyle="dashed" borderRadius={3}>
          <Icon color={textColor} name="cloud-upload-outline" size="3xl" as={MaterialCommunityIcons} />
          <Text color={textColor} fontSize="xl">
            Drop your image here to send it as a new
            {' '}
            {submitItem}
          </Text>
        </Box>
      </div>
      {props.children}
    </Box>
  );
}
