import {
  Box, IconButton, IIconButtonProps, IIconProps, Tooltip,
} from 'native-base';
import { getTooltipLabelWithShortcut } from '../shortcuts/getTooltipLabelWithShortcut';
import {
  closeCommandMenu, CommandItem, ProvideCommands, ProvidedCommandIds
} from '../CommandMenu';

export type ItemActionButtonProps = IIconButtonProps & {
  iconCollection?: any;
  iconName: string;
  iconColor?: IIconProps['color'];
  onPress?: () => void;
  triggerProps?: any;
  hint: string;
  smaller?: boolean;
  hasDelay?: boolean;
  shortcut?: string;
  provideCommandId?: ProvidedCommandIds;
};

export function ThreadListActionButton(props: ItemActionButtonProps) {
  const { hasDelay = false, shortcut } = props;

  const onPress = () => {
    if (props.triggerProps) {
      props.triggerProps.ref.current?.blur();
      props.triggerProps.onPress();
    }
    props.onPress?.();
  };

  const defaultIconColor = props.disabled ? 'opacityWhite.300' : 'white';
  return (
    <>
      {props.provideCommandId && (
        <ProvideCommands id={props.provideCommandId}>
          <CommandItem
            icon={{ name: props.iconName, collection: props.iconCollection }}
            shortcut={shortcut}
            onSelect={() => {
              closeCommandMenu();
              onPress();
            }}
          >
            {props.hint}
          </CommandItem>
        </ProvideCommands>
      )}
      <Tooltip
        label={getTooltipLabelWithShortcut(props.hint, shortcut)}
        openDelay={hasDelay ? 200 : 0}
        placement="top"
      >
        <Box>
          <IconButton
            variant="primary"
            p={1}
            size="md"
            _icon={{
              as: props.iconCollection,
              name: props.iconName,
              color: props.iconColor || defaultIconColor,
            }}
            {...props}
            {...props.triggerProps}
            onPress={onPress}
            borderRadius="sm"
            _hover={{
              bgColor: 'opacityWhite.100',
            }}
          />
        </Box>
      </Tooltip>
    </>
  );
}
