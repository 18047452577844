import { useNavigationState } from '@react-navigation/native';
import { useMemo } from 'react';
import { RootStackParamList } from '../../../navigation/navigators/root/RootStackProps';
import { useGetChannel } from '../api/useGetChannel';
import { getCurrentScreenParams, RouteParams } from '../../../navigation/getCurrentScreenParams';

// can be called outside a channel route, hence, channelId might be undefined (i.e. for the inbox)
export function useCurrentChannel() {
  const navigationState = useNavigationState<RootStackParamList, RouteParams<'Channel'> | null>((state) => getCurrentScreenParams(state, 'Channel') ?? null);

  const channelId = navigationState?.channelId;

  const channel = useGetChannel(channelId);

  return useMemo(() => {
    const { currentData, ...rest } = channel;
    return ({ ...rest, channel: currentData, channelId });
  }, [channel, channelId]);
}
