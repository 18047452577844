import { Command } from 'cmdk';
import { useHotkeys } from 'react-hotkeys-hook';
import React from 'react';
import './CommandMenu.css';
import { useShallow } from 'zustand/react/shallow';
import { CommandListScrollArea } from './CommandListScrollArea';
import { useCommandStore } from './store';
import { GoToChannelCommands, GoToFolderCommands } from './GoToCommands';
import { ProvidedCommandsGroup } from './ProvideCommands';

export const CommandMenu = () => {
  const [open, setOpen] = useCommandStore(useShallow((state) => ([state.open, state.setOpen])));
  useHotkeys('mod+k', () => setOpen(!open), { enableOnFormTags: true });
  return (
    <Command.Dialog
      open={open}
      onOpenChange={setOpen}
      label="Command Menu"
      onKeyDown={(e) => {
        e.stopPropagation();
        if (e.key === 'Escape') setOpen(false);
        if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
          e.preventDefault();
          setOpen(!open);
        }
      }}
    >
      <Command.Input placeholder="Type a command or search" />
      <Command.List asChild>
        <CommandListScrollArea>
          {/* eslint-disable-next-line react/no-unknown-property */}
          <div cmdk-commands="">
            <ProvidedCommandsGroup
              heading="Actions"
              ids={[
                'remove-from-inbox',
                'move-to-inbox',
                'mark-as-read',
                'mark-as-unread',
                'add-star',
                'remove-star',
                'add-channels',
                'add-participants',
                'mark-as-spam',
                'snooze',
                'shortcuts-modal',
              ]}
            />
            <GoToFolderCommands />
            <GoToChannelCommands />
            <Command.Empty>No results found.</Command.Empty>
          </div>
        </CommandListScrollArea>
      </Command.List>
    </Command.Dialog>
  );
};

// const NotImplementedCommands = () => {
//   const setOpen = useCommandStore((state) => state.setOpen);
//   const handleSelect = () => {
//     setOpen(false);
//     Toast.show({ title: 'Not implemented yet', placement: 'top' });
//   };
//   return (
//     <Command.Group heading="Not implemented">
//       <CommandItem onSelect={handleSelect}>Mark done / Mark not done</CommandItem>
//       <CommandItem onSelect={handleSelect} shortcut="b">Snooze</CommandItem>
//       <CommandItem onSelect={handleSelect} shortcut="!">Mark spam</CommandItem>
//       <CommandItem onSelect={handleSelect}>Mark not Spam</CommandItem>
//       <CommandItem onSelect={handleSelect}>Mark read / Mark unread</CommandItem>
//       <CommandItem onSelect={handleSelect}>Follow / Unfollow</CommandItem>
//       <CommandItem onSelect={handleSelect}>Reply all</CommandItem>
//       <CommandItem onSelect={handleSelect}>Reply</CommandItem>
//       <CommandItem onSelect={handleSelect}>Compose</CommandItem>
//       <CommandItem onSelect={handleSelect}>Search</CommandItem>
//       <CommandItem onSelect={handleSelect}>Add channel</CommandItem>
//     </Command.Group>
//   );
// };
