import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import {
  SnoozeThreadApiArg,
  SnoozeThreadApiResponse
} from '../api/codegen';
import { MutationHandler, MutationHandlerProps } from './MutationHandler';
import { ThreadViewModel, setThreadViewModelSnoozeDate } from '../view-models/ThreadViewModel';
import { createThreadUpdatePatchesForThreadId } from './thread-patch-factory/createThreadUpdatePatchesForThreadId';
import { ThreadsViewModel } from '../view-models/ThreadsViewModel';
import { getCurrentChannelParams } from './utils/getCurrentChannelParams';
import { getInboxParams } from './utils/getInboxParams';

export class SnoozeThreadMutationHandler extends MutationHandler<SnoozeThreadApiArg, SnoozeThreadApiResponse> {
  constructor(props: MutationHandlerProps<SnoozeThreadApiArg, SnoozeThreadApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: SnoozeThreadApiArg) {
    const updateThread = (thread: ThreadViewModel) => {
      this.updateThread(thread, patch);
    };

    return [
      this.createRemoveThreadFromInboxPatch(patch),
      this.createAddThreadToSnoozedFolderPatch(patch),
      ...createThreadUpdatePatchesForThreadId(patch.threadId, updateThread),
    ];
  }

  private createRemoveThreadFromInboxPatch(patch: SnoozeThreadApiArg) {
    return this.updateQueryData('getSelfInboxEvents', getInboxParams()!, (draftedInboxItems) => {
      const itemIndex = draftedInboxItems.inboxItems.findIndex((item) => item.thread.id === patch.threadId);

      if (itemIndex !== -1) {
        draftedInboxItems.inboxItems.splice(itemIndex, 1);
      }
    });
  }

  private createAddThreadToSnoozedFolderPatch(patch: SnoozeThreadApiArg) {
    const thread = this.getThreadInCurrentContext(patch.threadId);

    return this.updateQueryData('getSelfSnoozedThreads', {}, (draftedThreads) => {
      if (thread) {
        this.updateThread(thread, patch);
        removeExistingThread(draftedThreads);
        const actualInsertIndex = findIndexToInsertToThread(draftedThreads);

        draftedThreads.threads.splice(actualInsertIndex, 0, {
          ...thread,
          rawSnoozeEndDate: patch.snoozeThreadRequestBody.snooze_end_date,
        });

        if (draftedThreads.threads.length > 25) {
          draftedThreads.threads.pop();
        }
      }
    });

    function findIndexToInsertToThread(draftedThreads: MaybeDrafted<ThreadsViewModel>) {
      const snoozeEndDates = draftedThreads.threads
        .map((item) => item.rawSnoozeEndDate)
        .map((item) => new Date(item!));

      const newSnoozeEndDate = new Date(patch.snoozeThreadRequestBody.snooze_end_date * 1000);
      const insertIndex = snoozeEndDates.findIndex((date) => date.getTime() > newSnoozeEndDate.getTime());
      const actualInsertIndex = insertIndex === -1 ? draftedThreads.threads.length : insertIndex;
      return actualInsertIndex;
    }

    function removeExistingThread(draftedThreads: MaybeDrafted<ThreadsViewModel>) {
      const existingThreadIndex = draftedThreads.threads.findIndex((item) => item.id === patch.threadId);
      if (existingThreadIndex !== -1) {
        draftedThreads.threads.splice(existingThreadIndex, 1);
      }
    }
  }

  private updateThread(thread: ThreadViewModel, patch: SnoozeThreadApiArg) {
    setThreadViewModelSnoozeDate(thread, patch.snoozeThreadRequestBody.snooze_end_date * 1000);
  }

  protected generateInvalidationTags(arg: SnoozeThreadApiArg) {
    return [
      {
        type: 'Thread' as const,
        id: arg.threadId,
        schedule: {
          delayMs: 10000,
          uniqueKey: `ChannelThreadListManagement-${getCurrentChannelParams()}`,
        }
      },
      {
        type: 'SelfAccount' as const,
        schedule: {
          delayMs: 5000,
          uniqueKey: 'InboxUnreadThreadCount',
        }
      },
      {
        type: 'SelfSnoozedThreadsList' as const,
      }
    ];
  }
}
