import { MaterialIcons } from '@expo/vector-icons';
import { IconButton, Tooltip } from 'native-base';
import { ParticipantViewModel } from '../../../../adapters/view-models/ParticipantViewModel';
import { assertNever } from '../../../assertNever';

type SendButtonProps = {
  enabled: boolean;
  submit: () => void;
  emailRecipients?: ParticipantViewModel[] | null;
};

const labelForParticipantViewModel = (participant: ParticipantViewModel): string => {
  switch (participant._type) {
    case 'picture':
      return participant.name;
    case 'user':
      return participant.displayName;
    case 'channel':
      return participant.name;
    case 'external_user':
      return participant.email;
    default:
      return assertNever(participant);
  }
};

export function SendButton(props: SendButtonProps) {
  const variant = props.enabled ? 'solid' : 'ghost';

  const bgColor = props.enabled ? 'tertiary.600' : null;
  const bgColorHover = props.enabled ? 'tertiary.700' : null;
  const iconColor = props.enabled ? 'white' : 'dark.100';
  const iconColorHover = 'dark.200';

  const label = props.emailRecipients ? `Will be sent by email to ${props.emailRecipients?.map(labelForParticipantViewModel).join(', ')}` : 'Will not be sent by email.';

  return (
    <Tooltip openDelay={160} label={label} hasArrow placement="top" mr={3} isDisabled={!props.emailRecipients}>
      <IconButton
        disabled={!props.enabled}
        variant={variant}
        bgColor={bgColor}
        onPress={props.submit}
        p={2}
        _icon={{
          size: 5,
          as: MaterialIcons,
          name: 'send',
          color: iconColor,
        }}
        _hover={{
          bgColor: bgColorHover,
          _icon: {
            color: iconColorHover,
          },
        }}
      />
    </Tooltip>
  );
}
