import { IconButton, useToken } from 'native-base';
import { HeaderBackButtonProps } from '@react-navigation/elements';
import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Stack } from '../Stack';

type CustomStackNavigatorProps = {
  children: React.ReactNode;
};

export const CustomStackNavigator = function (props: CustomStackNavigatorProps) {
  const navigation = useNavigation();

  const headerLeft = useCallback((props: HeaderBackButtonProps) => (props.canGoBack ? <IconButton ml={3} variant="primary" _icon={{ name: 'arrow-back' }} onPress={() => navigation.goBack()} /> : null), [navigation]);

  const headerBg = useToken('colors', 'opacityPrimary.15');

  return (
    <Stack.Navigator
      screenOptions={{
        title: 'Upstream',
        headerStyle: { backgroundColor: headerBg },
        headerShadowVisible: false,
        headerLeft,
      }}
    >
      {props.children}
    </Stack.Navigator>
  );
};
