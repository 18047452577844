import { RemoveMessageReactionApiArg, RemoveMessageReactionApiResponse } from '../api/codegen';
import { MutationHandler, MutationHandlerProps } from './MutationHandler';

type ApiArg = RemoveMessageReactionApiArg;
type ApiResponse = RemoveMessageReactionApiResponse;

export class RemoveMessageReactionMutationHandler extends MutationHandler<ApiArg, ApiResponse> {
  constructor(props: MutationHandlerProps<ApiArg, ApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: ApiArg) {
    return [
      this.createMessagesListPatch(patch),
    ];
  }

  private createMessagesListPatch(patch: ApiArg) {
    const selfUser = this.getSelfUser();

    return this.updateQueryData('getThreadMessages', { threadId: this.getCurrentThreadId()! }, (draftedMessages) => {
      for (const message of draftedMessages.messages) {
        if (patch.messageId === message.id && selfUser) {
          const existingReaction = message.rawReactions.find((reaction) => reaction.reaction.code === patch.reactionRequestBody.reaction_code);
          if (existingReaction) {
            existingReaction.user_ids = existingReaction.user_ids.filter((userId) => userId !== selfUser.id);

            if (existingReaction.user_ids.length === 0) {
              message.rawReactions = message.rawReactions.filter((reaction) => reaction.reaction.code !== patch.reactionRequestBody.reaction_code);
            }
          }
        }
      }
    });
  }

  protected generateInvalidationTags(arg: ApiArg) {
    const messageTag = { type: 'Message' as const, id: arg.messageId };

    return [
      messageTag,
    ];
  }
}
