import { EnvironmentVariablesStoreInterface } from '../../domain/services/environment-variables/EnvironmentVariablesStoreInterface';
import { AppDependencies } from './AppDependencies';
import { EnvironmentVariablesStore } from '../environment-variables/EnvironmentVariablesStore';
import { apiClient } from '../../adapters/api';

export const environmentVariablesStore = new EnvironmentVariablesStore() as EnvironmentVariablesStoreInterface;

export const dependencies: AppDependencies = {
  apiClient,
  environmentVariablesStore
};
