import cx from 'classnames';
import {
  CSSProperties, forwardRef, useLayoutEffect, useRef,
} from 'react';
import Quill from 'quill';
import type { Range, QuillOptions } from 'quill';
import { Box } from 'native-base';
import { useMergeRefs } from '../../hooks/useMergeRefs';
import { useEffectEvent } from '../../hooks/useEffectEvent';
import { KEYS } from './constants';
import { MentionBlot } from './mention/MentionBlot';
import type { Delta, SelectionChangeHandler, TextChangeHandler } from './types';

const icons = Quill.import('ui/icons') as any;
icons.clean = <Box />;

const Block = Quill.import('blots/block');
// @ts-ignore
Block.tagName = 'DIV';
// @ts-ignore
Quill.register(Block, true);
Quill.register(MentionBlot);

const Delta = Quill.import('delta');

interface Props extends QuillOptions {
  defaultValueHTML?: string;
  className?: string;
  onTextChange?: TextChangeHandler;
  onSelectionChange?: SelectionChangeHandler;
  onBlur?: () => void;
  onFocus?: () => void;
  style?: CSSProperties;
}

export type ReactQuillHandle = Quill & { Delta: Delta; };

export const ReactQuill = forwardRef<ReactQuillHandle, Props>(function ReactQuill(props, ref) {
  const initialPropsRef = useRef(props);
  const { className, readOnly, style } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef<ReactQuillHandle | null>(null);
  const setRefs = useMergeRefs(ref, editorRef);

  const onTextChange = useEffectEvent(props.onTextChange);
  const onSelectionChange = useEffectEvent(props.onSelectionChange);
  const onBlur = useEffectEvent(props.onBlur);
  const onFocus = useEffectEvent(props.onFocus);

  useLayoutEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const editorContainer = container.appendChild(
      container.ownerDocument.createElement('div'),
    );
    const quill = new Quill(editorContainer, {
      theme: 'snow',
      placeholder: initialPropsRef.current.placeholder,
      readOnly: initialPropsRef.current.readOnly,
      modules: initialPropsRef.current.modules,
      formats: initialPropsRef.current.formats,
    }) as ReactQuillHandle;

    quill.Delta = Delta;
    setRefs(quill);

    if (initialPropsRef.current.defaultValueHTML) {
      const html = initialPropsRef.current.defaultValueHTML;
      const delta = quill.clipboard.convert({ html });
      quill.setContents(delta);
      quill.history.clear();
    }

    let currentSelection: Range | null = null;
    quill.on(Quill.events.SELECTION_CHANGE, (range) => {
      currentSelection = range;
      if (range === null) onBlur();
      else onFocus();
    });

    quill.on(Quill.events.TEXT_CHANGE, onTextChange);
    quill.on(Quill.events.SELECTION_CHANGE, onSelectionChange);

    quill.keyboard.addBinding({ key: 'y', shortKey: true }, () => {
      quill.history.redo();
    });
    // Numbered list	⌘/Ctrl + Shift + 7
    // Bulleted list	⌘/Ctrl + Shift + 8
    // Quote	⌘/Ctrl + Shift + 9
    // Indent less	⌘/Ctrl + [
    // Indent more	⌘/Ctrl + ]
    quill.keyboard.addBinding({ key: '7', shiftKey: true, shortKey: true }, () => {
      if (quill.getFormat().list === 'ordered') {
        quill.format('list', false);
      } else {
        quill.format('list', 'ordered');
      }
    });
    quill.keyboard.addBinding({ key: '8', shiftKey: true, shortKey: true }, () => {
      if (quill.getFormat().list === 'bullet') {
        quill.format('list', false);
      } else {
        quill.format('list', 'bullet');
      }
    });
    quill.keyboard.addBinding({ key: '9', shiftKey: true, shortKey: true }, () => {
      quill.format('blockquote', !quill.getFormat().blockquote);
    });
    quill.keyboard.addBinding({ key: 'x', shiftKey: true, shortKey: true }, () => {
      quill.format('strike', !quill.getFormat().strike);
    });
    quill.keyboard.addBinding({ key: '[', shortKey: true }, () => {
      quill.format('indent', '-1', Quill.sources.USER);
    });
    quill.keyboard.addBinding({ key: ']', shortKey: true }, () => {
      quill.format('indent', '+1', Quill.sources.USER);
    });
    quill.keyboard.addBinding({ key: KEYS.ESCAPE }, () => {
      quill.blur();
    });
    quill.keyboard.addBinding({ key: 'm', shiftKey: true, shortKey: true }, () => {
      quill.format('code-block', !quill.getFormat()['code-block']);
    });
    quill.keyboard.addBinding({ key: 220, shortKey: true }, () => {
      const range = quill.getSelection();
      if (range) {
        quill.removeFormat(range.index, range.length);
      }
    });

    return () => {
      if (currentSelection) {
        // unmounting while focused, trigger onBlur
        onBlur();
      }
      setRefs(null);
      container.innerHTML = '';
    };
  }, [setRefs]);

  useLayoutEffect(() => {
    editorRef.current?.enable(!readOnly);
  }, [readOnly]);

  return <div className={cx('quill', className)} style={style} ref={containerRef} />;
});
