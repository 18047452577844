import { createTheme, type MantineColorsTuple, type MantineTheme } from '@mantine/core';
import { type getMainTheme, mainTheme } from './main-theme/main-theme';

type PartialObjectDeep<T, U> = {
  [P in keyof T]?: T[P] extends U ? T[P] : PartialObjectDeep<T[P], U>;
};

export const getMantineTheme = (nativeBaseTheme: ReturnType<typeof getMainTheme>) => {
  const primaryColors = Object.values(nativeBaseTheme.colors.primary) as string[];
  return createTheme({
    colors: {
      primary: [primaryColors[0]].concat(primaryColors) as unknown as MantineColorsTuple,
    },
    primaryColor: 'primary',
  }) as PartialObjectDeep<MantineTheme, {}>;
};

export const mantineTheme = getMantineTheme(mainTheme);
