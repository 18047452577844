import {
  AddMessageReactionApiArg, AddMessageReactionApiResponse
} from '../api/codegen';
import { MutationHandler, MutationHandlerProps } from './MutationHandler';

type ApiArg = AddMessageReactionApiArg;
type ApiResponse = AddMessageReactionApiResponse;

export class AddMessageReactionMutationHandler extends MutationHandler<ApiArg, ApiResponse> {
  constructor(props: MutationHandlerProps<ApiArg, ApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: ApiArg) {
    return [
      this.createMessagesListPatch(patch),
    ];
  }

  private createMessagesListPatch(patch: ApiArg) {
    const selfUser = this.getSelfUser();

    return this.updateQueryData('getThreadMessages', { threadId: this.getCurrentThreadId()! }, (draftedMessages) => {
      for (const message of draftedMessages.messages) {
        if (patch.messageId === message.id && selfUser) {
          const existingReaction = message.rawReactions.find((reaction) => reaction.reaction.code === patch.reactionRequestBody.reaction_code);
          if (existingReaction) {
            existingReaction.user_ids.push(selfUser.id);
            existingReaction.update_date = new Date().toISOString();
          } else {
            message.rawReactions.push({
              reaction: {
                code: patch.reactionRequestBody.reaction_code,
              },
              update_date: new Date().toISOString(),
              user_ids: [selfUser.id],
            });
          }
        }
      }
    });
  }

  protected generateInvalidationTags(_arg: ApiArg) {
    // const messageTag = { type: 'Message' as const, id: arg.messageId };
    const messageTag = { type: 'Message' as const, id: 'no' };

    return [
      messageTag,
    ];
  }
}
