import { useEffect } from 'react';

export function usePreventArrowShortcutConflicts() {
  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (['ArrowDown', 'ArrowUp', 'Tab'].includes(event.key)) {
        event.stopPropagation();
      }
    };

    document.addEventListener('keydown', keyDownHandler, true);

    return () => {
      document.removeEventListener('keydown', keyDownHandler, true);
    };
  }, []);
}
