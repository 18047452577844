import { useCallback } from 'react';
import { buildStarMutationDescription } from '../../UndoMutationDescriptions';
import { useWithMutationMetadata } from '@/adapters/mutation-cancellation/mutationCancellation';
import { ThreadViewModel } from '@/adapters/view-models/ThreadViewModel';
import { useUnstarThreadsMutation } from '@/adapters/api/codegen';

export function useUnstarThreadCallback() {
  const [unstarThread] = useWithMutationMetadata(useUnstarThreadsMutation());
  const unstarThreadCallback = useCallback((thread: ThreadViewModel) => {
    void unstarThread({ threadIdsRequestBody: { thread_ids: [thread.id] } }, {
      initiator: 'user',
      description: buildStarMutationDescription(false),
    });
  }, [unstarThread]);
  return unstarThreadCallback;
}
