import React, { useCallback, useState } from 'react';
import { FloatingPosition, Popover } from '@mantine/core';
import { PressableProps } from 'react-native';
import { EmojiSelectedCallback } from './EmojiSelectedCallback';
import { Emoji } from './Emoji';
import { EmojiPopoverContent } from './EmojiPopoverContent';

export type EmojiPickerPopoverProps = {
  emojiSelectedCallback: EmojiSelectedCallback;
  emojiPickerOpenCallback?: (isShowing: boolean) => void;
  trigger: (triggerProps: PressableProps) => JSX.Element;
  placement?: FloatingPosition;
  onClose?: () => void;
};

export function EmojiPickerPopover({
  placement, onClose, trigger, emojiSelectedCallback, emojiPickerOpenCallback: isShowingReactionsCallback
}: EmojiPickerPopoverProps) {
  const [isOpen, setIsOpen] = useState(false);

  const wrappingEmojiSelectedCallback = useCallback((emoji: Emoji) => {
    emojiSelectedCallback(emoji);
    setIsOpen(false);
    isShowingReactionsCallback?.(false);
  }, [emojiSelectedCallback, isShowingReactionsCallback]);

  const wrappingOnClose = useCallback(() => {
    setIsOpen(false);
    onClose?.();
    isShowingReactionsCallback?.(false);
  }, [isShowingReactionsCallback, onClose]);

  const wrappingTrigger = useCallback(() => (
    trigger({
      onPress: () => {
        setIsOpen(!isOpen);
        isShowingReactionsCallback?.(!isOpen);
      },
    })), [isOpen, isShowingReactionsCallback, trigger]);

  return (
    <Popover
      opened={isOpen}
      onClose={wrappingOnClose}
      position={placement}
      transitionProps={{
        duration: 50
      }}
    >
      <Popover.Target>
        {wrappingTrigger()}
      </Popover.Target>
      <Popover.Dropdown>
        <EmojiPopoverContent emojiSelectedCallback={wrappingEmojiSelectedCallback} />
      </Popover.Dropdown>
    </Popover>

  );
}
