import { Platform } from 'react-native';

const withFallback = (fontFamily: string) => {
  if (Platform.OS === 'web') {
    return `${fontFamily}, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif`;
  }
  return fontFamily;
};

export const fontConfig = {
  SpaceGrotesk: {
    100: {
      normal: withFallback('SpaceGrotesk_300Light'),
    },
    200: {
      normal: withFallback('SpaceGrotesk_400Regular'),
    },
    300: {
      normal: withFallback('SpaceGrotesk_400Regular'),
    },
    400: {
      normal: withFallback('SpaceGrotesk_400Regular'),
    },
    500: {
      normal: withFallback('SpaceGrotesk_500Medium'),
    },
    600: {
      normal: withFallback('SpaceGrotesk_600SemiBold'),
    },
    700: {
      normal: withFallback('SpaceGrotesk_700Bold'),
    },
    800: {
      normal: withFallback('SpaceGrotesk_700Bold'),
    },
    900: {
      normal: withFallback('SpaceGrotesk_700Bold'),
    },
  },
  InterTight: {
    100: {
      normal: withFallback('InterTight_100Thin'),
      italic: withFallback('InterTight_100Thin_Italic'),
    },
    200: {
      normal: withFallback('InterTight_200ExtraLight'),
      italic: withFallback('InterTight_200ExtraLight_Italic'),
    },
    300: {
      normal: withFallback('InterTight_300Light'),
      italic: withFallback('InterTight_300Light_Italic'),
    },
    400: {
      normal: withFallback('InterTight_400Regular'),
      italic: withFallback('InterTight_400Regular_Italic'),
    },
    500: {
      normal: withFallback('InterTight_500Medium'),
      italic: withFallback('InterTight_500Medium_Italic'),
    },
    600: {
      normal: withFallback('InterTight_600SemiBold'),
      italic: withFallback('InterTight_600SemiBold_Italic'),
    },
    700: {
      normal: withFallback('InterTight_700Bold'),
      italic: withFallback('InterTight_700Bold_Italic'),
    },
    800: {
      normal: withFallback('InterTight_800ExtraBold'),
      italic: withFallback('InterTight_800ExtraBold_Italic'),
    },
    900: {
      normal: withFallback('InterTight_900Black'),
      italic: withFallback('InterTight_900Black_Italic'),
    },
  },
};
