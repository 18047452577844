import { GetSelfAccountApiResponse } from '../api/codegen';

export type AccountViewModel = {
  numberOfUnreadEvents: number;
  numberOfUnreadSpamThreads: number;
  hasEmailSyncEnabled: boolean;
  numberOfUnreadEventsByCategory: GetSelfAccountApiResponse['number_of_unread_events_by_category'];
  isEmailSyncOperational: boolean;
};

export function createAccountViewModel(account: GetSelfAccountApiResponse): AccountViewModel {
  return {
    numberOfUnreadEvents: account.number_of_unread_events,
    numberOfUnreadEventsByCategory: account.number_of_unread_events_by_category,
    hasEmailSyncEnabled: account.has_email_sync_enabled,
    numberOfUnreadSpamThreads: account.number_of_unread_spam_threads,
    isEmailSyncOperational: account.is_email_sync_operational,
  };
}
