import { InboxItem } from '../api/codegen';
import {
  ThreadViewModel, ComprehensiveThreadViewModel, createThreadViewModel, ThreadViewModelAdditionalParams, createComprehensiveThreadViewModel, ThreadEvent, ChannelAddedThreadEvent, SelfAddedAsParticipantThreadEvent, RegularThreadEvent
} from './ThreadViewModel';
import { formatDate } from './utilities';
import { createExtendedMessageReactionViewModelParams } from './utilities/createExtendedMessageReactionViewModelParams';
import { getEmojiByUnified } from '../other/getEmojiByUnified';
import { UserViewModel } from './UserViewModel';
import { TEMPORARY_ID_PENDING_REFRESH } from '../mutation-handlers/model-factories/mutation-constants';

export type ComprehensiveThreadOrInboxItemViewModel = ComprehensiveThreadViewModel | ComprehensiveInboxItemViewModel;

export function isInboxItemViewModel(item: ComprehensiveThreadOrInboxItemViewModel): item is ComprehensiveInboxItemViewModel {
  return 'thread' in item;
}

export function getThreadsFromComprehensiveThreadOrInboxItemViewModels(threads: ComprehensiveThreadOrInboxItemViewModel[]): ComprehensiveThreadViewModel[] {
  return threads.map((thread) => getThreadFromComprehensiveThreadOrInboxItemViewModel(thread));
}

export function getThreadFromComprehensiveThreadOrInboxItemViewModel(thread: ComprehensiveThreadOrInboxItemViewModel): ComprehensiveThreadViewModel {
  if ('thread' in thread) {
    return thread.thread;
  }

  return thread;
}

export type InboxItemViewModel = {
  id: string;
  thread: ThreadViewModel;
  formattedDate: string;
  type: InboxItem['type'];
  messageReactions: InboxItem['message_reactions'];
  initialDateTimestamp: number;
  snoozeStartDate?: string;
};

type ComprehensiveThreadProxyInboxItemViewModel = InboxItemViewModel & {
  thread: ComprehensiveThreadViewModel;
  displayType: 'snippet';
};

export type ComprehensiveReactionsInboxItemViewModel = InboxItemViewModel & {
  thread: ComprehensiveThreadViewModel;
  reactionSymbols: string;
  reactionText: string;
  displayType: 'reactions' | 'snippet_with_reactions';
};

export type ComprehensiveInboxItemViewModel = ComprehensiveThreadProxyInboxItemViewModel | ComprehensiveReactionsInboxItemViewModel;

export function createInboxItemViewModelFromThreadAddedToInbox(thread: ThreadViewModel): InboxItemViewModel {
  const date = new Date();
  return {
    id: thread.id,
    thread: {
      ...thread,
      showMoveToInboxButton: false,
      snoozedUntil: null,
      rawSnoozeEndDate: null,
      eventId: TEMPORARY_ID_PENDING_REFRESH,
      lastSenderMessageDate: formatDate(date.toISOString(), false),
      event: {
        type: 'added_to_inbox',
        date: date.toISOString(),
      }
    },
    formattedDate: formatDate(date.toISOString(), false),
    type: 'added_to_inbox',
    messageReactions: [],
    initialDateTimestamp: date.getTime(),
  };
}

export function createInboxItemViewModel(item: InboxItem): InboxItemViewModel {
  const threadEvent = determineThreadEvent(item);

  return {
    id: item.id,
    thread: createThreadViewModel(item.thread!, threadEvent),
    formattedDate: formatDate(item.date, false),
    type: item.type,
    messageReactions: item.message_reactions,
    initialDateTimestamp: new Date(item.initial_date).getTime(),
    snoozeStartDate: item.snooze_start_date,
  };
}

function determineThreadEvent(item: InboxItem): ThreadEvent {
  if (item.type === 'self_added_as_participant') {
    return {
      date: item.date,
      type: 'self_added_as_participant',
      userWhoAddedSelf: item.user_who_added_self!.user!,
    } satisfies SelfAddedAsParticipantThreadEvent;
  }

  if (item.type === 'channel_added') {
    return {
      date: item.date,
      type: 'channel_added',
      userWhoAddedChannel: item.user_who_added_channel!.user!,
      addedChannelId: item.channel_id!,
    } satisfies ChannelAddedThreadEvent;
  }

  return {
    date: item.date,
    type: item.type,
  } satisfies RegularThreadEvent;
}

export function createComprehensiveInboxItemViewModel(item: InboxItemViewModel, params: ThreadViewModelAdditionalParams): ComprehensiveInboxItemViewModel {
  const thread = createComprehensiveThreadViewModel(item.thread, params);

  if (item.messageReactions?.length) {
    const shouldDisplaySnippet = !['message_added', 'reactions_added'].includes(item.type) || item.thread.lastMessageDateTimestamp >= item.initialDateTimestamp;
    const reactionText = getTextForReactionsInboxItem(item.messageReactions!, params, thread.participants);
    const reactionSymbols = [...new Set(item.messageReactions?.map((reaction) => reaction.reactions.map((messageReaction) => getEmojiByUnified(messageReaction.reaction.code))).flat() ?? [])].join(' ');

    return {
      ...item,
      thread,
      reactionSymbols,
      reactionText,
      displayType: shouldDisplaySnippet ? 'snippet_with_reactions' : 'reactions',
    };
  }

  return {
    ...item,
    thread,
    displayType: 'snippet',
  };
}

function getTextForReactionsInboxItem(messageReactions: NonNullable<InboxItem['message_reactions']>, initialParams: ThreadViewModelAdditionalParams, threadParticipants: UserViewModel[]): string {
  const params = {
    ...initialParams,
    users: [...(initialParams.users ?? []), ...threadParticipants]
  };

  const reactionParams = createExtendedMessageReactionViewModelParams(params, true);

  const userIds = messageReactions.flatMap((messageReaction) => messageReaction.reactions.flatMap((reaction) => reaction.user_ids));
  const uniqueUserIds = [...new Set(userIds)];
  const userNames = uniqueUserIds.map((userId) => {
    const user = reactionParams.users?.find((user) => user.id === userId);
    return user?.displayName ?? 'Someone';
  }).join(', ');

  const userNamesWithoutDuplicates = [...new Set(userNames.split(', '))];
  const userNamesList = userNamesWithoutDuplicates.length > 1 ? `${userNamesWithoutDuplicates.slice(0, -1).join(', ')} and ${userNamesWithoutDuplicates.slice(-1)}` : userNamesWithoutDuplicates[0];

  const messageSnippet = messageReactions.length === 1 ? `to: “${messageReactions[0].snippet}“` : 'to your messages';

  return `${userNamesList} reacted ${messageSnippet}`;
}
