import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import {
  StarThreadsApiArg,
  StarThreadsApiResponse
} from '../api/codegen';
import { MutationHandler, MutationHandlerProps } from './MutationHandler';
import { ThreadViewModel } from '../view-models/ThreadViewModel';
import { createThreadUpdatePatchesForThreadId } from './thread-patch-factory/createThreadUpdatePatchesForThreadId';
import { ThreadsViewModel } from '../view-models/ThreadsViewModel';

export class StarThreadsMutationHandler extends MutationHandler<StarThreadsApiArg, StarThreadsApiResponse> {
  constructor(props: MutationHandlerProps<StarThreadsApiArg, StarThreadsApiResponse>) {
    super(props);
  }

  protected createOptimisticUpdatePatchWrappers(patch: StarThreadsApiArg) {
    return [
      ...this.createThreadUpdatePatchesForThreadId(patch),
      this.createAddThreadToStarFolderPatch(patch),
    ];
  }

  private updateThread(thread: ThreadViewModel) {
    thread.isStarred = true;
  }

  private createThreadUpdatePatchesForThreadId(patch: StarThreadsApiArg) {
    const threadIds = patch.threadIdsRequestBody.thread_ids;
    return threadIds.flatMap((threadId) => createThreadUpdatePatchesForThreadId(threadId, this.updateThread));
  }

  private createAddThreadToStarFolderPatch(patch: StarThreadsApiArg) {
    const threadIds = patch.threadIdsRequestBody.thread_ids;
    const threads = threadIds
      .map((threadId) => this.getThreadInCurrentContext(threadId))
      .filter((thread) => thread !== undefined) as ThreadViewModel[];

    threads.forEach((thread) => this.updateThread(thread));

    return this.updateQueryData('getSelfStarredThreads', {}, (draftedThreads) => {
      removeExistingThread(draftedThreads);

      threads.forEach((thread) => {
        const actualInsertIndex = findThreadInsertIndex(draftedThreads, thread);

        draftedThreads.threads.splice(actualInsertIndex, 0, thread);

        if (draftedThreads.threads.length > 25) {
          draftedThreads.threads.pop();
        }
      });
    });

    function findThreadInsertIndex(draftedThreads: MaybeDrafted<ThreadsViewModel>, thread: ThreadViewModel) {
      const dates = draftedThreads.threads
        .map((item) => new Date(item.lastActivity.date));

      const curentThreadLastActivityDate = new Date(thread.lastActivity.date);
      const insertIndex = dates.findIndex((date) => date.getTime() < curentThreadLastActivityDate.getTime());
      const actualInsertIndex = insertIndex === -1 ? draftedThreads.threads.length : insertIndex;
      return actualInsertIndex;
    }

    function removeExistingThread(draftedThreads: MaybeDrafted<ThreadsViewModel>) {
      const existingThreadIndex = draftedThreads.threads.findIndex((item) => threadIds.includes(item.id));
      if (existingThreadIndex !== -1) {
        draftedThreads.threads.splice(existingThreadIndex, 1);
      }
    }
  }

  protected generateInvalidationTags() {
    return [
      {
        type: 'SelfStarredThreadsList' as const,
        schedule: {
          delayMs: 5000,
          uniqueKey: 'ChannelsThreadCount',
        }
      }
    ];
  }
}
