import { ThreadListItemPressableContainerProps } from './ThreadListItemPressableContainer';
import { ThreadListItemAbstractContainerProps, ThreadListItemAbstractContainer } from './ThreadListItemAbstractContainer';
import { ThreadSnippetText } from './blocks/ThreadSnippetText';

export type ThreadListItemSnippetContainerProps = ThreadListItemAbstractContainerProps & Pick<ThreadListItemPressableContainerProps, 'threadHoveredCallback' | 'selectThreadCallback'>;

export function ThreadListItemSnippetContainer(props: ThreadListItemSnippetContainerProps) {
  return (
    <ThreadListItemAbstractContainer {...props}>
      <ThreadSnippetText text={props.thread.snippet} />
    </ThreadListItemAbstractContainer>
  );
}
