import { useMemo } from 'react';
import { createComprehensiveInboxItemsViewModel } from '../../../../adapters/view-models/InboxItemsViewModel';
import { UserViewModel } from '../../../../adapters/view-models/UserViewModel';
import { CHANNEL_THREADS_POLLING_INTERVAL, THREADS_QUERY_OPTIONS } from '../../../api/query-parameters';
import { useIsTabFocused } from '../../../hooks/useIsTabFocused';
import { apiClient, useGetSelfInboxEventsQuery } from '../../../../adapters/api';
import { CategorySlug, ChannelViewModel, getCategoryFromSlug } from '../../../../adapters/view-models/ChannelViewModel';

export const useGetSelfInboxEvents = (
  selfUserId?: string,
  users?: UserViewModel[],
  categorySlug?: CategorySlug,
  pageToken?: string,
  channels?: ChannelViewModel[],
) => {
  const params = useMemo(() => ({ selfUserId, users, channels }), [selfUserId, users, channels]);
  const hasFocus = useIsTabFocused();
  const category = getCategoryFromSlug(categorySlug);

  const inboxEvents = useGetSelfInboxEventsQuery({ category, pageToken }, {
    ...THREADS_QUERY_OPTIONS,
    pollingInterval: hasFocus ? 10000 : CHANNEL_THREADS_POLLING_INTERVAL,
  });

  const currentData = useMemo(() => {
    return inboxEvents.currentData ? createComprehensiveInboxItemsViewModel(inboxEvents.currentData, params) : undefined;
  }, [inboxEvents.currentData, params]);
  const threads = useMemo(() => currentData?.inboxItems.map((item) => item.thread), [currentData?.inboxItems]);

  return useMemo(() => ({
    ...inboxEvents,
    currentData,
    threads,
  }), [currentData, inboxEvents, threads]);
};

export const usePrefetchSelfInboxEvents = () => apiClient.usePrefetch('getSelfInboxEvents');
