import { Menu } from 'native-base';
import { useCallback, useState } from 'react';
import { ItemActionButton } from '../ItemActionButton';
import { AddStarMenuItemProps, ToggleStarMenuItem } from './ToggleStarMenuItem';
import { useMenuStore } from '../../date-picker-menu/menu/useMenuStore';

type MoreActionsButtonProps = AddStarMenuItemProps;
export function MoreActionsButton(props: MoreActionsButtonProps) {
  const { setOpen } = useMenuStore();

  const changeMenuOpenStatus = useCallback((isOpen: boolean) => {
    setIsMenuOpen(isOpen);
    setTimeout(() => {
      setOpen(isOpen);
    }, 0);
  }, [setOpen]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const trigger = useCallback((triggerProps: any, { open }: { open: boolean; }) => (
    <ItemActionButton
      smaller={false}
      hint="More actions"
      iconName="more-vert"
      shortcutEnabled={false}
      triggerProps={triggerProps}
      backgroundColor={open ? 'light.100' : 'transparent'}
    />
  ), []);

  return (
    <Menu
      my="4px"
      placement="bottom right"
      trigger={trigger}
      w="180px"
      _presenceTransition={{
        animate: {
          opacity: 1,
          transition: {
            duration: 50
          }
        }
      }}
      isOpen={isMenuOpen}
      onClose={() => changeMenuOpenStatus(false)}
      onOpen={() => changeMenuOpenStatus(true)}
    >
      <ToggleStarMenuItem {...props} />
    </Menu>
  );
}
