import type { Op } from 'quill/core';

export function isEmptyDeltaOps(deltaOps: Op[]) {
  const hasInsert = deltaOps.some((op) => {
    if (typeof op.insert === 'string') {
      return Boolean(op.insert.trim());
    }
    return Boolean(op.insert);
  });
  return !hasInsert;
}
