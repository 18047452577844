import { NavigationProp, StackActions, useNavigation } from '@react-navigation/native';
import { useHotkeys } from 'react-hotkeys-hook';
import { useEffect, useState } from 'react';
import { RootStackParamList } from '../navigation/navigators/root/RootStackProps';
import { useIsDisplayingAppScreen } from '../controllers/hooks/useIsDisplayingAppScreen';

export function useGoToComposeScreenHotkey() {
  const isDisplayingCompose = useIsDisplayingAppScreen('Compose');
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const [hotkeyPressed, setHotkeyPressed] = useState(false);

  useEffect(() => {
    if (hotkeyPressed) {
      navigation.dispatch(StackActions.replace('Compose'));
      setHotkeyPressed(false);
    }
  }, [hotkeyPressed, navigation]);

  useHotkeys(
    'c',
    () => {
      setHotkeyPressed(true);
    },
    [isDisplayingCompose],
    {
      enabled: !isDisplayingCompose,
      enableOnFormTags: false,
      enableOnContentEditable: false
    }
  );
}
