import { NavigationProp, useNavigation } from '@react-navigation/native';
import { RootStackParamList } from '../../../navigation/navigators/root/RootStackProps';
import { useAppDispatch } from '../../../state/hooks';
import { upsertThreadInCache } from './usePrefetchThreadCallback';
import { ComprehensiveThreadOrInboxItemViewModel, getThreadFromComprehensiveThreadOrInboxItemViewModel, getThreadsFromComprehensiveThreadOrInboxItemViewModels } from '@/adapters/view-models/InboxItemViewModel';
import { useEvent } from '@/infrastructure/hooks/useEffectEvent';

export function useSelectThreadCallback(items?: ComprehensiveThreadOrInboxItemViewModel[]) {
  const navigation = useNavigation<NavigationProp<RootStackParamList>>();
  const dispatch = useAppDispatch();

  const threadSelectedCallback = useEvent(async (item: ComprehensiveThreadOrInboxItemViewModel) => {
    const threads = getThreadsFromComprehensiveThreadOrInboxItemViewModels(items ?? []);
    const thread = getThreadFromComprehensiveThreadOrInboxItemViewModel(item);
    await upsertThreadInCache(threads, thread, dispatch);
    navigation.navigate('Thread', { threadId: thread.id });
  });

  return threadSelectedCallback;
}
