import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store'; // circular ./store ?
import { ComprehensiveThreadViewModel } from '../../adapters/view-models/ThreadViewModel';
import { useAppSelector } from '../../infrastructure/state/hooks';
import { PostMessageApiArg } from '../../adapters/api/codegen';

export const threadDraftIdPrefix = 'thread:';

type DraftContent = any; // ops from quilljs, or maybe better convert to HTML

// move to view-models?
export interface DraftViewModel {
  id: string;
  title: string;
  content: DraftContent;
  updatedAt: number;
}

// todo inline messageCreateRequestBody types
export type MessageCreateRequestBodyWithId = {
  /** ID of the message. */
  id: string; // required here
  /** Body of the message. */
  message_body: string;
  /** Public IDs of the participants. */
  participant_public_ids?: string[];
  /** Emails of the external participants. */
  external_participant_emails?: string[];
};

export interface SendingViewModel {
  id: string;
  messageCreateRequestBody: MessageCreateRequestBodyWithId;
  resendCount?: number;
}

type ThreadId = string;

export interface DraftsState {
  version: number;
  drafts: DraftViewModel[];
  sendingByThread?: Record<ThreadId, SendingViewModel[]>;
}

interface SendingPayload extends PostMessageApiArg {
  messageCreateRequestBody: MessageCreateRequestBodyWithId;
}

export function isSendingPayload(arg: PostMessageApiArg): arg is SendingPayload {
  // Making sure the messageCreateRequestBody has an id
  return Boolean(arg.messageCreateRequestBody.id);
}

export const draftsSlice = createSlice({
  name: 'drafts',
  initialState: {
    version: 1,
    drafts: [],
    sendingByThread: {},
  } as DraftsState,
  reducers: {
    saveDraft: (state, action: PayloadAction<DraftViewModel>) => {
      const draft = state.drafts.find((draft) => draft.id === action.payload.id);
      if (draft) {
        draft.title = action.payload.title;
        draft.content = action.payload.content;
        draft.updatedAt = action.payload.updatedAt;
      } else {
        state.drafts.push(action.payload);
      }
    },
    deleteDraft: (state, action: PayloadAction<string>) => {
      state.drafts = state.drafts.filter((draft) => draft.id !== action.payload);
    },
    sending: (state, action: PayloadAction<SendingPayload>) => {
      const { threadId, messageCreateRequestBody } = action.payload;
      state.sendingByThread = state.sendingByThread || {};
      state.sendingByThread[threadId] = state.sendingByThread[threadId] || [];
      const sendingMessage = state.sendingByThread[threadId].find((sent) => sent.id === messageCreateRequestBody.id);
      if (sendingMessage) {
        sendingMessage.resendCount = (sendingMessage.resendCount || 0) + 1;
      } else {
        state.sendingByThread[threadId].push({
          id: messageCreateRequestBody.id,
          messageCreateRequestBody,
        });
      }
    },
    sendSuccess: (state, action: PayloadAction<SendingPayload>) => {
      const { threadId, messageCreateRequestBody } = action.payload;
      if (state.sendingByThread?.[threadId]) {
        state.sendingByThread[threadId] = state.sendingByThread[threadId].filter((sent) => sent.id !== messageCreateRequestBody.id);
      }
    },
    discardFailedSending: (state, action: PayloadAction<{
      threadId: string;
      messageId: string;
    }>) => {
      const { threadId, messageId } = action.payload;
      if (state.sendingByThread?.[threadId]) {
        state.sendingByThread[threadId] = state.sendingByThread[threadId].filter((sent) => sent.id !== messageId);
      }
    },
  },
});

export const draftActions = draftsSlice.actions;

const selectDraftsSlice = (state: RootState) => state[draftsSlice.name];
export const selectThreadDraft = (state: RootState, threadId: string) => selectDraftsSlice(state).drafts.find((draft) => draft.id === threadId);

export function useThreadDraft(thread: ComprehensiveThreadViewModel) {
  return useAppSelector((state) => selectThreadDraft(state, `${threadDraftIdPrefix}${thread.id}`));
}

export function useDrafts() {
  return useAppSelector((state) => selectDraftsSlice(state).drafts);
}

export function useThreadDrafts() {
  return useAppSelector((state) => selectDraftsSlice(state).drafts.filter(({ id }) => id.startsWith(threadDraftIdPrefix)));
}

export function useThreadDraftsCount() {
  return useAppSelector((state) => selectDraftsSlice(state)
    .drafts
    .filter(({ id }) => id.startsWith(threadDraftIdPrefix))
    .length);
}

export function useSendingByThread(threadId: string): SendingViewModel[] | undefined {
  return useAppSelector((state) => selectDraftsSlice(state).sendingByThread?.[threadId]);
}
