import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  HStack, IconButton, Skeleton, Text,
} from 'native-base';
import React, { memo } from 'react';
import { MenuHeaderProps } from './screen-sections/menu/MenuScreenSection';

export default memo(MenuHeader);

function MenuHeader(props: MenuHeaderProps) {
  return (
    <HStack alignItems="center" pr={2} pl={6} justifyContent="space-between">
      {props.organizationName ? <Text bold>{props.organizationName}</Text> : <Skeleton.Text lines={1} width="110px" />}
      <IconButton
        variant="primary"
        _icon={{
          as: MaterialCommunityIcons,
          name: 'square-edit-outline',
          size: '4',
        }}
        onPress={props.composeCallback}
        p={1}
        w={8}
        h={8}
        bg="opacityWhite.700"
      />
    </HStack>
  );
}
