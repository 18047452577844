import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import React from 'react';
import { RootScreenNavigator } from './RootScreenNavigator';
import { linking } from '../../linking';
import { useDatadogLogs } from '../../../hooks/datadog/useDatadogLogs';
import { rootNavigationContainerRef } from './RootNavigationContainerRef';
import { usePreserveStateOnRefresh } from './hooks/usePreserveStateOnRefresh';
import { useInsertInboxInNavigationStackCallbackIfNeeded } from './hooks/useInsertInboxInNavigationStackCallbackIfNeeded';

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: 'transparent',
  },
};

export default function RootNavigationContainer() {
  useDatadogLogs();

  const { onStateChange, initialState } = usePreserveStateOnRefresh();
  const insertInboxInNavigationStackCallback = useInsertInboxInNavigationStackCallbackIfNeeded();

  return (
    <NavigationContainer
      ref={rootNavigationContainerRef}
      linking={linking}
      theme={MyTheme}
      onStateChange={onStateChange}
      initialState={initialState}
      onReady={insertInboxInNavigationStackCallback}
    >
      <RootScreenNavigator />
    </NavigationContainer>
  );
}
