import { Octicons } from '@expo/vector-icons';
import {
  VStack, Text, HStack, Icon
} from 'native-base';

export function List({ children }: { children: React.ReactNode; }) {
  return <VStack space={2} pl={4}>{children}</VStack>;
}

export function ListItem({ children }: { children: React.ReactNode; }) {
  return (
    <HStack alignItems="flex-start" space={1}>
      <Icon as={Octicons} name="dot-fill" size="3" color="black" flexShrink={0} mt={1} />
      <Text>{children}</Text>
    </HStack>
  );
}
