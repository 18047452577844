import isEqual from 'react-fast-compare';

const LOCK_TIMEOUT_MS = 12000;

export interface LockInterface {
  release(): void;
}

class AutoReleaseLock {
  private timeoutId: ReturnType<typeof setTimeout>;

  constructor(private releaseCallback: () => void, autoReleaseTimeMs: number) {
    this.timeoutId = setTimeout(() => this.release(), autoReleaseTimeMs);
  }

  public release() {
    clearTimeout(this.timeoutId);
    this.releaseCallback();
  }
}

export class EndpointLock {
  private mutationCount: number;
  private lockPromiseResolver: (() => void) | null = null;
  private lockPromise: Promise<void> | null = null;
  private lastUpdateTime?: Date;

  /* Sometimes RTK query triggers the query right before
   * we get notified of the lock release. This is why we
   * allow a small lag time before considering the query
   * result outdated. */
  private acceptableMutationLagTimeMs = 10;

  constructor(private readonly endpointName: string, private readonly args: any) {
    this.mutationCount = 0;
  }

  public isEndpoint(endpointName: string, args: any) {
    return this.endpointName === endpointName && isEqual(this.args, args);
  }

  public acquireLock(): LockInterface {
    this.mutationCount++;
    if (this.mutationCount === 1) {
      this.lockPromise = new Promise<void>((resolve) => {
        this.lockPromiseResolver = resolve;
      });
    }

    const lock = new AutoReleaseLock(() => this.decreaseMutationCount(), LOCK_TIMEOUT_MS);
    this.lastUpdateTime = new Date();

    return lock;
  }

  private decreaseMutationCount() {
    this.mutationCount--;
    if (this.mutationCount === 0) {
      this.lockPromiseResolver?.();
      this.lockPromise = null;
      this.lockPromiseResolver = null;
    }

    // This should not happen
    if (this.mutationCount < 0) {
      this.mutationCount = 0;
    }

    this.lastUpdateTime = new Date();
  }

  public waitForEndpointAvailability(): Promise<void> {
    return this.lockPromise ?? Promise.resolve();
  }

  public isLocked() {
    return Boolean(this.lockPromise);
  }

  public isQueryResultOutdated(queryStartDateTime: Date) {
    return Boolean(this.lastUpdateTime && queryStartDateTime.getTime() < (this.lastUpdateTime.getTime() - this.acceptableMutationLagTimeMs));
  }

  public isOlderThan(timeMs: number) {
    return Boolean(this.lastUpdateTime && new Date().getTime() > (this.lastUpdateTime.getTime() + timeMs));
  }
}
