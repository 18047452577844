import { useEffect, useState } from 'react';
import { Animated } from 'react-native';
import { Box } from 'native-base';
import { UNREAD_BUBBLE_SIZE } from '../constants';
import { usePreviousValue } from '../../../hooks/usePreviousValue';

export function ThreadListItemUnreadBubble({ isUnread }: { isUnread: boolean }) {
  const [opacity] = useState(() => new Animated.Value(isUnread ? 1 : 0));
  const previousIsUnread = usePreviousValue(isUnread) ?? isUnread;
  useEffect(() => {
    if (isUnread === previousIsUnread) return;
    Animated.spring(opacity, {
      toValue: isUnread ? 1 : 0,
      speed: 30,
      bounciness: 15,
      useNativeDriver: false,
    }).start();
  }, [isUnread, previousIsUnread, opacity]);
  return (
    <Box
      position="absolute"
      mr="3px"
      ml="3px"
      mt={`-${(UNREAD_BUBBLE_SIZE / 2)}px`}
    >
      <Animated.View
        style={{
          opacity,
          transform: [{ scale: opacity.interpolate({ inputRange: [0, 1], outputRange: [0.2, 1] }) }],
        }}
      >
        <Box
          bgColor="primary.500"
          borderRadius="full"
          h={`${UNREAD_BUBBLE_SIZE}px`}
          w={`${UNREAD_BUBBLE_SIZE}px`}
        />
      </Animated.View>
    </Box>
  );
}
