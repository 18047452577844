import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import {
  Box, IconButton, Menu, Text
} from 'native-base';
import React, { ComponentProps, useCallback } from 'react';
import { Channel } from '../../../../adapters/api/codegen';

type ChannelNotificationOption = Channel['notification_level'];

const defaultOption: ChannelNotificationOption = 'new_and_followed';

const options: Record<ChannelNotificationOption, { title: string; description: string, icon?: any }> = {
  all: {
    title: 'All messages',
    description: 'Threads will be added to your inbox every time someone sends a message',
    icon: { name: 'bell-ring-outline', as: MaterialCommunityIcons },
  },
  new_and_followed: {
    title: 'Only new and followed threads',
    description: 'New threads and threads you follow will be added to your inbox when someone sends a message',
    icon: { name: 'bell-outline', as: MaterialCommunityIcons },
  },
  followed: {
    title: 'Only followed threads',
    description: 'Only threads you follow will be added to your inbox when someone sends a message',
    icon: { name: 'bell-minus-outline', as: MaterialCommunityIcons },
  },
};

export function ChannelNotificationSettings({ value = defaultOption, onChange }: {
  value?: ChannelNotificationOption;
  onChange: (value: ChannelNotificationOption) => void;
}) {
  const trigger: ComponentProps<typeof Menu>['trigger'] = useCallback((triggerProps, { open }) => (
    <IconButton
      {...triggerProps}
      variant="primary"
      _icon={options[value].icon || { name: 'bell-outline', as: MaterialCommunityIcons }}
      _focus={{ borderColor: 'transparent' }}
      size="sm"
      backgroundColor={open ? 'light.100' : 'transparent'}
    />
  ), [value]);

  return (
    <Menu pt={0} trigger={trigger} shouldOverlapWithTrigger={false} placement="bottom right">
      <Menu.OptionGroup
        title="Channel Notifications"
        type="radio"
        value={value}
        onChange={(v) => onChange(v as ChannelNotificationOption)}
      >
        {Object.entries(options).map(([key, { title, description }]) => (
          <Menu.ItemOption
            rounded={0}
            mx={0}
            px={4}
            py={3}
            borderTopWidth={1}
            borderTopColor="dark.5"
            _icon={{ size: 'sm' }}
            _stack={{ flexDirection: 'row-reverse', mx: 0, px: 0 }}
            value={key as ChannelNotificationOption}
            key={key}
          >
            <Box maxW={260}>
              <Text color="dark.800" fontWeight="500">{title}</Text>
              <Text color="dark.400" fontSize="xs">{description}</Text>
            </Box>
          </Menu.ItemOption>
        ))}
      </Menu.OptionGroup>
    </Menu>
  );
}
