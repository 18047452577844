import { Image, Pressable } from 'native-base';

type AppLogoProps = {
  navigateToInbox?: () => void;
};

export function AppLogo(props: AppLogoProps) {
  return (
    <Pressable onPress={props.navigateToInbox} borderWidth={0} padding={0} w="204px" h="100%" flexGrow={0} flexDir="row" justifyContent="flex-start" py={2} ml={3}>
      <Image
        source={{
          uri: '/images/logo_whitescreen.png'
        }}
        alt="Upstream"
        resizeMode="contain"
        h="100%"
        w="120px"
      />
    </Pressable>
  );
}
