import React, { useCallback } from 'react';

import 'react-day-picker/dist/style.css';

import { Box, Icon } from 'native-base';
import { SideMenuButtonProps } from './SideMenuButton';
import { ThreadViewModel } from '../../../adapters/view-models/ThreadViewModel';
import { PopoverMenuButton } from './PopoverMenuButton';
import { ParticipantAvatar } from '../ParticipantAvatars';
import {
  ParticipantScreenSectionController
} from '../../controllers/screen-sections/ParticipantScreenSectionController';
import { ParticipantViewModel } from '../../../adapters/view-models/ParticipantViewModel';

export type ThreadAssigneeSideMenuButtonProps = {
  paddingLeft: SideMenuButtonProps['paddingLeft'];
  open: SideMenuButtonProps['open'];
  animationDuration: SideMenuButtonProps['animationDuration'];
  thread: ThreadViewModel;
  updateThreadAssigneeCallback: (threadId: string, assignee: string | null) => void;
};

function AssigneeIcon({ thread }: { thread: ThreadViewModel }) {
  if (thread.assigneePicture !== undefined) {
    return <ParticipantAvatar marginLeft={-1} pictureInfo={thread.assigneePicture} size="xs" />;
  }
  return <Icon name="supervisor-account" />;
}

export function ThreadAssigneeSideMenuButton({
  thread, updateThreadAssigneeCallback, open, paddingLeft, animationDuration
}: ThreadAssigneeSideMenuButtonProps) {
  const buttonTitle = thread.assigneeDisplayName ?? 'Set assignee';
  const clearable = thread.assignee !== undefined;

  const updateAssigneeAndCloseModal = useCallback((assignees: ParticipantViewModel[]) => {
    if (assignees.length > 0) {
      updateThreadAssigneeCallback(thread.id, assignees[0].id);
    }
  }, [updateThreadAssigneeCallback, thread.id]);

  const clearAssigneeAndCloseModal = useCallback(() => {
    updateThreadAssigneeCallback(thread.id, null);
  }, [updateThreadAssigneeCallback, thread.id]);

  return (
    <PopoverMenuButton
      paddingLeft={paddingLeft}
      open={open}
      animationDuration={animationDuration}
      buttonTitle={buttonTitle}
      icon={(<AssigneeIcon thread={thread} />)}
      isDisabled={thread.isTrashed}
    >
      <Box maxHeight="50vh" marginTop="-2">
        <ParticipantScreenSectionController
          selectedParticipantsListChangedCallback={updateAssigneeAndCloseModal}
          includeChannels={false}
          includeUsers
          type="set_assignee"
          singleParticipant
          includeSelfUser
          clearAllParticipantsCallback={clearable ? clearAssigneeAndCloseModal : undefined}
          clearAllText="Remove assignee"
        />
      </Box>
    </PopoverMenuButton>
  );
}
