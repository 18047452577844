import { Tooltip, useTheme } from 'native-base';
import { Calendar } from '@mantine/dates';
import { Indicator } from '@mantine/core';
import { isBefore, isSameDay, startOfDay } from 'date-fns';
import { useEffect } from 'react';

type DatePickerProps = {
  selectedDate: Date;
  setSelectedDate: (date: (prevDate: Date) => Date) => void;
  minDate?: Date;
  activeDate?: {
    date: Date;
    label: string;
  }
};

export function DatePicker(props: DatePickerProps) {
  const mainTheme = useTheme();
  const activeDateColor = (mainTheme.colors as any).snooze[500];
  const primaryColor = mainTheme.colors.primary[300];
  const minDateStartOfDay = props.minDate ? startOfDay(props.minDate) : undefined;

  useEffect(() => {
    setTimeout(() => {
      const selectedDateElement = document.getElementById('selected-date');
      if (selectedDateElement) {
        selectedDateElement.tabIndex = 0;
        selectedDateElement.focus();
      }
    }, 100);
  }, []);

  return (
    <Calendar
      renderDay={(date) => {
        const day = date.getDate();
        const isActiveDate = props.activeDate && isSameDay(date, props.activeDate.date);
        const isSelected = props.selectedDate && isSameDay(date, props.selectedDate);
        return (
          <Tooltip
            label={props.activeDate?.label ?? ''}
            placement="top"
            isDisabled={!isActiveDate}
            _text={{
              fontSize: 'xs'
            }}
            mb={2}
          >
            <Indicator size={4} color={!isSelected ? activeDateColor : primaryColor} offset={-2} disabled={!isActiveDate}>
              <div>{day}</div>
            </Indicator>
          </Tooltip>
        );
      }}
      getDayProps={(date) => {
        const isSelected = props.selectedDate && isSameDay(date, props.selectedDate);
        return ({
          disabled: minDateStartOfDay && isBefore(date, minDateStartOfDay),
          selected: isSelected,
          onClick: () => {
            props.setSelectedDate((prevDate) => {
              const newDate = new Date(date);
              newDate.setHours(prevDate.getHours(), prevDate.getMinutes(), 0);
              return newDate;
            });
          }
        });
      }}
    />
  );
}
