import { MaterialIcons } from '@expo/vector-icons';
import { IconButton } from 'native-base';
import React, { useCallback } from 'react';
import { AddReactionButtonProps } from './AddReactionButtonControllerProps';
import { EmojiPickerPopover } from '../../../emoji/popover/EmojiPickerPopover';

export function AddOtherReactionButton({ emojiSelectedCallback, isShowingReactionsCallback }: AddReactionButtonProps) {
  const trigger = useCallback((triggerProps: any) => (
    <IconButton
      {...triggerProps}
      variant="primary"
      size="sm"
      w={8}
      h={8}
      _icon={{
        as: MaterialIcons,
        name: 'insert-emoticon',
      }}
    />
  ), []);

  return (
    <EmojiPickerPopover
      placement="left"
      emojiSelectedCallback={emojiSelectedCallback}
      trigger={trigger}
      emojiPickerOpenCallback={isShowingReactionsCallback}
    />
  );
}
