import { intlFormat } from 'date-fns';
import { determineDateFromPrompt } from './determineDateFromPrompt';
import { DateProps } from './DateProps';

const monthNum = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
const daysInMonth = Array.from({ length: 31 }, (_, i) => i + 1);

const dayOptions = daysOfWeek.map((day) => {
  return {
    dateLabel: `Next ${day}`,
    date: determineDateFromPrompt(`next ${day} at 8:00`) as Date
  };
});

const monthOptions = monthNum.flatMap((month) => {
  return generateMonthOptions(month, 'long');
})
  .concat(monthNum.flatMap((month) => {
    return generateMonthOptions(month, 'short');
  }));

function generateMonthOptions(month: number, monthFormat: 'short' | 'long'): DateProps[] {
  return daysInMonth.flatMap((day) => {
    const date = getNextOccurrence(month, day);

    if (!date) {
      return [];
    }

    return {
      dateLabel: `${intlFormat(date, {
        month: monthFormat,
        day: 'numeric',
      })}`,
      date
    };
  });
}

export const dateOptions = [...dayOptions, ...monthOptions];

function getNextOccurrence(month: number, day: number) {
  const today = new Date();
  let year = today.getFullYear();

  if (month < today.getMonth() + 1 || (month === today.getMonth() + 1 && day < today.getDate())) {
    year++;
  }

  const date = new Date(year, month - 1, day, 8);

  if (date.getDate() === day && date.getMonth() === month - 1) {
    return date;
  }
}
