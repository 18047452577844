import { NavigationState, useNavigationState } from '@react-navigation/native';
import { AppScreenStackParamList } from '../../navigation/navigators/app/AppScreenProps';
import { RootStackParamList } from '../../navigation/navigators/root/RootStackProps';

export function useIsDisplayingAppScreen(appScreenName: keyof AppScreenStackParamList, alsoCheckInStack: boolean = false) {
  const isInScreen = useNavigationState<RootStackParamList, boolean>((state) => getScreenState(state, appScreenName, alsoCheckInStack));
  return isInScreen;
}

function getScreenState(state: NavigationState<RootStackParamList>, appScreenName: keyof AppScreenStackParamList, alsoCheckInStack: boolean): boolean {
  if (!state?.routes) {
    return false;
  }

  if (state.routes.length < 1) {
    return false;
  }

  const route = state.routes[0];
  if (route.name === 'App') {
    if (route.params && 'screen' in route.params && route.params.screen === appScreenName) {
      return true;
    }
  }

  if (route.name === appScreenName) {
    return true;
  }

  if (alsoCheckInStack) {
    for (const theRoute of route.state?.routes ?? []) {
      if (theRoute.name === appScreenName) {
        return true;
      }
    }
  } else if (route.state?.routes && route.state.routes.length > 0) {
    const lastRoute = route.state.routes[route.state.routes.length - 1];
    if (lastRoute.name === appScreenName) {
      return true;
    }
  }

  return false;
}
