import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import {
  EndpointRequestType, EndpointResponseType, PatchWrapper, QueryEndpointNames
} from '../MutationHandler';
import { apiClient } from '../../api';

export function updateQueryData<TEndpoint extends QueryEndpointNames>(endpointName: TEndpoint, args: EndpointRequestType<TEndpoint>, recipe: (draft: MaybeDrafted<EndpointResponseType<TEndpoint>>) => void): PatchWrapper<TEndpoint> {
  const patch = apiClient.util.updateQueryData(endpointName, args as any, recipe as any);

  return {
    endpointName,
    args,
    patch,
  };
}
