import { Text } from 'native-base';

type SnoozeHintTextProps = {
  children: React.ReactNode;
};
export function SnoozeHintText({ children }: SnoozeHintTextProps) {
  return (
    <Text selectable={false} fontSize="sm" color="snooze.500" fontWeight={600}>
      {children}
    </Text>
  );
}
