import { RouteProp, useRoute } from '@react-navigation/native';
import { useMemo } from 'react';
import { RootStackParamList } from '../../../navigation/navigators/root/RootStackProps';
import { useGetThread } from '../api/useGetThread';

export function useCurrentThread() {
  const route = useRoute<RouteProp<RootStackParamList, 'Thread'>>();
  const { threadId } = route.params ?? {};

  const thread = useGetThread(threadId);

  return useMemo(() => {
    const { currentData, ...rest } = thread;
    return ({ ...rest, thread: currentData, threadId });
  }, [thread, threadId]);
}
