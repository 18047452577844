import { convertHtmlBodyToSnippet } from '@monorepo/utilities';
import {
  ConversationParticipant,
  CreateThreadApiArg, Thread, User
} from '../../api/codegen';

export function createThread(patch: CreateThreadApiArg, user: User, organizationMembers?: User[]): Thread {
  const orgMembersWhoParticipate = patch.threadCreateRequestBody.participant_ids.map((participantId) => organizationMembers?.find((member) => member.id === participantId)).filter((member) => !!member) as User[];
  const participantsIncludingSelf = [...orgMembersWhoParticipate, user].filter((participant) => !!participant) as User[];

  const userParticipants: ConversationParticipant[] = participantsIncludingSelf.map((participant) => ({
    type: 'user' as const,
    user: participant
  }));

  const emailParticipants: ConversationParticipant[] = patch.threadCreateRequestBody.external_participant_emails?.map((email) => ({
    type: 'email' as const,
    email_participant: {
      email
    }
  })) ?? [];

  const participantsAsConversationParticipants: ConversationParticipant[] = [...userParticipants, ...emailParticipants];

  return {
    id: patch.threadCreateRequestBody.thread_id!,
    title: patch.threadCreateRequestBody.thread_title as Thread['title'],
    number_of_messages: 1,
    number_of_unread_messages: 0,
    last_activity: {
      author: {
        type: 'user',
        user
      },
      date: new Date().toISOString(),
      message_id: patch.threadCreateRequestBody.message_id!,
      snippet: convertHtmlBodyToSnippet(patch.threadCreateRequestBody.message_body),
    },
    participants: participantsAsConversationParticipants,
    channel_ids: patch.threadCreateRequestBody.channel_ids,
    status: null,
    email_recipients_for_next_message: emailParticipants,
    description: null,
    due_date: null,
    assignee: null,
    labels: [],
    isSpam: null,
    snooze_end_date: null,
    trashed_date: null,
    is_starred: false,
    number_of_scheduled_messages: patch.threadCreateRequestBody.scheduled_date ? 1 : 0,
  };
}
