import React from 'react';
import { AuthorizationParams } from '@auth0/auth0-react';
import { useAppDependencies } from '../../dependencies';
import { RootStackScreenProps } from '../../navigation/navigators/root/RootStackProps';
import { SignInScreenController } from './SignInScreenController';
import { generateAuthParams } from '../../generateAuthParams';

const getGoogleConnectionAuthorizationParams = (): AuthorizationParams => {
  const additionalGmailReadonlyScope: AuthorizationParams = {
    connection_scope: 'https://www.googleapis.com/auth/gmail.modify',
  };

  const parametersAllowingRefreshTokenCreation: AuthorizationParams = {
    access_type: 'offline',
    approval_prompt: 'force',
  };

  return {
    ...additionalGmailReadonlyScope,
    ...parametersAllowingRefreshTokenCreation,
  };
};

export function SignInWithEmailAccessScreenController(props: RootStackScreenProps<'SignInWithEmailAccess'>) {
  const authParams = {
    ...generateAuthParams(useAppDependencies().environmentVariablesStore.env),
    ...getGoogleConnectionAuthorizationParams(),
    scope: 'openid profile email offline_access',
  };

  return <SignInScreenController {...props} authorizationParams={authParams} />;
}
