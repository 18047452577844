import { AnimatePresence, usePresence } from 'framer-motion';
import {
  Box, HStack, Text, useToken, View
} from 'native-base';
import React, { useEffect, useRef, useState } from 'react';
import { Animated } from 'react-native';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import { useIsFocused } from '@react-navigation/native';
import { useHotkeys } from 'react-hotkeys-hook';
import { OptionsOrDependencyArray } from 'react-hotkeys-hook/dist/types';
import { MutationMetadata, useWithMutationMetadata } from '../../../adapters/mutation-cancellation/mutationCancellation';
import { useThreadListStore, useThreadListStoreSelector } from './context';
import { customShadow } from '../../styles/customShadow';
import { ThreadListActionButton } from './ThreadListActionButton';
import { ComprehensiveThreadOrInboxItemViewModel, isInboxItemViewModel } from '../../../adapters/view-models/InboxItemViewModel';
import { useArchiveThreadCallback, useChangeUnreadStatusCallback } from '../../controllers/hooks/channelScreenHooks';
import { useAddThreadToInboxMutation, useMarkThreadAsNotSpamMutation, useMarkThreadAsSpamMutation } from '../../../adapters/api/codegen';
import { closeCommandMenu, CommandItem, ProvideCommands } from '../CommandMenu';

const borderRadius = 'lg';

export function ThreadListMultiSelectActionBar({ items }: {
  items: ComprehensiveThreadOrInboxItemViewModel[] | undefined;
}) {
  const isFocusedScreen = useIsFocused();

  const changeUnreadStatus = useChangeUnreadStatusCallback();
  const archiveThreadCallback = useArchiveThreadCallback();
  const [moveToInbox] = useWithMutationMetadata(useAddThreadToInboxMutation());
  const [markAsSpam] = useWithMutationMetadata(useMarkThreadAsSpamMutation());
  const [markAsNotSpam] = useWithMutationMetadata(useMarkThreadAsNotSpamMutation());

  const store = useThreadListStore();
  const getSelectedThreads = (state = store.getState()) => (items ?? [])
    .map((i) => (isInboxItemViewModel(i) ? i.thread : i))
    .filter((i) => state.selectedIds.includes(i.id));
  const clearSelected = () => store.getState().clearSelected();

  const hasUnreadSelected = useThreadListStoreSelector((state) => getSelectedThreads(state).some((thread) => thread.isUnread));
  const hasInInboxSelected = useThreadListStoreSelector((state) => getSelectedThreads(state).some((thread) => thread.showRemoveFromInboxButton));
  const hasNotSpamSelected = useThreadListStoreSelector((state) => getSelectedThreads(state).some((thread) => thread.showMarkAsSpamButton));

  const numberOfThreadsSelected = useThreadListStoreSelector((state) => state.selectedIds.length);
  const shouldShow = numberOfThreadsSelected > 0;
  const pluralThreads = numberOfThreadsSelected > 1 ? 'threads' : 'thread';

  const mutationMetadata: MutationMetadata = {
    initiator: 'app',
  };

  const removeSelectedFromInbox = async () => {
    getSelectedThreads()
      .filter((thread) => thread.showRemoveFromInboxButton)
      .map((thread) => archiveThreadCallback(thread, mutationMetadata.initiator));
  };
  const addSelectedToInbox = async () => {
    getSelectedThreads()
      .filter((thread) => thread.showMoveToInboxButton)
      .map((thread) => moveToInbox({ threadId: thread.id }, mutationMetadata));
  };
  const markSelectedAsRead = async () => {
    getSelectedThreads()
      .filter((thread) => thread.isUnread)
      .map((thread) => changeUnreadStatus(thread, 'read', mutationMetadata.initiator));
  };
  const markSelectedAsUnread = async () => {
    getSelectedThreads()
      .filter((thread) => !thread.isUnread)
      .map((thread) => changeUnreadStatus(thread, 'unread', mutationMetadata.initiator));
  };
  const markSelectedAsSpamOrNotSpam = async () => {
    const threads = getSelectedThreads();
    const hasNotSpamSelected = threads.some((thread) => thread.showMarkAsSpamButton);
    if (hasNotSpamSelected) {
      threads
        .filter((thread) => thread.showMarkAsSpamButton)
        .map((thread) => markAsSpam({ threadId: thread.id }, mutationMetadata));
    } else {
      threads
        .filter((thread) => thread.showMarkAsNotSpamButton)
        .map((thread) => markAsNotSpam({ threadId: thread.id }, mutationMetadata));
    }
  };

  const hotkeyOptions: OptionsOrDependencyArray = {
    enabled: isFocusedScreen && shouldShow,
    preventDefault: true,
    enableOnFormTags: true,
  };
  useHotkeys('e', removeSelectedFromInbox, hotkeyOptions);
  useHotkeys('shift+i', markSelectedAsRead, hotkeyOptions);
  useHotkeys('shift+u', markSelectedAsUnread, hotkeyOptions);
  useHotkeys('shift+1', markSelectedAsSpamOrNotSpam, hotkeyOptions);
  useHotkeys('Escape', clearSelected, hotkeyOptions);

  return (
    <AnimatePresence>
      {shouldShow && (
        <SelectedThreadsMenuAnimated borderRadius={borderRadius}>
          {hasNotSpamSelected && (
            <ProvideCommands id="mark-as-spam">
              <CommandItem
                icon={{ name: 'report-gmailerrorred', collection: MaterialIcons }}
                shortcut="!"
                onSelect={() => {
                  closeCommandMenu();
                  void markSelectedAsSpamOrNotSpam();
                }}
                value="Mark as spam"
              >
                Mark as spam
              </CommandItem>
            </ProvideCommands>
          )}
          <Box
            bgColor="primary.700"
            py={1.5}
            pr={6}
            pl={4}
            borderRadius={borderRadius}
            style={customShadow}
          >
            <HStack alignItems="center">
              <Box minW="5em" alignItems="center" pb={0.5}>
                <Text color="white" fontWeight="600">
                  {`${numberOfThreadsSelected} ${pluralThreads}`}
                </Text>
              </Box>
              <ThreadListActionButton
                key="remove-from-inbox"
                provideCommandId="remove-from-inbox"
                hint="Remove from inbox"
                shortcut="e"
                iconCollection={MaterialCommunityIcons}
                iconName="archive-arrow-down-outline"
                onPress={removeSelectedFromInbox}
                disabled={!hasInInboxSelected}
              />
              {null}
              <ThreadListActionButton
                key="move-to-inbox"
                provideCommandId="move-to-inbox"
                hint="Move to inbox"
                iconName="move-to-inbox"
                onPress={addSelectedToInbox}
              />
              {hasUnreadSelected ? (
                <ThreadListActionButton
                  key="mark-as-read"
                  provideCommandId="mark-as-read"
                  hint="Mark as read"
                  shortcut="shift+i"
                  iconCollection={MaterialCommunityIcons}
                  iconName="email-open-outline"
                  onPress={markSelectedAsRead}
                />
              ) : (
                <ThreadListActionButton
                  key="mark-as-unread"
                  provideCommandId="mark-as-unread"
                  hint="Mark as unread"
                  shortcut="shift+u"
                  iconCollection={MaterialCommunityIcons}
                  iconName="email-outline"
                  onPress={markSelectedAsUnread}
                />
              )}
              <ThreadListActionButton
                shortcut="Escape"
                hint="Close"
                // iconCollection={MaterialIcons}
                iconName="close"
                onPress={clearSelected}
              />
            </HStack>
          </Box>
        </SelectedThreadsMenuAnimated>
      )}
    </AnimatePresence>
  );
}

const speed = 70;

function SelectedThreadsMenuAnimated({
  children,
  borderRadius,
}: {
  children: React.ReactNode;
  borderRadius: string;
}) {
  const [present, safeToUnmount] = usePresence();
  const [opacity] = useState(() => new Animated.Value(0));
  const [transform] = useState(() => new Animated.Value(0));
  const unmount = useRef(safeToUnmount);
  // eslint-disable-next-line no-return-assign
  useEffect(() => void (unmount.current = safeToUnmount));
  useEffect(() => {
    if (present) {
      Animated.spring(opacity, {
        speed: speed * 1.2, toValue: 1, bounciness: 0, useNativeDriver: false,
      }).start();
      Animated.spring(transform, {
        speed, toValue: 1, bounciness: 10, useNativeDriver: false,
      }).start();
    } else {
      const callback: Animated.EndCallback = ({ finished }) => finished && unmount.current?.();
      Animated.spring(opacity, {
        speed: speed * 1.2, toValue: 0, bounciness: 0, useNativeDriver: false
      }).start(callback);
      Animated.spring(transform, {
        speed, toValue: 0, bounciness: 10, useNativeDriver: false
      }).start();
    }
  }, [opacity, present, transform]);
  const borderRadiusToken = useToken('radii', borderRadius);
  return (
    <View
      position="absolute"
      bottom={4}
      w="full"
      flexDirection="column"
      alignItems="center"
      pointerEvents={present ? 'box-none' : 'none'}
    >
      <Animated.View
        style={{
          opacity,
          transform: [{
            translateY: transform.interpolate({
              inputRange: [0, 1],
              outputRange: [8, 0],
            }),
          }, {
            scale: transform.interpolate({
              inputRange: [0, 1],
              outputRange: [0.95, 1],
            })
          }],
          borderRadius: borderRadiusToken,
        }}
      >
        {children}
      </Animated.View>
    </View>
  );
}
