import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import {
  Channel,
  CreateChannelApiArg,
  CreateChannelApiResponse,
  CreateThreadApiArg, CreateThreadApiResponse
} from '../api/codegen';
import { MutationHandler, MutationHandlerProps } from './MutationHandler';
import { ChannelsViewModel } from '../view-models/ChannelsViewModel';
import { createChannelViewModel } from '../view-models/ChannelViewModel';
import { createPictureViewModelFromUser } from '../view-models/PictureViewModel';

export class CreateChannelMutationHandler extends MutationHandler<CreateChannelApiArg, CreateChannelApiResponse> {
  constructor(props: MutationHandlerProps<CreateThreadApiArg, CreateThreadApiResponse>) {
    super(props);
  }

  protected createRequestCompletedPatchWrappers(_patch: CreateChannelApiArg, data: CreateChannelApiResponse, _patchId: string) {
    return [
      this.createGetChannelPatch(data),
      this.createGetChannelParticipantsPatch(data),
      this.createSelfChannelsPatch(data),
      this.createOrganizationChannelsPatch(data)
    ];
  }

  private createGetChannelPatch(data: Channel) {
    return this.upsertQueryData('getChannel', { channelId: data.id }, createChannelViewModel(data));
  }

  private createGetChannelParticipantsPatch(data: Channel) {
    return this.upsertQueryData('getChannelParticipants', { channelId: data.id }, [createPictureViewModelFromUser(this.getSelfUser()!.rawUser)]);
  }

  private createSelfChannelsPatch(newChannel: Channel) {
    return this.updateQueryData('getSelfChannels', undefined, (draft) => {
      this.insertChannelInList(newChannel, draft);
    });
  }

  private createOrganizationChannelsPatch(newChannel: Channel) {
    return this.updateQueryData('getOrganizationChannels', { organizationId: this.getSelfOrganization()?.id! }, (draft) => {
      this.insertChannelInList(newChannel, draft);
    });
  }

  private insertChannelInList(newChannel: Channel, draft: MaybeDrafted<ChannelsViewModel>) {
    const channelViewModel = createChannelViewModel(newChannel);

    let insertIndex = draft.channels.findIndex(
      (c) => c.name.localeCompare(newChannel.name) > 0
    );
    if (insertIndex === -1) {
      insertIndex = draft.channels.findIndex(
        (c) => c.folderType === 'all'
      );
    }

    const index = insertIndex !== -1 ? insertIndex : draft.channels.length;
    draft.channels.splice(index, 0, channelViewModel);
  }

  protected generateInvalidationTags(_arg: CreateChannelApiArg) {
    return [{ type: 'SelfChannels' as const }, { type: 'OrganizationChannels' as const }];
  }
}
