import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { buildMoveToInboxMutationDescription } from '../../UndoMutationDescriptions';
import { useWithMutationMetadata } from '../../../../adapters/mutation-cancellation/mutationCancellation';
import { useAddThreadToInboxMutation } from '../../../../adapters/api/codegen';
import { ThreadViewModel } from '../../../../adapters/view-models/ThreadViewModel';

export function useMoveToInboxCallback(shouldGoBack = false) {
  const navigation = useNavigation();

  const [moveToInbox] = useWithMutationMetadata(useAddThreadToInboxMutation());
  const moveToInboxCallback = useCallback(async (thread: ThreadViewModel) => {
    await moveToInbox({ threadId: thread.id }, {
      initiator: 'user',
      description: buildMoveToInboxMutationDescription(),
    });

    if (shouldGoBack) {
      navigation.goBack();
    }
  }, [moveToInbox, navigation, shouldGoBack]);
  return moveToInboxCallback;
}
