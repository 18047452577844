import { IToastProps, Toast } from 'native-base';

export type Discriminant = string;
export type ToastId = number;
export type CloseToastCallback = () => void;

function waitFor(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/*
* The NativeBase Toast component has some race conditions preventing it from correctly closing and opening toasts at
* the same time. This service is a workaround to ensure that only one toast is shown at a time. You'll see some ugly
* setTimeouts in here, but they are necessary for the toasts to work correctly.
* */

export class SingleToastService {
  private static idGenerator: ToastId = 1;
  private static currentToastIds: Map<Discriminant, ToastId> = new Map();

  public static async show(discriminant: Discriminant, props: Omit<IToastProps, 'id' | 'onCloseComplete'>) {
    await this.close(discriminant);
    if (this.currentToastIds.get(discriminant)) {
      await waitFor(10);
      await this.show(discriminant, props);
      return;
    }

    const toastId = this.idGenerator++;
    Toast.show({
      ...props,
      id: toastId,
      onCloseComplete: () => {
        this.currentToastIds.delete(discriminant);
      }
    });

    this.currentToastIds.set(discriminant, toastId);
  }

  public static async close(discriminant: Discriminant) {
    const toastId = this.currentToastIds.get(discriminant);
    if (!toastId) {
      return;
    }

    // eslint-disable-next-line no-constant-condition
    while (true) {
      Toast.close(toastId);
      if (Toast.isActive(toastId)) {
        await waitFor(10);
      } else {
        break;
      }
    }
  }
}
