import { v4 as uuidv4 } from 'uuid';
import { useCallback, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { StackActions, useNavigation } from '@react-navigation/native';
import { CreateThreadApiArg, useCreateThreadMutation } from '../../../adapters/api/codegen';
import { ChannelViewModel } from '../../../adapters/view-models/ChannelViewModel';
import { UserViewModel } from '../../../adapters/view-models/UserViewModel';
import { AppScreenStackScreenProps } from '../../navigation/navigators/app/AppScreenProps';
import { ComposeScreenSection } from '../../ui/screen-sections/ComposeScreenSection';
import { ComposeNewExternalParticipantViewModel, ParticipantViewModel } from '../../../adapters/view-models/ParticipantViewModel';
import { ComposeAreaSubmitCallback } from '../../ui/compose';

type Recipient = UserViewModel | ChannelViewModel | ComposeNewExternalParticipantViewModel;

export function ComposeScreenSectionController(props : AppScreenStackScreenProps<'Compose'>) {
  const [selectedRecipients, setSelectedRecipients] = useState<ParticipantViewModel[]>([]);
  const createThreadCallback = useCreateThreadCallback(selectedRecipients as Recipient[], props.navigation);

  const navigation = useNavigation();
  useHotkeys('Escape', () => {
    if (navigation.canGoBack()) navigation.goBack();
    else navigation.dispatch(StackActions.replace('Inbox'));
  }, [navigation]);

  return (
    <ComposeScreenSection
      submitCallback={createThreadCallback}
      selectedParticipantsListChangedCallback={setSelectedRecipients}
      maxNumberOfChannelSuggestions={3}
      maxNumberOfUserSuggestions={3}
    />
  );
}

function useCreateThreadCallback(recipients: Recipient[], navigation: AppScreenStackScreenProps<'Compose'>['navigation']) {
  const [createThreadQuery] = useCreateThreadMutation();

  const createThreadCallback: ComposeAreaSubmitCallback = useCallback(async ({ body, title }) => {
    const participantIds = recipients.filter((recipient) => 'displayName' in recipient && !('external' in recipient)).map((recipient) => recipient.id);
    const externalParticipantEmails = recipients.filter((recipient) => 'email' in recipient && 'external' in recipient).map((recipient) => (recipient as ComposeNewExternalParticipantViewModel).email);
    const channelIds = recipients.filter((recipient) => !participantIds.includes(recipient.id) && !externalParticipantEmails.includes(recipient.id)).map((recipient) => recipient.id);

    const threadId = uuidv4();
    const threadRequest: CreateThreadApiArg = {
      threadCreateRequestBody: {
        thread_id: threadId,
        message_id: uuidv4(),
        message_body: body,
        thread_title: title || undefined,
        channel_ids: channelIds,
        participant_ids: participantIds,
        external_participant_emails: externalParticipantEmails,
      }
    };

    const createThreadPromise = createThreadQuery(threadRequest);
    navigation.navigate('Thread', { threadId, from: 'compose' });
    return await createThreadPromise;
  }, [createThreadQuery, navigation, recipients]);

  return createThreadCallback;
}
