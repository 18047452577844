import { useEffect, useMemo } from 'react';
import { usePrefetchThreadCallback } from '../controllers/hooks/channelScreenHooks';
import { useDeepMemo } from '../controllers/hooks/useDeepMemo';
import { ThreadViewModel } from '../../adapters/view-models/ThreadViewModel';

const TIME_SINCE_LAST_ACTIVITY_FOR_BEING_FRESH = 1000 * 60 * 60 * 12;

export function usePrefetchFreshThreads(threads: ThreadViewModel[] | undefined) {
  const prefetchThread = usePrefetchThreadCallback();

  const threadsToPrefetch = useMemo(() => threads?.filter((thread) => {
    const timeSinceLastActivity = Date.now() - new Date(thread.lastActivityDate).getTime();
    const isFresh = timeSinceLastActivity < TIME_SINCE_LAST_ACTIVITY_FOR_BEING_FRESH;
    return thread.isUnread && isFresh;
  }).slice(0, 5) ?? [], [threads]);

  const idsOfThreadsToPrefetch = useDeepMemo(threadsToPrefetch.map((thread) => thread.id).sort());

  useEffect(() => {
    threadsToPrefetch.forEach((thread) => {
      void prefetchThread(thread);
    });
  }, [idsOfThreadsToPrefetch, prefetchThread, threadsToPrefetch]);
}
