import { Box } from 'native-base';
import { ReactNode } from 'react';

/**
 * Allows the time picker to be unfocused when the
 * user clicks outside of it.
 */
export function FocusableArea({ children }: { children: ReactNode; }) {
  return (
    <Box focusable outlineColor="transparent">
      {children}
    </Box>
  );
}
