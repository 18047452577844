// @ts-ignore
import readDate from 'date.js';

export function determineDateFromPrompt(datePrompt: string, adjustTime = false) {
  try {
    const isTimePrompt = /^\d{1,4}$/.test(datePrompt);
    if (!isTimePrompt) {
      const absoluteDate = new Date(datePrompt);

      if (absoluteDate instanceof Date && !Number.isNaN(absoluteDate.getTime())) {
        adjustDateTime(absoluteDate, datePrompt);

        return absoluteDate;
      }
    }

    const relativeDate = readDate(datePrompt) as Date | undefined;
    if (relativeDate instanceof Date && relativeDate.getTime() > 0 && relativeDate.getTime() > new Date().getTime()) {
      if (adjustTime) {
        adjustDateTime(relativeDate, datePrompt);
      }

      return relativeDate;
    }
  } catch {
    return undefined;
  }
}

function adjustDateTime(date: Date, prompt: string) {
  const isMidnight = date.getHours() === 0 && date.getMinutes() === 0;
  const containsMidnightInPrompt = /midnight/.test(prompt) || /12:00 am/.test(prompt) || /00:00/.test(prompt) || /0:00/.test(prompt);

  if (isMidnight && !containsMidnightInPrompt) {
    date.setHours(8, 0, 0, 0);
  }
}
