import { Box } from 'native-base';
import React from 'react';
import { EmojiPicker } from '..';
import { Emoji } from './Emoji';

type EmojiPopoverContentProps = {
  emojiSelectedCallback: (emoji: Emoji) => void;
};

export function EmojiPopoverContent(props: EmojiPopoverContentProps) {
  return (
    <Box width="330px" height="416px" flexDirection="row" justifyContent="center" alignItems="center" m={1}>
      <EmojiPicker onEmojiSelect={props.emojiSelectedCallback} theme="light" autoFocus />
    </Box>
  );
}
