import {
  Box,
  Text
} from 'native-base';
import { ComprehensiveReactionsInboxItemViewModel } from '../../../../adapters/view-models/InboxItemViewModel';
import { VERTICAL_PADDING } from '../constants';
import { useThreadListStoreSelector } from '../../thread-list/context';

type ThreadReactionsProps = {
  inboxItem: ComprehensiveReactionsInboxItemViewModel;
};

export function ThreadReactionsBlock({ inboxItem }: ThreadReactionsProps) {
  const threadId = inboxItem.thread.id;
  const isFocusedOrSelected = useThreadListStoreSelector((state) => {
    return state.focusedId === threadId || state.selectedIds.includes(threadId);
  });
  return (
    <>
      <Box
        bgColor={isFocusedOrSelected ? 'primary.100' : 'muted.100'}
        borderRadius={4}
        mr={3}
        px={1}
        py={0.5}
      >
        <Text fontSize="sm" letterSpacing={1}>
          {inboxItem.reactionSymbols}
        </Text>
      </Box>

      <Text
        fontSize="sm"
        numberOfLines={1}
        selectable={false}
        color="dark.900"
        flexShrink={1}
        py={VERTICAL_PADDING}
        isTruncated
      >
        {inboxItem.reactionText}
      </Text>
    </>
  );
}
