import { GetSelfSpamThreadsApiArg } from '../api/codegen';
import { CacheTagProvider } from './CacheTagProvider';
import { ThreadsViewModel } from '../view-models/ThreadsViewModel';

export class GetSpamThreadsCacheTagProvider extends CacheTagProvider<GetSelfSpamThreadsApiArg, ThreadsViewModel> {
  constructor(private readonly getThreadMessagesCacheTagProvider: CacheTagProvider<any, any>) {
    super();
  }

  protected generateTags(arg: GetSelfSpamThreadsApiArg, result: ThreadsViewModel) {
    return [
      { type: 'SelfSpamThreadsList' as const },
      ...this.getThreadMessagesCacheTagProvider.provideTags(arg, result),
    ];
  }
}
