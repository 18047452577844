import { Input } from 'native-base';
import {
  ComponentPropsWithoutRef, ForwardedRef, forwardRef, useImperativeHandle, useRef, useState,
} from 'react';
import { TextInput } from 'react-native';
import { mergeEventHandlers } from '../global/mergeEventHandlers';

type InputProps = ComponentPropsWithoutRef<typeof Input>;

type InputUncontrolledProps = Omit<InputProps, 'value'>;

export interface InputUncontrolledHandle {
  get value(): string;
  set value(value: string);
  setValue(value: string): void;
  focus(): void;
  blur(): void;
}

function InputUncontrolled({ defaultValue, ...props }: InputUncontrolledProps, ref: ForwardedRef<InputUncontrolledHandle>) {
  const [value, setValue] = useState(defaultValue || '');
  const valueRef = useRef<string>(value);
  const inputRef = useRef<TextInput>(null);
  const updateValue = (text: string) => {
    valueRef.current = text;
    setValue(text);
  };
  useImperativeHandle(ref, (): InputUncontrolledHandle => ({
    get value() {
      return valueRef.current;
    },
    set value(value) {
      updateValue(value);
    },
    setValue: updateValue,
    focus: () => inputRef.current?.focus(),
    blur: () => inputRef.current?.blur(),
  }));
  return (
    <Input
      {...props}
      value={value}
      ref={inputRef}
      onChangeText={mergeEventHandlers(updateValue, props.onChangeText)}
    />
  );
}

const InputUncontrolledRef = forwardRef(InputUncontrolled);

export { InputUncontrolledRef as InputUncontrolled };
