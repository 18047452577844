import { OrganizationsViewModel } from '../../adapters/view-models/OrganizationsViewModel';
import { useGetSelfAccount } from '../controllers/hooks/api/useGetSelfAccount';

export const useHasEmailSync = (selfOrganizations?: OrganizationsViewModel) => {
  const { currentData: selfAccount } = useGetSelfAccount();

  const shouldHideEmailFeatures = (selfOrganizations?.organizations ?? []).some((organization) => organization.features.includes('NO_EMAILS')) || !selfAccount?.hasEmailSyncEnabled;

  return { shouldHideEmailFeatures };
};
